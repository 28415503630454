<template>
    <div class="txdialog">
        <div class="info-sec">
            <div style="display:flex;">
                <div class="number">1</div>
                <div class="info-title">请按照以下信息开具对应发票</div>
            </div>
            <div class="info-item">
                <div class="item-name">发票金额：</div>
                <div class="item-value">{{ afterTaxAmount }}</div>
            </div>
            <div class="info-item">
                <div class="item-name">发票类型：</div>
                <div class="item-value" v-if="radioType != 2"><span>对公-</span>{{ radioType == 3 ? 1 : radioType == 4 ? 3 : radioType == 5 ? 6 :  '' }}%类型专票</div>
                <div class="item-value" v-else><span>对公-</span>普通发票</div>
            </div>
            <div class="item-space"></div>
            <div class="info-item">
                <div class="item-name">发票抬头：</div>
                <div class="item-value">上海中彦信息科技有限公司</div>
            </div>
            <div class="info-item">
                <div class="item-name"><span>税</span><span>号：</span></div>
                <div class="item-value">913102306678110986</div>
            </div>
            <div class="info-item">
                <div class="item-name"><span>地</span><span>址：</span></div>
                <div class="item-value">上海市崇明区北沿公路2111号25幢106室-2<br />（崇明森林旅游园区）</div>
            </div>
            <div class="info-item">
                <div class="item-name"><span>电</span><span>话：</span></div>
                <div class="item-value">80231199</div>
            </div>
            <div class="info-item">
                <div class="item-name"><span>开</span><span>户</span><span>行：</span></div>
                <div class="item-value">上海银行张江支行</div>
            </div>
            <div class="info-item">
                <div class="item-name">银行账号：</div>
                <div class="item-value">03003632635</div>
            </div>
            <div class="info-item">
                <div class="item-name">开票内容：</div>
                <div class="item-value">*信息技术服务*信息服务费</div>
            </div>
            <div class="tip"><span></span>仅接收纸质发票；未完成的提现申请会在20个工作日后自动关闭，<br/>请尽快开票</div>
        </div>
        <div class="info-sec">
            <div style="display:flex;">
                <div class="number">2</div>
                <div class="info-title">并完成票号、代码等信息填写</div>
            </div>
            <a-button type="primary" class="btn-2" @click="closeDialogStepThree('ok')">已开好发票，直接填写</a-button>
        </div>
        <a-button class="btn" @click="closeDialogStepThree">关闭</a-button>
    </div>
</template>

<script>

export default {
    props: {
        radioType: {},
        amount: {
            default: ""
        },
        afterTaxAmount: {
            default: ""
        }
    },
    data () {
        return {

        }
    },
    methods: {
        closeDialogStepThree (data) {
            if (data === "ok") {
                this.$emit("closeDialogStepThree", { next: data, open: false })
            } else if (data === 'upstep') {
                this.$emit("closeDialogStepThree", { next: data, radioType: this.radioType, amount: this.amount, open: false })
            } else {
                this.$emit("closeDialogStepThree", { radioType: null, open: false })
            }
        },
    },
    mounted () {

    },
    destroyed () {
        
    }
}
</script>

<style lang="less" scoped>
.txdialog{
    height:570px;
}
.number {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    color: #fff;
    line-height: 28px;
    text-align: center;
    font-size: 20px;
    background: #4392F6;
    margin-right: 16px;
}
.info-sec {
    margin-bottom: 24px;
    .info-title {
        color: #333;
        font-size: 20px;
        font-weight: bold;
        margin-bottom:24px;
    }
    .tip {
        color: #FF4141;
        font-size: 14px;
        margin-left: 44px;
        span{
            display:inline-block;
            width:16px;
            height:16px;
            background:url(../images/warning.png) no-repeat;
            background-size:100%;
            margin-right:4px;
            vertical-align:-3px;
        }
    }
    .info-item {
        color: #333;
        font-size: 16px;
        line-height:24px;
        display: flex;
        margin-left: 44px;
        .item-value{
            span{
                color:#999;
            }
        }
        .item-name{
            display:flex;
            width:80px;
            justify-content: space-between;
            span{
                display:block;
            }
        }
    }
    .item-space{
        height:24px;
    }
}
.tip {
    color: #FF4141;
    font-size: 14px;
    margin: 12px 0;
}
.btn {
    width: 200px;
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    border-radius: 6px;
    position: absolute;
    right: 36px;
    bottom: 36px;
    color:#4392F6;
    border: 1px solid #4392F6;
    &.btn-up-step {
        bottom: 108px;
        color: #fff;
    }
}
.btn-2 {
    width: 200px;
    height: 46px;
    border-radius: 6px;
    margin-left: 44px;
}
</style>
<template>
    <div class="txdialog">
        <div class="info-sec" style="margin-top:0">
            <div class="info-title">请将您的发票寄给我们</div>
            <div class="info-item">我们将在发票寄出1周内完成审核并打款</div>
        </div>
        <div class="info-sec">
            <div class="info-title">发票寄到以下地址：</div>
            <div id="J_copy_data">
                <div class="info-item">
                    <div class="item-name">地址：</div>
                    <div class="item-value">上海市徐汇区龙漕路200弄乙字一号返利网</div>
                </div>
                <div class="info-item">
                    <div class="item-name">收件人：</div>
                    <div class="item-value">满天星事业部</div>
                </div>
                <div class="info-item">
                    <div class="item-name">电话：</div>
                    <div class="item-value">18117422167</div>
                </div>
            </div>
        </div>
        <a href="javascript:void(0);" class="copy" id="J_copy" data-clipboard-target="#J_copy_data">复制寄件信息</a>
        <div class="tip">自提交提现申请后15日未收到发票，将取消提现申请</div>
        <a-button class="btn" type="primary" @click="closeDialogStepTwo">完成</a-button>
    </div>
</template>

<script>
import ClipboardJS from 'clipboard'
export default {
    data () {
        return {
            ClipboardJSObj: null
        }
    },
    methods: {
        initCopy () {
            const that = this
            //在组件mounted钩子函数中实例化clipboard对象
            that.ClipboardJSObj = new ClipboardJS("#J_copy") //className中有copyEle的元素
            //定义复制成功回调
            that.ClipboardJSObj.on("success", e => {
                that.$message.success("复制成功")
                e.clearSelection();
            })
            that.ClipboardJSObj.on("error", e => {
                that.$message.error("复制失败，请重新复制")
            })
        },
        closeDialogStepTwo () {
            this.$emit("closeDialogStepTwo", false)
        }
    },
    mounted () {
        this.initCopy()
    },
    destroyed () {
        this.ClipboardJSObj.destroy()
    }
}
</script>

<style lang="less" scoped>
.info-sec {
    margin-top: 40px;
    .info-title {
        color: #333;
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .info-item {
        color: #666;
        font-size: 16px;
        display: flex;
    }
}
.copy {
    display: block;
    color: #4392F6;
    font-size: 16px;
    margin: 10px 0;
}
.tip {
    color: #FF4141;
    font-size: 14px;
}
.btn {
    width: 200px;
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    border-radius: 6px;
    position: absolute;
    right: 36px;
    bottom: 36px;
}
</style>

<template>
    <div>
        <!-- 提交成功 -->
        <div class="txdialog" v-if="radioType == 1">
            <div class="dialog-title">
                <img src="../../../images/success-icon.jpeg" class="status-icon" alt="">
                <div class="status-text">提交成功</div>
            </div>
            <div class="dialog-sub-title">提现金额预计3个工作日内到账</div>
            <a-button class="btn" type="primary" @click="closeDialogStepFour">完成</a-button>
        </div>
        <!-- 提现成功 1 -->
        <div class="txdialog" v-if="withDrawStatus == 4 && radioType != 1">
            <div class="dialog-title">
                <img src="../../../images/success-icon.jpeg" class="status-icon" alt="">
                <div class="status-text">提现成功</div>
            </div>
            <div class="dialog-sub-title">税后提现金额 {{ afterTaxAmount }} 元</div>
            <div class="info-sec">
                <div class="info-title">发票信息</div>
                <div class="info-item">
                    <div class="item-name">发票抬头：</div>
                    <div class="item-value">{{ invoiceInfo.title }}</div>
                </div>
                <div class="info-item">
                    <div class="item-name">发票号码：</div>
                    <div class="item-value">{{ invoiceInfo.number }}</div>
                </div>
                <div class="info-item">
                    <div class="item-name">发票代码：</div>
                    <div class="item-value">{{ invoiceInfo.code }}</div>
                </div>
                <div class="info-item">
                    <div class="item-name">开票日期：</div>
                    <div class="item-value">{{ invoiceInfo.date }}</div>
                </div>
            </div>
            <div class="info-sec">
                <div class="info-title">提现账户：</div>
                <div class="info-item">
                    <div class="item-name">公司名称：</div>
                    <div class="item-value">{{ payee.company }}</div>
                </div>
                <div class="info-item">
                    <div class="item-name">银行账户：</div>
                    <div class="item-value">{{ payee.bankcard }}</div>
                </div>
                <div class="info-item">
                    <div class="item-name">户名：</div>
                    <div class="item-value">{{ payee.name }}</div>
                </div>
                <div class="info-item">
                    <div class="item-name">开户银行：</div>
                    <div class="item-value">{{ payee.bankname }}</div>
                </div>
            </div>
            <a-button class="btn" type="primary" @click="closeDialogStepFour">完成</a-button>
        </div>
        <!-- 提现成功 2 -->
        <div class="txdialog" v-if="withDrawStatus == 4 && radioType == 1">
            <div class="dialog-title">
                <img src="../../../images/success-icon.jpeg" class="status-icon" alt="">
                <div class="status-text">提现成功</div>
            </div>
            <div class="dialog-sub-title">税后提现金额 {{ afterTaxAmount }} 元</div>
            <div class="info-sec">
                <div class="info-title">提现账户：</div>
                <div class="info-item">
                    <div class="item-name">收款人：</div>
                    <div class="item-value">{{ payee.name }}</div>
                </div>
                <div class="info-item">
                    <div class="item-name">手机号：</div>
                    <div class="item-value">{{ payee.mobile }}</div>
                </div>
                <div class="info-item">
                    <div class="item-name">身份证号：</div>
                    <div class="item-value">{{ payee.idcard }}</div>
                </div>
                <div class="info-item">
                    <div class="item-name">收款银行卡：</div>
                    <div class="item-value">{{ payee.bankcard }}</div>
                </div>
            </div>
            <a-button class="btn" type="primary" @click="closeDialogStepFour">完成</a-button>
        </div>
        <!-- 提现失败 1 -->
        <div class="txdialog" v-if="withDrawStatus == 5 && radioType != 1">
            <div class="dialog-title">
                <img src="../../../images/err-icon.jpeg" class="status-icon" alt="">
                <div class="status-text">提现失败</div>
            </div>
            <div class="dialog-sub-title">{{ faildReason == '过期退回'? '您尚未在规定时间内提交发票，请重新提交提现申请并按时开具发票' : faildReason != '其他' ? faildReason: faildReasonOther }}</div>
            <div class="info-sec" v-if="faildReason != '过期退回'">
                <div class="info-title">发票信息</div>
                <div class="info-item">
                    <div class="item-name">发票抬头：</div>
                    <div class="item-value">{{ invoiceInfo.title }}</div>
                </div>
                <div class="info-item">
                    <div class="item-name">发票号码：</div>
                    <div class="item-value">{{ invoiceInfo.number }}</div>
                </div>
                <div class="info-item">
                    <div class="item-name">发票代码：</div>
                    <div class="item-value">{{ invoiceInfo.code }}</div>
                </div>
                <div class="info-item">
                    <div class="item-name">开票日期：</div>
                    <div class="item-value">{{ invoiceInfo.date }}</div>
                </div>
            </div>
            <div class="info-sec" v-if="faildReason != '过期退回'">
                <div class="info-title">提现账户：</div>
                <div class="info-item">
                    <div class="item-name">公司名称：</div>
                    <div class="item-value">{{ payee.company }}</div>
                </div>
                <div class="info-item">
                    <div class="item-name">银行账户：</div>
                    <div class="item-value">{{ payee.bankcard }}</div>
                </div>
                <div class="info-item">
                    <div class="item-name">户名：</div>
                    <div class="item-value">{{ payee.name }}</div>
                </div>
                <div class="info-item">
                    <div class="item-name">开户银行：</div>
                    <div class="item-value">{{ payee.bankname }}</div>
                </div>
            </div>
            <a-button class="btn" type="primary" @click="closeDialogStepFour">完成</a-button>
        </div>
        <!-- 提现失败 2 -->
        <div class="txdialog" v-if="withDrawStatus == 5 && radioType == 1">
            <div class="dialog-title">
                <img src="../../../images/err-icon.jpeg" class="status-icon" alt="">
                <div class="status-text">提现失败</div>
            </div>
            <div class="dialog-sub-title">{{ faildReason == '过期退回'? '您尚未在规定时间内提交发票，请重新提交提现申请并按时开具发票' : faildReason != '其他' ? faildReason: faildReasonOther }}</div>
            <div class="info-sec" v-if="faildReason != '过期退回'">
                <div class="info-title">提现账户：</div>
                <div class="info-item">
                    <div class="item-name">收款人：</div>
                    <div class="item-value">{{ payee.name }}</div>
                </div>
                <div class="info-item">
                    <div class="item-name">手机号：</div>
                    <div class="item-value">{{ payee.mobile }}</div>
                </div>
                <div class="info-item">
                    <div class="item-name">身份证：</div>
                    <div class="item-value">{{ payee.idcard }}</div>
                </div>
                <div class="info-item">
                    <div class="item-name">收款银行卡：</div>
                    <div class="item-value">{{ payee.bankcard }}</div>
                </div>
            </div>
            <a-button class="btn" type="primary" @click="closeDialogStepFour">完成</a-button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        radioType: {
            type: Number,
            default: null
        },
        afterTaxAmount: {
            type: Number,
            default: null
        },
        withDrawStatus: {},
        invoiceInfo: {
            type: Object,
            default: () => {}
        },
        payee: {
            type: Object,
            default: () => {}
        },
        faildReason: {
            type: String,
            default: ""
        },
        faildReasonOther: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            submitSuccess: false,
            cashSuccessOne: true,
            cashSuccessTwo: false,
            cashErrOne: false,
            cashErrTwo: false
        }
    },
    methods: {
        closeDialogStepFour () {
            this.$emit("closeDialogStepFour", { open: false, status: null })
        }
    }
}
</script>

<style lang="less" scoped>
.dialog-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.dialog-sub-title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
}
.status-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 10px;

}
.status-text {
    font-size: 28px;
    color: #333333;
}
.btn {
    width: 200px;
    height: 60px;
    line-height: 60px;
    font-size: 20px;
    border-radius: 6px;
    position: absolute;
    right: 36px;
    bottom: 36px;
}
.info-sec {
    margin-top: 40px;
    .info-title {
        color: #333;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .info-item {
        color: #666;
        font-size: 16px;
        display: flex;
    }
}
</style>
<template>
  <div class="txdialog">
    <div class="dia-left">
      <div v-if="radioType != 1" class="invoice-box">
        <div class="fapiao">
          <div class="title">请填写您开具的发票信息<span>请准确填写，填写确认后将不可以修改</span></div>
          <div class="input-item">
            <div class="item-name">发票抬头：</div>
            <a-input v-if="!invoiceTitle" placeholder="请准确填写，确认后将不可修改" v-model="faPiaoForm.head"></a-input>
            <div v-else class="text">{{ invoiceTitle }}</div>
          </div>
          <div class="input-item">
            <div class="item-name">发票号码：</div>
            <a-input placeholder="请输入发票号码" v-model="faPiaoForm.num"></a-input>
          </div>
          <div class="input-item">
            <div class="item-name">发票代码：</div>
            <a-input placeholder="请输入发票代码" v-model="faPiaoForm.code"></a-input>
          </div>
          <div class="input-item">
            <div class="item-name">开票日期：</div>
            <a-date-picker placeholder="请选择日期" dropdownClassName="ant-calendar-customize" @change="pickerChange" :disabledDate="disabledDate"/>
          </div>
        </div>

        <div class="account">
          <div class="title">提现至<span>请准确填写，填写确认后将不可以修改</span></div>
          <div :class="{'input-item':true, 'value':!!payee.company}">
            <div class="item-name">公司名称：</div>
            <a-input v-if="!payee.company" v-model="faPiaoForm.company" ></a-input>
            <div v-else class="text">{{ payee.company }}</div>
          </div>
          <div :class="{'input-item':true, 'value':!!payee.bankcard}">
            <div class="item-name">银行账户：</div>
            <a-input v-if="!payee.bankcard" :maxLength="25" oninput="value=value.replace(/[^\d]/g,'')" v-model="faPiaoForm.bankCard" ></a-input>
            <div v-else class="text">{{ payee.bankcard }}</div>
          </div>
          <div :class="{'input-item':true, 'value':!!payee.name}">
            <div class="item-name">户名：</div>
            <a-input v-if="!payee.name" v-model="faPiaoForm.name" placeholder="该户名必须是您的公司名称"></a-input>
            <div v-else class="text">{{ payee.name }}</div>
          </div>
          <div :class="{'input-item':true, 'value':!!payee.bankname}">
            <div class="item-name">开户银行：</div>
            <a-input v-if="!payee.bankname" v-model="faPiaoForm.bankName" ></a-input>
            <div v-else class="text">{{ payee.bankname }}</div>
          </div>
        </div>
      </div>
      <div v-else class="personal-box">
        <div class="title">将¥{{ amount }}提现到</div>
        <div class="tip">填写的信息必须与购买满天星时信息一致</div>
        <div class="input-item">
            <div class="item-name">收款人姓名：</div>
            <a-input v-if="!payee.name" v-model="personalForm.name" placeholder="请输入收款人姓名"></a-input>
            <div v-else class="text">{{ payee.name }}</div>
          </div>
          <div class="input-item">
            <div class="item-name">手机号：</div>
            <a-input v-if="!payee.mobile" :maxLength="11" v-model="personalForm.mobile" oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入手机号"></a-input>
            <div v-else class="text">{{ payee.mobile }}</div>
          </div>
          <div class="input-item">
            <div class="item-name">身份证：</div>
            <a-input v-if="!payee.idcard" :maxLength="18" v-model="personalForm.IDCard" placeholder="请输入身份证"></a-input>
            <div v-else class="text">{{ payee.idcard }}</div>
          </div>
          <div class="input-item">
            <div class="item-name">收款银行卡：</div>
            <a-input v-if="!payee.bankcard" :maxLength="25" oninput="value=value.replace(/[^\d]/g,'')" v-model="personalForm.bankCard" placeholder="请输入收款银行卡"></a-input>
            <div v-else class="text">{{ payee.bankcard }}</div>
          </div>
      </div>
    </div>
    <div class="dia-right">
      <a href="javascript:void(0);" class="down" :class="{o: (radioType == 1 && !personFormStatus) || (!faPiaoFormStatus && radioType != 1)}" @click="submitHander">提交</a>
      <a href="javascript:void(0);" class="close" @click="closeDialogStepFive">关闭</a>
    </div>
  </div>
</template>

<script>
import { withDrawalPost } from '@/api/income'
import moment from 'moment';
export default {
  props: {
    radioType: {},
    isFirstTx: {
      type: Boolean,
      default: false
    },
    payee: {
      type: Object,
      default: () => {}
    },
    afterTaxAmount: {},
    amount: {},
    // invoiceTitle: {
    //   type: String,
    //   default: "上海中彦信息科技有限公司"
    // },
    withDrawId: {
      default: ""
    }
  },
  data () {
    return {
      personFormStatus: false,
      faPiaoFormStatus: false,
      personalForm: {
        name: "",
        mobile: "",
        IDCard: "",
        bankCard: ""
      },
      invoiceTitle:'上海中彦信息科技有限公司',
      faPiaoForm: {
        head: "上海中彦信息科技有限公司",
        num: "",
        code: "",
        date: "",
        company:"",
        bankCard: "",
        name: "",
        bankName: ""
      },
      isSendAjax: false,

    }
  },
  methods: {
    disabledDate (current){
      return current && current > moment().endOf('day');
    },
    submitHander () {
      if ((this.radioType == 1 && !this.personFormStatus) || (this.radioType != 1 && !this.faPiaoFormStatus)) return
      if (this.isSendAjax) return
      this.isSendAjax = true
      this.submitPost()
    },
    closeDialogStepFive (next) {
      if (next === "next") {
        this.$emit("closeDialogStepFive", { radioType: null, next: "next", open: false })
      } else if (next === "person") {
        this.$emit("closeDialogStepFive", { next: "person", open: false })
      } else {
        this.$emit("closeDialogStepFive", { radioType: null, open: false })
      }
    },
    pickerChange (dateString) {
      this.faPiaoForm.date = dateString
    },
    async submitPost () {
      const that = this
      const ajaxData = this.getPostData()
      const { status, info } = await withDrawalPost(ajaxData)
      if (status === 1) {
        if (that.radioType == 1) return this.closeDialogStepFive("person")
        if (that.radioType != 1) return this.closeDialogStepFive("next")
      } else {
        this.$message.error(info)
      }
      that.isSendAjax = false
    },
    getPostData () {
      let ajaxData = {
        payee_type: this.radioType,
        withdraw_id: this.withDrawId || "",
        withdraw: {
          amount: this.amount,
          after_tax_amount: this.afterTaxAmount
        },
        step:2
      }
      if (this.radioType == 1) {
        ajaxData = Object.assign(ajaxData, {
          payee: {
            name: this.personalForm.name ? this.personalForm.name : this.payee.name,
            mobile: this.personalForm.mobile ? this.personalForm.mobile : this.payee.mobile,
            idcard: this.personalForm.IDCard ? this.personalForm.IDCard : this.payee.idcard,
            bankcard: this.personalForm.bankCard ? this.personalForm.bankCard : this.payee.bankcard
          }
        })
      } else {
          ajaxData = Object.assign(ajaxData, {
            invoice: {
              code: this.faPiaoForm.code,
              number: this.faPiaoForm.num,
              date: this.faPiaoForm.date,
              title: this.faPiaoForm.head ? this.faPiaoForm.head : this.invoiceTitle
            },
            payee: {
              company: this.faPiaoForm.company ? this.faPiaoForm.company: this.payee.company,
              name: this.faPiaoForm.name ? this.faPiaoForm.name : this.payee.name,
              bankcard: this.faPiaoForm.bankCard ? this.faPiaoForm.bankCard : this.payee.bankcard,
              bankname: this.faPiaoForm.bankName ? this.faPiaoForm.bankName : this.payee.bankname
            }
          })
      }
      return ajaxData
    }
  },
  mounted () {

  },
  watch: {
    personalForm: {
      handler (newForm) {
        if (this.radioType == 1) {
          newForm.name = newForm.name ? newForm.name : this.payee.name
          newForm.mobile = newForm.mobile ? newForm.mobile : this.payee.mobile
          newForm.IDCard = newForm.IDCard ? newForm.IDCard : this.payee.idcard
          newForm.bankCard = newForm.bankCard ? newForm.bankCard : this.payee.bankcard
          if (!newForm.name || newForm.mobile.length !== 11 || newForm.IDCard.length !== 18 || newForm.bankCard.length < 10) {
            this.personFormStatus = false
          } else {
            this.personFormStatus = true
          }
        }
      },
      deep: true,
      immediate: true
    },
    faPiaoForm: {
      handler (newForm) {
        if (this.radioType > 1) {
          newForm.company = newForm.company ? newForm.company : this.payee.company
          newForm.head = newForm.head ? newForm.head : this.invoiceTitle
          newForm.name = newForm.name ? newForm.name : this.payee.name
          newForm.bankCard = newForm.bankCard ? newForm.bankCard : this.payee.bankcard
          newForm.bankName = newForm.bankName ? newForm.bankName : this.payee.bankname
          if (!newForm.company || !newForm.head || !newForm.num || !newForm.code || !newForm.date || !newForm.bankCard || !newForm.name || !newForm.bankName) {
            this.faPiaoFormStatus = false
          } else {
            this.faPiaoFormStatus = true
          }
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
.txdialog{
    height:570px;
}
.ant-calendar {
  width: 294px !important;
}
.txdialog {
  display: flex;
  .title {
    position: relative;
    display: flex;
    align-items: center;
    height: 28px;
    margin-bottom: 24px;
    font-size: 20px;
    color: #333;
    font-weight: 500;
    span {
      position:absolute;
      left:42px;
      top:3px;
      width:260px;
      margin-left: 30px;
      color: #FF4141;
      font-size: 14px;
      &:before{
        content:"";
        display:inline-block;
        width:16px;
        height:16px;
        background:url(../images/warning.png) no-repeat;
        background-size:100%;
        margin-right:2px;
        vertical-align:-3px;
      }
    }
  }
  .fapiao{
    .title{
      span{
        left:202px;
      }
    }
  }
  .account{
    .input-item{
      &.value{
        height:24px;
      }
    }
  }

  .dia-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    a {
      display: block;
      width: 200px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      border-radius: 6px;
      font-size: 20px;
      &.down {
        background: #4392F6;
        color: #fff;
        margin-bottom: 12px;
        &.o {
          opacity: 0.6;
        }
      }
      &.close {
        border: 1px solid #4392F6;
        color: #4392F6;
      }
    }
    .tips{
      line-height:24px;
      font-size:14px;
      margin-bottom:12px;
      color:#FF4141;
    }
  }
}
.input-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 398px;
  height: 36px;
  margin-bottom: 12px;
  .item-name {
    color: #333;
    font-size: 16px;
  }
  .ant-input {
    width: 294px;
    height: 36px;
    color: #333;
    font-size: 16px;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  .text {
    width: 294px;
    font-size: 16px;
    color: #333;
  }
}
.invoice-box {
  .fapiao {
    margin-bottom: 36px;
  }
}
.personal-box {
  .title {
    height: 39px;
    line-height: 39px;
    margin-bottom: 6px;
    font-size: 28px;
    color: #333;
  }
  .tip {
    height: 24px;
    line-height: 24px;
    margin-bottom: 24px;
    font-size: 14px;
    color: #FF4141;
  }
  .input-item {
    width: 414px;
  }
}
/deep/ .ant-calendar-picker {
  width: 294px;
  .ant-calendar-picker-input {
    height: 36px;
    color: #333;
    font-size: 16px;
  }
}
</style>

<template>
  <div v-if="item.id == transmitData.previewId">
    <template v-for="item in item.imgUrl">
      <div class="img-box" :key="item.id">
        <template v-if="transmitData.previewId == 3481">
          <img v-if="!transmitData.isOpenFanli && !transmitData.isOpenRemind" :src="transmitData.isElmSpecial ? item.urlWhite : item.url" alt="">
          <img v-if="transmitData.isOpenFanli && transmitData.isOpenRemind" :src="transmitData.isElmSpecial ? item.allUrlWhite : item.allUrl" alt="">
          <img v-if="transmitData.isOpenRemind && !transmitData.isOpenFanli" :src="transmitData.isElmSpecial ? item.remindUrlWhite : item.remindUrl" alt="">
          <img v-if="transmitData.isOpenFanli && !transmitData.isOpenRemind" :src="transmitData.isElmSpecial ? item.fanliUrlWhite : item.fanliUrl" alt="">
        </template>
        <template v-else-if="transmitData.previewId == 3483 || transmitData.previewId == 4007 || transmitData.previewId == 5735">
          <img v-if="transmitData.isOpenRemind" :src="transmitData.isElmSpecial ? item.remindUrlWhite : item.remindUrl" alt="">
          <img v-else :src="transmitData.isElmSpecial ? item.urlWhite : item.url" alt="">
        </template>
        <img v-else-if="transmitData.isElmSpecial" :src="item.urlWhite || item.url" alt="">
        <img v-else :src="item.url" alt="">
        <div v-if="transmitData.previewId == 3481 || transmitData.previewId == 3483 || transmitData.previewId == 5735" class="text">{{ item.title }}-{{ transmitData.isOpenRemind ? '有' : '无'}}会员</div>
        <div v-else class="text" v-html="item.title"></div>
      </div>
    </template>
    <slot name="arrow"></slot>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: (() => {})
    },
    transmitData: {
      type: Object,
      default: (() => {})
    }
  },
  mounted () {}
}
</script>

<style lang="less" scoped>
.img-box {
  width: 214px;
  height: 372px;
  text-align: center;
  margin: 0 24px 12px 0;
    &:last-child {
      margin-right: 0;
    }
  img {
    width: 214px;
    height: 372px;
    margin-bottom: 12px;
  }
  .text {
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    color: #666;
  }
}
.arrow {
  position: fixed;
  top: 210px;
  z-index: 22;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  &.arrow-left {
    left: 36px;
    background: url('./images/arrow-left.png') no-repeat;
    background-size: contain;
  }
  &.arrow-right {
    right: 293px;
    background: url('./images/arrow-right.png') no-repeat;
    background-size: contain;
  }
}
::-webkit-scrollbar {
  width: 0;
}
</style>

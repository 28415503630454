import axios from '@/utils/axios'

const API = {
  init: '/Miniprogrampath/ajaxInit'
}

function initPagePost () {
  return axios.post(API.init)
}

export {
  initPagePost
}
<template>
  <div class="toast">
    <div v-if="setStatus == 1 || setStatus == 2 || setStatus ==3" class="icon"></div>
    <div v-else class="icon fail"></div>
    <span>{{ setInfo ? setInfo:setStatus == 1 ? '选择成功' : setStatus == 2? '发布成功' : setStatus == 3 ? '复制成功' : '选择失败' }}</span>
  </div>
</template>

<script>
export default {
  props: {
    setStatus: {},
    setInfo:{
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 158px;
  padding:0 24px;
  height: 52px;
  border-radius: 9px;
  background: #000;
  opacity: 0.6;
  color: #fff;
  font-size: 20px;
  z-index: 1000;
  animation: show 2.1s linear;
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    background: url('./images/success.png') no-repeat;
    background-size: contain;
    &.fail {
      background: url('../../views/home/images/icon-close.png') no-repeat;
      background-size: contain;
    }
  }
  span {
    height: 28px;
    line-height: 28px;
  }
}
@keyframes show {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
const moduleData = {
  data () {
    return {
      list: [
        {
          id: 0,
          title: '小程序首页',
          subtitle: '集成今日红包、吃货电台、<br />好不好吃制造机等功能。',
          image: require('./images/cover-1.png')
        },
        {
          id: 1,
          title: '吃货电台',
          subtitle: '展示所有有趣好玩的<br />创意电台话题。',
          image: require('./images/cover-2.png')
        },
        {
          id: 2,
          title: '好不好吃制造机',
          subtitle: '记忆，需要照片加注释，<br />回味才更醇。',
          image: require('./images/cover-3.png')
        },
      ],
      previewList: [
        {
          id: 0,
          title: '小程序首页',
          subtitle1: '集成今日红包、吃货电台、',
          subtitle2: '好不好吃制造机等功能。',
          image: require('./images/preview-1.png'),
          appId: '',
          appPath: ''
        },
        {
          id: 1,
          title: '吃货电台',
          subtitle1: '展示所有有趣好玩的',
          subtitle2: '创意电台话题。',
          image: require('./images/preview-2.png'),
          appId: '',
          appPath: ''
        },
        {
          id: 2,
          title: '好不好吃制造机',
          subtitle1: '记忆，需要照片加注释，',
          subtitle2: '回味才更醇。',
          image: require('./images/preview-3.png'),
          appId: '',
          appPath: ''
        },
      ]
    }
  }
}

export default moduleData
<template>
  <div class="pay-success-page">
    <PageHeader :show-app-info="2" :auth-info="authInfo"></PageHeader>
    <div class="pay-success">
      <div class="icon"></div>
      <div class="text">预订成功{{month}}增长额度</div>
      <div class="sub-text">预计{{day}}完成加粉任务<br />开始推广后可在渠道管理【{{title}}】查看效果</div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/header/PageHeader"
import { initSalePagePost } from '@/api/paysuccess'

export default {
  components: {
    PageHeader,
  },
  data () {
    return {
      day:'',
      month:'',
      title:''
    }
  },
  methods: {
    async initPage () {
      const outOrderNo = this.$route.query.outOrderNo
      const { status, data, info } = await initSalePagePost({ outOrderNo })
      if (status === 1) {
        this.day = data.record.promote_date
        this.month = data.record.month
        this.title = data.record.product.title
      } else {
        this.$message.error(info)
      }
    }
  },
  created () {
    this.initPage()
  }
}
</script>

<style lang="less" scoped>
  .pay-success-page {
    position: relative;
    height: 100%;
    background: #F3F6FC;
  }
  .pay-success {
    margin: 47px auto 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 884px;
    height: 566px;
    border-radius: 12px;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px 0px #D2DBEE;
    padding-top: 180px;
    .icon {
      width: 90px;
      height: 90px;
      background: url("./images/success.png") no-repeat;
      background-size: contain;
      margin-bottom: 24px;
    }
    .text {
      height: 45px;
      line-height: 45px;
      text-align: center;
      color: #333847;
      font-size: 32px;
      margin-bottom: 24px;
    }
    .sub-text {
      line-height: 36px;
      text-align: center;
      color: #666;
      font-size: 20px;
    }
  }
</style>
<template>
  <div class="app-path">
    <contentHeader :is-show-tips="true" :is-show-switch="false" header-type="9"></contentHeader>
    <div class="content">
      <ModuleItem
        v-for="item in list"
        :key="item.id"
        :item="item" 
        @showPreviewDialog="showPreviewDialog"
      ></ModuleItem>
    </div>

    <PreviewDialog
      :is-show-preview-dialog="isShowPreviewDialog"
      :preview-item="previewItem"
      :click-id="clickId"
      @closePreviewDia="closePreviewDia"
    ></PreviewDialog>
  </div>
</template>

<script>
import contentHeader from '@/components/header/header'
import ModuleItem from './components/moduleItem'
import moduleData from './mixins'
import PreviewDialog from './components/previewDialog'
import { initPagePost } from '@/api/appPath'
export default {
  components: {
    contentHeader,
    ModuleItem,
    PreviewDialog
  },
  mixins: [moduleData],
  data () {
    return {
      isShowPreviewDialog: false,
      previewItem: {},
      clickId: null
    }
  },
  methods: {
    async initPage () {
      const { status, data } = await initPagePost()
      if (status == 1) {
        this.previewList.forEach((item, idx) => {
          item.appId = data.authorizerAppid
          data.pathList.forEach((ite, index) => {
            if (idx == index) {
              item.appPath = ite
            }
          })
        })
      }
    },
    showPreviewDialog (data) {
      this.clickId = data.id
      this.isShowPreviewDialog = data.isShow
      this.previewList.forEach(item => {
        if (item.id == this.clickId) {
          this.previewItem = item
        }
      });
    },
    closePreviewDia (data) {
      this.isShowPreviewDialog = data.isShow
    }
  },
  mounted () {
    this.initPage()
  },
}
</script>

<style lang="less" scoped>
.content {
  display: flex;
  flex-wrap: wrap;
  width: 1080px;
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/globalConst'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login.vue'),
    meta: { title: "满天星-微信生态流量变现系统" }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/home/Home.vue'),
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/dashboard/Dashboard.vue'),
      },
      {
        path: '/channelManagement',
        name: 'ChannelManagement',
        component: () => import('@/views/channelManagement/ChannelManagement.vue'),
      },
      {
        path: '/bottommenu',
        name: 'BottomMenu',
        component: () => import('@/views/bottomMenu/BottomMenu.vue'),
      },
      {
        path: '/welcomes',
        name: 'Welcomes',
        component: () => import('@/views/welcomes/Welcomes.vue'),
      },
      {
        path: '/interaction',
        name: 'Interaction',
        component: () => import('@/views/interaction/Interaction.vue'),
      },
      {
        path: '/hongbao',
        name: 'Hongbao',
        component: () => import('@/views/hongbao/Hongbao.vue'),
      },

      {
        path: '/sign',
        name: 'Sign',
        component: () => import('@/views/sign/Sign.vue'),
      },
      {
        path: '/mealreminder',
        name: 'MealReminder',
        component: () => import('@/views/mealReminder/MealReminder.vue'),
      },
      {
        path: '/skinbox',
        name: 'SkinBox',
        component: () => import('@/views/skinBox/SkinBox.vue'),
      },
      {
        path: '/invigorate',
        name: 'Invigorate',
        component: () => import('@/views/invigorate/Invigorate.vue'),
      },
      {
        path: '/income',
        name: 'Income',
        component: () => import('@/views/income/Income.vue'),
      },
      {
        path: '/apppath',
        name: 'AppPath',
        component: () => import('@/views/appPath/AppPath.vue'),
      },
      {
        path: '/growth',
        name: 'GrowthServices',
        component: () => import('@/views/growthServices/GrowthServices.vue'),
      },
      {
        path: '/shichituan',
        name: 'ShiChiTuan',
        component: () => import('@/views/shichituan/Shichituan.vue'),
      },
      {
        path: '/accountType',
        name: 'AccountType',
        component: () => import('@/views/accountType/AccountType.vue'),
      },
    ]
  },
  {
    path: '/authorizer',
    name: 'Authorizer',
    component: () => import('@/views/authorizer/Authorizer.vue'),
  },
  {
    path: '/wxpay',
    name: 'WxPay',
    component: () => import('@/views/wxpay/WxPay.vue')
  },
  {
    path: '/pay-success',
    component: () => import('@/views/paySuccess/Index.vue')
  },
  {
    path: '/rule',
    component: () => import('@/views/rule/Rule.vue'),
  },
  {
    path: '/cover',
    component: () => import('@/views/rule/Cover.vue'),
  },
  {
    path: '/help',
    component: () => import('@/views/help/Help.vue')
  },
  {
    path: '/pay-success-sale',
    component: () => import('@/views/paySuccess/Sale.vue')
  },
  {
    path: '/shichi',
    component: () => import('@/views/rule/Shichi.vue')
  },
  {
    path: '/help-account',
    component: () => import('@/views/helpAccount/HelpAccount.vue')
  },
  {
    path: '/auth-status',
    component: () => import('@/views/authStatus/AuthStatus.vue')
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const title = to.meta.title
  const TOKEN = storage.get(ACCESS_TOKEN)
  if(title){
    window.document.title = title 
  }
  if (to.path === '/login' || to.path === '/rule' || TOKEN) {
    next()
  } else {
    next('/login')
  }
})

export default router

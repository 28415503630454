<template>
  <div class="auth-taobao">
    <!-- <div class="mask"></div> -->
    <div class="dialog-content">
      <header>{{authTaobaoStatus ? "第一步：授权淘宝联盟账户" : "授权失败"}}</header>
      <div class="attention">授权淘宝联盟账号请注意：</div>
      <div class="item">1、需授权有 <span>渠道专属推广位权限</span> 的淘宝联盟账户，且保证该账户没有被封。</div>
      <router-link :to="{ path: '/help-account?id=anchor-3&type=elm'}" target="_blank">如何确认我有渠道专属推广位权限</router-link>
      <div class="item">2、最多仅支持授权 <span>5个</span>淘宝联盟账户。</div>
      <div class="btn-wrap" v-if="!authTaobaoStatus">
        <a-button type="primary" @click="authTaobaoHandler" style="color: #fff;">再次重新授权</a-button>
      </div>
      <div class="btn-wrap" v-else>
        <a-button type="primary" @click="authTaobaoHandler">授权淘宝联盟账户</a-button>
        <a-button @click="closeAuthTaobao">上一步</a-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    authTaobaoStatus: {
      type: Boolean,
      default: true
    },
    authUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  methods: {
    authTaobaoHandler () {
      this.closeAuthTaobao()
      window.open(this.authUrl)
      this.$emit('authResultDialog')
    },
    closeAuthTaobao () {
      this.$emit('closeAuthTaobao', false)
    }
  },
  mounted() {},
}
</script>
<style lang="less" scoped>
.dialog-content {
  position: fixed;
  top: 50%;
  left:50%;
  width:762px;
  height: 440px;
  background:#fff;
  border-radius:12px;
  padding: 36px;
  transform: translate(-50%,-50%);
  z-index: 4;
  color: #666;
  font-size: 18px;
  header {
    height: 36px;
    line-height: 39px;
    margin-bottom: 24px;
    color: #333;
    font-size: 28px;
  }
  .attention {
    height: 25px;
    line-height: 25px;
    margin-bottom: 12px;
  }
  .item {
    line-height: 25px;
    span {
      color: #333;
    }
  }
  .btn-wrap {
    position: absolute;
    right: 36px;
    bottom: 36px;
    .ant-btn {
      display: block;
      width: 200px;
      height: 60px;
      margin-top: 12px;
      font-size: 20px;
      &:last-of-type {
        color: #4392F6;
        border-color: #4392F6;
      }
    }
  }
}
a {
  display: inline-block;
  height: 24px;
  line-height: 24px;
  margin: 12px 0;
  font-size: 14px;
}
</style>
import axios from '@/utils/axios'

const API = {
  init: '/order/ajaxInit',
  setModule: '/order/ajaxSetOrderConfig',
  exportExcel: '/order/ajaxGetDownloadInfo',
}

function initPagePost () {
  return axios.post(API.init)
}

function setModulePost (param) {
  return axios.post(API.setModule, param)
}

function exportExcelPost (param) {
  return axios.post(API.exportExcel, param)
}

export {
  initPagePost,
  setModulePost,
  exportExcelPost
}

<template>
  <a-layout-sider width="240" style="background: #FEFFFF">
    <a-menu
      mode="inline"
      :selected-keys="[defaultMenu.defaultSelected]"
      :default-open-keys="[defaultMenu.defaultOpen]"
      :style="{ height: '100%', borderRight: 0 }"
    >
    <template v-for="item in menuList">
      <a-sub-menu :key="item.id">
        <span slot="title">{{ item.title }}</span>
        <template v-for="list in item.list">
          <a-menu-item :key="list.id" @click="scrollTo('anchor-'+list.id)"><span>·</span>{{ list.text }}</a-menu-item>
        </template>
      </a-sub-menu>
    </template>
    </a-menu>
  </a-layout-sider>
</template>

<script>
import { menuMixins } from './mixins'

export default {
  mixins: [menuMixins],
  props: {
    defaultMenu: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
    }
  },
  methods: {
    scrollTo (selector) {
      this.$emit('scrollToId', selector)
    },
    initScroll () {
      let session = window.sessionStorage
      let seesionStr = 'ANCHOR_HB'
      let sessionVal = session.getItem(seesionStr)
      if(this.$route.query.source == 'hb' && !sessionVal){
        this.scrollTo('anchor-hb')
        session.setItem(seesionStr, 1)
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.initScroll()
    }, 1000)
  }
}
</script>

<style lang="less" scoped>
.ant-layout-sider {
  height: calc(100vh - 60px);
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-menu-submenu {
  border-bottom: 1px solid #F3F6FC;
  &.ant-menu-submenu-selected {
    color: #333;
    &:hover {
      color: #333;
    }
  }
  /deep/ &.ant-menu-submenu-open {
    .ant-menu-submenu-title {
      .ant-menu-submenu-arrow {
        transform: translateY(-2px);
        &::before, &::after {
          width: 10px;
          transform: rotate(45deg) translateX(5px);
        }
        &::after {
          transform: rotate(-45deg) translateX(-5px);
        }
      }
    }
  }
  /deep/ .ant-menu-submenu-title {
    font-size: 18px;
    height: 60px;
    display: flex;
    align-items: center;
    margin: 0;
    span {
      height: 25px;
      line-height: 25px;
      color: #333;
    }
    .ant-menu-submenu-arrow {
      transform: translateY(5px);
      &::before, &::after {
        width: 10px;
        transform: rotate(-135deg) translateX(5px);
        background: #4392F6;
      }
      &::after {
        transform: rotate(135deg) translateX(-5px);
      }
    }
  }
  /deep/ .ant-menu-item {
    display: flex;
    align-items: center;
    height: 60px;
    color: #666;
    font-size: 16px;
    padding-left: 24px !important;
    margin: 0;
    &.anchor {
      padding-left: 0 !important;
    }
    &.ant-menu-item-selected {
      color: #333;
      font-weight: 700;
      background: #F3F6FC;
      &::after {
        border: none;
        opacity: 0;
      }
    }
    span {
      font-size: 30px;
      margin-right: 6px;
    }
  }
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #E6E6FA;
}
::-webkit-scrollbar-track {
  background: #F3F6FC;
}
</style>
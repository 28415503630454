<template>
  <div class="hongbao">
    <contentHeader @checkSwitch="checkSwitch" :is-default-checked="isDefaultChecked" :is-show-tips="true" header-type="11"></contentHeader>
    <div class="shichi-tips">
      <div class="tips-title">功能说明</div>
      <div class="tips-subtitle">用户授权定位后，可以通过您的公众号进入“小胖试吃团”小程序，查看周围支持试吃的商家（当前仅限部分地区，持续拓展中）。<br />用户在小胖试吃团申请试吃并下单，该试吃通过试吃团审核后，您将获得1~2元人民币的流量主奖励。<br />试吃具体规则&常见问题可见<a href="javascript:void(0)" @click="pathTo('/shichi')">《试吃团活动规则》</a></div>
      <div class="tips-tips">注意：若自行提取试吃团小程序链接使用，可能造成任务不能正常归属，导致您无法取得奖励。</div>
    </div>
    <div class="shichi-steps">
      <div class="steps-title">操作说明</div>
      <div class="steps-subtitle">如何开启试吃功能：</div>
      <div class="steps-subtitle">1.打开本页面右上角功能开关</div>
      <div class="steps-subtitle">2.前往底部菜单-满天星定制菜单页面，选择试吃团功能菜单（如图所示），配置到您需要的位置。</div>
      <img src="./images/steps-img.png" alt="">
    </div>
    <statusDialog
      @closeStatusDialog="closeStatusDialog"
      :show-status-dialog="showStatusDialog"
      :dialog-status="dialogStatus"
    ></statusDialog>
  </div>
</template>

<script>
import contentHeader from '@/components/header/header'
import statusDialog from '@/components/statusDialog/statusShichiDialog'
import { initPagePost, setSwitchPost } from '@/api/shichituan'
export default {
  components: {
    contentHeader,
    statusDialog
  },
  data () {
    return {
      selectedId: 1,
      isDefaultChecked: null,
      transmitData: { // 传给弹窗的数据
        previewId: null, // 点击预览的id
        showPreview: false, // 显示预览弹窗
        imgNum: null // 弹窗的图片的数量
      },
      appPath: "",
      showStatusDialog: false,
      dialogStatus: 1,
    }
  },
  methods: {
    async initPage () {
      const { status, data } = await initPagePost()
      if (status === 1) {
        this.isDefaultChecked = data.switch
        if(data.is_close_switch == 1){
          this.dialogStatus = 2
          this.showStatusDialog = true
        }
      }
    },
    async selectedOk (appData) {
      let ajaxData = {}
      // if (appData) {
        ajaxData = { ...appData }
      // } else {
      //   ajaxData = {
      //     switch: this.isDefaultChecked
      //   }
      // }
      const { status, info, data } = await setSwitchPost(ajaxData)
      if (status === 1) {
        // this.initPage()
        if(appData.switch === 0 || appData.switch === 1){
          this.$message.success("配置成功")
          this.isDefaultChecked = appData.switch
        } 
      } else {
        if(!data.is_relation && appData.switch === 1){
          this.dialogStatus = 1
          this.showStatusDialog = true
        } else {
          this.$message.error(info)
        }
      }
    },
    checkSwitch (val) {
      // if (!this.appPath) return
      // this.isDefaultChecked = val
      this.selectedOk({switch: val})
    },
    // showPreviewDia (data) {
    //   this.transmitData = { ...data }
    // },
    // closePreviewDia (val) {
    //   this.transmitData.showPreview = val
    // },
    closeStatusDialog (val) {
      this.showStatusDialog = val
    },
    pathTo (path) {
      let routeUrl = this.$router.resolve({ path });
      window.open(routeUrl.href, '_blank');
    },
  },
  created () {
    this.initPage()
  }
}
</script>

<style lang="less" scoped>
  .shichi-tips{
    width: 1080px;
    padding:24px;
    box-sizing:border-box;
    background:#FFF;
    border-radius:8px;
    margin-bottom:24px;
    .tips-title{
      line-height:25px;
      font-size:18px;
      color:#333;
      margin-bottom:5px;
    }
    .tips-subtitle{
      line-height:30px;
      font-size:14px;
      color:#666;
    }
    .tips-tips{
      margin-top:6px;
      line-height:20px;
      font-size:14px;
      color:#FF7A00;
    }
  }
  .shichi-steps{
    width: 1080px;
    box-sizing: border-box;
    padding:24px;
    background:#FFF;
    border-radius:8px;
    .steps-title{
      line-height:25px;
      font-size:18px;
      color:#333;
      margin-bottom:12px;
    }
    .steps-subtitle{
      line-height:20px;
      font-size:14px;
      color:#666;
      margin-bottom:24px;
      &:nth-child(4){
        margin-bottom:10px;
      }
    }
    img{
      display:block;
      width:1032px;
      height:240px;
    }
  }
</style>
<template>
  <div class="plan-dialog txdialog" :class="{ disabled: !defaultActiveItem || dialogInfo.remain_fans_num == 0 }">
    <div class="dialog-title">{{ dialogInfo.title }}
      <span class="close" @click="closePlanDialog">关闭
        <a-icon type="close" />
      </span>
      <div class="cover-btn">满天星持续监控粉丝质量，包您售后无忧 <a class="cover" href='https://saas.mtx.net.cn/#/cover' target="_blank">保障详情 ></a></div>
    </div>
    <div class="select">选择计划：</div>
    <div class="plan-box">
      <div class="plan-item" :class="[{ active: defaultActiveItem && (defaultActiveItem.id === plan.id) }, { disabled: plan.stock_l < 1000 || plan.status == 2 }]" v-for="plan in dialogPlanList" :key="plan.id" @click="selectPlan(plan)">
        <div class="plan-name">{{ plan.month }}</div>
        <div class="text">粉丝单价：{{ dialogInfo.fans_amount }}元</div>
        <div class="text">剩余可订粉丝数：<span :style="{ color: plan.stock_l > 0 && plan.status == 1 ? '#19AF4E' : '' }">{{ plan.stock_l }}个</span></div>
        <div class="text" v-if="!showAllCountBtn">预计 <span>{{ plan.promote_date }}</span> 之前完成所有加粉任务</div>
      </div>
    </div>
    <div class="count">预定粉丝：
      <div class="count-box">
        <a href="javascript:void(0);" class="count-item" :class="{ disabled: fansNum <= 1000 }" @click="fanNumCount(1)">-</a>
        <div class="count-num">{{ fansNum }}</div>
        <a href="javascript:void(0);" class="count-item" :class="{ disabled: fansNum >= 50000 || defaultActiveItem && (fansNum >= defaultActiveItem.num) }" @click="fanNumCount(2)">+</a>
      </div>
      <a href="javascript:void(0);" class="count-all" @click="fanNumCountAll" v-if="showAllCountBtn">最大</a>
    </div>
    <div class="tip">推广结束后剩余预算可退，超出预算加粉5%以内，不额外收取费用。</div>
    <footer>
      <div style="display: flex;align-items: center;height: 60px;">
        <a-checkbox @change="changeCheckBox" />我已阅读并同意<a href="javascript:void(0);" @click="showAgreementDialog">《增长服务订购协议》</a>
      </div>
      <div style="display: flex;align-items:center;height: 60px;">
        <div class="amount-box">
          总计金额：<span class="amount-num">￥{{ totalAmount }}</span>
        </div>
        <a-button type="primary" @click="zfbPay">
          <a-icon type="alipay-circle" />支付宝支付
        </a-button>
      </div>
    </footer>
  </div>
</template>
<script>
import { countAmountPost, getPayLinkPost, getPlanItemDataPost } from "@/api/growth"
import { agreeProtocolPost, getProtocolVersionPost } from '@/utils/common.js';

export default {
  props: {
    closePlanDialog: {
      type: Function
    },
    showAgreementDialog: {
      type: Function
    },
    fansType: {
      type: Number
    }
  },
  data() {
    return {
      dialogInfo: {},
      dialogPlanList: [],
      fansNum: 1000,
      defaultActiveItem: {},
      agreeBuy: false, // 是否同意购买协议
      totalAmount: 0,
      isPayPost: false,
      protocolVersion: {},
      showAllCountBtn:false
    }
  },
  methods: {
    fanNumCountAll () {
      const { remain_fans_num } = this.dialogInfo

      this.fansNum = remain_fans_num

      this.countAmountFn()
    },
    fanNumCount (type) {
      const { fansNum, defaultActiveItem } = this

      if (type === 1) {
        if (fansNum <= 1000) return
        this.fansNum -= 1000
      }
      if (type === 2) {
        if (fansNum >= 50000 || fansNum >= (defaultActiveItem && defaultActiveItem.num) || !defaultActiveItem) return
        this.fansNum += 1000
      }
      this.countAmountFn();
    },
    selectPlan (plan) {
      const { remain_fans_num } = this.dialogInfo

      if (!this.defaultActiveItem || plan.id === this.defaultActiveItem.id) return
      if (plan.stock_l < 1000 || plan.status == 2 || remain_fans_num == 0) return
      this.fansNum = 1000
      this.defaultActiveItem = plan
      this.countAmountFn();
    },
    agreeProtocol () {
      getProtocolVersionPost(2).then(res => {
        this.protocolVersion = res
      })
    },
    changeCheckBox (e) {
      const { protocolVersion } = this

      this.agreeBuy = e.target.checked
      if (protocolVersion.current_version == protocolVersion.system_current_version) return
      agreeProtocolPost(2, protocolVersion.system_current_version).then(res => {})
    },
    // 计算金额
    async countAmountFn () {
      const { fansNum } = this;
      const { status, data, info } = await countAmountPost({ fansNum,fans_type: this.fansType });

      if (status === 1) {
        this.totalAmount = data.fansCost;
      } else {
        this.$message.error(info);
      }
    },
    async getPayLink (item) {
      const { isPayPost, fansNum, totalAmount, dialogInfo } = this;

      if (isPayPost) return;
      this.isPayPost = true;
      let ajaxData = {
        date: item.date,
        fans_type: dialogInfo.fans_type,
        fansNum: fansNum,
        fansCost: totalAmount
      };
      const { status, data, info } = await getPayLinkPost(ajaxData);

      if (status === 1) {
        this.closePlanDialog()
        this.$emit("showPayStatusDialog", { open: true, outOrderNo: data.outOrderNo });
        window.open(data.payLink);
      } else {
        if (data.err_code === 13008 || data.err_code === 13005) {
          this.$emit("showEmptyDialog", this.getPlanItemData);
        } else {
          this.$message.error(info);
        }
      }
      this.isPayPost = false;
    },
    zfbPay () {
      const { agreeBuy, defaultActiveItem } = this

      if (!defaultActiveItem) return
      if (!agreeBuy) return this.$emit('showAgreeBuy')
      this.getPayLink(defaultActiveItem)
    },
    async getPlanItemData () {
      const { status, data, info } = await getPlanItemDataPost({ fans_type: this.fansType })
      if (status === 1) {
        this.dialogInfo = data.product
        this.dialogPlanList = data.product.sub_products
        this.showAllCountBtn = !!data.product.is_buy_all
        this.defaultActiveItem = this.dialogPlanList.find(item => {
          return item.stock_l >= 1000 && item.status != 2 && this.dialogInfo.remain_fans_num != 0
        });
        this.countAmountFn()
      } else {
        this.$message.error(info)
      }
    },
  },
  mounted() {
    this.getPlanItemData()
    this.agreeProtocol(2)
    console.log(this.protocolVersion);
  },
}
</script>
<style lang="less" scoped>
.plan-dialog {
  width: 910px;
  height: 611px;
  z-index: 4;
  color: #333;
  .dialog-title {
    position: relative;
    height: 85px;
    line-height: 28px;
    padding-bottom: 56px;
    margin-bottom: 24px;
    border-bottom: 1px solid #EBEBEB;
    font-size: 20px;
    .close {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      height: 20px;
      font-size: 14px;
      cursor: pointer;
      .anticon-close {
        margin-left: 4px;
      }
    }
    .cover-btn{
      position:absolute;
      left:0;
      bottom:24px;
      line-height:20px;
      font-size:14px;
      a{
        color:#4392F6
      }
    }
  }
  .select {
    height: 28px;
    line-height: 28px;
    font-size: 20px;
    margin-bottom: 12px;
  }
  .plan-box {
    display: flex;
    margin-bottom: 36px;
    .plan-item {
      width: 336px;
      height: 182px;
      border-radius: 4px;
      border: 1px solid #CCCCCC;
      padding: 24px;
      margin-right: 24px;
      cursor: pointer;
      .plan-name {
        height: 34px;
        line-height: 34px;
        margin-bottom: 12px;
        font-size: 24px;
      }
      .text {
        height: 16px;
        line-height: 16px;
        font-size: 16px;
        margin-bottom: 18px;
        &:last-of-type {
          height: 20px;
          line-height: 20px;
          margin-bottom: 0;
          color: #666;
          font-size: 14px;
          span {
            color: #333;
          }
        }
      }
      &.active {
        position: relative;
        border-color: #4392F6;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 0;
          height: 0;
          border-radius: 0 4px;
          border-top: 30px solid #4392f6;
          border-right: 30px solid #4392f6;
          border-bottom: 30px solid transparent;
          border-left: 30px solid transparent;
        }
        &::before {
          content: "";
          position: absolute;
          right: 5px;
          top: 10px;
          width: 20px;
          height: 10px;
          border-left: 3px solid #fff;
          border-bottom: 3px solid #fff;
          border-radius: 2px;
          transform: rotate(-45deg);
          z-index: 1;
        }
      }
      &.disabled {
        color: #999;
        .text, span {
          color: #999 !important;
        }
      }
    }
  }
  .count {
    display: flex;
    height: 28px;
    line-height: 28px;
    font-size: 20px;
    margin-bottom: 17px;
  }
  .count-box {
    display: flex;
    align-items: center;
    height: 27px;
    margin-left: 12px;
    .count-item {
      width: 24px;
      height: 24px;
      line-height: 18px;
      text-align: center;
      border-radius: 2px;
      border: 1px solid #D7E3F5;
      box-sizing: border-box;
      font-size: 20px;
      &.disabled {
        color: #ccc;
      }
    }
    .count-num {
      text-align: center;
      font-size: 20px;
      margin: 0 12px;
      color: #ff4141;
    }
  }
  .count-all{
    display:block;
    width:60px;
    text-align: center;
  }
  .tip {
    height: 20px;
    line-height: 20px;
    margin-bottom: 24px;
    font-size: 14px;
    color: #666;
  }
  footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #EBEBEB;
    padding-top: 24px;
    font-size: 20px;
    /deep/ .ant-checkbox-wrapper {
      width: 18px;
      height: 18px;
      margin-right: 4px;
       .ant-checkbox, .ant-checkbox-input, .ant-checkbox-inner {
          width: 18px;
          height: 18px;
       }
    }
    .amount-box {
      height: 39px;
      line-height: 34px;
      margin-right: 24px;
    }
    .amount-num {
      font-size: 28px;
      color: #FF4141;
    }
    .ant-btn {
      width: 200px;
      height: 60px;
      font-size: 20px;
      box-sizing: border-box;
      .anticon {
        margin-top: 5px;
      }
      &.close {
        color: #4392f6;
        border-color: #4392f6;
      }
    }
  }
}
.plan-dialog.disabled {
  .plan-box {
    .text, span {
      color: #999 !important;
    }
  }
  .count-box {
    .count-item {
      color: #D7E3F5 !important;
    }
  }
  .tip, .amount-num, .count-num, .plan-box {
    color: #999;
  }
  .ant-btn {
    background: #999;
    border-color: #999;
  }
}
</style>
<template>
  <div class="dialog-right">
    <div v-for="item in dataList" :key="item.id">
      <template v-if="item.id == previewId">
        <div class="space-box"></div>
        <div class="title" v-html="item.title"></div>
        <div class="introduce" :style="{lineHeight: sizeStatus == 3 ? '24px' : ''}" v-html="item.subTitle"></div>
        <div class="introduce" v-if="item.subTitle2">{{ item.subTitle2 }}</div>
        <ul v-if="sizeStatus == 2 || sizeStatus == 4">
          <li class="cur" v-for="list in item.list" :key="list.id" v-html="list.text"></li>
          <li class="cur" v-if="item.id == 1">将佣金的{{fanliRateNum}}%作为返利<i>（30%）</i></li>
          <li style="listStyle: none"></li>
        </ul>
        <div class="text" v-if="sizeStatus == 2">
          您可以调节发放返利的比例：
          <div class="can-set">出让
            <a-input class="box" :style="{'color': fanliRateNum == 0 || fanliRateNum > 100 ? '#FF4141' : ''}" v-model="fanliRateNum" />
              <span :style="{'color': fanliRateNum == 0 || fanliRateNum > 100 ? '#FF4141' : ''}">%</span>的佣金作为返利
          </div>
          <div class="danger" v-if="fanliRateNum <= 0">佣金比例必须大于0</div>
          <div class="danger" v-if="fanliRateNum > 100">佣金比例必须不超过100</div>
        </div>
        <template v-if="sizeStatus == 3">
          <div class="subtitle">需要配置的小程序页面路径：</div>
          <input v-model="miniPath" type="text" class="mini-path-input" placeholder="请输入...">
          <a class="a-pro" href="javascript:void(0);" @click="pathTo('/help')">如何获取</a>
        </template>
        <a v-if="sizeStatus == 99" href="javascript:void(0);" :class="['selecte-btn', 'use']">{{ item.id == selectedId ? '正在使用这个模版' : '选择这个模板'}}</a>
        <a v-else-if="sizeStatus == 3" href="javascript:void(0);" class="selecte-btn hb" :class="{o: !miniPath.trim().length}" @click="selectedOk(item.id)">保存并使用</a>
        <a v-else-if="sizeStatus == 2" href="javascript:void(0);" :class="['selecte-btn', {'use' : showBtnText(item.id)}]" @click="selectedOk(item.id)">{{ showBtnText(item.id) ? '正在使用这个模版' : '选择这个模板'}}</a>
        <a v-else href="javascript:void(0);" :class="['selecte-btn', {'use' : item.id == selectedId}, {o: item.id === 5813 && !isOpenRandom}]" @click="selectedOk(item.id)">{{ item.id == selectedId ? '正在使用这个模版' : '选择这个模板'}}</a>
        <div class="tips" v-if="item.id === 5813 && !isOpenRandom">请先在<span>【红包设置】</span>中添加随机红包</div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Array
    },
    previewId: {
      default: 0
    },
    /**
     * 预览弹窗右侧文字多少
     * 1. 欢迎语、每日签到、
     * 2. 综合互动
     */
    sizeStatus: {
      type: String,
      default: ''
    },
    /**
     * 下单互动返利佣金
     */
    fanliRate: {},
    selectedId: {
     default: 0
    },
    appPath: {
      type: String,
      default: ""
    },
    isOpenRandom: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      fanliRateNum: null,
      miniPath: ""
    }
  },
  computed: {
  },
  methods: {
    selectedOk (id) {
      if (this.sizeStatus == 2) {
        if ((this.fanliRateNum <= 0 || this.fanliRateNum > 100) || (this.selectedId == id && this.fanliRateNum == this.fanliRate)) return
        this.$emit('selectedOk', { id, fanliRateNum: this.fanliRateNum })
      } else if (this.sizeStatus == 3) {
        if (this.miniPath.trim() === '') return
        this.$emit('selectedOk', { app_path: this.miniPath, switch: 1 })
      } else {
        if (this.selectedId == id || (id === 5813 && !this.isOpenRandom)) return
        this.$emit('selectedOk', { id })
      }
    },
    showBtnText (id) {
      const status = id == this.selectedId && this.fanliRateNum == this.fanliRate
      return status
    },
    pathTo (path) {
      let routeUrl = this.$router.resolve({
        path,
        query: {source:'hb'}
      });
      window.open(routeUrl.href, '_blank');
    },
  },
  created () {
    if (this.sizeStatus == 2) {
      this.fanliRateNum = this.fanliRate
    } else if (this.sizeStatus == 3) {
      this.miniPath = this.appPath
    }
  }
}
</script>

<style lang="less" scoped>
.dialog-right {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding-bottom: 60px;
  color:  #666;
  font-size: 14px;
  .space-box {
    height: 20px;
  }
  .title {
    // height: 28px;
    line-height: 28px;
    font-size: 20px;
    color: #333847;
    font-weight: 500;
    margin-bottom: 12px;
  }
  .introduce {
    line-height: 30px;
  }
}
ul {
  padding-left: 17px;
  margin-bottom: 0;
}
li {
  line-height: 32px;
  i{
    font-style: normal;
    color:#ccc
  }
}
.selecte-btn {
  display: block;
  width: 220px;
  height: 60px;
  margin-top: 24px;
  border-radius: 6px;
  line-height: 60px;
  background: #4392F6;
  text-align: center;
  color: #fff;
  font-size: 20px;
  &.use {
    background: #ADB1C3;
    color: #fff;
  }
  &.hb {
    margin-top: 24px;
    &.o {
      opacity: 0.5;
    }
  }
}
.cur {
  line-height: 32px;
}
.text {
  line-height: 32px;
  // margin-bottom: 36px;
  .can-set {
    display: flex;
    align-items: center;
    span {
      color: #4392F6;
    }
    .box {
      width: 55px;
      height: 21px;
      border: 1px solid #ADB1C3;
      border-radius: 2px;
      margin: 0 6px;
      text-align: center;
      color: #4392F6;
      font-size: 12px;
    }
  }
}
.subtitle {
  height: 20px;
  line-height: 20px;
  margin: 24px 0 6px;
  font-size: 14px;
  color: #333847;
}
.mini-path-input {
  width: 100%;
  height: 32px;
  line-height: 32px;
  padding-left: 5px;
  margin-bottom: 6px;
  border-radius: 2px;
  border: 1px solid #ADB1C3;
  color: #4392F6;
  font-size: 14px;
}
.a-pro {
  height: 20px;
  line-height: 20px;
  color: #4392F6;
  font-size: 14px;
}
.danger {
  color: #FF4141;
  padding-left: 30px;
  background: url('./images/icon-danger.png') left center no-repeat;
  background-size: 24px 20px;
}
.tips {
  position: absolute;
  bottom: 24px;
  left: -4px;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  color: #999;
  span {
    color: orange;
  }
}
</style>

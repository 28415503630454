<template>
  <div class="selected-dialog">
    <div class="mask"></div>
    <div class="dialog-content">
      <a href="javascript:void(0);" class="close-dia-btn" @click="closeDetailDialog">关闭</a>
      <div class="title">{{ accountType === 5513 ? "满天星系统" : accountType === 5515 ? "淘宝联盟" : "美团分销联盟" }}账户</div>
      <div class="subtitle" v-if="accountType === 5513">该账户模式将绑定满天星系统的淘宝联盟账户，并通过满天星结算。<br />饿了么订单相关数据都将在满天星系统呈现和统计。</div>
      <div class="subtitle ziyou" v-else-if="accountType === 5515">饿了么的订单与佣金结算将会使用您自己的淘宝联盟账户。每次授权有效期为30天，该淘宝联盟账户需支持配置推广位。</div>
      <div class="subtitle ziyou" v-else>美团的订单与佣金结算将会使用您自己的美团分销联盟账户。<br />仅支持授权1个美团分销联盟账户。</div>
      <div class="tip">如果接入{{ accountType === 5513 ? "满天星系统" : "您自己的联盟" }}账户：</div>
      <div v-if="accountType === 5513">
        <p>1.可使用“数据看板”“我的收入”“渠道管理”的功能。</p>
        <p>2.通过满天星系统进行结算。</p>
        <p>3. 饿了么和美团订单都使用满天星系统账户时，下单互动支持使用返利模版。</p>
      </div>
      <div v-else>
        <p>1. {{accountType == 5515 ? '饿了么' : '美团'}}的订单和佣金将不会统计在“数据看板”、“我的<br />收入”、“渠道管理”。</p>
        <p>2.下单互动中将不可使用返利模版。</p>
      </div>
      <div class="btn-wrap">
        <a-button v-if="accountType == 5515 && (!tbAccountList.length || !promotionList.length) && selectedElmTypeId == 5513" type="primary" @click="detaliBtnHandler(1)">下一步</a-button>
        <a-button v-else-if="accountType === 7355 && !mtAccountList.length" type="primary" @click="detaliBtnHandler(1)">下一步</a-button>
        <a-button v-else type="primary" @click="detaliBtnHandler(0)">确定</a-button>
        <!-- <a-button @click="closeDetailDialog" v-if="from === 1">上一步</a-button> -->
      </div>
    </div>
  </div>
</template>
// <script>
// import { setAccountTypePost } from '@/api/authorizer'
export default {
  props: {
    accountType: {
      type: Number,
      default: 5513
    },
    tbAccountList: {
      type: Array,
      default: () => []
    },
    promotionList: {
      type: Array,
      default: () => []
    },
    mtAccountList: {
      type: Array,
      default: () => []
    },
    selectedMtTypeId: {
      type: Number,
      default: null
    },
    selectedElmTypeId: {
      type: Number,
      default: null
    }
    // from: { // 1、授权页面过来 2、账户类型菜单过来
    //   type: Number,
    //   default: null
    // }
  },
  data() {
    return {
      authUrl: '',
      authTaobaoCode: ''
    }
  },
  methods: {
    // async 
    /**
     * type
     * 0 确定选择此账户 
     * 1 下一步 授权账户步骤
     */
    detaliBtnHandler (type) {
      // if (this.from === 1) {
      //   const { accountType } = this
      //   const { status, data } = await setAccountTypePost({ config_id: accountType })
      //   if (status === 1) {
      //     this.authUrl = data.auth_url
      //     this.authTaobaoCode = data.auth_code
      //     this.$emit('detaliBtnHandler', {
      //       accountType,
      //       authUrl: this.authUrl,
      //       authTaobaoCode: this.authTaobaoCode
      //     })
      //   }
      // } else {
        this.$emit('detaliBtnHandler', type)
      // }
    },
    closeDetailDialog () {
      this.$emit('closeDetailDialog')
    }
  },
  mounted() {},
}
</script>
<style lang="less" scoped>
.dialog-content {
  position: fixed;
  top: 50%;
  left:50%;
  width:762px;
  height: 440px;
  background:#fff;
  border-radius:12px;
  padding: 36px;
  transform: translate(-50%,-50%);
  z-index: 4;
  .title {
    height: 39px;
    line-height: 39px;
    margin-bottom: 24px;
    font-size: 28px;
    color: #333;
  }
  .subtitle {
    // width: 450px;
    margin-bottom: 24px;
    line-height: 28px;
    font-size: 18px;
    color: #666;
    &.ziyou {
      width: 100%;
      margin-bottom: 86px;
    }
  }
  .tip {
    height: 25px;
    line-height: 25px;
    font-size: 18px;
    color: #666;
    margin-bottom: 12px;
  }
  p {
    // width: 448px;
    line-height: 30px;
    font-size: 18px;
    color: #666;
    margin-bottom: 0;
  }
  .btn-wrap {
    position: absolute;
    right: 36px;
    bottom: 36px;
    .ant-btn {
      display: block;
      width: 200px;
      height: 60px;
      margin-top: 12px;
      font-size: 20px;
      &:nth-of-type(2) {
        color: #4392F6;
        border-color: #4392F6;
      }
    }
  }
}

.close-dia-btn{
  color: #333;
  &::after{
    top: 2px;
    right: -20px;
    width: 18px;
    height: 18px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiBAMAAADIaRbxAAAAG1BMVEUAAAAyMjIwMDAwMDAzMzMyMjIyMjIyMjIzMzOMyWXQAAAACHRSTlMA3yAQz4BwYCC2TscAAAB7SURBVCjPxdDBDYAgDAVQg3EAOfUIJno2bmDcwBVcwBVIODi2pPb7wwT0AD/wSGi75uVOC/NqYUjhu5EbRrzuMZspUZGTo6xEIEQgRCBEkxGi0QjRA8ITj4hXewo1yZsQ2XcjEDpCdyTojgTdkSj6J1ZIPbH+srDAtKsX7oIUKxBDeOgAAAAASUVORK5CYII=)no-repeat center;
    background-size: 100%;
    border: none;
    transform: none;
  }
}
</style>
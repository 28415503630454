<template>
  <div class="pay-success-page">
    <PageHeader :show-app-info="2" :auth-info="authInfo"></PageHeader>
    <div class="pay-success">
      <div class="icon"></div>
      <div class="text">支付成功</div>
      <div class="sub-text">支付金额：<span>{{ totalMoney }}</span>元</div>
      <!-- <a href="javascript:void(0);" @click="goSetSignRule" v-if="isFromSwitchDialog === 'switchBuy'" class="set-rule-btn">去设置签到送会员规则</a> -->
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/header/PageHeader"
import { initPagePost } from '@/api/paysuccess'

export default {
  components: {
    PageHeader,
  },
  data () {
    return {
      authInfo: {
        headimgurl: "",
        nickname: ""
      },
      totalMoney: "",
      // isFromSwitchDialog: ""
    }
  },
  methods: {
    async initPage () {
      const outOrederNo = this.$route.query.out_order_no
      // this.isFromSwitchDialog = this.$route.query.is_from_switch_dialog
      const { status, data, info } = await initPagePost({ out_order_no: outOrederNo })
      if (status === 1) {
        this.totalMoney = data.amount
        this.authInfo.headimgurl = data.head_img
        this.authInfo.nickname = data.nick_name
      } else {
        this.$message.error(info)
      }
    },
    // goSetSignRule () {
    //   const path = { path: 'sign', query: { is_from_switch_dialog: 'switchBuy' }}
    //   this.$router.replace(path)
    // }
  },
  created () {
    this.initPage()
  }
}
</script>

<style lang="less" scoped>
  .pay-success-page {
    position: relative;
    height: 100%;
    background: #F3F6FC;
  }
  .pay-success {
    margin: 47px auto 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 884px;
    height: 566px;
    border-radius: 12px;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px 0px #D2DBEE;
    padding-top: 180px;
    .icon {
      width: 90px;
      height: 90px;
      background: url("./images/success.png") no-repeat;
      background-size: contain;
      margin-bottom: 24px;
    }
    .text {
      height: 45px;
      line-height: 45px;
      text-align: center;
      color: #333847;
      font-size: 32px;
      margin-bottom: 24px;
    }
    .sub-text {
      height: 24px;
      line-height: 24px;
      color: #5C6485;
      font-size: 20px;
      span {
        color: #FF4141;
      }
    }
    // .set-rule-btn {
    //   display: block;
    //   width: 248px;
    //   height: 60px;
    //   text-align: center;
    //   line-height: 60px;
    //   margin: 48px auto 0;
    //   font-size: 20px;
    //   color: #fff;
    //   border-radius: 6px;
    //   background: #4392F6;
    // }
  }
</style>
<template>
  <div class="help-account">
    <PageHeader :show-app-info="1" :is-show="false" />
    <div class="content-wrap">
      <div v-if="type === 'elm'" class="container">
        <div class="title" id="anchor-1">满天星-淘宝联盟饿了么操作流程</div>
        <div>
          <div class="subtitle">淘宝联盟授权</div>
          <div class="text">1、选择账户类型-饿了么-淘宝联盟账户，进入淘宝联盟账户配置页面流程，点击授权淘宝联盟账户，在淘宝提供的官方页面进行授权，授权成功后进入第二步；</div>
          <img src="./images/image-2.png" alt="" />
          <img src="./images/image-3.png" alt="" />
          <img src="./images/image-4.png" alt="" />
          <!-- <img src="./images/image-5.png" alt="" /> -->
        </div>

        <div id="anchor-2">
          <div class="subtitle">淘宝联盟-新媒体备案</div>
          <div class="text">1. 打开<a href="http://www.alimama.com" target="_blank">www.alimama.com</a> ，登录账号之后点击进入我的阿里妈妈账户。点击：推广管理——>媒体备案管理——>新增媒体备案<span style="color: red">（根据提示说明，选择对应的备案类型）</span></div>
          <img src="./images/image-6.png" alt="" />
          <img src="./images/image-7.png" alt="" />
          <div class="text">2. 根据您要投放淘宝客商品的推广阵地，选择对应类型，根据提示填写备案信息；</div>
          <div style="color: red">注意：不同媒体类型，所需填写的备案信息不同，以对应展示的填写框内容为准</div>
          <img src="./images/image-8.png" alt="" />
          <div class="text">3. 真实准确且完整地填写完相关信息后，提交；</div>
          <img src="./images/image-9.png" alt="" />
          <img src="./images/image-10.png" alt="" />
          <div class="text">4. 回到备案列表，可看到对应的备案审核结果。</div>
          <div class="text">审核拒绝者可重新修改备案信息；</div>
          <div class="text">审核通过者，鼠标放上去，可以看到该媒体下，对应的：推广位、查看、修改、删除、APPKEY申请、数据的按钮，点击可进行相应查看或操作。注意如果要删除媒体ID，必须先删除它下面的所有推广位。</div>
          <img src="./images/image-11.png" alt="" />
        </div>

        <div id="anchor-3">
          <div class="subtitle">渠道管理-饿了么配置（渠道链接）</div>
          <div class="text">1.打开<a href="http://www.alimama.com" target="_blank">www.alimama.com</a> ，登录账号之后点击进入我的阿里妈妈账户。点击推广管理——>渠道管理——>立即申请</div>
          <img src="./images/image-12.png" alt="" />
          <div class="text">2.申请成功后，选择“渠道专属推广位”设置推广位；</div>
          <div style="color: red">（如设置不了请确认“推广位管理”里面是否有建立推广位）</div>
          <img src="./images/image-13.png" alt="" />
          <img src="./images/image-14.png" alt="" />
          <div class="text">3.请将“推广位管理”里的推广位名称复制到<span style="font-weight: 700;">添加推广位名称</span>，再选择将PID复制到<span style="font-weight: 700;">添加推广位PID</span>（见下图）</div>
          <img src="./images/image-15.png" alt="" />
          <div class="text">4.点击添加后，满天星授权淘宝联盟饿了么流程全部完成，可在账户类型中查看对应淘宝账户信息和对应推广位信息；</div>
          <img src="./images/image-16.png" alt="" />
          <img src="./images/image-17.png" alt="" />
        </div>
      </div>
      <div v-if="type === 'mt'" class="container">
        <div class="title" id="anchor-1">满天星-美团分销联盟操作流程</div>
        <div>
          <div class="subtitle">选择美团分销联盟账户</div>
          <div class="text">1. 选择账户类型-美团-美团分销联盟账户，开始配置并点击“下一步；</div>
          <img src="./images/image-mt-1.png" alt="" />
          <img src="./images/image-mt-2.png" alt="" />
        </div>

        <div id="anchor-2">
          <div class="subtitle">获取美团分销联盟账号和ID</div>
          <div class="text">1. 打开<a href="https://pub.meituan.com" target="_blank">https://pub.meituan.com</a> ，登录账号之后进入美团分销联盟网页。<span style="color: red">（根据提示说明，选择对应的备案类型）</span></div>
          <div class="text">2. 查看右上角账户头像右边的字段，格式为：账号【id号码】，支持复制获取。</div>
          <img src="./images/image-mt-3.png" alt="" />
        </div>

        <div id="anchor-3">
          <div class="subtitle">获取Appkey</div>
          <div class="text">1. 在美团分销联盟网页中选择顶部导航API文档；</div>
          <div class="text">2. 在API文档页下查看：你的AppKey，支持复制获取；</div>
          <img src="./images/image-mt-4.png" alt="" />
        </div>
        <div id="anchor-4">
          <div class="subtitle">获取推广位ID</div>
          <div class="text">1. 在美团分销联盟网页中的顶部导航推广管理中选择下拉菜单“推广位管理”，查看所有已录入的媒体列表，包括推广位名称、推广位id、媒体名称、媒体类型、媒体id、创建时间等；</div>
          <div class="text">2. 查看推广位ID，支持复制获取；</div>
          <img src="./images/image-mt-5.png" alt="" />
        </div>
        <div id="anchor-5">
          <div class="subtitle">添加美团分销联盟账户</div>
          <div class="text">1. 进入添加美团分销联盟账户入口，将以上三步复制的的参数【美团分销联盟账号】、【美团分销联盟ID】、【Appkey】、【推广位ID】依次填写；</div>
          <div class="text">2. 请仔细核查复制的参数的正确性，以保证填写正确的美团分销联盟账户信息；</div>
          <img src="./images/image-mt-6.png" alt="" />
          <img src="./images/image-mt-7.png" alt="" />
        </div>
        <div id="anchor-6">
          <div class="subtitle">完成美团分销联盟账户授权</div>
          <div class="text">1.	点击添加后，满天星授权美团分销联盟流程全部完成，可在账户类型中查看对应美团分销联盟账户信息；</div>
          <img src="./images/image-mt-8.png" alt="" />
          <img src="./images/image-mt-9.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from '@/components/header/PageHeader'
export default {
  props: {},
  components: {
    PageHeader
  },
  data() {
    return {
      type: ''
    }
  },
  methods: {
    scrollTo (selector) {
      let anchor = this.$el.querySelector(selector)
      anchor.scrollIntoView({ behavior: 'smooth' })
    },
  },
  mounted() {
    const id = this.$route.query.id
    this.type = this.$route.query.type
    setTimeout(() => {
      this.scrollTo(`#${id}`)
    }, 1000)
  },
}
</script>
<style lang="less" scoped>
.content-wrap {
  min-height: calc(100vh - 60px);
  background: #F3F6FC;
  padding: 24px 0 31px;
  .container {
    width: 960px;
    margin: 0 auto;
    padding: 36px;
    background: #FEFFFF;
    .title {
      height: 28px;
      line-height: 28px;
      margin-bottom: 36px;
      font-size: 20px;
      color: #333;
      text-align: center;
    }
    img {
      width: 100%;
      margin-bottom: 36px;
    }
    div {
      line-height: 20px;
      &.subtitle {
        color: #333;
        margin-bottom: 12px;
      }
      &.text {
        color: #666;
        margin-bottom: 24px;
      }
    }
  }
}
</style>
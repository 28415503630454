<template>
  <div class="account-type">
    <contentHeader
      :isShowSwitch="false"
      :is-show-tips="false"
      header-type="12"
    ></contentHeader>
    <div class="tab">
      <a-button
        class="elm"
        :class="{ select: selectTab == 712 }"
        @click="checkTab(712)"
        >饿了么</a-button
      >
      <a-button
        class="mt"
        :class="{ select: selectTab == 2002 }"
        @click="checkTab(2002)"
        >美团</a-button
      >
    </div>

    <div class="tips" v-if="isShowTips && selectTab == 712">
      <img alt="" src="../../components/header/images/horn.png" />
      <div v-if="tipsInfo === 1" class="tips-text">
        您的淘宝联盟账户没有可用的淘宝联盟账号。如果您想继续使用自有联盟账户，请更新账户信息。
      </div>
      <div v-else-if="tipsInfo === 2" class="tips-text">
        您的淘宝联盟账户没有可用的推广位。如果您想继续使用自有联盟账户，请更新账户信息。
      </div>
      <div v-else-if="tipsInfo === 3" class="tips-text">
        您的淘宝联盟账户没有可用的淘宝联盟账号。您的淘宝联盟账户没有可用的推广位。如果您想继续使用自有联盟账户，请更新账户信息。
      </div>
    </div>
    <div class="box">
      <div class="page-title">选择账户类型</div>
      <p>如果选择自有联盟账户，【下单互动】中将不可使用返利模板</p>
      <div class="account-wrap">
        <!-- 饿了么tab -->
        <template v-if="selectTab == 712">
          <div
            class="account-item"
            :class="{ selected: selectedElmTypeId === 5513 }"
            @click="priviewHandler(5513)"
          >
            <div class="item-title">满天星系统账户</div>
            <div class="item-text">
              该账户模式将绑定满天星系统的淘宝联盟账户，并通过满天星结算
            </div>
            <a href="javascript:void(0);" class="item-btn">预览</a>
          </div>
<!--          <div-->
<!--            class="account-item"-->
<!--            :class="{ selected: selectedElmTypeId === 5515 }"-->
<!--            @click="priviewHandler(5515)"-->
<!--          >-->
<!--            <div class="item-title">淘宝联盟账户</div>-->
<!--            <div class="item-text">-->
<!--              饿了么的订单与佣金结算将会使用您自己的淘宝联盟账户-->
<!--            </div>-->
<!--            <a href="javascript:void(0);" class="item-btn">预览</a>-->
<!--          </div>-->
        </template>
        <!-- 美团tab -->
        <template v-if="selectTab == 2002">
          <div
            class="account-item"
            :class="{ selected: selectedMtTypeId === 5513 }"
            @click="priviewHandler(5513)"
          >
            <div class="item-title">满天星系统账户</div>
            <div class="item-text">
              该账户模式将绑定满天星系统的淘宝联盟账户，并通过满天星结算
            </div>
            <a href="javascript:void(0);" class="item-btn">预览</a>
          </div>
          <!-- <div
            class="account-item"
            :class="{ selected: selectedMtTypeId === 7355 }"
            @click="priviewHandler(7355)"
          >
            <div class="item-title">美团分销联盟账户</div>
            <div class="item-text">
              美团的订单与佣金结算将会使用您自己的美团分销联盟账户
            </div>
            <a href="javascript:void(0);" class="item-btn">预览</a>
          </div> -->
        </template>
      </div>
    </div>
    <div
      class="account-table"
      v-if="selectTab == 712 && selectedElmTypeId === 5515"
    >
      <div class="title-wrap">
        <div class="account-box">
          <div class="account-title">淘宝账户</div>
          <div class="account-num">
            当前已授权{{ accountList.length }}/5个淘客账号
          </div>
        </div>
        <a-button
          class="add-btn"
          :class="{ disable: accountList.length >= 5 }"
          type="primary"
          @click="authTaobaoHandler"
          >继续授权淘宝账号</a-button
        >
      </div>
      <a-table
        :columns="accountColumns"
        :data-source="accountList"
        :pagination="false"
        rowKey="id"
        bordered
      >
        <template slot="pid_count" slot-scope="text">
          <span :class="text == 0 ? 'danger' : text == 20 ? 'none' : ''"
            >{{ text }}/20</span
          >
        </template>
        <template slot="expire_time" slot-scope="item">
          <span>{{ item.expire_time }}</span>
          <div
            class="tag"
            :class="
              item.left_day < 29 && item.left_day > 7
                ? 'warning'
                : item.left_day < 7 && item.left_day > 0
                ? 'danger'
                : item.left_day <= 0
                ? 'none'
                : ''
            "
          >
            {{item.left_day &lt; 29  && item.left_day > 1 ? `剩余${item.left_day}天` : item.left_day &lt;= 1 && item.left_day > 0 ? '即将过期' : 0 >= item.left_day ? '授权过期' : '' }}
          </div>
        </template>
        <template slot="setting" slot-scope="item">
          <div class="setting-wrap">
            <a
              href="javascript:void(0);"
              :class="{ disable: item.pid_count == 20 }"
              @click="addPromotion(item)"
              >添加推广位</a
            >
            <a href="javascript:void(0);" @click="extendAuth(item)">延长授权</a>
            <a href="javascript:void(0);" @click="deleteAccount(item)">删除</a>
          </div>
        </template>
      </a-table>
    </div>
    <div
      class="account-table promotion-table"
      v-if="selectTab == 712 && selectedElmTypeId === 5515"
    >
      <div class="title-wrap">
        <div class="account-box">
          <div class="account-title">推广位管理</div>
          <div class="account-num">
            我们建议每1000个粉丝添加1个推广位，添加推广位是为了保证跟单准确，请根据粉丝情况添加相应数量的推广位。
          </div>
          <div class="account-num">
            共添加了 {{ pagination.total }} 个推广位，有效 {{ validCount }} 个
            。
          </div>
        </div>
      </div>
      <div class="search-box">
        <a-select
          default-value="全部淘客账号"
          style="width: 228px"
          @change="handleChange"
        >
          <a-select-option value="all"> 全部淘客账号 </a-select-option>
          <a-select-option
            v-for="item in accountList"
            :key="item.id"
            :value="item.tb_user_id"
          >
            {{ item.tb_user_nick }}
          </a-select-option>
        </a-select>
        <a-input-search
          v-model="pidName"
          placeholder="输入推广位名称查询"
          style="width: 228px"
          @search="onSearch"
        />
      </div>
      <a-table
        :columns="promotionColumns"
        :data-source="promotionList"
        :pagination="pagination"
        @change="getPidList"
        rowKey="id"
        bordered
      >
        <template slot="account" slot-scope="item">
          <a-popover trigger="click" @visibleChange="showPopover">
            <template slot="content">
              <div>{{ item.account }}</div>
            </template>
            <div class="account-tb-box" style="cursor: pointer">
              <div class="account-tb">{{ item.account }}</div>
              <span class="arrow" :class="{ down: isRotateArrow }"></span>
            </div>
          </a-popover>
        </template>
        <template slot="pid" slot-scope="item">
          <a-popover trigger="click" @visibleChange="showPidPopover">
            <template slot="content">
              <div>{{ item.pid }}</div>
            </template>
            <div class="account-tb-box" style="cursor: pointer">
              <div class="pro-name">{{ item.pid }}</div>
              <span class="arrow" :class="{ down: isRotatePidArrow }"></span>
            </div>
          </a-popover>
        </template>
        <!-- <template slot="pid" slot-scope="item">
          <div class="pro-name">{{ item.pid }}</div>
        </template> -->
        <template slot="status" slot-scope="item">
          <span
            :class="item.status == 2 ? 'orange' : item.status == 3 ? 'red' : ''"
          >
            {{ item.status === 1 ? "有效" : "推广位不存在" }}
          </span>
          <!-- <br /><a href="javascript: void(0)" v-if="item.status === 3">如何生效</a> -->
        </template>
        <template slot="setting" slot-scope="item">
          <a href="javascript:void(0);" @click="deletePromotion(item)">删除</a>
        </template>
      </a-table>
    </div>
    <div
      class="account-table"
      v-if="selectTab === 2002 && selectedMtTypeId === 7355"
    >
      <div class="title-wrap">
        <div class="account-box">
          <div class="account-title">美团账户</div>
          <div class="account-num">仅支持授权1个美团分销联盟账户</div>
        </div>
      </div>
      <a-table
        :columns="mtAccountColumns"
        :data-source="mtAccountList"
        :pagination="false"
        rowKey="mt_id"
        bordered
      >
        <template slot="mtStatus">有效</template>
        <template slot="mtHandler" slot-scope="item">
          <a href="javascript:void(0);" @click="editMtAccount(item)">编辑</a>
        </template>
      </a-table>
    </div>
    <div class="delete-dialog txdialog" v-if="isShowDeleteDialog">
      <div class="title">
        你将要删除{{ deleteType === 1 ? "淘客账号" : "推广位" }}
      </div>
      <div class="content">
        {{ deleteType === 1 ? deleteItem.tb_user_nick : deleteItem.pid }}
      </div>
      <div class="tip" v-if="deleteType === 1">对应的推广位也会一起删除</div>
      <div class="tip" v-else>删除后该推广位关联的用户将无法跟单</div>
      <div class="btn-wrap">
        <a-button @click="submitDelete(1)">删除</a-button>
        <a-button @click="submitDelete(2)">取消</a-button>
      </div>
    </div>
    <div class="result-main" v-if="isShowAuthResult">
      <img src="../authorizer/images/auth-result-icon.png" alt="" />
      <div class="text">是否已完成授权</div>
      <div class="result-btn-wrap">
        <a-button type="primary" @click="getAuthResult(0)">是的</a-button>
        <a-button @click="getAuthResult(1)">没有</a-button>
      </div>
    </div>
    <!-- <div class="auth-success" v-if="authTaobaoStatus == 1">
      <img src="../authorizer/images/icon-success.png" alt="">
      <div class="text">授权成功</div>
      <div class="subtext">当前已授权{{authAccountNum}}个淘宝联盟账户</div>
      <a-button type="primary" @click="showAddPromoteDialog">下一步</a-button>
    </div> -->
    <div class="extend-auth-success" v-if="extendSuccess">
      <img src="../authorizer/images/success.png" alt="" />
      <div class="text">
        <div style="display: flex; justify-content: center">
          <span>淘宝账号【</span>
          <div class="max-length">
            {{ authType == 2 && authInfo.nick ? authInfo.nick : "" }}
          </div>
          <span>】延长授权成功</span>
        </div>
        本次授权有效期至：
        {{ authType == 2 && authInfo.expire_time ? authInfo.expire_time : "" }}
      </div>
      <a href="javascript: void(0);" class="btn" @click="extendSuccess = false"
        >我知道了</a
      >
    </div>
    <div class="auth-fail" v-if="isShowAuthFail">
      <img src="../authorizer/images/wraning.png" alt="" />
      <div class="text">淘宝账号延长授权失败，请重新授权</div>
      <div class="btn-wrap">
        <a-button type="primary" @click="extendAuth(null)">重新授权</a-button>
        <a-button @click="closeAuthFail">稍后重试</a-button>
      </div>
    </div>
    <!-- <div class="submit-dialog" v-if="isShowSubmitCheckAccount">
      <div class="text">每月仅限切换1次账户类型<br />请确认是否切换账户</div>
      <div class="btn-wrap">
        <a-button class="submit" @click="submitCheck">确定</a-button>
        <a-button class="close" @click="isShowSubmitCheckAccount = false">关闭</a-button>
      </div>
    </div> -->
    <div class="set-success-dialog txdialog" v-if="isShowSetSucDialog">
      <img src="../../components/statusDialog/images/success.png" alt="" />
      <div class="title">
        {{
          selectTab == 712 && accountType === 5515 ? "淘宝" : "美团分销"
        }}联盟账户设置成功
      </div>
      <div class="subtitle">
        您之后的{{
          selectTab == 712 && accountType === 5515 ? "饿了么" : "美团"
        }}订单将使用您自己的{{
          selectTab == 712 && accountType === 5515 ? "淘宝" : "美团分销"
        }}联盟账户结算<br />
        <span v-if="selectTab == 712 && accountType === 5515"
          >如需添加更多淘客账号和推广位，可在【账户类型】饿了么订单中添加和编辑</span
        >
        <span v-else>如需更改账户信息，可在【账户类型】美团订单中编辑修改</span>
      </div>
      <a href="javascript:void(0);" @click="knowBtnHandler">知道了</a>
    </div>
    <div class="mt-dialog txdialog" v-if="isShowMtDialog">
      <div class="err-tip" v-if="writeInfoErr">{{ mtErrText }}</div>
      <div class="dia-title">{{ dialogTitle }}美团分销联盟账户</div>
      <div class="input-item">
        <div class="top">
          <span>美团分销联盟账户：</span>
          <router-link
            :to="{ path: '/help-account?id=anchor-2&type=mt' }"
            target="_blank"
            >如何获取美团分销联盟账户</router-link
          >
        </div>
        <a-input @change="writeInfoErr = false" v-model.trim="mtObj.account" />
      </div>
      <div class="input-item">
        <div class="top">
          <span>美团分销联盟ID：</span>
          <router-link
            :to="{ path: '/help-account?id=anchor-2&type=mt' }"
            target="_blank"
            >如何获取美团分销联盟ID</router-link
          >
        </div>
        <a-input @change="writeInfoErr = false" v-model.trim="mtObj.id" />
      </div>
      <div class="input-item">
        <div class="top">
          <span>Appkey：</span>
          <router-link
            :to="{ path: '/help-account?id=anchor-3&type=mt' }"
            target="_blank"
            >如何获取Appkey</router-link
          >
        </div>
        <a-input @change="writeInfoErr = false" v-model.trim="mtObj.key" />
      </div>
      <div class="input-item">
        <div class="top">
          <span>推广位ID：</span>
          <router-link
            :to="{ path: '/help-account?id=anchor-4&type=mt' }"
            target="_blank"
            >如何获取推广位ID</router-link
          >
        </div>
        <a-input @change="writeInfoErr = false" v-model.trim="mtObj.pid" />
      </div>
      <a-button
        class="add-btn"
        :class="{ disable: disableAddMtBtn }"
        type="primary"
        @click="addMtHandler"
        >添加</a-button
      >
      <a-button class="close-btn" @click="closeAddMtDialog">关闭</a-button>
    </div>
    <div class="guide" v-if="guideStep === 1 || guideStep === 2">
      <div
        class="elm-box"
        :style="{ left: guideStep === 1 ? '277px' : '439px' }"
      >
        <a-button v-if="guideStep === 1" class="elm">饿了么</a-button>
        <a-button v-else class="mt">美团</a-button>
      </div>
      <div class="account">
        <div class="account-item" v-if="guideStep === 1">
          <div class="item-title">淘宝联盟账户</div>
          <div class="item-text">
            饿了么的订单与佣金结算将会使用您自己的淘宝联盟账户
          </div>
          <a href="javascript:void(0);" class="item-btn">预览</a>
        </div>
        <div class="account-item" v-else>
          <div class="item-title">美团分销联盟账户</div>
          <div class="item-text">
            美团的订单与佣金结算将会使用您自己的美团分销联盟账户
          </div>
          <a href="javascript:void(0);" class="item-btn">预览</a>
        </div>
      </div>
      <div class="arrow">
        <img class="arrow-btn" src="./images/guide-arrow.png" alt="" />
        <img
          class="arrow-elm"
          v-if="guideStep === 1"
          src="./images/guide-arrow1.png"
          alt=""
        />
        <img
          class="arrow-mt"
          v-if="guideStep === 2"
          src="./images/guide-arrow2.png"
          alt=""
        />
      </div>
      <div class="text">
        点击这里的“预览”，<br />可以设置{{
          guideStep === 1 ? "淘宝" : "美团分销"
        }}联盟账户
      </div>
      <a class="btn" href="javascript:void(0);" @click="guideBtnHandler"
        >知道了</a
      >
    </div>
    <div class="txdialog dialog-no-account" v-if="tbAccountAllInvalid">
      <a href="javascript:void(0);" class="close-dia-btn" @click="tbAccountAllInvalid = false">关闭</a>
      <div class="title">您的淘宝联盟账户没有可用的淘宝联盟账号</div>
      <div class="subtitle">
        如果您想继续使用自有联盟账户，请选择已失效的账号更新信息或新增账号授权。<br />最多仅支持授权5个淘宝联盟账号。
      </div>
      <a-table
        :columns="dialogColumns"
        :data-source="accountList"
        :pagination="false"
        rowKey="id"
        bordered
      >
        <template slot="left_day" slot-scope="item">
          {{ item.left_day &lt;= 0 ? '已失效' : '' }}
        </template>
        <template slot="setting" slot-scope="item">
          <a href="javascript:void(0);" @click="extendAuth(item, 'add-promote')">更新账号</a>
        </template>
      </a-table>
      <div
        class="dialog-bottom"
        @click="authTaobaoHandler"
        v-if="accountList.length < 5"
      >
        <a-icon type="plus-circle" />新增授权淘宝联盟账号
      </div>
    </div>
    <AuthTaobao
      v-if="isShowAuthTaobaoDialog"
      :auth-taobao-status="authTaobaoStatus"
      :auth-url="authUrl"
      @closeAuthTaobao="closeAuthTaobao"
      @authResultDialog="authResultDialog"
    />
    <AddPromote
      v-if="isShowAddPromote"
      :add-promote-title="addPromoteTitle"
      :add-promote-taobao-account="addPromoteTaobaoAccount"
      :auth-info="authInfo"
      @closeAddPromote="closeAddPromote"
    />
    <DetailIntroduce
      v-if="isShowDetailIntroduceDialog"
      :account-type="accountType"
      :selected-elm-type-id="selectedElmTypeId"
      :selected-mt-type-id="selectedMtTypeId"
      :tb-account-list="accountList"
      :promotion-list="promotionList"
      :mt-account-list="mtAccountList"
      @detaliBtnHandler="detaliBtnHandler"
      @closeDetailDialog="closeDetailDialog"
    />
    <div
      class="mask"
      v-if="
        tbAccountAllInvalid ||
        isShowMtDialog ||
        isShowSetSucDialog ||
        extendSuccess ||
        isShowAuthFail ||
        isShowDeleteDialog ||
        isShowAuthResult ||
        isShowAuthTaobaoDialog
      "
    ></div>
  </div>
</template>
<script>
import contentHeader from "@/components/header/header";
import AddPromote from "./../authorizer/components/addPromote";
import DetailIntroduce from "./../authorizer/components/detailIntroduce";
import AuthTaobao from "../authorizer/components/authTaobao";
import {
  initPagePost,
  accountListPost,
  deleteAccountPost,
  deletePidPost,
  pidListPost,
  setMtInfoPost,
  mtAccountListPost,
  getTkAuthUrlPost,
} from "@/api/accountType";
import { authStatusPost, setAccountTypePost } from "@/api/authorizer";
const accountColumns = [
  {
    title: "序号",
    dataIndex: "index",
    customRender: (text, record, index) => `${index + 1}`,
    width: "90px",
    align: "center",
  },
  {
    title: "淘宝账号",
    dataIndex: "tb_user_nick",
    width: "150px",
    align: "center",
  },
  {
    title: "推广位数量",
    dataIndex: "pid_count",
    scopedSlots: { customRender: "pid_count" },
    width: "152px",
    align: "center",
  },
  {
    title: "授权到期时间",
    scopedSlots: { customRender: "expire_time" },
    width: "274px",
    align: "center",
  },
  {
    title: "操作",
    scopedSlots: { customRender: "setting" },
    width: "360px",
    align: "center",
  },
];
const promotionColumns = [
  {
    title: "序号",
    // dataIndex: "index",
    customRender: (text, record, index) => `${index + 1}`,
    width: "89px",
    align: "center",
  },
  {
    title: "淘宝账号",
    // dataIndex: "account",
    scopedSlots: { customRender: "account" },
    width: "150px",
    align: "center",
  },
  {
    title: "推广位名称&PID",
    // dataIndex: "pid",
    scopedSlots: { customRender: "pid" },
    width: "306px",
    align: "center",
  },
  {
    title: "创建时间",
    dataIndex: "createtime",
    width: "177px",
    align: "center",
  },
  {
    title: "状态",
    // dataIndex: "status",
    scopedSlots: { customRender: "status" },
    width: "152px",
    align: "center",
  },
  {
    title: "操作",
    scopedSlots: { customRender: "setting" },
    width: "152px",
    align: "center",
  },
];
const mtAccountColumns = [
  {
    title: "美团分销账号",
    dataIndex: "mt_account",
    width: "172px",
    align: "center",
  },
  {
    title: "美团分销联盟ID",
    dataIndex: "mt_id",
    width: "184px",
    align: "center",
  },
  {
    title: "Appkey",
    dataIndex: "mt_app_key",
    width: "210px",
    align: "center",
  },
  {
    title: "推广位ID",
    dataIndex: "mt_promotion_id",
    width: "144px",
    align: "center",
  },
  {
    title: "状态",
    // dataIndex: "status",
    scopedSlots: { customRender: "mtStatus" },
    width: "140px",
    align: "center",
  },
  {
    title: "操作",
    // dataIndex: "index",
    scopedSlots: { customRender: "mtHandler" },
    width: "176px",
    align: "center",
  },
];
const dialogColumns = [
  {
    title: "淘宝账号",
    dataIndex: "tb_user_nick",
    width: "229px",
    align: "center",
  },
  {
    title: "状态",
    // dataIndex: "tb_user_nick",
    scopedSlots: { customRender: "left_day" },
    width: "229px",
    align: "center",
  },
  {
    title: "操作",
    scopedSlots: { customRender: "setting" },
    width: "229px",
    align: "center",
  },
];
export default {
  name: "AccountType",
  components: {
    contentHeader,
    AddPromote,
    DetailIntroduce,
    AuthTaobao,
  },
  props: {},
  data() {
    return {
      accountColumns,
      accountList: [],
      promotionColumns,
      promotionList: [],
      mtAccountColumns,
      mtAccountList: [],
      dialogColumns,
      selectedElmTypeId: null, // 用户正在使用elm账户id
      selectedMtTypeId: null, // 用户正在使用mt账户id
      isShowDeleteDialog: false,
      deleteType: null, // 删除弹窗上的内容
      isShowAddPromote: false, // 添加推广位弹窗
      addPromoteTaobaoAccount: {}, // 新增推广位的淘宝账户信息
      isShowDetailIntroduceDialog: false,
      accountType: null, // 预览的弹窗id
      isShowAuthResult: false,
      authTaobaoStatus: true, // 淘宝授权状态 成功失败
      authInfo: {},
      authAccountNum: null,
      authUrl: "",
      authTaobaoCode: "",
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      pidName: "",
      isShowAuthFail: false, // 延长授权失败
      authType: null, // 授权类型 1、授权 2、延长授权
      extendSuccess: false, // 控制延长授权成功弹窗
      // isShowSubmitCheckAccount: false,
      // canChangeAccount: false,
      isRotateArrow: false,
      validCount: null, // 有效推广位个数
      isRotatePidArrow: false,
      selectTab: 712, // 美团: 2002 饿了么: 712 tab
      isShowAuthTaobaoDialog: false, // 授权淘客联盟弹窗
      isShowSetSucDialog: false, // 设置账户成功弹窗
      isShowMtDialog: false, // 填写美团信息
      mtObj: {
        // 美团联盟信息对象
        account: "",
        id: "",
        key: "",
        pid: "",
      },
      dialogTitle: "添加",
      writeInfoErr: false, // 展示美团错误信息
      guideStep: 0,
      mtErrText: "",
      isSendMtAjax: false,
      addPromoteTitle: "",
      tipsInfo: 0,
      isShowTips: false,
      accountNum: 0, // 账户数量
      promoteNum: 0, // 推广位数量
      tbAccountAllInvalid: false,
      addPromoteStep: '', // 无可用账户更新后跳转状态
    };
  },
  computed: {
    disableAddMtBtn() {
      const { mtObj } = this;
      if (!mtObj.account || !mtObj.id || !mtObj.key || !mtObj.pid) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async initPage() {
      const { status, data } = await initPagePost();
      if (status === 1) {
        this.selectedElmTypeId = Number(data.config.id);
        this.selectedMtTypeId = Number(data.config.mt_id);
        this.guideStep = data.config.is_need_guide?2:0;
        // this.canChangeAccount = data.config.available === 1 ? true : false
        if (this.selectedElmTypeId === 5515 && this.selectTab === 712) this.getAccountList();
        // if (this.selectedMtTypeId === 7355 && this.selectTab === 2002) this.getMtAccountList();
      }
    },
    async getMtAccountList(type) {
      const { status, data } = await mtAccountListPost();
      // data.mt_accounts = {
      //   "authorizer_appid": "wxb76fd7bf366f7f89",
      //   "mt_account": "kyjnn",
      //   "mt_id": "2038",
      //   "mt_app_key": "jS8oDwolOvJlbSp2",
      //   "mt_promotion_id": "46534"
      // }
      if (status === 1) {
        if (!data.mt_accounts.length && !data.mt_accounts.mt_id) {
          this.mtAccountList = data.mt_accounts
        } else {
          this.mtAccountList = [ data.mt_accounts ]
        }
        console.log(this.mtAccountList);
        if (type) {
          this.isShowDetailIntroduceDialog = true;
        }
      }
    },
    async getAccountList(type) {
      const { status, data } = await accountListPost({ shopid: 712 });
      if (status === 1) {
        this.authUrl = data.auth_url;
        this.authTaobaoCode = data.auth_code;
        this.accountList = data.list;
        this.accountNum = data.list.length;
        this.getPidList(null, null, null);
        if (type) {
          this.isShowDetailIntroduceDialog = true;
        }
      }
    },
    addPromotion(item) {
      if (item.pid_count == 20) return;
      this.addPromoteTaobaoAccount = item;
      this.isShowAddPromote = true;
      this.addPromoteTitle = "";
    },

    priviewHandler(type) {
      this.accountType = type;
      const { selectTab, accountType } = this;
      if (accountType === 5513) {
        this.isShowDetailIntroduceDialog = true
      }
      if (selectTab === 712 && accountType === 5515) this.getAccountList(type);
      if (selectTab === 2002 && accountType === 7355) this.getMtAccountList(type);
    },
    authTaobaoHandler() {
      if (this.accountList.length >= 5) return;
      if (this.tbAccountAllInvalid) this.tbAccountAllInvalid = false
      window.open(this.authUrl);
      this.isShowAuthResult = true;
    },
    async getAuthResult(diaStatus) {
      const { status, data } = await authStatusPost({
        auth_code: this.authTaobaoCode,
      });
      if (status === 1) {
        this.authInfo = data.auth_info;
        this.authType = data.op_type;
        if (diaStatus !== 1) {
          if (data.op_type === 1) {
            this.authAccountNum = data.total;
            this.authTaobaoStatus = data.err_code === 0 ? true : false;
            data.err_code === 0 ? this.showAddPromoteDialog() : (this.isShowAuthTaobaoDialog = true);
          } else {
            if (this.addPromoteStep === 'add-promote') return this.showAddPromoteDialog()
            data.err_code === 0 ? (this.extendSuccess = true) : (this.isShowAuthFail = true);
          }
        }
        this.isShowAuthResult = false;
        this.getAccountList();
      } else {
        // this.showAddPromoteDialog()
        this.isShowAuthResult = false;
        if (diaStatus !== 1) this.isShowAuthFail = true;
      }
    },
    closeAuthTaobao() {
      this.isShowAuthTaobaoDialog = false;
    },
    authResultDialog() {
      this.isShowAuthResult = true;
    },
    showAddPromoteDialog() {
      this.isShowAddPromote = true;
    },
    detaliBtnHandler(type) {
      const {
        accountType,
        selectedElmTypeId,
        selectedMtTypeId,
        selectTab,
        promotionList,
      } = this;
      if (type === 0) {
        this.closeDetailDialog();
        if (selectTab === 712) {
          if (accountType !== selectedElmTypeId)
            return this.submitCheck(selectTab, accountType);
        } else {
          if (accountType !== selectedMtTypeId)
            return this.submitCheck(selectTab, accountType);
        }
      } else {
        if (selectTab === 712 && accountType === 5515) {
          this.addPromoteTitle = "第二步：";
          this.getTkAuthUrl();
        }
        if (selectTab === 2002 && accountType === 7355) {
          this.isShowMtDialog = true;
        }
        this.closeDetailDialog();
      }
    },
    async getTkAuthUrl() {
      const { accountNum, promoteNum } = this
      console.log(accountNum, 'tk');
      // const { validCount, accountList } = this;
      const { status, data } = await getTkAuthUrlPost();
      if (status === 1) {
        this.authUrl = data.auth_url;
        this.authTaobaoCode = data.auth_code;
        if (accountNum <= 0) {
          this.authTaobaoStatus = true;
          this.isShowAuthTaobaoDialog = true;
          console.log(this.authTaobaoStatus, 'authTaobaoStatus');
        } else {
          const accountStatus = this.accountList.some((item) => {
            return item.left_day > 0;
          });
          if (accountStatus && !promoteNum) {
            let arr = [];
            this.isShowAddPromote = true;
            this.accountList.forEach((item) => {
              arr.push(item.left_day);
            });
            this.addPromoteTaobaoAccount = this.accountList.find((item) => {
              return item.left_day == Math.max(...arr);
            });
          } else if (accountStatus) {
            this.isShowAddPromote = true;
          } else if (!accountStatus && !promoteNum) {
            this.tbAccountAllInvalid = true;
            this.isShowTips = true;
            this.tipsInfo = 3;
          } else if (!accountStatus) {
            this.tbAccountAllInvalid = true;
            this.isShowTips = true;
            this.tipsInfo = 1;
          } else if (!promoteNum) {
            this.isShowTips = true;
            this.tipsInfo = 2;
          }
        }
        // if (!validCount && accountList.length) {
        //   this.isShowAddPromote = true
        //   this.authInfo.nick = accountList[0].tb_user_nick
        //   this.authInfo.tb_user_id = accountList[0].tb_user_id
        // }
      }
    },
    closeDetailDialog() {
      this.isShowDetailIntroduceDialog = false;
    },
    closeAddPromote(type) {
      const { selectTab, accountType, addPromoteTitle } = this;
      // if (addPromoteTitle && type === 'close') {

      // } //this.submitCheck(selectTab, accountType)
      if (addPromoteTitle && type === "add") this.isShowSetSucDialog = true;
      if (type === "add") this.getAccountList();
      this.isShowAddPromote = false;
      this.addPromoteTaobaoAccount = {};
    },
    extendAuth(item, step) {
      this.isShowAuthResult = true;
      this.isShowAuthFail = false;
      this.addPromoteStep = step ? step : ''
      if (item) {
        this.authUrl = item.auth_url;
        this.authTaobaoCode = item.auth_code;
      }
      if (this.tbAccountAllInvalid) this.tbAccountAllInvalid = false
      window.open(this.authUrl);
    },
    deleteAccount(item) {
      this.deleteItem = item;
      this.showDeleteDialog(1);
    },
    handleChange(value) {
      if (value === "all") {
        this.getPidList();
      } else {
        this.getPidList(null, value, this.pidName);
      }
    },
    onSearch() {
      const { pidName } = this;
      this.getPidList(null, null, pidName);
    },
    async deleteAccountFun() {
      const { deleteItem } = this;
      const { status, data, info } = await deleteAccountPost({
        tb_user_id: deleteItem.tb_user_id,
      });
      if (status === 1) {
        this.$message.success(info);
        this.isShowDeleteDialog = false;
        this.getAccountList();
        this.getPidList();
      }
    },
    async getPidList(page, account, pid) {
      let ajaxData = {};
      if (page && page.current) {
        this.pagination.current = page.current;
        ajaxData = {
          page: page.current,
          pagesize: 20,
        };
      } else if (pid || account) {
        ajaxData = { pid, account };
      } else {
        ajaxData = {
          page: this.pagination.current,
          pagesize: 20,
        };
      }
      const { status, data, info } = await pidListPost(ajaxData);
      if (status === 1) {
        this.promotionList = data.list;
        this.pagination.total = data.valid_count;
        this.validCount = data.valid_count;
        this.promoteNum = data.list.length;
      }
    },
    async deletePidFun() {
      const { deleteItem } = this;
      const { status, data, info } = await deletePidPost({
        pid: deleteItem.pid,
      });
      if (status === 1) {
        this.$message.success(info);
        this.isShowDeleteDialog = false;
        this.getPidList();
        this.getAccountList();
      }
    },
    deletePromotion(item) {
      this.deleteItem = item;
      this.showDeleteDialog(2);
    },
    showDeleteDialog(type) {
      this.isShowDeleteDialog = true;
      this.deleteType = type;
    },
    submitDelete(type) {
      const { deleteType } = this;
      if (type === 2) return (this.isShowDeleteDialog = false);
      if (deleteType === 1) {
        this.deleteAccountFun();
      } else {
        this.deletePidFun();
      }
    },
    closeAuthFail() {
      this.isShowAuthFail = false;
      this.isShowAuthResult = false;
    },
    async submitCheck(shop_id, config_id) {
      const { status, data } = await setAccountTypePost({ shop_id, config_id });
      if (status === 1) {
        this.authUrl = data.auth_url;
        this.authTaobaoCode = data.auth_code;
        // this.isShowSubmitCheckAccount = false
        this.initPage();
      } else {
        this.$message.error(data.msg);
      }
    },
    showPopover(value) {
      this.isRotateArrow = value;
    },
    showPidPopover(value) {
      this.isRotatePidArrow = value;
    },
    async addMtHandler() {
      const { mtObj, isSendMtAjax } = this;
      if (!mtObj.account || !mtObj.id || !mtObj.key || !mtObj.pid) return;
      if (isSendMtAjax) return;
      this.isSendMtAjax = true;
      const ajaxData = {
        mt_account: mtObj.account,
        mt_id: mtObj.id,
        mt_app_Key: mtObj.key,
        mt_promotion_id: mtObj.pid,
      };
      const { status, data, info } = await setMtInfoPost(ajaxData);
      if (status === 1) {
        this.closeAddMtDialog();
        this.getMtAccountList();
        this.isShowSetSucDialog = true;
      } else {
        this.mtErrText = info;
        this.writeInfoErr = true;
      }
      this.isSendMtAjax = false;
    },
    closeAddMtDialog() {
      this.mtObj.account = "";
      this.mtObj.id = "";
      this.mtObj.key = "";
      this.mtObj.pid = "";
      this.isShowMtDialog = false;
      if (this.writeInfoErr) this.writeInfoErr = false;
    },
    guideBtnHandler() {
      this.guideStep += 1;
      if (this.guideStep > 2) this.guideStep = 0;
    },
    editMtAccount(item) {
      this.mtObj.account = item.mt_account;
      this.mtObj.id = item.mt_id;
      this.mtObj.key = item.mt_app_key;
      this.mtObj.pid = item.mt_promotion_id;
      this.isShowMtDialog = true;
      this.dialogTitle = "编辑";
    },
    knowBtnHandler() {
      const { accountType, selectTab } = this;
      this.isShowSetSucDialog = false;
      this.submitCheck(selectTab, accountType);
    },
    checkTab(tabId) {
      this.selectTab = tabId;
      const { selectTab, selectedElmTypeId, selectedMtTypeId } = this;
      if (selectTab === 712 && selectedElmTypeId === 5515)
        this.getAccountList();
      if (selectTab === 2002 && selectedMtTypeId === 7355)
        this.getMtAccountList();
    },
  },
  mounted() {
    this.initPage();
  },
};
</script>
<style lang="less" scoped>
.tab {
  margin-bottom: 36px;
  .ant-btn {
    width: 135px;
    height: 50px;
    margin-right: 28px;
    background: #fff;
    border: 0.5px solid #999999;
    font-size: 20px;
    color: #333;
    font-weight: 500;
    &.elm {
      padding-left: 47px;
      background: #fff url("./images/elm-1.png") 13px center no-repeat;
      background-size: 28px 28px;
      &.select {
        color: #fff;
        background: #4392f6 url("./images/elm.png") 13px center no-repeat;
        border-color: #4392f6;
        background-size: 28px 28px;
      }
    }
    &.mt {
      padding-left: 54px;
      background: #fff url("./images/mt.png") 18px center no-repeat;
      background-size: 28px 28px;
      &.select {
        color: #fff;
        background: #4392f6 url("./images/mt-1.png") 18px center no-repeat;
        border-color: #4392f6;
        background-size: 28px 28px;
      }
    }
  }
}
.box {
  width: 1080px;
  height: 430px;
  padding: 24px;
  margin-bottom: 48px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 12px 0px #dce3f0;
  p {
    height: 22px;
    line-height: 22px;
    margin-bottom: 24px;
    color: #666;
    font-size: 16px;
  }
}
.page-title {
  height: 28px;
  line-height: 28px;
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
}
.account-wrap {
  display: flex;
  margin-bottom: 48px;
  .account-item {
    position: relative;
    width: 336px;
    height: 264px;
    text-align: center;
    padding: 36px 32px;
    margin-right: 36px;
    font-size: 18px;
    color: #666;
    background: #fff;
    border-radius: 12px;
    cursor: pointer;
    box-shadow: 0px 8px 20px 0px #dce3f0;
    border: 1px solid #d9d9d9;
    &.selected {
      position: relative;
      border: 1px solid #4392f6;
      box-sizing: border-box;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-radius: 0 8px;
        border-top: 30px solid #4392f6;
        border-right: 30px solid #4392f6;
        border-bottom: 30px solid transparent;
        border-left: 30px solid transparent;
      }
      &::before {
        content: "";
        position: absolute;
        right: 5px;
        top: 10px;
        width: 20px;
        height: 10px;
        border-left: 3px solid #fff;
        border-bottom: 3px solid #fff;
        border-radius: 2px;
        transform: rotate(-45deg);
        z-index: 1;
      }
    }
    .item-title {
      height: 28px;
      line-height: 28px;
      margin-bottom: 16px;
      font-size: 20px;
      color: #333;
    }
    .item-text {
      line-height: 24px;
    }
    .item-btn {
      position: absolute;
      bottom: 36px;
      left: 36px;
      width: 264px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      font-size: 18px;
      background: #f3f6fc;
      border-radius: 4px;
    }
  }
}
.account-table {
  width: 1080px;
  padding: 24px;
  margin-bottom: 48px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 12px 0px #dce3f0;
  &.promotion-table {
    .account-num {
      margin-bottom: 6px;
    }
  }
  .title-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .account-title {
      height: 28px;
      line-height: 28px;
      font-size: 20px;
      color: #333;
      margin-bottom: 10px;
    }
    .account-num {
      height: 22px;
      line-height: 22px;
      font-size: 16px;
      color: #666;
    }
    .add-btn {
      width: 200px;
      height: 60px;
      font-size: 20px;
      &.disable {
        opacity: 0.3;
      }
    }
  }
  .danger {
    color: #ff4141;
  }
  .none {
    color: #ccc;
  }
  .tag {
    display: inline-block;
    // height: 28px;
    // line-height: 28px;
    padding: 4px 6px;
    border-radius: 4px;
    font-size: 14px;
    margin-left: 12px;
    &.danger {
      color: #fff;
      background: #ff4141;
    }
    &.warning {
      color: #ff7a00;
      background: #fff3e8;
    }
    &.none {
      color: #fff;
      background: #cccccc;
    }
  }
  .setting-wrap {
    display: flex;
    justify-content: center;
    a.disable {
      color: #999999;
    }
    a:nth-child(2) {
      margin: 0 36px;
    }
  }
}
.promotion-table {
  .search-box {
    display: flex;
    justify-content: space-between;
    height: 36px;
    margin-bottom: 24px;
    /deep/ .ant-select-selection--single {
      height: 36px;
    }
    /deep/ .ant-input {
      height: 36px;
    }
  }
  span.orange {
    color: #ff7a00;
  }
  span.red {
    color: #ff4141;
  }
  .pro-name {
    margin: 0 auto;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.delete-dialog {
  width: 456px;
  height: 291px;
  padding: 48px 24px 24px;
  text-align: center;
  .title {
    height: 34px;
    line-height: 34px;
    color: #333;
    font-size: 24px;
    margin-bottom: 24px;
  }
  .content {
    width: 408px;
    height: 25px;
    line-height: 25px;
    margin-bottom: 12px;
    font-size: 18px;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .tip {
    height: 28px;
    line-height: 28px;
    margin-bottom: 36px;
    font-size: 20px;
    color: #ff4141;
  }
  .btn-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    .ant-btn {
      width: 192px;
      height: 60px;
      color: #4392f6;
      font-size: 20px;
      border-color: #4392f6;
    }
  }
}
.result-main,
.auth-success,
.auth-fail,
.extend-auth-success,
.submit-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 480px;
  height: 334px;
  background: #fff;
  border-radius: 12px;
  padding: 63px 36px 36px;
  transform: translate(-50%, -50%);
  z-index: 4;
  color: #333;
  font-size: 20px;
  text-align: center;
  img {
    width: 74px;
    height: 86px;
    margin-bottom: 25px;
  }
  .text {
    height: 28px;
    left: 28px;
    margin-bottom: 36px;
  }
  .result-btn-wrap {
    display: flex;
    justify-content: space-between;
    .ant-btn {
      width: 192px;
      height: 60px;
      font-size: 20px;
      &:last-of-type {
        color: #4392f6;
        border-color: #4392f6;
      }
    }
  }
}
.auth-success {
  width: 762px;
  height: 440px;
  padding-top: 65px;
  img {
    width: 90px;
    height: 90px;
    margin-bottom: 24px;
  }
  .text {
    height: 45px;
    line-height: 45px;
    margin-bottom: 2px;
    font-size: 32px;
    color: #333;
  }
  .subtext {
    height: 24px;
    line-height: 24px;
    margin-bottom: 70px;
    color: #ff7a00;
    font-size: 18px;
  }
  .ant-btn {
    width: 248px;
    height: 60px;
    font-size: 20px;
  }
}
.auth-fail {
  height: 329px;
  padding: 46px 36px 36px;
  img {
    width: 114px;
    height: 99px;
    margin-bottom: 24px;
  }
  .text {
    height: 28px;
    line-height: 28px;
    margin-bottom: 36px;
    font-size: 20px;
    color: #333;
    font-weight: 700;
  }
  .btn-wrap {
    display: flex;
    justify-content: space-between;
  }
  .ant-btn {
    width: 192px;
    height: 60px;
    font-size: 20px;
    border-color: #4392f6;
    &:last-child {
      color: #4392f6;
    }
  }
}
.extend-auth-success {
  height: 357px;
  padding: 69px 36px 32px;
  img {
    width: 80px;
    height: 80px;
    margin-bottom: 24px;
  }
  .text {
    // display: flex;
    height: 56px;
    line-height: 28px;
    margin-bottom: 36px;
    color: #333;
    font-weight: 700;
    .max-length {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  a {
    display: block;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #4392f6;
    color: #4392f6;
    font-size: 20px;
  }
}
.submit-dialog {
  width: 408px;
  height: 248px;
  padding: 48px 24px 24px;
  .text {
    height: 80px;
    line-height: 40px;
    color: #333333;
    font-size: 24px;
    text-align: center;
  }
  .btn-wrap {
    display: flex;
    justify-content: space-between;
    .ant-btn {
      height: 60px;
      font-size: 20px;
      color: #666666;
      border-color: #666;
    }
    .submit {
      width: 216px;
    }
    .close {
      width: 132px;
    }
  }
}
/deep/ .ant-table-thead > tr > th,
/deep/ .ant-table-tbody > tr > td {
  color: #666;
  height: 60px;
  padding: 0;
}
.account-tb-box {
  position: relative;
  display: flex;
  padding-left: 14px;
  .account-tb {
    max-width: 101px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .arrow {
    position: absolute;
    top: 3.5px;
    right: 12px;
    width: 14px;
    height: 14px;
    background: url("./images/arrow.png") center center no-repeat;
    background-size: contain;
    transform: rotate(90deg);
    &.down {
      transform: rotate(270deg);
    }
  }
}
.set-success-dialog {
  height: 464px;
  padding: 65px 41px 38px;
  text-align: center;
  font-size: 20px;
  color: #333;
  img {
    width: 90px;
    height: 90px;
    margin-bottom: 24px;
  }
  .title {
    height: 45px;
    line-height: 45px;
    margin-bottom: 20px;
    font-size: 32px;
  }
  .subtitle {
    line-height: 32px;
  }
  a {
    position: absolute;
    bottom: 38px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    border-radius: 6px;
    background: #4392f6ff;
    font-size: 20px;
    color: #fff;
  }
}
.mt-dialog {
  height: 504px;
  padding: 36px 36px 42px 36px;
  .err-tip {
    position: absolute;
    top: 45px;
    left: 352px;
    height: 21px;
    line-height: 21px;
    color: #f00;
    font-size: 15px;
    &::before {
      content: "!";
      position: absolute;
      left: -18px;
      top: 4px;
      width: 13px;
      height: 13px;
      line-height: 13px;
      text-align: center;
      border-radius: 50%;
      color: #fff;
      background: #f00;
      font-size: 12px;
    }
  }
  .dia-title {
    height: 39px;
    line-height: 39px;
    font-size: 28px;
    margin-bottom: 31px;
    color: #333;
    font-weight: 500;
  }
  .input-item {
    width: 454px;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 24px;
      margin-bottom: 11px;
      font-size: 14px;
      span {
        font-size: 16px;
      }
    }
    .ant-input {
      height: 36px;
    }
  }
  .ant-btn {
    width: 200px;
    height: 60px;
    position: absolute;
    right: 36px;
    font-size: 20px;
  }
  .add-btn {
    bottom: 114px;
    &.disable {
      opacity: 0.3;
    }
  }
  .close-btn {
    bottom: 42px;
    color: #4392f6;
    border-color: #4392f6;
  }
}
.guide {
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 9998;
  background: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  .elm-box {
    position: absolute;
    top: 191px;
    left: 277px;
    width: 177px;
    height: 78px;
    background: #fff;
    .ant-btn {
      width: 135px;
      height: 50px;
      margin-top: 15px;
      margin-left: 23px;
      padding-left: 47px;
      background: #fff;
      font-size: 20px;
      color: #fff;
      font-weight: 500;
      background: #4392f6 url("./images/elm.png") 13px center no-repeat;
      border-color: #4392f6;
      background-size: 28px 28px;
      cursor: default;
      &.mt {
        border: 0.5px solid #999999;
        color: #333;
        padding-left: 54px;
        background: #fff url("./images/mt.png") 18px center no-repeat;
        background-size: 28px 28px;
      }
    }
  }
  .account {
    position: absolute;
    top: 382px;
    left: 668px;
    width: 384px;
    height: 305px;
    background: #fff;
    padding: 17px 28px 24px;
    .account-item {
      position: relative;
      width: 336px;
      height: 264px;
      text-align: center;
      padding: 36px 32px;
      margin-right: 36px;
      font-size: 18px;
      color: #666;
      background: #fff;
      border-radius: 12px;
      box-shadow: 0px 8px 20px 0px #dce3f0;
      border: 1px solid #d9d9d9;
      .item-title {
        height: 28px;
        line-height: 28px;
        margin-bottom: 16px;
        font-size: 20px;
        color: #333;
      }
      .item-text {
        line-height: 24px;
      }
      .item-btn {
        position: absolute;
        bottom: 36px;
        left: 36px;
        width: 264px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        font-size: 18px;
        background: #f3f6fc;
        border-radius: 4px;
        cursor: default;
      }
    }
  }
  .text {
    position: absolute;
    top: 477px;
    left: 1118px;
    width: 216px;
    height: 55px;
    line-height: 27px;
    color: #fff;
    font-size: 18px;
    text-align: center;
  }
  .btn {
    position: absolute;
    top: 552px;
    left: 1171px;
    width: 100px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 4px;
    border: 1px solid #ffffff;
    color: #fff;
    font-size: 16px;
  }
  .arrow {
    img {
      position: absolute;
      width: 182px;
      height: 182px;
    }
    .arrow-elm {
      top: 210px;
      left: 490px;
    }
    .arrow-mt {
      top: 192px;
      left: 698px;
    }
    .arrow-btn {
      top: 560px;
      left: 1080px;
      width: 65px;
      height: 65px;
    }
  }
}

.tips {
  display: flex;
  align-items: center;
  width: 100%;
  height: 68px;
  border-radius: 8px;
  background: #ffe9e8;
  margin-bottom: 36px;
  padding: 0 24px;
  font-size: 14px;
  color: #ff4141;

  img {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  .tips-text {
    flex: 1;
    line-height: 20px;
  }
}
.dialog-no-account {
  height: auto;
  .title {
    height: 39px;
    line-height: 39px;
    margin-bottom: 24px;
    font-size: 28px;
    color: #333;
  }
  .subtitle {
    margin-bottom: 24px;
    line-height: 28px;
    color: #666;
    font-size: 18px;
  }
  /deep/ .ant-table-bordered .ant-table-body > table {
    background-color: #f3f6fc;
    border: 1px solid #d7e3f5;
  }
  /deep/ .ant-table-bordered.ant-table-empty .ant-table-placeholder {
    border: 1px solid #d7e3f5;
  }
  /deep/ .ant-table-placeholder {
    border-radius: 0;
  }
  .close-dia-btn{
    top: 36px;
    color: #333;
    &::after{
      top: 2px;
      right: -20px;
      width: 18px;
      height: 18px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiBAMAAADIaRbxAAAAG1BMVEUAAAAyMjIwMDAwMDAzMzMyMjIyMjIyMjIzMzOMyWXQAAAACHRSTlMA3yAQz4BwYCC2TscAAAB7SURBVCjPxdDBDYAgDAVQg3EAOfUIJno2bmDcwBVcwBVIODi2pPb7wwT0AD/wSGi75uVOC/NqYUjhu5EbRrzuMZspUZGTo6xEIEQgRCBEkxGi0QjRA8ITj4hXewo1yZsQ2XcjEDpCdyTojgTdkSj6J1ZIPbH+srDAtKsX7oIUKxBDeOgAAAAASUVORK5CYII=)no-repeat center;
      background-size: 100%;
      border: none;
      transform: none;
    }
  }
  .dialog-bottom {
    height: 60px;
    line-height: 60px;
    text-align: center;
    color: #4392f6;
    font-size: 14px;
    border: 1px solid #d7e3f5;
    border-top: none;
    border-radius: 0 0 4px 4px;
    cursor: pointer;
    .anticon {
      margin-right: 4px;
    }
  }
}
</style>

<template>
  <div :class="['preview', type == 1 ? 'small' : '']"  @click="showPreviewDia(data)">
    <div class="preview-title">{{ data.title }}</div>
    <div class="tips" v-if="type != 3">
      <div class="tip-item" v-html="data.text"></div>
    </div>
    <div :class="['img-box', type == 4 ? 'wide' : '']">
      <img
        v-if="isElmSpecial"
        :class="imgSize"
        :src="data.imgUrlWhite || data.imgUrl"
        alt=""
      />
      <img :class="imgSize" v-else :src="data.imgUrl" alt="" />
    </div>
    <a
      href="javascript:void(0);"
      class="preview-btn"
      >预览</a
    >
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    /**
     * 根据不同的type展示不同大小的模块
     * 0. 默认 皮肤模板  模块内的图片最大
     * 1. 每日签到  模块图片内的最小
     * 2. 欢迎语、吃饭提醒、保持活跃  模块内的图片一般
     */
    type: {
      default: "",
    },
    isElmSpecial: {
      default: 0,
    },
  },
  data() {
    return {};
  },
  computed: {
    imgSize() {
      const type = Number(this.type);

      if (type === 1) {
        return "mini";
      } else if (type === 2 || type === 4) {
        return "small";
      } else {
        return "";
      }
    },
  },
  methods: {
    showPreviewDia(data) {
      const dataObj = {
        previewId: data.id,
        imgNum: data.imgNum,
        showPreview: true,
      };
      this.$emit("showPreviewDia", dataObj);
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.preview {
  display: flex;
  flex-direction: column;
  width: 336px;
  height: 454px;
  padding: 36px 0;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 8px 20px 0px #dce3f0;
  text-align: center;
  margin: 0 36px 36px 0;
  cursor: pointer;
  .preview-title {
    height: 28px;
    line-height: 28px;
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 20px;
    color: #333847;
  }
  .tips {
    line-height: 24px;
    margin-bottom: 39px;
    color: #666;
    font-size: 14px;
  }
  .img-box {
    flex: 1;
    width: 264px;
    margin: 0 auto 24px;
    img {
      width: 264px;
      height: 258px;
      &.small {
        width: 264px;
        height: 186px;
      }
      &.mini {
        width: 264px;
        height: 106px;
      }
    }
  }
  .preview-btn {
    width: 264px;
    height: 48px;
    margin: 0 auto;
    border-radius: 4px;
    background: #f3f6fc;
    line-height: 48px;
    text-align: center;
    color: #4392f6;
    font-size: 18px;
  }
  &.selected {
    position: relative;
    border: 1px solid #4392f6;
    box-sizing: border-box;
    .preview-title,
    .tip-item {
      color: #4392f6;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-radius: 0 8px;
      border-top: 30px solid #4392f6;
      border-right: 30px solid #4392f6;
      border-bottom: 30px solid transparent;
      border-left: 30px solid transparent;
    }
    &::before {
      content: "";
      position: absolute;
      right: 5px;
      top: 10px;
      width: 20px;
      height: 10px;
      border-left: 3px solid #fff;
      border-bottom: 3px solid #fff;
      border-radius: 2px;
      transform: rotate(-45deg);
      z-index: 1;
    }
  }
  &.small {
    height: 362px;
  }
}
</style>

import axios from '@/utils/axios'
const API = {
  // init: '/bonus/ajaxinit',
  // appPath: '/bonus/ajaxSetBonusConfig',
  initHbList: '/coupon/ajaxInit',
  hbPosition: '/coupon/ajaxSetCouponConfig',
}
// function initPagePost () {
//   return axios.post(API.init)
// }
// function setAppPathPost (param) {
//   return axios.post(API.appPath, param)
// }
function initHbListPost () {
  return axios.post(API.initHbList)
}
function hbPositionPost (param) {
  return axios.post(API.hbPosition, param)
}

export {
  // initPagePost,
  // setAppPathPost,
  initHbListPost,
  hbPositionPost
}
<template>
  <span class="count-down">{{ isShowHour || hour ? hourString + ':' + minuteString + ':' + secondString : minuteString + ':' + secondString }}</span>
</template>

<script>
export default {
  props: {
    remainTime: {
      type: Number,
      default: null,
    },
    isShowHour: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hour: "",
      minute: "",
      second: "",
      promiseTimer: "",
    };
  },
  mounted() {
    if (this.remainTime > 0) {
      this.hour = Math.floor((this.remainTime / 3600) % 24);
      this.minute = Math.floor((this.remainTime / 60) % 60);
      this.second = Math.floor(this.remainTime % 60);
      this.initCountDown();
    }
  },
  methods: {
    initCountDown() {
      let that = this;

      clearInterval(this.promiseTimer)
        this.promiseTimer = setInterval(function () {
          if (that.hour === 0) {
            if (that.minute !== 0 && that.second === 0) {
              that.second = 59
              that.minute -= 1
            } else if (that.minute === 0 && that.second === 0) {
              that.second = 0
              // that.$emit('countDowmEnd', orderNo)
              clearInterval(that.promiseTimer)
            } else {
              that.second -= 1
            }
          } else {
            if (that.minute !== 0 && that.second === 0) {
              that.second = 59
              that.minute -= 1
            } else if (that.minute === 0 && that.second === 0) {
              that.hour -= 1
              that.minute = 59
              that.second = 59
            } else {
              that.second -= 1
            }
          }
        }, 1000)
    },
    formatNum (num) {
      return num < 10 ? '0' + num : '' + num
    },
  },
  computed: {
    hourString () {
      return this.formatNum(this.hour)
    },
    minuteString () {
      return this.formatNum(this.minute)
    },
    secondString () {
      return this.formatNum(this.second)
    }
  }
};
</script>

<style lang="less" scoped>
.count-down {
  line-height: 20px;
  font-size: 14px;
  color: #666;
}
</style>

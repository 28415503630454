<template>
  <div class="status-dialog" v-if="showStatusDialog">
    <div class="mask"></div>
    <div class="status-dialog-box">
      <div class="dialog-status-icon">
        <img src="./images/danger.png" alt="">
      </div>
      <div class="dialog-status-title">{{ dialogStatus == 1 ? '发布' : dialogStatus == 2 ? '确认删除' : dialogStatus == 3 ? '子菜单确认' : '发布失败'}}</div>
      <div class="dialog-status-subtitle" v-html="dialogStatus == 1 ? '发布成功后会覆盖原版本，且将在24小时内对所有用户生<br>效，确认发布？' : dialogStatus == 2 ? '一旦删除，“外卖红包”下设置的所有内容都将被删除' : dialogStatus == 3 ? '添加子菜单后，一级菜单的内容将被清除，<br>确定添加子菜单？': '系统繁忙，请稍后重试'"></div>
      <div class="dialog-status-btn-box">
        <a href="javascript:void(0);" class="dialog-status-btn-again" @click="confirmStatusDialog">确认</a>
        <a href="javascript:void(0);" class="dialog-status-btn-cancel" @click="closeStatusDialog">取消</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dialogStatus: {
      default: 0
    },
    showStatusDialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  methods: {
    closeStatusDialog () {
      this.$emit('closeStatusDialog', false)
    },
    confirmStatusDialog(){
      this.$emit('confirmStatusDialog')
    }
  }
}
</script>

<style lang="less" scoped>
.status-dialog {
  position: relative;
  .status-dialog-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    width: 432px;
    // height: 370px;
    background: #fff;
    border-radius: 12px;
    padding: 49px 36px 36px;
    text-align: center;
    .dialog-status-icon {
      width:114px;
      height: 99px;
      text-align: center;
      margin:0 auto 24px;
      img {
        display:block;
        margin:0 auto;
        width: 100%;
      }
    }
    .dialog-status-title {
      height: 28px;
      margin-bottom: 12px;
      line-height: 28px;
      font-size: 20px;
      font-weight: medium;
      color: #333847;
      font-weight: 500;
    }
    .dialog-status-subtitle {
      // height: 20px;
      margin-bottom: 36px;
      line-height: 20px;
      font-size: 14px;
      color: #5C6485;
    }
    .dialog-status-btn {
      display: block;
      width: 360px;
      height: 60px;
      background: #4392F6;
      border-radius: 6px;
      line-height: 60px;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }
    .dialog-status-btn-box{
      display:flex;
      justify-content: center;
      height:60px;
      .dialog-status-btn-cancel{
        display:block;
        width:120px;
        height:60px;
        margin-left:24px;
        line-height:58px;
        text-align: center;
        font-size:20px;
        color:#5C6485;
        font-weight: medium;
        box-sizing: border-box;
        border-radius: 6px;
        border: 1px solid #5C6485;
      }
      .dialog-status-btn-again{
        display:block;
        width:216px;
        height:60px;
        line-height:60px;
        text-align: center;
        font-size:20px;
        color:#FFF;
        font-weight: medium;
        background: #4392F6;
        border-radius: 6px;
      }
    }
  }
}
</style>
<template>
  <div class="skin-box">
    <contentHeader :isShowSwitch="false" header-type="6"></contentHeader>
    <div class="content">
      <template v-for="item in previewList">
        <previewModule
          :key="item.id"
          :data="item"
          :class="[selectedId == item.id ? 'selected' : '']"
          @showPreviewDia="showPreviewDia"
          type="3"
        ></previewModule>
      </template>
      <div class="more-skin">
        <img src="./images/more-skin.png" alt="">
        <span>更多模版即将上架</span>
      </div>
    </div>
    <previewDialog
      :transmit-data="transmitData"
      :data-list="dataList"
      :selected-id="selectedId"
      @selectedOk="selectedOk"
      @closePreviewDia="closePreviewDia"
      size-status="1"
    ></previewDialog>
    <toast v-if="isShowToast" :set-status="setStatus"></toast>
  </div>
</template>

<script>
import contentHeader from '@/components/header/header'
import previewModule from '@/components/preview/preview'
import previewDialog from '@/components/dialog/dialog'
import toast from '@/components/toast/toast'
import { skinMixin } from '@/mixins/mixins'
import { initPagePost, setModulePost } from '@/api/skinbox'

export default {
  mixins: [skinMixin],
  components: {
    contentHeader,
    previewModule,
    previewDialog,
    toast
  },
  data () {
    return {
      selectedId: null,
      transmitData: { // 传给弹窗的数据
        previewId: null, // 点击预览的id
        showPreview: false, // 显示预览弹窗
        imgNum: null, // 弹窗的图片的数量
        isElmSpecial: 0
      },
      isDefaultChecked: null,
      setStatus: null, // 设置模板失败或者成功 0 失败 1 成功
      isShowToast: false
    }
  },
  methods: {
    async initPage () {
      const { status, data } = await initPagePost()
      if (status === 1) {
        this.isDefaultChecked = data.switch
        this.selectedId = data.config.id
        this.transmitData.isElmSpecial = data.is_elm_special
        this.previewList = this.previewList.filter(item => {
          return data.config.ids.indexOf(item.id + '') > -1
        });
      }
    },
    showPreviewDia (data) {
      // this.transmitData = { ...data }
      this.transmitData = Object.assign(this.transmitData, data)
    },
    closePreviewDia (val) {
      this.transmitData.showPreview = val
    },
    async selectedOk ({ id }) {
      const { status, data } = await setModulePost({ config_id: id })
      if (status == 1) {
        this.selectedId = id
        this.setStatus = 1
      } else {
        this.setStatus = 0
      }
      this.isShowToast = true
      setTimeout(() => {
        this.isShowToast = false
      }, 2000)
    }
  },
  created () {
    this.initPage()
  }
}
</script>

<style lang="less" scoped>
.content {
  display: flex;
  flex-wrap: wrap;
  width: 1080px;
  .preview:nth-child(3n) {
    margin-right: 0;
  }
  .more-skin {
    width: 336px;
    height: 454px;
    border-radius: 12px;
    background: #E9EDF8;
    text-align: center;
    img {
      display: block;
      width: 180px;
      height: 180px;
      margin: 119px auto 12px;
    }
    span {
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      color: #5C6485;
    }
  }
}
</style>

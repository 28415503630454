import router from '@/router'
import storage from 'store'
import { initGlobalPagePost } from '@/api/init'
import { initAuthPost, authBackPost, initMiniPost, miniBackPost } from '@/api/authorizer'
import { ACCESS_TOKEN } from '@/store/globalConst'

const authorizer = {
  state: {
    authLink: '',
    authStatus: 1, // 授权状态 1 初次授权 2 授权过期 3 授权失败-不是服务号 4 授权失败-服务号未认证 5 授权失败-未开启权限
    relationStatus: 1, // 关联小程序状态 0 关联失败 1 关联成功
    stepCurrent: 0,
    isNeedAuth: 0, // 是否需要授权 1 是 0 否
    isNeedLink: 0, // 是否需要关联小程序 1 是 0 否
    authInfo: null, // 小程序信息
    isShowAgreement: false, // 是否展示用户使用协议弹窗
    systemVersionCommon: null, // 用户协议版本号
    statusDialog:0, //授权或关联失败状态弹窗
    serviceEndDate: "", // 服务期至...
    shichiSwitch:'',//试吃团开关
    accountTypeSwitch: '', // 账户类型开关
    // authStepSwitch: '', // 授权选择账户开关
    // isSelectAccount: '', // 是否展示选择联盟账户
  },
  getters: {
    authLink: ({ authLink }) => authLink,
    authStatus: ({ authStatus }) => authStatus,
    relationStatus: ({ relationStatus }) => relationStatus,
    stepCurrent: ({ stepCurrent }) => stepCurrent,
    isNeedAuth: ({ isNeedAuth }) => isNeedAuth,
    isNeedLink: ({ isNeedLink }) => isNeedLink,
    authInfo: ({ authInfo }) => authInfo,
    isShowAgreement: ({ isShowAgreement }) => isShowAgreement,
    systemVersionCommon: ({ systemVersionCommon }) => systemVersionCommon,
    statusDialog: ({ statusDialog }) => statusDialog,
    serviceEndDate: ({ serviceEndDate }) => serviceEndDate,
    shichiSwitch: ({ shichiSwitch }) => shichiSwitch,
    accountTypeSwitch: ({ accountTypeSwitch }) => accountTypeSwitch,
    // authStepSwitch: ({ authStepSwitch }) => authStepSwitch,
    // isSelectAccount: ({ isSelectAccount }) => isSelectAccount,
  },
  mutations: {
    SET_AUTH_LINK: (state, link) => state.authLink = link,
    SET_AUTH_STATUS: (state, status) => state.authStatus = status,
    SET_RELATION_STATUS: (state, status) => state.relationStatus = status,
    SET_STEP_CURRENT: (state, step) => state.stepCurrent = step,
    SET_ISNEED_AUTH: (state, status) => state.isNeedAuth = status,
    SET_ISNEED_LINK: (state, status) => state.isNeedLink = status,
    SET_AUTH_INFO: (state, info) => state.authInfo = info,
    SET_ISSHOW_AGREEMENT: (state, isShow) => state.isShowAgreement = isShow,
    SET_SYSTEM_VERSION_COMMON: (state, version) => state.systemVersionCommon = version,
    SET_STATUS_DIALOG: (state, status) => state.statusDialog = status,
    SET_SERVICE_END_DATE: (state, date) => state.serviceEndDate = date,
    SET_SHICHI_SWITCH: (state, status) => state.shichiSwitch = status,
    SET_ACCOUNT_TYPE_SWITCH: (state, status) => state.accountTypeSwitch = status,
    // SET_AUTH_STEP_SWITCH: (state, status) => state.authStepSwitch = status,
    // SET_IS_SELECT_ACCOUNT: (state, type) => state.isSelectAccount = type,
  },
  actions: {
    // 授权初始化  获取授权链接
    async getAuthLink ({ commit,  dispatch, state }, auth_code) {
      const { status, data } = await initAuthPost()
      if (status == 1) {
        commit('SET_ISNEED_AUTH', data.is_need_auth)
        commit('SET_AUTH_LINK', data.auth_link)
        // commit('SET_AUTH_STEP_SWITCH', data.is_need_union)
        // commit('SET_IS_SELECT_ACCOUNT', data.is_select_account)
        if (data.info) {
          commit('SET_AUTH_INFO', data.info)
          commit('SET_AUTH_STATUS', 2)
        }
        if (data.is_need_auth == 1) {
          if (auth_code) {
            dispatch('authBack', auth_code)
          } else {
            commit('SET_STEP_CURRENT', 0)
          }
        } else {
          dispatch('initRelationApp')
          commit('SET_STEP_CURRENT', 1)
        }
      } else if (status == 0 && data.err_code == 10011) {
        router.replace('/login')
      }
    },
    // 微信授权回来 调用接口
    async authBack ({ commit, state, dispatch }, auth_code) {
      if (!auth_code || !state.isNeedAuth) return
      const { status, data } = await authBackPost({ auth_code })
      if (status == 1) {
        let authStatus
        switch (data.err_code) {
          case 30000:
            dispatch('initRelationApp')
            commit('SET_STEP_CURRENT', 1)
            break;
          case 30001:
            commit('SET_STATUS_DIALOG', 1)
            break;
          case 30002:
            authStatus = 3
            break;
          case 30003:
            authStatus = 4
            break;
          case 30004:
            authStatus = 5
            break;
          default:
            break;
        }
        commit('SET_AUTH_STATUS', authStatus)
      }
    },
    // 关联小程序初始化
    async initRelationApp ({ commit, state }) {
      const { status, data } = await initMiniPost()
      if (status == 1) {
        commit('SET_ISNEED_LINK', data.is_need_link)
        if (data.is_need_link == 0) {
          commit('SET_STEP_CURRENT', 2)
          // commit('SET_STEP_CURRENT', state.authStepSwitch === 1 && state.isSelectAccount !== 1 ? 2 : 3)
        }
      }
    },
    async goRelationApp ({ commit }) {
      const { status, data } = await miniBackPost()
      if (status == 1) {
        commit('SET_STEP_CURRENT', 2)
          // commit('SET_STEP_CURRENT', state.authStepSwitch === 1 && state.isSelectAccount !== 1 ? 2 : 3)
        if (data.err_code == 40000) {
          commit('SET_RELATION_STATUS', 1)
        } else if (data.err_code == 40001) {
          commit('SET_RELATION_STATUS', 0)
        }
      } else {
        commit('SET_STATUS_DIALOG', 2)
      }
    },
    // 全局初始化判断是否授权跳转不同页面
    async initGlobalPage ({ commit, dispatch }, path) {
      const { status, data } = await initGlobalPagePost()
      if (status == 1) {
        const { service_end_date, protocol, authorities } = data
        commit('SET_SERVICE_END_DATE', service_end_date)
        dispatch('checkAuthStatus', data).then(() => {
          commit('SET_SHICHI_SWITCH', authorities.foretaste)
          commit('SET_ACCOUNT_TYPE_SWITCH', authorities.union)
          if (path) router.replace(path)
          if (protocol && (protocol.current_version_common != protocol.system_current_version_common)) {
            commit('SET_SYSTEM_VERSION_COMMON', protocol.system_current_version_common)
            commit('SET_ISSHOW_AGREEMENT', true)
          } else {
            commit('SET_ISSHOW_AGREEMENT', false)
          }
        })
      } else if (status == 0 && data.err_code == 10011) {
        storage.remove(ACCESS_TOKEN)
        router.replace('/login')
      }
    },
    // 检查授权状态
    checkAuthStatus ({ commit }, data){
      const { auth } = data
      return new Promise(resolve => {
        if (auth && auth.info) {
          commit('SET_AUTH_INFO', auth.info)
        }
        if (auth && (auth.is_need_auth || auth.is_need_link)) {
          commit('SET_ISNEED_AUTH', auth.is_need_auth)
          commit('SET_ISNEED_LINK', auth.is_need_link)
          router.replace('/authorizer')
        } else {
          resolve()
        }
      })
    }
  }
}

export default authorizer
<template>
  <div class="bottom-menu">
    <contentHeader :is-show-switch="false" header-type="1"></contentHeader>
    <div class="setting-container">
      <div class="setting-weixin">
        <div class="setting-list">
          <div class="setting-item" v-for="(item, index) in myCaidanList" :key="index" :class="{'current': item.current}" >
            <div class="setting-item-box" @click="caidanClick(item)">
              {{item.btn.name}}
            </div>
            <div class="setting-son-list" v-if="item.btn.sub_button">
              <div class="setting-son-item" v-for="(it, ind) in item.btn.sub_button" :key="ind" @click="caidanClick(it, item)" :class="{'current': it.current}">{{it.btn.name}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="setting-caidan">
        <div class="title-box">
          <p class="caidan-title" :class="{active: isSelectMenu === 1}" @click="checkMenu(1)">满天星定制菜单</p>
          <p class="caidan-title" :class="{active: isSelectMenu === 2}" @click="checkMenu(2)">自定义菜单</p>
        </div>
        <p class="caidan-subtitle">
          {{isSelectMenu === 1 ? 
          "满天星定制菜单是对公众号功能的延伸，也是各项活动和策略的展示入口，基于历史实践经验和数据分析，我们挑出了一些转化效果不错的菜单内容供您使用。" :
          "在自定义菜单中您可以选择点击自定义菜单后发生的行为，包括发送图片、文字消息、跳转到网页或小程序，未来会开放更多的自定义功能。"}}
        </p>
        <div class="caidan-name">
          <span>菜单名称</span>
          <div class="input">
            <input type="text" v-model.trim="currentCaidanName" :class="{'need': needName}" @input="inputEvent" @focus="focusEvent" :placeholder="needName?'请设置当前菜单名称':'请输入...'">
            <em :class="{'over':currentCaidanNum > limitCaidanNum}">{{currentCaidanNum}}/{{limitCaidanNum}}</em>
            <i :class="{'over':currentCaidanNum > limitCaidanNum}">仅支持中英文和数字，字数不超过{{limitCaidanNum/2}}个汉字或{{limitCaidanNum}}个字母</i>
          </div>
        </div>
        <div class="caidan-box-title">
          <span>菜单内容</span>
          <em>{{!currentCaidan.empty?'由于您已添加子菜单，该菜单内容无法选择': needContent || needImg || needText || needLink || checkLink || needUrl || checkUrl || needAppid || needApppath?'请设置当前菜单内容':''}}</em>
          <a href="javascript:void(0)" @click="deleteClick">删除菜单</a>
        </div>
        <div class="caidan-list" v-if="isSelectMenu === 1">
          <!-- current cancel need -->
          <div class="caidan-item" v-for="item, index in systemList" :key="index" :class="{'cancel': !currentCaidan.empty, 'need': currentCaidan.empty && needContent,'current': !needContent && currentCaidan.empty && (item.current || currentCaidan.btn.id == item.id)}" @click="chooseClick(item)">
            <p class="item-title">{{item.button.name}}({{item.sub_name}})</p>
            <p class="item-subtitle">{{item.content}}</p>
            <div class="item-btn"></div>
          </div>
        </div>
        <div class="upload-box" v-else-if="isSelectMenu === 2">
          <a-radio-group v-model="radioValue" @change="selectMenuItem" :class="{'need': currentCaidan.empty && needContent}" :disabled="!currentCaidan.empty">
            <a-radio :value="1">图片</a-radio>
            <a-radio :value="2">文字消息</a-radio>
            <a-radio :value="3">跳转网页</a-radio>
            <a-radio :value="4">跳转小程序</a-radio>
          </a-radio-group>
          <div class="set-box">
            <div class="item" v-if="radioValue === 0">
              <span>尚未选择菜单内容定制方式</span>
            </div>
            <div class="item" :class="{'need':currentCaidan.empty && needImg}" v-else-if="radioValue === 1">
              <input type="file" name="file" id="file" accept="image/*" class="upload-img" @change="uploadImg" ref="uploadImg" />
              <label v-if="!uploadImgUrl" for="file" class="upload-btn">上传图片</label>
              <img v-if="uploadImgUrl" class="upload-img-box" :src="uploadImgUrl" alt="">
              <label v-if="uploadImgUrl" for="file" class="upload-btn new-up">重新上传图片</label>
            </div>
            <div class="item item-area" :class="{'need':currentCaidan.empty && needText}" v-else-if="radioValue === 2">
              <a-textarea :maxLength="600" class="area-box" placeholder="请输入文字消息内容..." @input="messageEvent" v-model.trim="message" />
              <div class="text-num">{{ message.length + "/" + 600 }}</div>
            </div>
            <div class="item item-url" :class="{'need':currentCaidan.empty && (needLink || checkLink)}" v-else-if="radioValue === 3">
              <a-input size="large" @input="linkEvent" placeholder="请输入跳转网页链接..." v-model.trim="link" />
              <div class="notes" :class="{'check':currentCaidan.empty && checkLink}">* 链接必须以“http://”或“https://”开头</div>
            </div>
            <div class="item item-mini" v-else-if="radioValue === 4">
              <div class="box">
                <a-textarea :class="{'need':currentCaidan.empty && needAppid}" rows="5" cols="50" class="area" placeholder="请输入小程序AppID..." @input="appidEvent" v-model.trim="appID" />
                <a-textarea :class="{'need':currentCaidan.empty && needApppath}" rows="5" cols="50" class="area" placeholder="请输入小程序页面地址..." @input="appurlEvent" v-model.trim="appUrl" />
                <a-textarea :class="{'need':currentCaidan.empty && (needUrl || checkUrl)}" rows="5" cols="50" class="area" placeholder="请输入备用网页地址..." @input="spareurlEvent" v-model.trim="spareUrl" />
                <div class="get-fun">
                  <a href="https://docs.qq.com/doc/DRFJPbUZEZFdFaHdF?pub=1&dver=2.1.26967325" target="_blank">如何获取</a>
                </div>
                <div class="get-fun">
                  <a href="https://docs.qq.com/doc/DRExZY2ZZRXNDY05H?pub=1&dver=2.1.26967325" target="_blank">如何获取</a>
                </div>
              </div>
              <div class="text-box">
                <div class="text">* 需要在公众号后台关联此小程序，否则无法生效<br><a href="https://docs.qq.com/doc/DRHRvUnprRExJQ2VP?pub=1&dver=2.1.26955590" target="_blank">如何关联</a></div>
                <div class="text"></div>
                <div class="text">* 旧版微信不支持小程序，用户点击菜单时将打开备用网页，<span :class="{'check':currentCaidan.empty && checkUrl}">链接必须以“http://”或“https://”开头</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="setting-btn-box">
        <p class="btn-title">配置底部菜单</p>
        <p class="btn-subtitle">公众号的菜单结构根据功能变化</p>
        <a class="btn-fabu" href="javascript:void(0)" @click="holeSendClick">保存并发布</a>
      </div>
    </div>
    <statusDialog
      @closeStatusDialog="closeStatusDialog"
      @confirmStatusDialog="confirmStatusDialog"
      :show-status-dialog="showStatusDialog"
      :dialog-status="dialogStatus"
    ></statusDialog>
    <toast v-if="isShowToast" :set-status="setStatus" :set-info="setInfo"></toast>
  </div>
</template>

<script>
import contentHeader from '@/components/header/header'
import statusDialog from '@/components/statusDialog/statusCaidanDialog'
import toast from '@/components/toast/toast'
import { initPagePost, setMenuPost, saveImgPost } from '@/api/bottommenu'
// import { mapActions } from 'vuex'

export default {
  components: {
    contentHeader,
    statusDialog,
    toast
  },
  data () {
    return {
      showStatusDialog: false,
      dialogStatus: 1,
      myCaidanList:[],
      systemList:[],
      currentCaidan:{},
      currentCaidanName:'',
      currentCaidanNum:0,
      limitCaidanNum:10,
      confirmStatusDialog:'',
      isShowToast:false,
      setStatus:2,
      setInfo:'',
      needName:false,
      needContent:false,
      isSelectMenu: 1,
      radioValue: 0,
      uploadImgUrl: "",
      message: "",
      appID: "",
      appUrl: "",
      spareUrl: "",
      link:"",
      canChoose:false,
      needImg:false,
      needText:false,
      needLink:false,
      needUrl: false,
      needAppid: false,
      needApppath:false,
      checkLink:false,
      checkUrl:false
    }
  },
  computed:{

  },
  methods:{
    // ...mapActions(['checkAuthStatus']),
    async initPage () {
      const res = await initPagePost()

      if (res.status != 1) return

      // this.checkAuthStatus(res.data.auth).then(() => {
        const { data } = res;
        this.myCaidanList = data.menu.my;
        this.systemList = data.menu.system.map((item, index) => {
          item.current = false;
          return item;
        });

        let listLength = this.myCaidanList.length;

        this.myCaidanList = this.myCaidanList.map((item, index) => {
          let setObj = {};
          setObj.btn = item;
          setObj.current = false;
          return setObj;
        })

        if(listLength < 3){
          let isFirst = false;
          for(let i = 0; i < 3 - listLength; i++){
            if(!isFirst){
              this.myCaidanList.push({
                btn:{
                  name:'+'
                },
                current:true,
              })
              this.currentCaidan = this.myCaidanList[listLength + i];
              isFirst = true;
            } else {
              this.myCaidanList.push({
                btn:{
                  name:'+'
                },
              })
            }
          }
        } else {
          this.myCaidanList[0].current = true;
          this.currentCaidan = this.myCaidanList[0];
        }

        this.myCaidanList = this.myCaidanList.map(function(item, index){
          if(!item.btn.sub_button){
            item.btn.sub_button = []
            item.empty = true;
          } else {
            item.empty = false;
          }

          if(item.btn.sub_button.length < 5){
            let length = item.btn.sub_button.length;
            for(let i = 0; i < 5 - length; i++){
              item.btn.sub_button.push({
                name:'+'
              })
            }
          }

          item.btn.sub_button = item.btn.sub_button.map((it, ind) => {
            let setObj = {};
            setObj.btn = it;
            setObj.current = false;
            setObj.empty = true;
            return setObj;
          })

          item.btn.sub_button.reverse();

          return item;
        })

        this.isSelectMenu = !this.currentCaidan.btn.id && this.currentCaidan.empty && this.currentCaidan.btn.name != '+'?2:1;
        if(!this.currentCaidan.empty){
          this.radioValue = 0
        } else if(!this.currentCaidan.btn.id){
          this.radioValue = this.initType(this.currentCaidan.btn)
        }

        this.currentCaidanName = this.currentCaidan.btn.name != '+'?this.currentCaidan.btn.name:'';
        this.limitCaidanNum = !this.currentCaidan.btn.sub_button?40:10;
        this.currentCaidanNum = this.getStrLength(this.currentCaidanName);
      // })
    },
    initType (obj) {
       switch (obj.type) {
          case "media_id":
            this.uploadImgUrl = obj.link?obj.link:''
            return 1
          case "text":
            this.message = obj.content?obj.content:''
            return 2
          case 'view':
            this.link = obj.url?obj.url:''
            return 3
          case 'miniprogram':
            this.appID = obj.appid?obj.appid:''
            this.appUrl = obj.pagepath?obj.pagepath:''
            this.spareUrl = obj.url?obj.url:''
            return 4
          default:
            return 0
        }
    },
    closeStatusDialog (val) {
      this.showStatusDialog = val
    },
    caidanFun (obj, parentObj){
      let that = this;
      that.myCaidanList = that.myCaidanList.map(function(item, index){
        item.current = false;
        item.btn.sub_button = item.btn.sub_button.map((it, ind) => {
          it.current = false;
          return it;
        })

        return item;
      })

      obj.current = true;

      that.systemList = that.systemList.map((item, index) => {
        item.current = false;
        return item;
      });

      that.currentCaidan = obj;
      that.isSelectMenu = !that.currentCaidan.btn.id && !!that.currentCaidan.btn.type?2:1;
      if(!that.currentCaidan.empty){
        that.radioValue = 0
      } else if(!that.currentCaidan.btn.id){
        that.radioValue = that.initType(that.currentCaidan.btn)
      }
      that.currentCaidanName = that.currentCaidan.btn.name != '+'?that.currentCaidan.btn.name:'';
      that.limitCaidanNum = !that.currentCaidan.btn.sub_button?40:10;
      that.currentCaidanNum = that.getStrLength(that.currentCaidanName);
    },
    caidanClick (obj, parentObj, ischeck){
      let that = this;

      if(!ischeck){
        that.needContent = false;
        that.needName = false;
        that.needText = false;
        that.needImg = false;
        that.needUrl = false;
        that.needLink = false;
        that.needAppid = false;
        that.needApppath = false;
        that.checkLink = false;
        that.checkUrl = false;
      }

      if(parentObj && !ischeck){

        let subButtonIsNotEmpty = parentObj.btn.sub_button.some((it) => {
          if(it.btn.name != '+'){
            return true;
          }
        })

        if(parentObj.btn.name != '+' && !subButtonIsNotEmpty){
          that.dialogStatus = 3;
          that.showStatusDialog = true;
          that.confirmStatusDialog = () => {
            parentObj.empty = false;

            that.caidanFun(obj, parentObj);

            for(let key in parentObj.btn){
              if(key != 'sub_button' && key != 'name'){
                delete parentObj.btn[key]
              }
            }

            that.closeStatusDialog(false);
          }

          return;
        } else {
          parentObj.empty = false
        }
      } else if(parentObj && ischeck){
        parentObj.empty = false

        that.caidanFun(obj, parentObj);

        for(let key in parentObj.btn){
          if(key != 'sub_button' && key != 'name'){
            delete parentObj.btn[key]
          }
        }
      } else {
        obj.empty = true;
        obj.btn.sub_button.map((it, ind) => {
          if(it.btn.name != '+'){
            obj.empty = false;
          }
        })
      }

      that.caidanFun(obj, parentObj);
    },
    inputEvent(){
      this.currentCaidanNum = this.getStrLength(this.currentCaidanName);
      this.currentCaidan.btn.name = this.currentCaidanName == ''?'+':this.currentCaidanName;
    },
    focusEvent(){
      this.needName = false;
    },
    getStrLength( Str ){
      return Str.replace(/[\u0391-\uFFE5]/g,"aa").length; //"g" 表示全局匹配
    },
    chooseClick (obj) {
      let that = this;

      if(!this.currentCaidan.empty){
        return;
      }

      this.needContent = false;
      this.needName = false;
      this.needImg = false
      this.needText = false
      this.needAppid = false
      this.needApppath = false
      this.needLink = false
      this.needUrl = false
      this.checkLink = false
      this.checkUrl = false

      this.systemList = this.systemList.map((item, index) => {
        item.current = false;
        return item;
      });

      obj.current = true;
      this.currentCaidanName = obj.button.name;
      this.currentCaidanNum = this.getStrLength(this.currentCaidanName);

      for(let key in that.currentCaidan.btn){
        if(key != 'sub_button' && !obj.button[key]){
          delete that.currentCaidan.btn[key]
        }
      }

      for(let key in obj.button){
        that.currentCaidan.btn[key] = obj.button[key];
      }
    },
    sendClick(){
      let that = this;

      try{
        that.needName = false
        that.needContent = false;
        that.needName = false;
        that.needText = false;
        that.needImg = false;
        that.needUrl = false;
        that.needLink = false;
        that.needAppid = false;
        that.needApppath = false;
        that.checkUrl = false;

        that.myCaidanList.forEach((item, index) => {
          let realItem = item.btn;
          let arr = realItem.sub_button;
          let empty = true;

          arr.forEach((it,ind) => {
            if(it.btn.name != '+' || !!it.btn.type){
              empty = false;
              if(it.btn.name == '+') {
                that.needName = true;
                that.caidanClick(it, item, true);
                throw new Error();
              } else if(!it.btn.type){
                that.needContent = true;
                that.caidanClick(it, item, true);
                throw new Error();
              } else if(!it.btn.id) {
                that.catchSendObj(it, item)
              }
              return false;
            }
          })

          if(empty && realItem.name != '+' && (!realItem.type || realItem.type == '')){
            that.needContent = true;
            that.caidanClick(item, '', true);
            throw new Error();
          } else if(empty && realItem.name != '+' && realItem.type && realItem.type != '' && !realItem.id) {
            that.catchSendObj(item, '')
          } else if(realItem.name && realItem.name == '+' && ((empty && realItem.type && realItem.type != '') || !empty)) {
            that.needName = true;
            that.caidanClick(item, '', true);
            throw new Error();
          }
        })
      }catch(e){

      }

      if(that.needContent || that.needName || that.needImg || that.needText || that.needLink || that.checkLink || that.needUrl || that.checkUrl || that.needAppid || that.needApppath){
        return
      }

      that.dialogStatus = 1;
      that.showStatusDialog = true;
      that.confirmStatusDialog = () => {
        let newList = JSON.parse(JSON.stringify(that.myCaidanList))

        let ajaxList = newList.map((item,index) => {
          let realItem = item.btn;
          let arr = realItem.sub_button;

          for(let i=0,flag=true,len=arr.length;i<len;flag ? i++ : i){
            if( arr[i] && arr[i].btn && arr[i].btn.name == '+' ){
                  arr.splice(i,1);
                  flag = false;
              } else {
                  flag = true;
              }
          }

          arr.reverse();

          if(realItem.sub_button.length <= 0){
            delete realItem.sub_button;
            this.setSendObj(realItem)
          } else {
            realItem.sub_button = realItem.sub_button.map(item => {
              item = item.btn;
              this.setSendObj(item)
              return item;
            })
          }

          return realItem;
        })

        for(let i = 0,flag=true,len=ajaxList.length;i<len;flag ? i++ : i){
          if( ajaxList[i] && ajaxList[i].name == '+' ){
                ajaxList.splice(i,1);
                flag = false;
            } else {
                flag = true;
            }
        }
// console.log(ajaxList);
        that.ajaxPost({'button':JSON.stringify(ajaxList)});
        that.closeStatusDialog(false);
      } 
    },
    catchSendObj (obj, parentObj){
      if(obj.btn.type == "media_id" && (!obj.btn.media_id || !obj.btn.link || !obj.btn.path)){
        this.needImg = true;
        this.caidanClick(obj, parentObj, true);
        throw new Error();
      } else if (obj.btn.type == "text" && !obj.btn.content) {
        this.needText = true;
        this.caidanClick(obj, parentObj, true);
        throw new Error();
      } else if (obj.btn.type == "view" && !obj.btn.url) {
        this.needLink = true;
        this.caidanClick(obj, parentObj, true);
        throw new Error();
      } else if (obj.btn.type == "view" && obj.btn.url.indexOf("http://") != 0 && obj.btn.url.indexOf("https://") != 0) {
        this.checkLink = true;
        this.caidanClick(obj, parentObj, true);
        throw new Error();
      } else if (obj.btn.type == "miniprogram") {
        if(!obj.btn.url){
          this.needUrl = true;
        }
        if(obj.btn.url && obj.btn.url.indexOf("http://") != 0 && obj.btn.url.indexOf("https://") != 0){
          this.checkUrl = true;
        }
        if(!obj.btn.appid){
          this.needAppid = true;
        }
        if(!obj.btn.pagepath){
          this.needApppath = true;
        }

        if(this.needUrl || this.needAppid || this.needApppath || this.checkUrl){
          this.caidanClick(obj, parentObj, true);
          throw new Error();
        }
      }
    },
    setSendObj (obj){
      if(!obj.id){
        if(obj.type == "media_id"){
          for(let key in obj){
            if(key != 'name' && key != 'type' && key != 'media_id' && key != 'link' && key != 'path'){
              delete obj[key]
            }
          }
        } else if (obj.type == "text") {
          for(let key in obj){
            if(key != 'name' && key != 'type' && key != 'content'){
              delete obj[key]
            }
          }
        } else if (obj.type == "view") {
          for(let key in obj){
            if(key != 'name' && key != 'type' && key != 'url'){
              delete obj[key]
            }
          }
        } else if (obj.type == "miniprogram") {
          for(let key in obj){
            if(key != 'name' && key != 'type' && key != 'url' && key != 'appid' && key != 'pagepath'){
              delete obj[key]
            }
          }
        }
      }
    },
    async ajaxPost(obj){
      let that = this;
      const res = await setMenuPost(obj)

      // that.checkAuthStatus(res.data.auth).then(() => {
        if (res.status === 1) {
          that.setStatus = 2
          that.setInfo = ""
          that.isShowToast = true;
          setTimeout(() => {
            that.isShowToast = false
          }, 2000)
        } else {
          that.dialogStatus = 4;
          that.showStatusDialog = true;
          that.confirmStatusDialog = () => {
            that.closeStatusDialog(false);
          }
        }
      // })
    },
    deleteClick(){
      let that = this;

      if(that.currentCaidan.btn.name == '+' && !that.currentCaidan.btn.type) return
      that.dialogStatus = 2;
      that.showStatusDialog = true;
      that.confirmStatusDialog = () => {
        that.systemList = that.systemList.map((item, index) => {
          item.current = false;
          return item;
        });
        that.currentCaidanName = '';
        that.currentCaidanNum = 0;

        that.currentCaidan.empty = true;

        for(let key in that.currentCaidan.btn){
          if(key == 'name'){
            that.currentCaidan.btn[key] = '+';
          } else if(key == 'sub_button'){
            that.currentCaidan.btn[key] = that.currentCaidan.btn[key].map((item,index) => {
              for(let sonkey in item.btn){
                if(sonkey == 'name'){
                  item.btn[sonkey] = '+'
                } else {
                  delete item.btn[sonkey]
                }
              }

              return item;
            })
          } else {
            delete that.currentCaidan.btn[key]
          }
        }

        that.isSelectMenu = 1;
        that.radioValue = 0

        that.closeStatusDialog(false);
      }
    },
    checkMenu (menuId) {
      if (this.isSelectMenu === menuId) return
      this.isSelectMenu = menuId
      
      if(this.currentCaidan.btn.id){
        this.radioValue = 0
      } else {
        this.radioValue = this.initType(this.currentCaidan.btn)
      }
    },
    selectMenuItem (e) {
      this.radioValue = e.target.value

      this.needContent = false
      this.needImg = false
      this.needText = false
      this.needAppid = false
      this.needApppath = false
      this.needLink = false
      this.needUrl = false
      this.checkLink = false
      this.checkUrl = false

      for(let key in this.currentCaidan.btn){
        if(key != "name" && key != "type" && key != "sub_button"){
          this.currentCaidan.btn[key] = "";
        }
      }
      
      this.systemList = this.systemList.map((item, index) => {
        item.current = false;
        return item;
      });

      switch (this.radioValue) {
        case 1:
          this.currentCaidan.btn.type = 'media_id'
          this.initType(this.currentCaidan.btn)
          return
        case 2:
          this.currentCaidan.btn.type = 'text'
          this.initType(this.currentCaidan.btn)
          return
        case 3:
          this.currentCaidan.btn.type = 'view'
          this.initType(this.currentCaidan.btn)
          return
        case 4:
          this.currentCaidan.btn.type = 'miniprogram'
          this.initType(this.currentCaidan.btn)
          return
        default:
          return 
      }
    },
    uploadImg (e) {
      let formData = new FormData();
      let uploadImgName = this.$refs.uploadImg.files[0]

      formData.append('media_file',uploadImgName);
      this.ajaxSaveImg(formData, e)
      // e.srcElement.value = ""
      this.needImg = false
    },
    async ajaxSaveImg (obj, event) {
      const res = await saveImgPost(obj)

      if(res.status == 1){
        this.currentCaidan.btn.media_id = res.data.info.media_id
        this.uploadImgUrl = this.currentCaidan.btn.link = res.data.info.link
        this.currentCaidan.btn.path = res.data.info.path
      } else {
        this.setStatus = 4
        this.setInfo = res.info
        this.isShowToast = true;
        setTimeout(() => {
          this.isShowToast = false
        }, 2000)
      }

      event.srcElement.value = ""
    },
    holeSendClick () {
      this.sendClick()
    },
    messageEvent () {
      this.currentCaidan.btn.content = this.message
      this.needText = false
    },
    linkEvent () {
      this.currentCaidan.btn.url = this.link
      this.needLink = false
      this.checkLink = false
    },
    appidEvent () {
      this.currentCaidan.btn.appid = this.appID
      this.needAppid = false
    },
    appurlEvent () {
      this.currentCaidan.btn.pagepath = this.appUrl
      this.needApppath = false
    },
    spareurlEvent () {
      this.currentCaidan.btn.url = this.spareUrl
      this.needUrl = false
      this.checkUrl = false
    },
    getObjectURL (file) {
      var url = null;
      if (window.createObjectURL != undefined) { // basic
          url = window.createObjectURL(file);
      } else if (window.URL != undefined) { // mozilla(firefox)
          url = window.URL.createObjectURL(file);
      } else if (window.webkitURL != undefined) { // webkit or chrome
          url = window.webkitURL.createObjectURL(file);
      }
      return url;
    }
  },
  created () {
    this.initPage()
  },
  mounted() {

  },
}
</script>

<style lang="less" scoped>
.bottom-menu{
  .setting-container{
    display:flex;
    width:1080px;
    height:454px;
    padding:24px;
    box-sizing: border-box;
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px 0px #DCE3F0;
    .setting-weixin{
      position:relative;
      width:228px;
      height:406px;
      background:url(./images/weixin-bg.png) no-repeat;
      background-size:100% auto;
      margin-right:12px;
      .setting-list{
        position:absolute;
        left:29px;
        bottom:0;
        display:flex;
        width:201px;
        height:34px;
        .setting-item{
          position:relative;
          width:67px;
          height:34px;
          margin-left:-1px;
          text-align:center;
          line-height:34px;
          font-size:12px;
          color:#333847;
          box-sizing: border-box;
          border:1px solid transparent;
          .setting-item-box{
            padding:0 2px;
            overflow:hidden; white-space:nowrap; text-overflow:ellipsis;
          }
          &:first-child{
            margin-left:0;
          }
          &.current{
            color:#4392F6;
            border:1px solid #4392F6;
          }
          .setting-son-list{
            position:absolute;
            top:-166px;
            left:2px;
            width:61px;
            height:160px;
            background:url(./images/weixin-son-bg.png) no-repeat;
            background-size:100% auto;
            .setting-son-item{
              width:61px;
              height:32px;
              margin-top:-1px;
              box-sizing: border-box;
              padding:0 2px;
              line-height:30px;
              text-align:center;
              font-size:10px;
              color:#333847;
              overflow:hidden; white-space:nowrap; text-overflow:ellipsis;
              border:1px solid transparent;
              &:first-child{
                margin-top:0;
              }
              &.current{
                color:#4392F6;
                border:1px solid #4392F6;
              }
            }
          }
        }
      }
    }
    .setting-caidan{
      width:548px;
      height:406px;
      box-sizing: border-box;
      padding: 24px 24px 24px 36px;
      background:url(./images/setting-bg.png) no-repeat;
      background-size:548px 405px;
      .title-box {
        display: flex;
        border-bottom:1px solid #D7E3F5;
      }
      .caidan-title{
        position:relative;
        margin-bottom:0px;
        margin-right: 24px;
        line-height:20px;
        font-size:14px;
        color:#4392F6;
        padding-bottom: 5px;
        cursor: pointer;
        box-sizing: border-box;
        &.active {
          color: #333847;
          &:before{
            content:"";
            position:absolute;
            bottom:0;
            left:0;
            width:100%;
            height:1px;
            background:#333;
          }
        }
      }
      .caidan-subtitle{
        padding-bottom:9px;
        padding-top: 6px;
        margin-bottom:0;
        line-height:14px;
        font-size:10px;
        color:#5C6485;
      }
      .caidan-name{
        padding:12px 0 32px;
        height:80px;
        display:flex;
        align-items: center;
        span{
          display:block;
          width:72px;
          line-height:36px;
          font-size:12px;
          color:#333847;
        }
        .input{
          position: relative;
          width:416px;
          height:36px;
          input{
            display:block;
            width:416px;
            height:36px;
            box-sizing: border-box;
            border-radius: 2px;
            background: #FFFFFF;
            border: 1px solid #D7E3F5;
            line-height:34px;
            padding-left:12px;
            font-size:12px;
            color:#333847;
            &.need{
              border:1px solid #FF4141;
              &::-webkit-input-placeholder {
                  color: #FF4141;
              }
            }
          }
          em{
            position:absolute;
            top:10px;
            right:12px;
            line-height:17px;
            font-size:12px;
            font-style: normal;
            color:#ADB1C3;
            &.over{
              color:#FF4141;
            }
          }
          i{
            position: absolute;
            bottom:-20px;
            left:0;
            font-style: normal;
            line-height:14px;
            font-size:10px;
            color:#5C6485;
            &.over{
              color:#FF4141;
            }
          }
        }
      }
      .caidan-box-title{
        display: flex;
        line-height:17px;
        font-size:12px;
        margin-bottom:12px;
        span{
          display:block;
          width:72px;
          color:#333847;
        }
        em{
          display:block;
          flex:1;
          font-size:10px;
          font-style: normal;
          color:#FF4141;
        }
        a{
          display:block;
          color:#4392F6;
        }
      }
      .caidan-list{
        display:flex;
        width:500px;
        height:200px;
        flex-wrap:wrap;
        overflow-y: auto;
        // justify-content: space-between;
        .caidan-item{
          position: relative;
          width:154px;
          height:89px;
          padding:12px 12px 0;
          box-sizing: border-box;
          border-radius: 2px;
          background: #FFFFFF;
          border:1px solid #D7E3F5;
          margin-right: 10px;
          margin-bottom:10px;
          overflow: hidden;
          &:nth-child(3n) {
            margin-right: 0;
          }
          .item-title{
            margin-bottom:6px;
            line-height:17px;
            font-size:12px;
            color:#333;
          }
          .item-subtitle{
            line-height:14px;
            font-size:10px;
            color:#999;
          }
          .item-btn{
            position: absolute;
            top:-1px;
            right:-1px;
            display:none;
            width:14px;
            height:14px;
            background:url(./images/cur-icon.png) no-repeat;
            background-size:14px;
          }
          &.cancel{
            background:#F6F6F6;
            border:1px solid #E3E5EC;
            .item-title{
              color:#ADB1C3;
            }
            .item-subtitle{
              color:#ADB1C3;
            }
            .item-btn{
              border: 1px solid #E3E5EC;
            }
          }
          &.need{
            border:1px solid #FF4141;
          }
          &.current{
            border:1px solid #333;
            .item-btn{
              display:block;
            }
          }
        }
      }
    }
    .setting-btn-box{
      padding-left:24px;
      .btn-title{
        margin-bottom:12px;
        line-height:28px;
        font-size:20px;
        font-weight: medium;
        color:#333847;
      }
      .btn-subtitle{
        line-height:24px;
        font-size:14px;
        color:#5C6485;
      }
      .btn-fabu{
        display:block;
        width:220px;
        height:60px;
        margin-top:282px;
        line-height:60px;
        text-align: center;
        border-radius: 6px;
        background: #4392F6;
        font-size:20px;
        color:#FFF;
        font-weight:medium;
      }
    }
  }
}
/deep/ .ant-radio-group {
  margin-bottom: 8px;
  .ant-radio-wrapper {
    font-size: 12px;
    .ant-radio {
      width: 14px;
      height: 14px;
      .ant-radio-inner {
        width: 100%;
        height: 100%;
        &::after {
          top: 2px;
          left: 2px;
        }
      }
    }
  }
}
.set-box {
  .item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 488px;
    height: 128px;
    background: #fff;
    border: 1px solid #D7E3F5;
    border-radius: 2px;
    color: #ADB1C3;
    &.need{
      border-color:#FF4141;
      .ant-input{
        border-color:#FF4141;
      }
    }
    &.item-area {
      border: none;
      .area-box {
        width: 488px;
        max-width: 488px;
        height: 128px;
        resize: none;
      }
    }
    &.item-url {
      flex-direction: column;
      align-items: flex-start;
      height: auto;
      background: rgba(0, 0, 0, 0);
      border: none;
      .notes {
        height: 14px;
        line-height: 14px;
        font-size: 10px;
        color: #5C6485;
        margin-top: 12px;
        &.check{
          color:#FF4141;
        }
      }
    }
    &.item-mini {
      display: block;
      justify-content: space-between;
      height: auto;
      border: none;
      background: rgba(0, 0, 0, 0);
      .box {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 7px;
        .area {
          width: 154px;
          height: 78px;
          padding-bottom: 30px;
          resize: none;
          &.need{
            border-color:#FF4141;
          }
        }
        .get-fun {
          position: absolute;
          left: 12px;
          bottom: 1px;
          font-size: 12px;
          width: 126px;
          height: 29px;
          background: #fff;
          &:last-child {
            left: 178px;
          }
        }
      }
      .text-box {
        display: flex;
        justify-content: space-between;
        .text {
          width: 154px;
          color: #5C6485;
          font-size: 10px;
          span.check{
            color:#FF4141;
          }
        }
      }
    }
  }
  .upload-img {
    display: none;
  }
  .upload-img-box {
    width: 120px;
    height: 120px;
  }
  .upload-btn {
    display: block;
    width: 96px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 29px;
    background: #F3F6FC;
    color: #4392F6;
    font-size: 14px;
    cursor: pointer;
    &.new-up {
      position: absolute;
      top: 13px;
      right: 12px;
      height: auto;
      width: auto;
      line-height: 17px;
      background: #fff;
    }
  }
  .text-num {
    position: absolute;
    bottom: 12px;
    right: 20px;
    color: #ADB1C3;
    font-size: 12px;
  }
}
::-webkit-input-placeholder {
  font-size: 12px;
}
input {
  font-size: 12px;
}
/deep/ .upload-box .need .ant-radio-wrapper{
  color:#FF4141;
}
</style>

<template>
  <div class="header">
    <div class="header-wrap">
      <img :src="headerLogo" alt="" />
      <div class="header-text-box">
        <div class="title">{{ headerTitle }}</div>
        <p class="introduce">{{ introduce1 }}</p>
        <p class="introduce">{{ introduce2 }}</p>
      </div>
      <a-switch
        v-if="isShowSwitch"
        :checked="isDefaultChecked == 1"
        checked-children="开启"
        un-checked-children="停用"
        @click="checkSwitch"
      />
    </div>
    <div v-if="isShowTips" class="tips">
      <img alt="" src="./images/horn.png" />
      <div class="tips-text">{{ tipsText }}</div>
    </div>
    <div v-if="isShowConfirmDialog" class="close-switch-dialog">
      <div class="mask"></div>
      <div class="dialog-content">
        <div class="dialog-title">确定要停用这个功能吗？</div>
        <div class="text">一旦停用该功能，{{ confrimText }}</div>
        <div class="btn-box">
          <a href="javascript:void(0);" @click="closeConfirmDialog">取消</a>
          <a href="javascript:void(0);" @click="submitHandler">确定</a>
        </div>
      </div>
    </div>
    <!-- <div v-if="headerType == 8 && isDefaultChecked == 0" class="tipsb">
      需配置饿了么赏金红包，才能打开此功能。
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    /**
     * 顶部header不同类型展示不同内容
     * 1. 底部菜单
     * 2. 欢迎语
     * 3. 下单互动
     * 4. 每日签到
     * 5. 吃饭提醒
     * 6. 皮肤模板
     * 7. 保持活跃
     * 8. 更多红包
     * 11. 试吃团
     */
    headerType: {
      type: String,
      default: "1",
    },
    isDefaultChecked: {
      default: 0,
    },
    isShowSwitch: {
      type: Boolean,
      default: true,
    },
    isShowTips: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headerLogo: "",
      headerTitle: "",
      introduce1: "",
      introduce2: "",
      tipsText: "",
      isShowConfirmDialog: false,
    };
  },
  computed: {
    confrimText() {
      const num = Number(this.headerType);

      switch (num) {
        case 2:
          return "你会失去一个自我介绍的机会，可能会导致用户对你不够了解或完全没有印象。";
        case 3:
          return "你可能会失去在用户下单后进行互动营销的机会，在一定程度上会降低复购率和留存，不利于用户忠诚度的培养。";
        case 4:
          return "你会失去一个提升用户留存的强力抓手，在一定程度上会降低用户的粘性与忠诚度。";
        case 5:
          return "你会失去一个提醒用户领红包下单的机会，在一定程度上会影响领红包率和下单率。";
        case 7:
          return "当用户与公众号48小时内没有互动后将无法收到公众号发出的对话消息。";
        case 8:
          return "用户领红包的入口会减少。";
        default:
          return "";
      }
    },
  },
  methods: {
    getHeaderLogo() {
      switch (this.headerType) {
        case "1":
          this.headerLogo = require("./images/header-setting.png");
          this.headerTitle = "公众号底部菜单";
          this.introduce1 =
            "底部菜单是公众号界面底部的点击组件，用于方便的展示和打开公众号所拥有的功能。";
          this.introduce2 =
            "根据所选功能自动适配菜单，让用户对公众号的功能有更持久的记忆，并更加快速的到达各个明星功能。";
          break;
        case "2":
          this.headerLogo = require("./images/header-hello.png");
          this.headerTitle = "关注公众号的欢迎语";
          this.introduce1 =
            "欢迎语是粉丝关注公众号后默认显示的第一条信息，一般用于自我介绍或直达部分功能。";
          this.introduce2 =
            "选择一个欢迎语，让用户第一时间了解公众号，并更容易进行第一次互动。";
          break;
        case "3":
          this.headerLogo = require("./images/header-interaction.png");
          this.headerTitle = "下单后自动互动";
          this.introduce1 =
            "外卖确认送达后，自动向用户推送多样的互动、返利消息。";
          this.introduce2 = "选择合适的互动方案，有助于提升用户忠诚度。";
          this.tipsText =
            "互动开启后，请在[我的钱包-我的支出]中预存用于发放返利的费用。发给用户的返利会从预存费用中扣除，请确保费用充足，避免用户投诉。";
          break;
        case "4":
          this.headerLogo = require("./images/header-calendar.png");
          this.headerTitle = "每日签到送会员";
          this.introduce1 =
            "用户每月累计领红包20天可以获赠饿了么和美团外卖会员月卡，成本高，但运营效果很好。";
          this.introduce2 =
            "选择合适的签到赠送方案，提升领红包率，获得更高的留存和忠诚度。";
          this.tipsText =
            "开启签到送会员之前，请预先采购用于赠送的会员卡，避免库存不足用户投诉。我们会在功能开启过程中持续为您监控会员卡的预计发放情况。";
          break;
        case "5":
          this.headerLogo = require("./images/header-cloak.png");
          this.headerTitle = "每日吃饭提醒";
          this.introduce1 =
            "每天在点餐高峰为用户发送点餐提醒，可以直接互动领取红包。";
          this.introduce2 =
            "选择合适的提醒模版，提升领红包率和下单率，并保护持续向用户发送消息的能力。";
          break;
        case "6":
          this.headerLogo = require("./images/icon-skin.png");
          this.headerTitle = "更换小程序皮肤";
          this.introduce1 =
            "我们为您准备了多种风格的小程序皮肤，您可根据自身的风格选取，以配合您公众号的整体调性。";
          this.introduce2 = "";
          break;
        case "7":
          this.headerLogo = require("./images/header-heart.png");
          this.headerTitle = "保持用户活跃";
          this.introduce1 =
            "当用户与公众号产生特定交互动作的48小时内，公众号可以给用户主动发送客服消息。";
          this.introduce2 =
            "为了保持该能力，需要通过不同的方式引导用户进行互动，从而保证公众号各类消息在最大限度内触达更多用户。与其他功能配合使用，可以更大程度发挥其他功能的效能。";
          break;
        case "8":
          this.headerLogo = require("./images/hongbao.png");
          this.headerTitle = "红包设置";
          this.introduce1 = "可根据运营需求配置红包的位置和样式，为您的用户提供更丰富的红包领取场景。";
          this.introduce2 = "配置合适的红包展示方案，提升领红包率，获得更高的留存和忠诚度。";
          break;
        case "9":
          this.headerLogo = require("./images/header-apppath.png");
          this.headerTitle = "我的路径";
          this.introduce1 =
            "你可根据自身营销场景选择页面，复制提取AppID和页面路径，";
          this.introduce2 = "配置到微信公众号的推文、菜单等位置。";
          this.tipsText =
            "通过这个链接产生的订单属于你的公众号，你可以获得订单的佣金，但无法知道是哪个用户下的单。";
          break;
        case "10":
          this.headerLogo = require("./images/growth.png");
          this.headerTitle = "增长服务";
          this.introduce1 = "满天星为您提供公众号粉丝增长服务，";
          this.introduce2 = "以低于线上投放的价格带来更优质的粉丝。";
          this.tipsText = "";
          break;
        case "11":
          this.headerLogo = require("./images/shichituan.png");
          this.headerTitle = "试吃团功能";
          this.introduce1 = "根据用户的位置展示有试吃活动的商家，用户可以选择相应的商家报名参与。";
          this.introduce2 = "试吃团功能可以丰富用户的使用场景，提升体验，为您带来更高的收入。";
          this.tipsText = "功能开启后，我们将在后台记录属于您的用户任务明细及奖励金额。后续功能上线后，您可以查看历史任务明细，并追溯提现。";
          break;
        case "12":
          this.headerLogo = require("./images/account-type.png");
          this.headerTitle = "账户类型设置";
          this.introduce1 = "默认使用满天星系统的结算账户";
          this.introduce2 = "您可在此处选择您需要的账户类型，支持选择自己的淘宝联盟账户和美团分销联盟账户";
          this.tipsText = "";
        default:
          break;
      }
    },
    showConfirmDialog() {
      this.isShowConfirmDialog = true;
    },
    closeConfirmDialog() {
      this.isShowConfirmDialog = false;
    },
    checkSwitch(e) {
      if (!e) {
        this.showConfirmDialog();
      } else {
        this.$emit("checkSwitch", 1);
      }
    },
    submitHandler() {
      this.$emit("checkSwitch", 0);
      this.closeConfirmDialog();
    },
  },
  mounted() {
    this.getHeaderLogo();
  },
};
</script>

<style lang="less" scoped>
.header {
  position: relative;
  width: 1080px;
  margin-bottom: 48px;

  .header-wrap {
    display: flex;

    img {
      width: 98px;
      height: 98px;
      margin-right: 24px;
    }

    .header-text-box {
      flex: 1;

      .title {
        height: 28px;
        line-height: 28px;
        color: #333847;
        font-size: 20px;
        font-weight: 500;
        margin: 6px 0 12px;
      }

      .introduce {
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        color: #5c6485;
        margin-bottom: 0;

        &:nth-child(2) {
          margin-bottom: 6px;
        }
      }
    }

    .ant-switch {
      width: 86px;
      height: 36px;
      margin-top: 2px;

      /deep/ .ant-switch-inner {
        font-size: 18px;
      }

      &::after {
        top: 3px;
        width: 28px;
        height: 28px;
      }
    }
  }

  .tips {
    display: flex;
    align-items: center;
    width: 100%;
    height: 68px;
    border-radius: 8px;
    background: #ffe9e8;
    margin-top: 24px;
    padding: 0 24px;
    font-size: 14px;
    color: #ff4141;

    img {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }

    .tips-text {
      flex: 1;
      line-height: 20px;
    }

    .path-to {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 110px;
      height: 20px;
      line-height: 20px;
      color: #4392f6;

      &::after {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        border-top: 1px solid #4392f6;
        border-right: 1px solid #4392f6;
        transform: rotate(45deg);
      }
    }
  }
}

.dialog-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 480px;
  // height: 283px;
  padding: 48px 36px 36px;
  background: #fff;
  border-radius: 12px;
  text-align: center;
  color: #333847;
  z-index: 10;

  .dialog-title {
    height: 39px;
    line-height: 39px;
    margin-bottom: 24px;
    font-size: 28px;
    font-weight: 500;
  }

  .text {
    // height: 28px;
    line-height: 28px;
    font-size: 20px;
    margin-bottom: 48px;
  }

  .btn-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;

    a {
      width: 192px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      border-radius: 8px;
      background: #4392f6;
      font-size: 20px;
      color: #fff;

      &:first-child {
        background: #fff;
        border: 1px solid #4392f6;
        color: #4392f6;
      }
    }
  }
}

// .tipsb {
//   position: absolute;
//   top: 58px;
//   right: -48px;
//   width: 180px;
//   padding: 12px;
//   box-sizing: border-box;
//   font-size: 14px;
//   color: #5c6485;
//   background: #fff;
//   box-shadow: 0px 4px 40px 0px #8f9cb7;
//   border-radius: 2px;

//   &::before {
//     content: "";
//     position: absolute;
//     top: -5px;
//     left: 50%;
//     transform: translateX(-50%);
//     width: 10px;
//     height: 10px;
//     background: #fff;
//     transform: rotate(45deg);
//   }
// }
</style>

import axios from '@/utils/axios'

const API = {
  init: '/menu/ajaxInit',
  setMenu: '/menu/ajaxSetMenuConfig',
  saveImg: '/menu/ajaxUploadImageMedia'
}

function initPagePost () {
  return axios.post(API.init)
}

function setMenuPost (param) {
  return axios.post(API.setMenu, param)
}

function saveImgPost (param) {
  return axios.post(API.saveImg, param)
}

export {
  initPagePost,
  setMenuPost,
  saveImgPost
}

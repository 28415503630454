const welcomeMixins = { // 欢迎语
  data () {
    return {
      previewList: [
        {
          id: 3481,
          title: '智能文字互动',
          text: '通过枚举利益点快速植入用户心智，<br />含互动，对用户触达有一定帮助。',
          imgUrl: require('./images/welcomes-icon-1.png'),
          imgNum: 2
        },
        {
          id: 3483,
          title: '智能文字礼包互动',
          text: '通过新人礼包促使用户被公众号吸引，<br />含互动，对用户触达有一定帮助。',
          imgUrl: require('./images/welcomes-icon-2.png'),
          imgNum: 2
        },
        // {
        //   id: 4007,
        //   title: '智能礼包-含用户发消息',
        //   text: '通过新人礼包促使用户被公众号吸引，<br />易使用户与公众号产生互动，保持用户触达率。',
        //   imgUrl: require('./images/welcomes-icon-multiple.png'),
        //   imgNum: 2
        // },
        {
          id: 5735,
          title: '智能礼包-安卓蓝字促活',
          text: '公众号通过新人礼包吸引新用户注意，<br />安卓用户可通过蓝字与公众号互动，保持活跃度。',
          imgUrl: require('./images/welcomes-icon-android.png'),
          imgNum: 2
        },
        // {
        //   id: 5813,
        //   title: '饿了么随机红包版',
        //   text: '主推赏金红包提升进粉期收益，并利用新人礼包<br />促使用户与公众号产生互动，保持用户触达率。',
        //   imgUrl: require('./images/welcome-ele.png'),
        //   imgNum: 2
        // },
        {
          id: 1,
          title: '文字与小程序结合(旧模板)',
          text: '易促使用户领取外卖红包，<br />但无互动，对用户触达没有帮助。',
          imgUrl: require('./images/text+minipro.png'),
          imgNum: 1
        },
        {
          id: 3,
          title: '仅文字(旧模板)',
          text: '更简洁，但无互动，<br />对用户触达没有帮助。',
          imgUrl: require('./images/text.png'),
          imgNum: 1
        },
        {
          id: 5,
          title: '仅小程序(旧模板)',
          text: '超级简洁，但无互动，<br />对用户触达没有帮助。',
          imgUrl: require('./images/minipro.png'),
          imgNum: 1
        }
      ],
      dataList: [
        {
          id: 3481,
          title: '智能文字互动',
          subTitle: '通过枚举利益点快速植入用户心智，含互动，对用户触达有一定帮助。欢迎语首次触达后用户50秒未主动回复还会触发二次追粉，保持用户触达率。<br />本模板将根据你是否开启每日签到功能和是否开启全返利模版做文案自适应。',
          imgUrl: [
            {
              id: 1,
              url: require('./images/welcomes-preview-current-empty.png'),
              urlWhite: require('./images/welcomes-preview-current-empty-white.png'),
              fanliUrl: require('./images/welcomes-preview-text-fanli.png'),
              fanliUrlWhite: require('./images/welcomes-preview-text-fanli-white.png'),
              remindUrl: require('./images/welcomes-preview-text-vip.png'),
              remindUrlWhite: require('./images/welcomes-preview-text-vip-white.png'),
              allUrl: require('./images/welcomes-preview-current-all.png'),
              allUrlWhite: require('./images/welcomes-preview-current-all-white.png'),
              title: '首次触达'
            },
            {
              id: 2,
              url: require('./images/welcomes-preview-current-empty-2.png'),
              urlWhite: require('./images/welcomes-preview-current-empty-2.png'),
              fanliUrl: require('./images/welcomes-preview-text-fanli-2.png'),
              fanliUrlWhite: require('./images/welcomes-preview-text-fanli-2.png'),
              remindUrl: require('./images/welcomes-preview-text-vip-2.png'),
              remindUrlWhite: require('./images/welcomes-preview-text-vip-2.png'),
              allUrl: require('./images/welcomes-preview-current-all-2.png'),
              allUrlWhite: require('./images/welcomes-preview-current-all-2.png'),
              title: '二次追粉'
            },
          ]
        },
        {
          id: 3483,
          title: '智能文字礼包互动',
          subTitle: '通过新人礼包促使用户被公众号吸引，含互动，对用户触达有一定帮助。欢迎语首次触达后用户50秒未主动回复还会触发二次追粉，保持用户触达率。<br />本模板将根据你是否开启每日签到功能和是否开启全返利模版做文案自适应。',
          imgUrl: [
            {
              id: 1,
              url: require('./images/welcomes-preview-bag-fanli.png'),
              urlWhite: require('./images/welcomes-preview-bag-fanli-white.png'),
              remindUrl: require('./images/welcomes-preview-bag-vip.png'),
              remindUrlWhite: require('./images/welcomes-preview-bag-vip-white.png'),
              title: '首次触达'
            },
            {
              id: 2,
              url: require('./images/welcomes-preview-bag-fanli-2.png'),
              urlWhite: require('./images/welcomes-preview-bag-fanli-2.png'),
              remindUrl: require('./images/welcomes-preview-bag-vip-2.png'),
              remindUrlWhite: require('./images/welcomes-preview-bag-vip-2.png'),
              title: '二次追粉'
            },
          ]
        },
        // {
        //   id: 4007,
        //   title: '智能礼包-含用户发消息',
        //   subTitle: '用新人礼包促使用户发送消息同公众号产生互动。欢迎语首次触达后用户50秒未主动回复还会触发二次追粉，保持用户触达率。<br />本模板将根据你是否开启每日签到功能和是否开启全返利模版做文案自适应。',
        //   imgUrl: [
        //     {
        //       id: 1,
        //       url: require('./images/welcomes-preview-multiple-fanli-2.png'),
        //       urlWhite: require('./images/welcomes-preview-multiple-fanli-2.png'),
        //       remindUrl: require('./images/welcomes-preview-multiple-vip-2.png'),
        //       remindUrlWhite: require('./images/welcomes-preview-multiple-vip-2.png'),
        //       title: '首次触达'
        //     },
        //     {
        //       id: 2,
        //       url: require('./images/welcomes-preview-multiple-fanli.png'),
        //       urlWhite: require('./images/welcomes-preview-multiple-fanli.png'),
        //       remindUrl: require('./images/welcomes-preview-multiple-vip.png'),
        //       remindUrlWhite: require('./images/welcomes-preview-multiple-vip.png'),
        //       title: '二次追粉'
        //     },
        //   ]
        // },
        {
          id: 5735,
          title: '智能礼包-安卓蓝字促活',
          subTitle: '用礼包促使用户发消息互动。欢迎语首次触达后，用户50秒未回复会触发二次追粉，保持用户触达率。<br />此模板支持微信8.0.11版本及以上的安卓用户蓝字促活，刺激其他用户手动回复。文案会根据返利、每日签到设置变化。',
          imgUrl: [
            {
              id: 1,
              url: require('./images/welcomes-preview-no-vip.png'),
              remindUrl: require('./images/welcomes-preview-vip.png'),
              title: '首次触达'
            },
            {
              url: require('./images/welcomes-preview-no-vip-2.png'),
              remindUrl: require('./images/welcomes-preview-vip-2.png'),
              title: '二次追粉'
            },
          ]
        },
        // {
        //   id: 5813,
        //   title: '饿了么随机红包版',
        //   subTitle: '主推赏金红包提升进粉期收益，并利用新人礼包促使用户与公众号产生互动，保持用户触达率。',
        //   imgUrl: [
        //     {
        //       id: 1,
        //       url: require('./images/welcomes-random.png'),
        //       title: '首次触达'
        //     },
        //     {
        //       id: 2,
        //       url: require('./images/welcomes-random-1.png'),
        //       title: '二次触达'
        //     },
        //   ]
        // },
        {
          id: 1,
          title: '文字与小程序结合<br />(旧模板)',
          subTitle: '易促使用户领取外卖红包，对用户触达没有帮助。',
          imgUrl: [
            {
              id: 1,
              url: require('./images/welcomes-all.png'),
              urlWhite: require('./images/welcomes-all-white.png'),
              title: '文字和小程序(旧模板)-无会员'
            },
          ]
        },
        {
          id: 3,
          title: '仅文字(旧模板)',
          subTitle: '更简洁，但无互动，<br />对用户触达没有帮助。',
          imgUrl: [
            {
              id: 1,
              url: require('./images/welcomes-text.png'),
              title: '仅文字(旧模板)-无会员'
            }
          ]
        },
        {
          id: 5,
          title: '仅小程序(旧模板)',
          subTitle: '超级简洁，但无互动，<br />对用户触达没有帮助。',
          imgUrl: [
            {
              id: 1,
              url: require('./images/welcomes-minip.png'),
              urlWhite: require('./images/welcomes-minip-white.png'),
              title: '仅小程序(旧模板)-领红包'
            },
          ]
        }
      ]
    }
  }
}

const checkMixin = { //每日签到
  data () {
    return {
      futureTips: ''
    }
  }
}

const mealMixin = { // 吃饭提醒
  data () {
    return {
      previewList: [
        {
          id: 9,
          title: '互动文字-日期更新版',
          text: '利用日期的更新提醒用户领取当日的新红包，<br/>含互动，对用户触达有一定帮助。',
          imgUrl: require('./images/mealreminder-text-small.png'),
          imgNum: 1
        },
        {
          id: 2443,
          title: '互动文字-碰碰运气版',
          text: '利用碰运气心理诱惑用户查看红包金额，<br />含互动，对用户触达有一定帮助。',
          imgUrl: require('./images/mealreminder-text-small-2.png'),
          imgNum: 1
        },
        {
          id: 4011,
          title: '互动文字-含用户发送消息',
          text: '随机触达文案，富有多样性，使用户对公众号<br/>保持新鲜感，易产生互动，保持用户触达率。',
          imgUrl: require('./images/mealreminder-user-small.png'),
          imgNum: 3
        },
        {
          id: 5733,
          title: '互动文字-安卓蓝字促活',
          text: '利用日期的更新提醒用户领取当日的新红包，<br/>支持安卓用户蓝字促活，刺激其他用户手动回复。',
          imgUrl: require('./images/mealreminder-user-android.png'),
          imgNum: 1
        },
        {
          id: 11,
          title: '小程序卡片',
          text: '使用体验最为便利，但无互动，<br />对用户触达没有帮助。',
          imgUrl: require('./images/mealreminder-minip-small.png'),
          imgUrlWhite: require('./images/mealreminder-minip-small-white.png'),
          imgNum: 1
        }
      ],
      dataList: [
        {
          id: 9,
          title: '互动文字-日期更新版',
          subTitle: '利用日期的更新提醒用户领取当日的新红包，含互动，对用户触达有一定帮助。',
          imgUrl: [
            {
              id: 1,
              url: require('./images/mealreminder-text.png'),
              urlWhite: require('./images/mealreminder-text-white.png'),
              title: '小程序卡片'
            },
          ]
        },
        {
          id: 2443,
          title: '互动文字-碰碰运气版',
          subTitle: '利用碰运气心理诱惑用户查看红包金额，好运祝福可减少用户的反感，含互动，对用户触达有一定帮助。',
          imgUrl: [
            {
              id: 1,
              url: require('./images/mealreminder-text-2.png'),
              urlWhite: require('./images/mealreminder-text-2-white.png'),
              title: '小程序卡片'
            }
          ]
        },
        {
          id: 4011,
          title: '互动文字-含用户发送消息',
          subTitle: '随机触达文案，富有多样性，使用户对公众号保持新鲜感，易产生互动，首次触达后用户50秒未主动回复还会触发二次追粉触达，保持用户触达率。',
          imgUrl: [
            {
              id: 1,
              url: require('./images/mealreminder-user-1.png'),
              urlWhite: require('./images/mealreminder-user-1.png'),
              title: '二次追粉'
            },
            {
              id: 2,
              url: require('./images/mealreminder-user-2.png'),
              urlWhite: require('./images/mealreminder-user-2.png'),
              title: '首次触达随机文案一'
            },
            {
              id: 3,
              url: require('./images/mealreminder-user-3.png'),
              urlWhite: require('./images/mealreminder-user-3.png'),
              title: '首次触达随机文案二'
            },
            {
              id: 4,
              url: require('./images/mealreminder-user-4.png'),
              urlWhite: require('./images/mealreminder-user-4.png'),
              title: '首次触达随机文案三'
            },
          ]
        },
        {
          id: 5733,
          title: '互动文字-安卓蓝字促活',
          subTitle: '利用日期的更新提醒用户领取当日的新红包，支持微信8.0.11版本及以上的安卓用户蓝字促活，刺激其他用户手动回复。',
          imgUrl: [
            {
              id: 1,
              url: require('./images/mealreminder-user-android-big.png'),
              title: '互动文字'
            }
          ]
        },
        {
          id: 11,
          title: '小程序卡片',
          subTitle: '使用体验最为便利，但无互动，对用户触达没有帮助。',
          imgUrl: [
            {
              id: 1,
              url: require('./images/mealreminder-minip.png'),
              urlWhite: require('./images/mealreminder-minip-white.png'),
              title: '互动文字'
            },
          ]
        }
      ]
    }
  }
}
const skinMixin = { // 皮肤模板
  data () {
    return {
      previewList: [
        {
          id: 13,
          title: '清新版皮肤',
          imgUrl: require('./images/skinbox-small.png'),
          imgNum: 1
        },
        {
          id: 15,
          title: '营销版皮肤',
          imgUrl: require('./images/skinbox-sale-small.png'),
          imgNum: 1
        },
        {
          id: 3007,
          title: '春日限定版',
          imgUrl: require('./images/skinbox-spring-small.png'),
          imgNum: 1
        },
        {
          id: 4425,
          title: '【曾经尊贵的】黑猫皮肤',
          imgUrl: require('./images/skinbox-black-small.png'),
          imgNum: 1
        }
      ],
      dataList: [
        {
          id: 13,
          title: '清新版皮肤',
          subTitle: '清新脱俗，简约大方，营销感不重，视觉辨识度较高',
          imgUrl: [
            {
              id: 1,
              url: require('./images/skinbox.png'),
              urlWhite: require('./images/skinbox-white.png'),
              title: '清新款'
            },
          ]
        },
        {
          id: 15,
          title: '营销版皮肤',
          subTitle: '明亮张扬，时髦吸睛，营销感强烈，易引发消费冲动',
          imgUrl: [
            {
              id: 1,
              url: require('./images/skinbox-sale.png'),
              urlWhite: require('./images/skinbox-sale-white.png'),
              title: '营销款'
            }
          ]
        },
        {
          id: 3007,
          title: '春日限定版',
          subTitle: '阳光，青草和樱花的味道融入到界面里，自然而然的让用户身临其境，感受到野餐的欲望，点外卖分分钟！',
          imgUrl: [
            {
              id: 1,
              url: require('./images/skinbox-spring.png'),
              urlWhite: require('./images/skinbox-spring-white.png'),
              title: '春日限定版'
            }
          ]
        },
        {
          id: 4425,
          title: '【曾经尊贵的】黑猫皮肤',
          subTitle: '与外卖省很多设计风格高度接近。视觉辨识度高，画风可爱，有较强的记忆点。',
          imgUrl: [
            {
              id: 1,
              url: require('./images/skinbox-black.png'),
              urlWhite: require('./images/skinbox-black-white.png'),
              title: '【曾经尊贵的】黑猫皮肤'
            }
          ]
        }
      ]
    }
  }
}
const invigorateMixin = { // 保持用户活跃
  data () {
    return {
      previewList: [
        {
          id: 7,
          title: '吃货小问题',
          text: '内容方向仅为饮食冷知识，含互动，<br />对用户触达有一定帮助。',
          imgUrl: require('./images/invigorate-small.png'),
          imgNum: 1
        },
        {
          id: 4009,
          title: '综合内容',
          text: '内容方向多样，使用户对公众号保持新鲜感，<br />易产生互动，保持用户触达率。',
          imgUrl: require('./images/invigorate-multiple-small.png'),
          imgNum: 3
        },
      ],
      dataList: [
        {
          id: 7,
          title: '吃货小问题',
          subTitle: '内容方向仅为饮食冷知识，含互动，对用户触达有一定帮助。',
          imgUrl: [
            {
              id: 1,
              url: require('./images/invigorate.png'),
              title: '48小时拉活'
            },
          ]
        },
        {
          id: 4009,
          title: '综合内容',
          subTitle: '内容方向包括但不限于饮食冷知识、影视剧推荐、占卜，使用户对公众号保持新鲜感，易产生互动。',
          imgUrl: [
            {
              id: 1,
              url: require('./images/invigorate-multiple-movie.png'),
              title: '影视剧推荐'
            },
            {
              id: 2,
              url: require('./images/invigorate-multiple-divine.png'),
              title: '趣味占卜文字'
            },
            {
              id: 3,
              url: require('./images/invigorate-multiple-cold.png'),
              title: '冷知识科普'
            },
          ]
        },
      ]
    }
  }
}

/**
* setData
* 能否修改弹窗里面的数据
*/
const interactionMixins = { // 下单互动
  data () {
    return {
      previewList: [
        {
          id: 1,
          title: '综合互动-含返利',
          subTitle1: '当用户下单后，按照概率选择以下推送中的一个：',
          subTitle2:'当月已结算的美团、饿了么订单返利将在次月25日到账',
          list: [
            '趣味互动（如电影推荐、饮食冷知识、占卜等）（70%）',
          ],
          imgNum: 3
        },
        {
          id: 2,
          title: '返利',
          subTitle1: '每次都将部分佣金作为返利，可以自己设定比例',
          subTitle2:'当月已结算的美团、饿了么订单返利将在次月25日到账',
          imgNum: 1
        },
        {
          id: 3,
          title: '综合互动-无返利',
          subTitle1: '当用户下单后，随机推送以下任一素材：',
          list: [
            '电影推荐图片',
            '趣味占卜文字',
            '冷知识科普',
            '其他趣味互动',
          ],
          imgNum: 2
        },
      ],
      dataList: [
        {
          id: 1,
          title: '综合互动-含返利',
          subTitle: '当用户下单后，按概率推送以下任一素材：',
          subTitle2: '',
          setData: 1,
          list: [
            {
              id: 1,
              text: '趣味互动（如电影推荐、饮食冷知识、占卜等）<i style="font-style: normal;color:#ccc;">（70%）</i>'
            },
          ],
          imgUrl: [
            {
              id: 1,
              url: require('./images/interaction-movie.png'),
              title: '综合互动-电影'
            },
            {
              id: 2,
              url: require('./images/interaction-divine1.png'),
              title: '综合互动-占卜'
            },
            {
              id: 3,
              url: require('./images/interaction-fanli.png'),
              title: '综合互动-返利'
            },
          ]
        },
        {
          id: 2,
          title: '返利',
          subTitle: '每次都将部分佣金作为返利，',
          setData: 2,
          imgUrl: [
            {
              id: 1,
              url: require('./images/interaction-fanli.png'),
              title: '返利'
            }
          ]
        },
        {
          id: 3,
          title: '综合互动-无返利',
          subTitle: '当用户下单后，随机推送以下任一素材：',
          setData: 2,
          imgUrl: [
            {
              id: 1,
              url: require('./images/interaction-movie.png'),
              title: '综合互动-电影'
            },
            {
              id: 2,
              url: require('./images/interaction-divine1.png'),
              title: '综合互动-占卜'
            },
          ],
          list: [
            {
              id: 1,
              text: '电影推荐图片'
            },
            {
              id: 2,
              text: '趣味占卜文字'
            },
            {
              id: 3,
              text: '冷知识科普'
            },
            {
              id: 4,
              text: '其他趣味互动'
            }
          ]
        }
      ]
    }
  }
}

const incomeMisin = {
  data () {
    return {
      elmTips:'',
      mtTips:'',
      cashTips:'',
      allCashTips:'',
      statusTips:''
    }
  }
}
const hongBaoMixins = {
  data () {
    return {
      previewList: [
        {
          id: 1,
          title: '饿了么赏金红包',
          subTitle1: '被邀请者领取该红包下单，邀请者可赚取赏金。该赏金不经过满天星<br />系统，公众号主可自行前往饿了么APP提现。',
          subTitle2:'',
          imgUrl: require("./images/hongbao-index.jpg"),
          imgNum: 1
        }
      ],
      dataList: [
        {
          id: 1,
          title: '饿了么赏金红包',
          subTitle: '被邀请者领取该红包下单，邀请者可赚取赏金。该赏金不经过满天星系统，公众号主可自行前往饿了么APP提现。',
          imgUrl: [
            {
              id: 1,
              url: require('./images/hongbao-a.jpg'),
              title: '饿了么赏金红包'
            }
          ]
        }
      ]
    }
  }
}
export {
  welcomeMixins,
  mealMixin,
  skinMixin,
  invigorateMixin,
  interactionMixins,
  incomeMisin,
  checkMixin,
  hongBaoMixins
}

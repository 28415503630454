import axios from '@/utils/axios'

const API = {
  init: '/sign/ajaxInit',
  setModule: '/sign/ajaxSetSignConfig',
  buyDetail: '/sign/ajaxBuyRecord',
  payLink: '/pay/ajaxPay',
  grantList: '/sign/ajaxExchangeList',
  getCgPrice:'/sign/ajaxPurchasePrice',
  setRule: '/sign/ajaxSetSignRule',
  exportExcel: '/sign/ajaxGetDownloadInfo',
  setDiyong: '/sign/ajaxSetDiyongConfig'
}

function initPagePost () {
  return axios.post(API.init)
}

function setModulePost (param) {
  return axios.post(API.setModule, param)
}

function getBuyDetailPost (param) {
  return axios.post(API.buyDetail, param)
}

function getPayLinkPost (param) {
  return axios.post(API.payLink, param)
}

function getGrantListPost (param) {
  return axios.get(API.grantList, { params: param })
}

function getCgPricePost (param) {
  return axios.get(API.getCgPrice, { params: param })
}

function setRulePost (param) {
  return axios.post(API.setRule, param)
}

function exportExcelPost (param) {
  return axios.post(API.exportExcel, param)
}

function setDiyongSwitch (param) {
  return axios.get(API.setDiyong, { params: param })
}

export {
  initPagePost,
  setModulePost,
  getBuyDetailPost,
  getPayLinkPost,
  getGrantListPost,
  getCgPricePost,
  setRulePost,
  exportExcelPost,
  setDiyongSwitch,
}

<template>
  <div class="preview-dialog" v-if="isShowPreviewDialog">
    <div class="mask"></div>
    <div class="dialog-content">
      <a href="javascript:void(0);" class="close-dia-btn" @click="closePreviewDia">返回</a>
      <div class="dialog-left">
        <img :src="previewItem.image" alt="">
        <div class="name">{{ previewItem.title }}</div>
      </div>
      <div class="dialog-right">
        <div class="title">{{ previewItem.title }}</div>
        <div class="subtitle">{{ previewItem.subtitle1 }}</div>
        <div class="subtitle">{{ previewItem.subtitle2 }}</div>
        <div class="input-item">
          <div class="text" id="J_copy_appId">{{ previewItem.appId }}</div>
          <a-button type="primary" id="J_copy_app" @click="initCopy('#J_copy_app')" data-clipboard-target="#J_copy_appId">复制AppID</a-button>
        </div>
        <div class="input-item">
          <div class="text" id="J_copy_appPath">{{ previewItem.appPath }}</div>
          <a-button type="primary" id="J_copy_path" @click="initCopy('#J_copy_path')" data-clipboard-target="#J_copy_appPath">复制路径</a-button>
        </div>
        <div class="detail">
          <span>authorizerFansOpenId：</span><br />
          <span>公众号粉丝用户openId，如需跟单至用户，请在路径后添加此参数</span>
        </div>
      </div>
    </div>
    <toast v-if="isShowToast" :set-status="3"></toast>
  </div>
</template>

<script>
import ClipboardJS from 'clipboard'
import toast from '@/components/toast/toast'
export default {
  props: {
    isShowPreviewDialog: {
      type: Boolean,
      default: false
    },
    clickId: {
      type: Number,
      default: null
    },
    previewItem: {
      type: Object,
      default: (() => {})
    }
  },
  components: {
    toast
  },
  data () {
    return {
      isShowToast: false
    }
  },
  methods: {
    initCopy (ele) {
      const ClipboardJSObj = new ClipboardJS(ele)
      ClipboardJSObj.on("success", e => {
        ClipboardJSObj.destroy()
        this.isShowToast = true
        setTimeout(() => {
          this.isShowToast = false
        }, 2000)
        e.clearSelection();
      })
      ClipboardJSObj.on("error", e => {
        ClipboardJSObj.destroy()
        that.$message.error("复制失败，请重试")
      })
    },
    closePreviewDia () {
      this.$emit('closePreviewDia', { isShow: false })
    }
  },
  mounted () {},
}
</script>

<style lang="less" scoped>
.dialog-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  width: 772px;
  height: 468px;
  padding: 36px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 12px;
  .close-dia-btn {
    top: 36px;
  }
  .dialog-left {
    width: 214px;
    margin-right: 36px;
    img {
      width: 100%;
      height: 372px;
      margin-bottom: 12px;
    }
    .name {
      height: 24px;
      text-align: center;
      line-height: 24px;
      color: #666;
      font-size: 14px;
    }
  }
  .dialog-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 372px;
    .title {
      height: 28px;
      line-height: 28px;
      margin-bottom: 12px;
      color: #333;
      font-size: 20px;
      font-weight: 700;
    }
    .subtitle {
      line-height: 24px;
      margin-bottom: 6px;
      font-size: 14px;
      color: #666;
      &:nth-child(3) {
        margin-bottom: 24px;
      }
    }
    .input-item {
      display: flex;
      height: 40px;
      margin-bottom: 24px;
      .text {
        width: 320px;
        height: 40px;
        line-height: 40px;
        margin-right: 12px;
        border-radius: 6px;
        padding: 0 10px;
        box-sizing: border-box;
        border: 1px solid #999999;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      .ant-btn {
        width: 118px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #4392F6;
        font-size: 16px;
        color: #fff;
      }
      &:nth-child(5) {
        margin-bottom: 12px;
      }
    }
    .detail {
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      color: #999;
      span {
        margin-right: 10px;
      }
    }
  }
}
</style>
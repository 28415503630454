import axios from '@/utils/axios'

const API = {
  init: '/growth/ajaxinit',
  countAmount: '/growth/ajaxCalculateFansCost',
  payLink: '/growth/ajaxPayFansCost',
  getPayRecordsList: '/growth/ajaxGetGrowthPayRecords',
  queryPayInfo: '/growth/ajaxQueryPayInfo',
  getPlanItemData: '/growth/ajaxGetSellProduct',
  protocolVersion: '/protocol/ajaxGetProtocol'
}

function initPagePost() {
  return axios.post(API.init)
}

function countAmountPost(params) {
  return axios.post(API.countAmount, params)
}

function getPayLinkPost(params) {
  return axios.post(API.payLink, params)
}

function getPayRecordsListPost(params) {
  return axios.post(API.getPayRecordsList, params)
}

function queryPayInfoPost(params) {
  return axios.post(API.queryPayInfo, params)
}

function getPlanItemDataPost(params) {
  return axios.post(API.getPlanItemData, params)
}

function protocolVersionPost() {
  return axios.post(API.protocolVersion, params)
}

export {
  initPagePost,
  countAmountPost,
  getPayLinkPost,
  getPayRecordsListPost,
  queryPayInfoPost,
  getPlanItemDataPost,
  protocolVersionPost
}
<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <router-view/>
  </div>
  </a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
  export default {
    data() {
      return {
        zh_CN,
      };
    },
  };
</script>


<style scoped>
#app {
  height: 100%;
  min-width: 1440px;
  font-family: pingFang;
}
.login, .ant-layout, .authorizer, .wxpay, .rule {
  animation: showPage 0.3s linear;
}
</style>

<template>
  <div class="channel-manage">
    <div class="top-sec">
        <div class="top-left-sec sec">
            <div class="channel-title">渠道数量</div>
            <div class="channel-info">
                <div class="channel-data">{{ channelCount }}</div>
                <div>
                    <a-button class="channel-btn" type="primary" @click="showDiaBtn('1')">新建渠道</a-button>
                </div>
            </div>
        </div>
        <div class="top-right-sec sec">
            <div class="data-item">
                <div class="data-title">渠道总加粉</div>
                <div class="data-content-wrap">
                    <div class="data-content">
                        <div class="data-content-title">今日加粉</div>
                        <div class="data-content-txt">{{ statisticsData.subscribe_today }}</div>
                    </div>
                    <div class="data-content">
                        <div class="data-content-title">累计加粉</div>
                        <div class="data-content-txt">{{ statisticsData.subscribe_total }}</div>
                    </div>
                </div>
            </div>
            <div class="data-item">
                <div class="data-title">订单量</div>
                <div class="data-content-wrap">
                    <div class="data-content">
                        <div class="data-content-title">今日订单量</div>
                        <div class="data-content-txt">{{ statisticsData.order_today }}</div>
                    </div>
                    <div class="data-content">
                        <div class="data-content-title">累计订单量</div>
                        <div class="data-content-txt">{{ statisticsData.order_total }}</div>
                    </div>
                </div>
            </div>
            <div class="data-item">
                <div class="data-title">佣金收入</div>
                <div class="data-content-wrap">
                    <div class="data-content">
                        <div class="data-content-title">今日佣金</div>
                        <div class="data-content-txt">{{ statisticsData.commission_today }}</div>
                    </div>
                    <div class="data-content">
                        <div class="data-content-title">累计佣金</div>
                        <div class="data-content-txt">{{ statisticsData.commission_total }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="table-sec sec">
        <div class="table-tab">
            <div class="tab-item" :class="[selectItem == 2 ? 'tab-active' : '']" @click="chooseTab(1)">今日数据</div>
            <div class="tab-item" :class="[selectItem == 1 ? 'tab-active' : '']" @click="chooseTab(2)">累计数据</div>
            <div class="tips">加粉数为今日新加粉丝数，其余数据包含新老粉丝</div>
        </div>
        <a-table v-if="selectItem == 1" :pagination="paginationA" rowKey="id" :columns="columnsA" :data-source="dailyDataList" @change="getTodayDataList" bordered>
            <template slot="title" slot-scope="item">
                 <a-popover placement="top" overlayClassName="popover-box">
                    <div class="channel-name">{{ item.title }}</div>
                    <template slot="content">{{ item.title }}</template>
                 </a-popover>
             </template>
            <template slot="type" slot-scope="item">
                <div v-if="item.status == 2" style="color: #333;">停用</div>
                <div v-else-if="item.type == 1 || item.type == 3">永久</div>
                <div v-else-if="item.type == 2">30天</div>
                <!-- <div v-else-if="item.expire_time == 4">失效</div> -->
            </template>
            <template slot="status" slot-scope="item">
                <!-- <a-popover v-if="item.status == 2" placement="bottom" overlayClassName="popover-box">
                    <div class="manage"></div>
                    <template slot="content">
                        <div class="popover" @click="showDiaBtn('2', item)">编辑</div>
                        <div class="popover" @click="createOrDownload(item)">{{item.hasQrCode == 1 ? "下载" : "生成"}}二维码</div>
                        <div class="popover">下载明细</div>
                    </template>
                </a-popover> -->
                <div v-if="item.type == 3"></div>
                <div v-else-if="item.status == 2" @click="showDiaBtn('2', item)" style="color:#4392F6;cursor:pointer;">管理</div>
                <div v-else-if="item.type == 1 || item.type == 2">
                    <span @click="showDiaBtn('2', item)" style="color:#4392F6;cursor:pointer;">管理</span>
                    <span class="cz-line">|</span>
                    <span style="color:#4392F6;cursor:pointer;" @click="createOrDownload(item)">{{item.hasQrCode == 1 ? "下载" : "生成"}}</span>
                </div>
                <div v-else>/</div>
            </template>
        </a-table>
        <a-table v-if="selectItem == 2" :pagination="paginationB" rowKey="id" :columns="columnsB" :data-source="totalDataList" @change="getTotalDataList" bordered>
             <template slot="title" slot-scope="item">
                 <a-popover placement="top" overlayClassName="popover-box">
                    <div class="channel-name">{{ item.title }}</div>
                    <template slot="content">{{ item.title }}</template>
                 </a-popover>
             </template>
             <template slot="type" slot-scope="item">
                <div v-if="item.status == 2" style="color: #333;">停用</div>
                <div v-else-if="item.type == 1 || item.type == 3">永久</div>
                <div v-else-if="item.type == 2">30天</div>
                <!-- <div v-else-if="item.expire_time == 4">失效</div> -->
            </template>
            <template slot="status" slot-scope="item">
                <!-- <a-popover v-if="item.status == 2" placement="bottom" overlayClassName="popover-box">
                    <div class="manage"></div>
                    <template slot="content">
                        <div class="popover" @click="showDiaBtn('2', item)">编辑</div>
                        <div class="popover" @click="createOrDownload(item)">{{item.hasQrCode == 1 ? "下载" : "生成"}}二维码</div>
                        <div class="popover">下载明细</div>
                    </template>
                </a-popover> -->
                <div v-if="item.type == 3"></div>
                <div v-else-if="item.status == 2" @click="showDiaBtn('2', item)" style="color:#4392F6;cursor:pointer;">管理</div>
                <div v-else-if="item.type == 1 || item.type == 2">
                    <span @click="showDiaBtn('2', item)" style="color:#4392F6;cursor:pointer;">管理</span>
                    <span class="cz-line">|</span>
                    <span style="color:#4392F6;cursor:pointer;" @click="createOrDownload(item)">{{item.hasQrCode == 1 ? "下载" : "生成"}}</span>
                </div>
                <div v-else>/</div>
            </template>
        </a-table>
    </div>
    <ChannelDialog
      v-if="isShowDia"
      :dia-type="diaType"
      :channel-info="channelInfo"
      @closeDia="closeDia"
    >
    </ChannelDialog>
  </div>
</template>

<script>
import ChannelDialog from './components/ChannelDialog'
import { initPagePost, todayDataListPost, totalDataListPost, channelInfoPost, channelCodePost, channelCodeCreatePost } from '@/api/channel'
const columnsA = [
    {
        title: "渠道名称",
        // dataIndex: 'title',
        scopedSlots: { customRender: 'title' },
        align: "center",
        width: "128px"
    },
    {
        title: '加粉数',
        dataIndex: 'promotionData.subscribe_today',
        align: "center",
        width: "112px"
    },
    {
        title: '领红包数',
        dataIndex: 'promotionData.coupon_today',
        align: "center",
        width: "112px"
    },
    {
        title: '订单数',
        dataIndex: 'promotionData.order_today',
        align: "center",
        width: "112px"
    },
    {
        title: '取关数',
        dataIndex: 'promotionData.unsubscribe_today',
        align: "center",
        width: "113px"
    },
    // {
    //     title: '下单人数',
    //     dataIndex: 'promotionData.coupon_today1',
    //     align: "center",
    //     width: "103px"
    // },
    // {
    //     title: '订单量',
    //     dataIndex: 'promotionData.coupon_today2',
    //     align: "center",
    //     width: "103px"
    // },
    {
        title: 'GMV',
        dataIndex: 'promotionData.gmv_today',
        align: "center",
        width: "113px"
    },
    {
        title: '佣金',
        dataIndex: 'promotionData.commission_today',
        align: "center",
        width: "113px"
    },
    {
        title: '有效期',
        scopedSlots: { customRender: 'type' },
        align: "center",
        width: "113px"
    },
    {
        title: '操作',
        scopedSlots: { customRender: 'status' },
        align: "center",
        width: "113px"
    }
]
const columnsB = [
    {
        title: "渠道名称",
        // dataIndex: 'title',
        scopedSlots: { customRender: 'title' },
        align: "center",
        width: "128px"
    },
    {
        title: '加粉数',
        dataIndex: 'promotionData.subscribe_total',
        align: "center",
        width: "112px"
    },
    {
        title: '领红包数',
        dataIndex: 'promotionData.coupon_total',
        align: "center",
        width: "112px"
    },
    {
        title: '订单数',
        dataIndex: 'promotionData.order_total',
        align: "center",
        width: "112px"
    },
    {
        title: '取关数',
        dataIndex: 'promotionData.unsubscribe_total',
        align: "center",
        width: "112px"
    },
    // {
    //     title: '下单人数',
    //     dataIndex: 'promotionData.coupon_total1',
    //     align: "center",
    //     width: "103px"
    // },
    // {
    //     title: '订单量',
    //     dataIndex: 'promotionData.unsubscribe_total2',
    //     align: "center",
    //     width: "103px"
    // },
    {
        title: 'GMV',
        dataIndex: 'promotionData.gmv_total',
        align: "center",
        width: "113px"
    },
    {
        title: '佣金',
        dataIndex: 'promotionData.commission_total',
        align: "center",
        width: "113px"
    },
    {
        title: '有效期',
        scopedSlots: { customRender: 'type' },
        align: "center",
        width: "113px"
    },
    {
        title: '操作',
        scopedSlots: { customRender: 'status' },
        align: "center",
        width: "113px"
    }
]

export default {
  data() {
    return {
        statisticsData: {},
        channelCount: 0,
        selectItem: 1,
        columnsA,
        columnsB,
        dailyDataList: [],
        totalDataList: [],
        paginationA: {
            current: 1,
            pageSize: 10,
            total: 20
        },
        paginationB: {
            current: 1,
            pageSize: 10,
            total: 20
        },
        isShowDia: false,
        diaType: '',
        newChannelData: '', // 创建渠道提交的数据
        updateChannelData: {}, // 管理渠道提交的数据,
        channelInfo: {}, // 更新时子组件默认展示的数据
        createCodeStatus: false,
        downloadCodeStatus: false
    };
  },
  components: {
    ChannelDialog
  },
  mounted() {
      this.initPage()
      this.getTodayDataList()
  },
  methods: {
        async initPage () {
            const { status, data } = await initPagePost()
            if (status === 1) {
                this.channelCount = data.channelCount
                this.statisticsData = data.data
                this.paginationA.total = Number(data.channelCount)
                this.paginationB.total = Number(data.channelCount)
            }
        },
        async getTodayDataList (current) {
            let params = {}
            if (current && current.current) this.paginationA.current = current.current
            params = {
                page: this.paginationA.current,
                pageSize: this.paginationA.pageSize
            }
            const { status, data } = await todayDataListPost(params)
            if (status === 1) {
                this.dailyDataList = data
            }
        },
        async getTotalDataList (current) {
            let params = {}
            if (current && current.current) this.paginationB.current = current.current
            params = {
                page: this.paginationB.current,
                pageSize: this.paginationB.pageSize
            }
            const { status, data } = await totalDataListPost(params)
            if (status === 1) {
                this.totalDataList = data
            }
        },
        chooseTab (tab) {
            if (this.selectItem == tab) return
            if (tab == 1) {
                this.paginationA.current = 1
                this.getTodayDataList()
            }
            if (tab == 2) {
                this.paginationB.current = 1
                this.getTotalDataList()
            }
            this.selectItem = tab
        },
        showDiaBtn (diaType, item) {
            this.diaType = diaType
            if (diaType == 2) {
                this.getChannelInfo(item.id)
            } else {
                this.isShowDia = true
            }
        },
        async getChannelInfo (id) {
            const { status, data, info } = await channelInfoPost({id})
            if (status === 1) {
                this.channelInfo = data.channel
                this.isShowDia = true
            } else {
                this.$message.error(info)
            }
        },
        closeDia (data) {
            this.isShowDia = data.dialogStatus
            if (data && data.initPage) {
                this.initPage()
                if (this.selectItem == 1) this.getTodayDataList()
                if (this.selectItem == 2) this.getTotalDataList()
            }
        },
        createOrDownload (item) {
            if (item.hasQrCode != 1) {
                this.channelCodeCreate(item.id)
            } else {
                this.downloadChannelCode(item.id)
            }
        },
        async downloadChannelCode (id) {
            if (this.downloadCodeStatus) return
            this.downloadCodeStatus = true
            const { status, data, info } = await channelCodePost({id})
            if (status === 1) {
                this.openLink(data.channelQrCodeLink)
                this.downloadCodeStatus = false
            } else {
                this.$message.error(info)
            }
        },
        async channelCodeCreate (id) {
            if (this.createCodeStatus) return
            this.createCodeStatus = true
            const { status, data, info } = await channelCodeCreatePost({id})
            if (status === 1) {
                if (data.status === 1) {
                    this.$message.success("渠道码生成成功")
                } else {
                    this.$message.error("渠道码生成失败")
                }
                if (this.selectItem == 1) this.getTodayDataList()
                if (this.selectItem == 2) this.getTotalDataList()
                this.createCodeStatus = false
            } else {
                this.$message.error(info)
            }
        },
        openLink(url, target) {
            let a = document.createElement('a');
            a.setAttribute('href', url);
            a.setAttribute('target', target || '_blank');
            document.body.appendChild(a);
            a.click();
        }
  }
};
</script>

<style lang="less" scoped>
div span {
    box-sizing: border-box;
}
.channel-manage {
    width: 1080px;
    .sec {
        border-radius: 4px;
        background: #FFFFFF;
        box-shadow: 0px 2px 12px 0px #DCE3F0;
    }
    .top-sec {
        display: flex;
        justify-content: space-between;
        .top-left-sec {
            width: 234px;
            height: 144px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 24px;
            .channel-title {
                color: #666666;
                font-size: 14px;
            }
            .channel-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .channel-data {
                    color: #333;
                    font-size: 32px;
                }
            }
        }
        .top-right-sec {
            width: 822px;
            height: 144px;
            padding: 24px 0;
            display: flex;
            .data-item {
                flex: 1;
                // border-left: 1px solid #EDF1F4;
                padding-left: 24px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                &:first-child{
                    border:none;
                }
                .data-title {
                    color: #666666;
                    font-size: 14px;
                }
                .data-content-wrap {
                    display: flex;
                    .data-content {
                        flex: 1;
                        .data-content-title {
                            color: #999999;
                            font-size: 14px;
                        }
                        .data-content-txt {
                            color: #333333;
                            text-shadow: 0px 2px 8px 0px #338BDA;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
    .table-sec {
        padding: 19px 24px 24px;
        margin-top: 24px;
        .table-tab {
            // width: 140px;
            position: relative;
            display: flex;
            // justify-content: space-between;
            margin-bottom: 18px;
            .tab-item {
                cursor: pointer;
                margin-right: 28px;
            }
            .tips {
                position: absolute;
                right: 0;
                height: 20px;
                line-height: 20px;
                padding-left: 30px;
                box-sizing: border-box;
                font-size: 14px;
                color: #FF7A00;
                background: url('./images/horn.png') left center no-repeat;
                background-size: 24px 24px;
            }
        }
        .tab-active {
            color: #4392F6;
        }
        .cz-line {
            display: inline-block;
            margin: 0 10px;
            color: #D7E3F5;
        }
    }

    /deep/ .ant-table {
        color: #666;
        .ant-table-thead th {
            color: #666;
        }
    }
}
.manage {
    width: 100%;
    height: 24px;
    color: #4392F6;
    font-size: 30px;
    // cursor: pointer;
    background: url('./images/more.png') center center no-repeat;
    background-size: 24px 4px;
}
.popover-box {
    height: 138px;
    padding: 0 10px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 4px 40px 0px #8F9CB7;
}
.popover {
    width: 140px;
    height: 46px;
    line-height: 46px;
    font-size: 14px;
    color: #4392F6;
    text-align: center;
    border-bottom: 1px solid #e8e8e8;
    box-sizing: border-box;
    cursor: pointer;
    &:last-child {
        border-bottom: none;
    }
}
/deep/ .ant-table-title {
    height: 0;
    padding: 0;
    border: none !important;
}
/deep/ .ant-table-tbody > tr > td {
    padding: 16px 10px;
}
.channel-name {
    width: 107px;
    max-width: 107px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    cursor: default;
}
</style>

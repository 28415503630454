<template>
  <div class="add-promote">
    <div class="mask"></div>
    <div class="dialog-content">
      <header>{{addPromoteTitle}}添加推广位</header>
      <div class="text">已授权的淘宝联盟账户：
        <div class="max-length">{{addPromoteTaobaoAccount && addPromoteTaobaoAccount.tb_user_nick ? addPromoteTaobaoAccount.tb_user_nick : authInfo && authInfo.nick ? authInfo.nick : ''}}</div>
      </div>
      <div class="input-wrap">
        <div class="name-wrap">
          <div class="left">添加推广位名称：</div>
          <router-link :to="{ path: '/help-account?id=anchor-2&type=elm' }" target="_blank">如何获取推广位名称</router-link>
        </div>
        <a-input v-model="promoteName" placeholder="前往阿里妈妈找到推广位名称，复制后粘贴在这里" />
      </div>
      <div class="input-wrap">
        <div class="name-wrap">
          <div class="left">添加推广位PID：</div>
          <router-link :to="{ path: '/help-account?id=anchor-2&type=elm' }" target="_blank">如何获取推广位PID</router-link>
        </div>
        <a-input :class="{error: pidError}" v-model="pid" placeholder="前往阿里妈妈找到推广位PID，复制后粘贴在这里" />
        <div class="err-text" v-if="pidError">{{pidErrorText}}</div>
      </div>
      <div class="btn-wrap">
        <a-button type="primary" :class="{ disable: !promoteName || !pid }" @click="addPromote">添加</a-button>
        <a-button @click="closeAddPromote('close')">关闭</a-button>
      </div>
    </div>
    <toast v-if="isShowToast" set-info="推广位添加成功" :set-status="1"></toast>
  </div>
</template>
<script>
import {
  addPromotePost
} from '@/api/accountType'
import toast from '@/components/toast/toast'
export default {
  components: {
    toast
  },
  props: {
    authInfo: {
      type: Object,
      default: () => {}
    },
    addPromoteTaobaoAccount: {
      type: Object,
      default: () => {}
    },
    addPromoteTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      promoteName: '',
      pid: '',
      pidError: false,
      pidErrorText: '',
      isSendAjax: false,
      isShowToast: false
    }
  },
  methods: {
    async addPromote () {
      const { promoteName, pid, addPromoteTaobaoAccount, authInfo, isSendAjax } = this
      if (!promoteName || !pid || isSendAjax) return
      this.isSendAjax = true
      const ajaxData = {
        tb_user_nick: addPromoteTaobaoAccount && addPromoteTaobaoAccount.tb_user_nick ? addPromoteTaobaoAccount.tb_user_nick : authInfo && authInfo.nick ? authInfo.nick : '',
        tb_user_id: addPromoteTaobaoAccount && addPromoteTaobaoAccount.tb_user_id ? addPromoteTaobaoAccount.tb_user_id : authInfo && authInfo.tb_user_id ? authInfo.tb_user_id : '',
        name: promoteName,
        pid
      }
      const { status, data, info } = await addPromotePost(ajaxData)
      if (status === 1) {
        // this.$message.success(info)
        this.isShowToast = true
        setTimeout(() => {
          this.isShowToast = false
        }, 2000)
        this.closeAddPromote('add')
      } else {
        this.pidErrorText = info
        this.pidError = true
      }
      this.isSendAjax = false
    },
    closeAddPromote (type) { // add 添加成功关闭弹窗  close 直接关闭弹窗
      this.$emit('closeAddPromote', type)
    },
    pathTo (path) {
      this.$router.push(path)
    }
  },
  mounted() {},
}
</script>
<style lang="less" scoped>
.dialog-content {
  position: fixed;
  top: 50%;
  left:50%;
  width:762px;
  height: 440px;
  background:#fff;
  border-radius:12px;
  padding: 36px;
  transform: translate(-50%,-50%);
  z-index: 4;
  color: #333;
  font-size: 16px;
  header {
    height: 36px;
    line-height: 39px;
    margin-bottom: 24px;
    color: #000;
    font-size: 28px;
  }
  .text {
    display: flex;
    align-items: center;
    height: 22px;
    margin-bottom: 25px;
    .max-length {
      max-width: 270px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }
  }
  .input-wrap {
    width: 454px;
    margin-bottom: 24px;
    .ant-input {
      height: 36px;
      line-height: 36px;
    }
  }
  .name-wrap {
    width: 454px;
    height: 24px;
    left: 24px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 11px;
    a {
      line-height: 24px;
      font-size: 14px;
    }
    .ant-checkbox-wrapper {
      margin-right: 4px;
    }
    .checkbox-wrap {
      height: 24px;
      line-height: 24px;
    }
  }
  .btn-wrap {
    position: absolute;
    right: 36px;
    bottom: 36px;
    .ant-btn {
      display: block;
      width: 200px;
      height: 60px;
      margin-top: 12px;
      font-size: 20px;
      &:last-of-type {
        color: #4392F6;
        border-color: #4392F6;
      }
      &.disable {
        opacity: 0.3;
      }
    }
  }
}
.error {
  color: #FF4141;
}
.err-text {
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  margin-top: 12px;
  color: #FF4141;
}
</style>
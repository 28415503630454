<template>
  <div class="rule">
    <PageHeader class="page-rule" :show-app-info="1"></PageHeader>
    <div class="space-fixed"></div>
    <div class="container">
      <div class="rule-container">
        <div class="title">满天星增长服务保障计划</div>
        <div class="item">
          为保障使用满天星增长服务的客户权益，满天星已推出“满天星增长服务保障计划”。具体内容如下：
        </div>
        <ul class="list">
          <li>每月15日为满天星“增长服务质控日”。质控日当天，满天星官方公众号会公布《满天星增长服务质量月度控制报告》，其中包含增长服务的整体粉丝单粉日产、展示所有异常处理明细等内容。</li>
          <li>持续监测正在执行（或已经执行完成）的服务中粉丝异常情况，并在增长服务菜单内每日更新。</li>
        </ul>
        <div class="item">
          满天星的“增长服务质量控制小组”将通过以下方式来支持服务保障计划的运行：
          </div>
        <div class="subtitle">1、异常推广者识别：</div>
        <div class="item">
          根据满天星内部质量风控标准，对疑似有质量问题的推广人员进行识别、初步标识。
        </div>
        <div class="item">
          1.1加粉行为异常标识<br />
          风控系统对加粉行为增设多项异常指标（IP异常/时间异常等），在加粉过程中<span style="color:red;">实时</span>识别异常。一旦被标识为异常，相关推广者将被提交核查。
        </div>
        <div class="item">
          1.2举报投诉异常标识<br />
          如接到加粉异常举报或投诉，相关推广者将被直接提交核查，质控小组会<span style="color:red;">在2个工作日内</span>确认加粉数据是否异常。
        </div>
        <div class="item">
          1.3粉丝质量异常标识<br />
          对粉丝产出质量建立T0~T15多维度预警指标，进行更长期的监测。<span style="color:red;">每日</span>，系统将根据质量控制规则进行分批检查，并将疑似异常推广者提交核查。
        </div>
        <div class="subtitle">2、异常核实</div>
        <div class="item">
          对于被标识为“疑似异常”的推广者，风控小组<span style="color:red;">每日</span>进行人工核实。一经确认，推广者将被标识为“异常推广者”，我方会进一步确认出现数据异常的“异常期间”。
        </div>
        <div class="item">
          <span style="color:red;">*如未正确按照满天星运营规则配置后台，将导致质量数据难以监测。相关推广数据无法被纳入服务保障计划，满天星对此类情况将保留判断和解释的权利。</span>
        </div>
        <div class="subtitle">3、异常处理</div>
        <div class="item">
          3.1异常推广者处理<br />
          一旦确认推广异常，将立即暂停相关团队一切推广活动，由<span style="color:red;">增长团队大区负责人</span>负责监督整改。异常推广者处理完毕、内部整改完成，推广活动才会再次开展。
        </div>
        <div class="item">
          3.2补偿处理<br />
          风控团队将<span style="color:red;">每日</span>拉取受异常推广影响的公众号，明确异常粉丝数量，异常粉丝数量将不计入增粉数量。
        </div>
        <div class="item">
          3.3后台显示<br />
          针对购买增长服务的客户，满天星系统将<span style="color:red;">每日更新</span>被筛除的异常粉丝数，以及扣除异常粉丝后的实际增粉进度。
        </div>
        <div class="item">
          3.4定期公示<br />
          每月15日，满天星官方公众号会公布《满天星增长服务质量月度控制报告》，异常处理明细将作为报告的一部分呈现给公众。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/header/PageHeader";
export default {
  components: {
    PageHeader,
  },
};
</script>

<style lang="less" scoped>
.rule {
  min-height: 100%;
  color: #333847;
  background: #f3f6fc;
  padding-bottom: 24px;
  .page-rule {
    position: fixed;
    top: 0;
    left: 0;
  }
  .space-fixed {
    height: 60px;
  }
}
.container {
  width: 100%;
  height: calc(100vh - 84px);
  overflow-y: auto;
}
.rule-container {
  width: 960px;
  margin: 24px auto;
  padding: 36px;
  box-sizing: border-box;
  font-size: 14px;
  background: #feffff;
  .title {
    height: 28px;
    line-height: 28px;
    font-size: 20px;
    margin-bottom: 24px;
    text-align: center;
  }
  .subtitle {
    height: 20px;
    line-height: 20px;
    margin: 24px 0 12px;
  }
  .item {
    color: #707793;
    margin-bottom: 12px;
  }
  .list{
    padding-left:20px;
    list-style-type: disc;
    color: #707793;
  }
}
</style>

<template>
  <div>
    <div class="authtwo">
      关联一个属于你的小程序，与之建立绑定关系。<br />
      你可以直接复用「外卖省很多」小程序的功能和玩法，<br />
      省心又高效地提升各项转化。
    </div>
    <div class="bottom-care">
      <div class="bottom-title">关联小程序注意：</div>
      <ul>
        <li>公众号可关联3个非同主体小程序，且一个月可新增关联非同主体小程序3次</li>
      </ul>
    </div>
    <!-- <div v-else-if="relationStatus == 2" class="authtwo">
      请求已于1月12日12:23:23通过微信消息发送至您的手机，<br />
      请前往确认。
    </div>
    <div v-else-if="relationStatus == 3" class="authtwo">
      您在1月28日12:23:23确认关联，请等待满天星平台为您审核确认。若超时2小时未确认，请及时
      <a-popover placement="bottom">
        <template slot="content">
          <img class="code-img" src="" alt="">
        </template>
        <a-button class="tips">联系客服顾问</a-button>
      </a-popover>为您解决。
    </div> -->
    <!-- <div class="authtwo icon" v-else-if="relationStatus == 4">
      您的关联请求被拒绝<br />
      很抱歉暂时无法通过您的关联请求，请及时
      <a-popover placement="bottom">
        <template slot="content">
          <img class="code-img" src="" alt="">
        </template>
        <a-button class="tips">联系客服顾问</a-button>
      </a-popover>为您解决。
    </div> -->
    <!-- <div v-else-if="relationStatus == 5" class="authtwo icon">
      您已拒绝关联<br />
      此举将导致您无法正常使用「外卖省很多」小程序的功能和玩法，建议您重新关联小程序。
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    // relationStatus: {
    //   default: 1
    // }
  }
}
</script>

<style lang="less" scoped>
.authtwo {
  color: #333847;
  font-size: 28px;
  line-height: 51px;
  .tips.ant-btn {
    height: 45px;
    border: none;
    box-shadow: none;
    padding: 0;
    color: #4384FC;
    /deep/ span {
      font-size: 28px;
    }
  }
}
img.code-img {
  width: 156px;
  height: 156px;
}
.icon {
  background: url('../images/icon-close.png') left 6px no-repeat;
  background-size: 30px 30px;
  text-indent: 42px;
}
.bottom-care {
  margin-top:24px;
  margin-bottom: 39px;
  .bottom-title {
    height: 30px;
    line-height: 30px;
    padding-left: 36px;
    margin-bottom: 23px;
    background: url('../images/clock.png') center left no-repeat;
    background-size: contain;
    font-size:20px;
    color: #333847;
  }
  ul {
    padding-left: 0;
    li {
      list-style:none;
      height: 28px;
      line-height: 28px;
      font-size:20px;
      margin-bottom: 12px;
      color:#999;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
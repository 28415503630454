<template>
  <div class="hongbao">
    <contentHeader @checkSwitch="checkSwitch" :is-default-checked="isDefaultChecked" header-type="8"></contentHeader>
    <div class="content">
      <div class="mobile-box" :class="{edit: editHb}">
        <div class="tip" v-if="!editHb">当前小程序红包</div>
          <div class="hb" v-if="editHb && isDefaultChecked == 1">
            <div class="hb-item" :class="[{border: editHb}, {select: selectLeftHbItem.id === item.id && editHb}]" @click="leftHbItemHandler(item)" v-for="(item, index) in leftHbList" :key="index">
              <a href="javascript:void(0);" class="close" v-if="selectLeftHbItem.id === item.id && editHb && item.type" @click="rightHbListHandler(null)">
                <div class="icon"></div>
              </a>
              <img v-if="item.type" :src="item.img" alt="">
              <div class="add-hb" v-else>
                <a-icon type="plus-circle" class="icon" />
                <div>添加红包</div>
              </div>
            </div>
          </div>
          <div class="hb" v-else>
            <div class="hb-item" v-for="(item, index) in leftHbList" :key="index">
              <img :src="item.img" alt="" />
            </div>
          </div>
        <a-button type="primary" @click="editHb = true" v-if="!editHb && isDefaultChecked == 1">编辑红包</a-button>
        <div class="space"></div>
        <div class="space"></div>
      </div>
      <div class="hb-set" v-if="editHb && isDefaultChecked == 1">
        <div class="title">红包设置</div>
        <div class="subtitle">第一排红包显示为大红包样式，第二排红包显示为小卡片样式，您可以通过“预览”查看实际的展示效果</div>
        <div class="hb-box">
          <div class="hb-item" v-for="(item, index) in hbList" :key="index">
            <div class="name">{{item.name}}</div>
            <img :src="item.img" alt="">
            <a-button type="link" v-if="selectLeftHbItem.type !== item.type" @click="rightHbListHandler(item)">选择</a-button>
            <a-button class="selected" type="link" v-else>已选择</a-button>
          </div>
        </div>
        <footer>
          <a-button @click="showQuitEditDialog = true">退出编辑</a-button>
          <a-button type="primary" @click="priviewHandler">预览并使用</a-button>
        </footer>
      </div>
    </div>
    <div class="txdialog quit-edit-dialog" v-if="showQuitEditDialog">
      <img src="./../authorizer/images/wraning.png" alt="">
      <div class="title">确定退出红包编辑吗？</div>
      <div class="subtitle">退出后，所有修改的内容不会保存</div>
      <div class="btn-box">
        <a-button type="primary" @click="showQuitEditDialog = false">继续编辑</a-button>
        <a-button @click="quitEditHandler">退出编辑</a-button>
      </div>
    </div>
    <div class="txdialog quit-edit-dialog" v-if="showRetainDialog">
      <img src="./../authorizer/images/wraning.png" alt="">
      <div class="title">需要至少保留一个红包</div>
      <div class="subtitle">为保证小程序显示正常，请至少选择添加一个红包</div>
      <a-button class="btn" @click="showRetainDialog = false">我知道了</a-button>
    </div>
    <div class="dialog txdialog" v-if="showHbdialog">
      <div class="title">红包效果预览
        <a href="javascript:void(0);" class="close-dia-btn" @click="showHbdialog = false">关闭</a>
      </div>
      <div class="content">
        <div class="mobile-box">
          <div class="hb">
            <div class="hb-item" v-for="(item, index) in leftHbList" :key="index">
              <img :src="item.img" alt="">
            </div>
          </div>
          <div class="space"></div>
          <div class="space"></div>
        </div>
      </div>
      <div class="bottom">
        <a-button type="primary" @click="submitHandler">确定使用</a-button>
      </div>
    </div>
    <!-- <HongBaoModule v-for="item in previewList" :key="item.id" @showPreviewDia="showPreviewDia" :item="item" :class="{selected: item.id == selectedId}"></HongBaoModule> -->
    <previewDialog :app-path="appPath" :transmit-data="transmitData" :data-list="dataList" @closePreviewDia="closePreviewDia" @selectedOk="selectedOk" size-status="3"></previewDialog>
    <div class="mask" v-if="showQuitEditDialog|| showHbdialog || showRetainDialog"></div>
    <toast v-if="isShowToast" :set-status="1" set-info="红包编辑成功"></toast>
  </div>
</template>

<script>
import contentHeader from '@/components/header/header'
// import HongBaoModule from './components/hongBaoModule'
import toast from '@/components/toast/toast'
import { hongBaoMixins } from '@/mixins/mixins'
import previewDialog from '@/components/dialog/dialog'
import { initHbListPost, hbPositionPost } from '@/api/hongbao'
const hbList = [
  {
    id: 0,
    name: '饿了么红包',
    type: 'ele_common',
    img: require('./images/eleme.png'),
    imgB: require('./images/elm-b.png'),
    imgS: require('./images/elm-s.png')
  },
  {
    id: 1,
    name: '美团红包',
    type: 'mt_common',
    img: require('./images/meituan.png'),
    imgB: require('./images/mt-b.png'),
    imgS: require('./images/mt-s.png')
  },
  {
    id: 2,
    name: '幸运红包',
    type: 'ele_lucky',
    img: require('./images/xingyun.png'),
    imgB: require('./images/xy-b.png'),
    imgS: require('./images/xy-s.png')
  },
  {
    id: 3,
    name: '随机红包',
    type: 'ele_random',
    img: require('./images/suiji.png'),
    imgB: require('./images/sj-b.png'),
    imgS: require('./images/sj-s.png')
  },
]
export default {
  components: {
    contentHeader,
    // HongBaoModule,
    previewDialog,
    toast
  },
  mixins: [hongBaoMixins],
  data () {
    return {
      selectedId: 1,
      isDefaultChecked: null,
      transmitData: { // 传给弹窗的数据
        previewId: null, // 点击预览的id
        showPreview: false, // 显示预览弹窗
        imgNum: null // 弹窗的图片的数量
      },
      appPath: "",
      hbList,
      leftHbList: [],
      editHb: false,
      selectLeftHbItem: {},
      selectRightHbItem: {},
      showQuitEditDialog: false,
      showHbdialog: false,
      showRetainDialog: false,
      isShowToast: false
    }
  },
  methods: {
    async initHbList () {
      const { status, data } = await initHbListPost()

      if (status === 1) {
        this.isDefaultChecked = data.switch
        this.appPath = data.config.app_path
        this.leftHbList = this.restructureLeftHbList(data.config.info)
        this.selectLeftHbItem = this.leftHbList[0]
      }
    },
    restructureLeftHbList (list = []) {
      const ids = [0, 1, 2, 3]
      return list.map((item,idx) => {
        item.id = ids[idx]
        item.img = item.type ? this.setImage(idx, item.type) : idx < 2 ? require('./images/gd-b.png') : require('./images/gd-s.png')
        return item
      })
    },
    setImage (idx = null, type = '') {
      if (idx < 2) {
        if (type === 'ele_common') return require('./images/elm-b.png')
        if (type === 'mt_common') return require('./images/mt-b.png')
        if (type === 'ele_lucky') return require('./images/xy-b.png')
        if (type === 'ele_random') return require('./images/sj-b.png')
      } else {
        if (type === 'ele_common') return require('./images/elm-s.png')
        if (type === 'mt_common') return require('./images/mt-s.png')
        if (type === 'ele_lucky') return require('./images/xy-s.png')
        if (type === 'ele_random') return require('./images/sj-s.png')
      }
    },
    selectedOk (appData) {
      const { selectRightHbItem, leftHbList } = this
      this.appPath = appData.app_path
      this.leftHbList = this.changeLeftHbList(leftHbList, selectRightHbItem)
    },
    async checkSwitch (val) {
      const { status, info } = await hbPositionPost({ switch: val })
      if (status === 1) {
        this.isDefaultChecked = val
        this.initHbList()
      } else {
        this.$message.error(info)
      }
    },
    showPreviewDia (data) {
      this.transmitData = { ...data }
    },
    closePreviewDia (val) {
      this.transmitData.showPreview = val
    },
    leftHbItemHandler (hbItem) {
      const { selectLeftHbItem } = this
      if (selectLeftHbItem.id === hbItem.id) return
      this.selectLeftHbItem = hbItem
    },
    rightHbListHandler (hbItem) {
      const { leftHbList } = this
      this.selectRightHbItem = hbItem
      if (hbItem && hbItem.type === "ele_random") {
        this.showPreviewDia({
          showPreview: true,
          previewId: 1,
          imgNum: 1
        })
      } else {
        this.leftHbList = this.changeLeftHbList(leftHbList, hbItem)
      }
    },
    changeLeftHbList (list = [], hbItem = {}) {
      const { selectLeftHbItem } = this
      return list.map(item => {
        if (item.id === selectLeftHbItem.id) {
          item.img = hbItem && hbItem.type ? (selectLeftHbItem.id < 2 ? hbItem.imgB : hbItem.imgS) : (selectLeftHbItem.id < 2 ? require('./images/gd-b.png') : require('./images/gd-s.png'))
          item.type = hbItem && hbItem.type ? hbItem.type : '',
          item.name = hbItem && hbItem.type ? hbItem.name : ''
        }
        return item
      })
    },
    quitEditHandler () {
      this.initHbList()
      this.showQuitEditDialog = false
      this.editHb = false
    },
    priviewHandler () {
      const { leftHbList } = this
      const isEmpty = leftHbList.some(item => {
        return item.type !== ''
      })
      if (!isEmpty) return this.showRetainDialog = true
      this.showHbdialog = true
    },
    async submitHandler () {
      const { leftHbList } = this
      let config = this.getAjaxData(leftHbList)
      let params = {
        config,
        app_path: this.appPath,
        switch: this.isDefaultChecked
      }
      const { status, data } = await hbPositionPost(params)
      if (status === 1) {
        this.isShowToast = true
        setTimeout(() => {
          this.isShowToast = false
        }, 2000)
        this.editHb = false
      }
      this.showHbdialog = false
      this.initHbList()
    },
    getAjaxData (list = []) {
      return list.map(item => {
        item = {
          name: item.name ? item.name : '',
          type: item.type ? item.type : ''
        }
        return item
      })
    },
  },
  created () {
    this.initHbList()
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 1080px;
  display: flex;
  .mobile-box {
    position: relative;
    width: 298px;
    height: 482px;
    border-radius: 4px;
    margin-right: 24px;
    border: 1px solid #333;
    padding: 97px 16px 0 54px;
    background: url('./images/mobile.png') center center no-repeat;
    background-size: contain;
    overflow: hidden;
    &.edit {
      border-color: #ccc;
    }
    .tip {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 32px;
      line-height: 32px;
      text-align: center;
      font-size: 14px;
      color: #fff;
      background: rgba(0, 0, 0, 0.6);
    }
    .hb {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      .hb-item {
        position: relative;
        width: 110px;
        height: 108px;
        border-radius: 4px;
        border: 1px solid transparent;
        box-sizing: border-box;
        cursor: pointer;
        color: #999;
        overflow: hidden;
        &.border {
          border-color: #ccc;
          &.select {
            border-color: #333;
            color: #333;
          }
        }
        &:nth-child(3), &:nth-child(4) {
          height: 80px;
          margin-top: 8px;
          .add-hb {
            margin-top: 15px;
          }
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }
        .close {
          position: absolute;
          right: -26px;
          top: -26px;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          color: #fff;
          font-size: 10px;
          font-weight: 700;
          background: rgba(0, 0, 0, 0.5);
          .icon {
            position: absolute;
            bottom: 10px;
            left: 10px;
            width: 8px;
            height: 8px;
            background: url('./images/close.png') center center no-repeat;
            background-size: 100% 100%;
          }
        }
        .add-hb {
          height: 100%;
          text-align: center;
          margin-top: 29px;
          .icon {
            margin-bottom: 8px;
            font-size: 22px;
          }
        }
      }
    }
    .ant-btn {
      position: absolute;
      bottom: 24px;
      left: 50%;
      transform: translateX(-50%);
      width: 104px;
      height: 30px;
      font-size: 14px;
    }
    .space {
      width: 100%;
      height: 130px;
      margin-top: 20px;
      border-radius: 4px;
      background: #efefef;
    }
  }

  .hb-set {
    position: relative;
    width: 758px;
    height: 482px;
    padding: 24px;
    border-radius: 4px;
    background: #FFFFFF;
    .title {
      height: 22px;
      line-height: 22px;
      font-size: 16px;
      margin-bottom: 12px;
      color: #000;
      font-weight: 500;
    }
    .subtitle {
      height: 20px;
      line-height: 20px;
      margin-bottom: 16px;
      font-size: 14px;
      color: #666;
    }
    .hb-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .hb-item {
        width: 160px;
        height: 160px;
        padding-top: 16px;
        border-radius: 4px;
        background: #F6F6F6;
        text-align: center;
        .name {
          height: 20px;
          line-height: 20px;
          margin-bottom: 6px;
          font-size: 14px;
          color: #333;
        }
        img {
          width: 68px;
          height: 68px;
          margin-bottom: 8px;
        }
        .ant-btn {
          display: block;
          width: 104px;
          height: 30px;
          margin: 0 auto;
          background: #fff;
          border-radius: 4px;
          text-align: center;
          line-height: 30px;
          font-size: 14px;
          &.selected {
            color: #999;
          }
        }
      }
    }
    footer {
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: flex-end;
      width: calc(100% - 48px);
      height: 88px;
      padding: 24px 0;
      border-top: 1px solid #EBEBEB;
      .ant-btn {
        width: 104px;
        height: 40px;
        margin-left: 24px;
        font-size: 16px;
        &:first-of-type {
          color: #4392F6;
          border-color: #4392F6;
        }
      }
    }
  }
}
.quit-edit-dialog {
  width: 480px;
  height: 361px;
  padding: 46px 36px 36px;
  text-align: center;
  img {
    width: 114px;
    height: 99px;
    margin-bottom: 24px;
  }
  .title {
    height: 28px;
    line-height: 28px;
    margin-bottom: 12px;
    font-size: 20px;
    color: #333;
  }
  .subtitle {
    height: 20px;
    line-height: 20px;
    margin-bottom: 36px;
    font-size: 14px;
    color: #666;
  }
  .btn-box {
    display: flex;
    justify-content: space-between;
    height: 60px;
    .ant-btn {
      width: 192px;
      height: 60px;
      font-size: 20px;
      &:last-of-type {
        color: #4392F6;
        border-color: #4392F6;
      }
    }
  }
  .btn {
    width: 100%;
    height: 60px;
    font-size: 20px;
    color: #4392F6;
    border-color: #4392F6;
  }
}
.dialog {
  width: 480px;
  height: 579px;
  padding: 0;
  .title {
    height: 88px;
    padding: 36px 36px 0;
    margin-bottom: 24px;
    border-bottom: 1px solid #EBEBEB;
    font-size: 20px;
    color: #333;
    .close-dia-btn{
      top: 40px;
      color: #333;
      &::after{
        top: 2px;
        right: -20px;
        width: 18px;
        height: 18px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiBAMAAADIaRbxAAAAG1BMVEUAAAAyMjIwMDAwMDAzMzMyMjIyMjIyMjIzMzOMyWXQAAAACHRSTlMA3yAQz4BwYCC2TscAAAB7SURBVCjPxdDBDYAgDAVQg3EAOfUIJno2bmDcwBVcwBVIODi2pPb7wwT0AD/wSGi75uVOC/NqYUjhu5EbRrzuMZspUZGTo6xEIEQgRCBEkxGi0QjRA8ITj4hXewo1yZsQ2XcjEDpCdyTojgTdkSj6J1ZIPbH+srDAtKsX7oIUKxBDeOgAAAAASUVORK5CYII=)no-repeat center;
        background-size: 100%;
        border: none;
        transform: none;
      }
    }
  }
  .content {
    width: 480px;
    height: 347px;
    overflow: hidden;
    .mobile-box {
      width: 298px;
      height: 482px;
      border-radius: 4px;
      border: 1px solid #333333;
      margin: 0 auto;
      background: url('./images/mobile.png') center center no-repeat;
      background-size: contain;
    }
  }
  .bottom {
    height: 120px;
    text-align: center;
    border-top: 1px solid #EBEBEB;
    padding-top: 24px;
    .ant-btn {
      width: 200px;
      height: 60px;
      font-size: 20px;
    }
  }
}
</style>
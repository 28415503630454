<template>
  <div class="status-dialog" v-if="showStatusDialog">
    <div class="mask"></div>
    <div class="status-dialog-box">
      <div class="dialog-status-icon">
        <img src="./images/danger.png" alt="">
      </div>
      <div class="dialog-status-title">{{ dialogStatus == 2 ? '功能已关闭' : '功能开启失败'}}</div>
      <div class="dialog-status-subtitle" v-html="dialogStatus == 2?'您的公众号未关联试吃团小程序，<br />满天星已为您关闭试吃相关功能。':'您已关联3个【不同主体】小程序，无法再关联试吃团小程序。<br />请前往微信公众平台，解绑适量小程序后再重试。'"></div>
      <a href="javascript:void(0);" class="dialog-status-btn" @click="closeStatusDialog">我知道了</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dialogStatus: {
      default: 0
    },
    showStatusDialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  methods: {
    closeStatusDialog () {
      this.$emit('closeStatusDialog', false)
    }
  }
}
</script>

<style lang="less" scoped>
.status-dialog {
  position: relative;
  .status-dialog-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    width: 480px;
    height: 381px;
    background: #fff;
    border-radius: 12px;
    padding: 46px 36px 36px;
    text-align: center;
    .dialog-status-icon {
      width:114px;
      height: 99px;
      text-align: center;
      margin:0 auto 24px;
      img {
        width: 100%;
        // height: 100%;
      }
    }
    .dialog-status-title {
      height: 28px;
      margin-bottom: 12px;
      line-height: 28px;
      font-size: 20px;
      color: #333;
      font-weight: 500;
    }
    .dialog-status-subtitle {
      height: 40px;
      margin-bottom: 36px;
      line-height: 20px;
      font-size: 14px;
      color: #666;
    }
    .dialog-status-btn {
      display: block;
      width: 408px;
      height: 60px;
      box-sizing: border-box;
      border:1px solid #4392F6;
      background: #FFF;
      border-radius: 8px;
      line-height: 58px;
      text-align: center;
      color: #4392F6;
      font-size: 20px;
      font-weight:500;
    }
  }
}
</style>
<template>
  <div class="status-dialog" v-if="showStatusDialog">
    <div class="mask"></div>
    <div class="status-dialog-box">
      <div class="close-dia-btn" @click="closeStatusDialog" v-if="dialogStatus == 3">返回</div>
      <div class="dialog-status-icon">
        <img :src="dialogStatus == 1 || dialogStatus == 2 ? require('./images/success.png') : require('./images/danger.png')" alt="">
      </div>
      <div class="dialog-status-title">{{ dialogStatus == 1 ? '提交成功' : dialogStatus == 2 ? '支付成功' : dialogStatus == 4? '支付失败': '当前访问人数较多'}}</div>
      <div :class="['dialog-status-subtitle', dialogStatus == 3 ? 'danger' : '']">{{ dialogStatus == 1 ? '提现金额预计3个工作日内到账' : dialogStatus == 2 ? '您所采购的会员卡将会在24小时内进入库存' : dialogStatus == 4 ? '请稍后重新支付' :'请稍等几秒重试' }}</div>
      <template v-if="dialogStatus == 4">
        <div class="dialog-status-btn-box">
          <a href="javascript:void(0);" class="dialog-status-btn-cancel" @click="closeStatusDialog">取消支付</a>
          <a href="javascript:void(0);" class="dialog-status-btn-again" @click="closeStatusDialog">重新支付</a>
        </div>
      </template>
      <a href="javascript:void(0);" v-else class="dialog-status-btn" @click="closeStatusDialog">确定</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dialogStatus: {
      default: 0
    },
    showStatusDialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  methods: {
    closeStatusDialog () {
      this.$emit('closeStatusDialog', false)
    }
  }
}
</script>

<style lang="less" scoped>
.status-dialog {
  position: relative;
  .status-dialog-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    width: 432px;
    height: 370px;
    background: #fff;
    border-radius: 12px;
    padding: 51px 36px 36px;
    text-align: center;
    .dialog-status-icon {
      width:114px;
      height: 99px;
      text-align: center;
      margin:0 auto 24px;
      img {
        width: 100%;
        // height: 100%;
      }
    }
    .dialog-status-title {
      height: 28px;
      margin-bottom: 12px;
      line-height: 28px;
      font-size: 20px;
      color: #333847;
      font-weight: 500;
    }
    .dialog-status-subtitle {
      height: 24px;
      margin-bottom: 36px;
      line-height: 24px;
      font-size: 14px;
      color: #5C6485;
      &.danger {
        color: #333847;
        font-size: 20px;
        font-weight: 500;
      }
    }
    .dialog-status-btn {
      display: block;
      width: 360px;
      height: 60px;
      background: #4392F6;
      border-radius: 6px;
      line-height: 60px;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }
    .dialog-status-btn-box{
      display:flex;
      justify-content: center;
      height:60px;
      .dialog-status-btn-cancel{
        display:block;
        width:120px;
        height:60px;
        margin-right:24px;
        line-height:58px;
        text-align: center;
        font-size:20px;
        color:#5C6485;
        font-weight: medium;
        box-sizing: border-box;
        border-radius: 6px;
        border: 1px solid #5C6485;
      }
      .dialog-status-btn-again{
        display:block;
        width:216px;
        height:60px;
        line-height:60px;
        text-align: center;
        font-size:20px;
        color:#FFF;
        font-weight: medium;
        background: #4392F6;
        border-radius: 6px;
      }
    }
  }
}
</style>
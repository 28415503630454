<template>
  <div class="invigorate">
    <contentHeader
      @checkSwitch="checkSwitch"
      :is-default-checked="isDefaultChecked"
      header-type="7"
    ></contentHeader>
    <div class="content">
      <template v-for="item in previewList">
        <previewModule
          :key="item.id"
          :data="item"
          :class="[selectedId == item.id ? 'selected' : '']"
          @showPreviewDia="showPreviewDia"
          type="2"
        ></previewModule>
      </template>
    </div>
    <!-- size-status="1" -->
    <previewDialog
      :transmit-data="transmitData"
      :data-list="dataList"
      :selected-id="selectedId"
      @selectedOk="selectedOk"
      @closePreviewDia="closePreviewDia"
      size-status="99"
    ></previewDialog>
    <toast v-if="isShowToast" :set-status="setStatus"></toast>
  </div>
</template>

<script>
import contentHeader from "@/components/header/header";
import previewModule from "@/components/preview/preview";
import previewDialog from "@/components/dialog/dialog";
import toast from "@/components/toast/toast";
import { invigorateMixin } from "@/mixins/mixins";
import { initPagePost, setModulePost } from "@/api/invigorate";

export default {
  mixins: [invigorateMixin],
  components: {
    contentHeader,
    previewModule,
    previewDialog,
    toast,
  },
  data() {
    return {
      selectedId: null, // 默认选中模块的id
      transmitData: {
        // 传给弹窗的数据
        previewId: null, // 点击预览的id
        showPreview: false, // 显示预览弹窗
        imgNum: null, // 弹窗的图片的数量
      },
      isDefaultChecked: null,
      isShowToast: false,
      setStatus: null,
    };
  },
  methods: {
    async initPage() {
      const { status, data } = await initPagePost();
      if (status === 1) {
        this.isDefaultChecked = false,// data.switch;
        this.selectedId = data.config.id;
      }
    },
    showPreviewDia(data) {
      this.transmitData = { ...data };
    },
    closePreviewDia(val) {
      this.transmitData.showPreview = val;
    },
    async selectedOk(dataObj) {
      let ajaxData = {
        switch: this.isDefaultChecked,
      };
      if (dataObj && dataObj.id) {
        ajaxData = { config_id: dataObj.id, switch: 1};
      }
      const { status } = await setModulePost(ajaxData);
      if (status === 1) {
        if (!dataObj || !dataObj.id) return;
        this.selectedId = dataObj.id;
        this.isDefaultChecked = 1;
        this.setStatus = 1;
      } else {
        this.setStatus = 0;
      }
      this.isShowToast = true;
      setTimeout(() => {
        this.isShowToast = false;
      }, 2000);
    },
    checkSwitch(val) {
      this.isDefaultChecked = false; // val;
      //this.selectedOk();
    },
  },
  created() {
    this.initPage();
  },
};
</script>

<style lang="less" scoped>
.content {
  display: flex;
}
</style>

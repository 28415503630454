import axios from '@/utils/axios'

const API = {
  init: '/union/ajaxinit',
  accountList: '/union/ajaxGetAccountList',
  addPromote: '/union/ajaxAddPid',
  deleteAccount: '/union/ajaxDelTkAuth',
  deletePid: '/union/ajaxDelPid',
  pidList: '/union/ajaxGetPidList',
  setMtInfo: '/union/ajaxSetMtAccount',
  mtAccountList: '/union/ajaxGetMtAccount',
  getTkAuthUrl: '/union/ajaxGetTkAuthUrl'
}

function initPagePost () {
  return axios.post(API.init)
}

function accountListPost (params) {
  return axios.post(API.accountList, params)
}

function addPromotePost (params) {
  return axios.post(API.addPromote, params)
}

function deleteAccountPost (params) {
  return axios.post(API.deleteAccount, params)
}
function deletePidPost (params) {
  return axios.post(API.deletePid, params)
}
function pidListPost (params) {
  return axios.post(API.pidList, params)
}
function setMtInfoPost (params) {
  return axios.post(API.setMtInfo, params)
}
function mtAccountListPost () {
  return axios.get(API.mtAccountList)
}
function getTkAuthUrlPost (params) {
  return axios.post(API.getTkAuthUrl, params)
}
export {
  initPagePost,
  accountListPost,
  addPromotePost,
  deleteAccountPost,
  deletePidPost,
  pidListPost,
  setMtInfoPost,
  mtAccountListPost,
  getTkAuthUrlPost
}

const menuMixins = {
  data () {
    return {
      menuList: [
        {
          id: 1,
          title: '用户使用手册',
          list: [
            {
              id: 1,
              text: '登录'
            },
            {
              id: 2,
              text: '授权'
            },
            {
              id: 3,
              text: '配置公众号菜单'
            },
            {
              id: 4,
              text: '配置欢迎语'
            },
            {
              id: 5,
              text: '下单后的互动'
            },
            {
              id: 6,
              text: '粉丝签到送会员'
            },
            {
              id: 7,
              text: '粉丝吃饭提醒'
            },
            {
              id: 8,
              text: '更换小程序皮肤'
            },
            {
              id: 9,
              text: '保持粉丝活跃'
            },
            {
              id: 10,
              text: '收入和提现'
            },
            {
              id: 11,
              text: '微信小程序相关问题'
            },
            {
              id: 12,
              text: '《满天星用户使用协议》'
            },
          ]
        },
        {
          id: 2,
          title: '售前问题Q&A',
          list: [
            {
              id: 13,
              text: '配置相关问题'
            },
            {
              id: 14,
              text: '运营相关问题'
            },
            {
              id: 15,
              text: '收益与结算问题'
            },
            {
              id: 16,
              text: '安全性与归属问题'
            },
            {
              id: 17,
              text: '其他问题'
            },
          ]
        },
        {
          id: 3,
          title: '增长服务订购协议',
          list: [
            {
              id: 19,
              text: '协议内容及签署'
            },
            {
              id: 20,
              text: '增长服务订购及支付流程'
            },
            {
              id: 21,
              text: '知识产权'
            },
            {
              id: 22,
              text: '免责声明'
            },
            {
              id: 23,
              text: '协议中止、解除及终止'
            },
            {
              id: 24,
              text:'法律适用、管辖与其他'
            }
          ]
        },
      ]
    }
  }
}

const contentMixins = {
  data () {
    return {
      contentList: [
        {
          id: 1,
          parent_title: '一、用户手册',
          title: "1. 登录",
          first_anchor: true,
          contentArr: [
            {
              text: "在电脑浏览器中输入满天星官网地址：<a href='http://mtx.net.cn/' target='_blank'>http://mtx.net.cn/</a> 进入官网。点击右上角登录，进入满天星登录页面。",
              imageSrc: require("../images/1.png"),
            },
            {
              text: "也可以在浏览器中直接输入登录入口的网址<a href='https://saas.mtx.net.cn' target='_blank'>https://saas.mtx.net.cn</a> ，进入登录页面。",
              imageSrc: require("../images/2.png"),
            },
            {
              text: "在登录页，输入购买满天星服务时预留的手机号，获取验证码后进行登录。（注意是购买满天星服务时预留的手机号，不是公众号绑定的手机号。）"
            },
          ]
        },
        {
          id: 2,
          title: "2. 授权",
          contentArr: [
            {
              text: "<div style='text-indent:0'>如何授权？</div><div style='margin:18px 0'>首次登录后，需要授权所要管理的公众号，并关联满天星外卖小程序。</div>",
              imageSrc: require("../images/3.png"),
            },
            {
              text: "点击“开始授权公众号”，将会跳转至微信公众号后台。<div style='margin:18px 0'>(如果非首次授权，或者授权过期、主动取消授权、被动取消授权、登录时系统会提示重新授权，按照提示重新授权即可。授权流程与首次授权时一致。)</div>",
              imageSrc: require("../images/5.png"),
            },
            {
              text: "打开公众号绑定的管理员个人微信扫描页面上的二维码。<div style='margin:18px 0'>注意：如果个人微信绑定的有多个公众号，请选择想要通过满天星管理的公众号。公众号必须是已认证的服务号，且已开启全部权限。</div>",
              imageSrc: require("../images/4.png"),
            },
            {
              text: "扫描后，手机微信将会跳转至授权页面：",
              imageSrc: require("../images/6.png"),
              imageSize: 'small'
            },
            {
              text: "点击“授权”按钮进行授权：",
              imageSrc: require("../images/7.png"),
              imageSize: 'small'
            },
            {
              text: "手机微信授权完成后，电脑浏览器将回到满天星页面，并自动进入关联小程序页面。",
              imageSrc: require("../images/8.png"),
            },
            {
              text: "公众号粉丝下单时，需要通过外卖小程序进行。满天星平台为用户免费提供了小程序服务。为使用该服务，公众号需关联满天星小程序。<div style='margin:18px 0'>点击“关联小程序”按钮即可。</div>",
              imageSrc: require("../images/9.png"),
            },
            {
              text: "至此，已完成授权公众号及关联小程序步骤。<div style='margin:18px 0'>点击“配置菜单”，即可进入满天星平台。</div>",
              imageSrc: require("../images/10.png"),
            },
            {
              text: "新用户首次进入满天星时，系统会为用户默认配置好欢迎语、吃饭提醒、保持用户活跃、配套小程序四项常用功能。"
            }
          ]
        },
        {
          id: 3,
          title: "3. 配置公众号菜单",
          contentArr: [
            {
              text: "底部菜单是公众号界面底部的点击组件，用于方便的展示和打开公众号所拥有的功能。",
              imageSrc: require("../images/11.png"),
            },
            {
              text: "满天星定制菜单是对公众号功能的延伸，也是各项活动和策略的展示入口，基于历史实践经验和数据分析，我们挑出了一些转化效果不错的菜单内容供使用。<div style='margin:18px 0'>菜单可添加一级菜单和二级菜单。最多创建3个一级菜单。每个一级菜单下的子菜单最多可创建5个。添加子菜单后，一级菜单的内容将被清除。</div><div style='margin:18px 0'>满天星定制菜单是对公众号功能的延伸，也是各项活动和策略的展示入口，基于历史实践经验和数据分析，我们挑出了一些转化效果不错的菜单内容供使用。</div><div style='margin:18px 0'>菜单可添加一级菜单和二级菜单。最多创建3个一级菜单。每个一级菜单下的子菜单最多可创建5个。添加子菜单后，一级菜单的内容将被清除。</div><div style='margin:18px 0'>目前可定制的菜单包括：领外卖红包、免费送会员、返利账户。</div><div style='margin:18px 0'>领外卖红包是外卖公众号最重要的菜单。如果不添加该菜单，用户将不能便利地领取外卖红包。点击该菜单，公众号将发送“小程序卡片”消息。点击卡片进入小程序，可领取美团和饿了么红包。</div><div style='margin:18px 0'>免费送会员是提升用户留存和忠诚度的工具。如果不添加该菜单，用户将无法找到免费领会员的活动入口。点击该菜单，公众号将发送“小程序卡片”消息。点击卡片进入小程序，领取外卖红包，每月累计领20天红包得外卖会员。</div><div style='margin:18px 0'>返利账户是与粉丝自身的返利数据息息相关。如果不添加该菜单，粉丝将无法查看自己的账户信息。</div>"
            }
          ]
        },
        {
          id: 4,
          title: "4. 配置欢迎语",
          contentArr: [
            {
              text: "欢迎语，是粉丝关注公众号后默认显示的第一条信息，一般用于自我介绍或部分功能直达。",
              imageSrc: require("../images/12.png"),
            },
            {
              text: "<div style='margin:18px 0'>有三种模板：仅文字、仅小程序、文字+小程序。系统为新用户默认配置了文字与小程序结合的模板。用户也可手动选择其他模板。选择不同模板，粉丝关注公众号时将发送以下内容：</div><div style='text-indent:36px;margin:10px 0'>● 仅文字</div><div style='text-indent:72px;margin:10px 0'>未开启签到领会员功能时：</div><div style='text-indent:108px'>{昵称} 终于等到你啦！谢谢关注|ω•`)✧</div><div style='text-indent:108px'>你可以通过我：每天领3-20元外卖红包</div><div style='text-indent:108px'>1. 红包金额不等，建议都领，哪个大用哪个</div><div style='text-indent:108px'></div><div style='text-indent:108px'>2. 可以置顶我，领红包不会忘哦~ </div><div style='text-indent:108px'>3. 入口：点底部菜单栏领取↓</div><div style='text-indent:72px;margin:10px 0'>开启签到领会员功能时：</div><div style='text-indent:108px'>{昵称} 终于等到你啦！谢谢关注|ω•`)✧</div><div style='text-indent:108px'>你可以通过我：</div><div style='text-indent:108px'>1. 领外卖红包（每日更新 越领越大）</div><div style='text-indent:108px'>2. 免费领外卖会员月卡（每月1张）</div><div style='text-indent:108px'>3. 点下方菜单栏领取↓</div>",
              imageSrc: require("../images/13.png"),
            },
            {
              text: "<div style='text-indent:36px;margin:10px 0'>● 仅小程序</div><div style='text-indent:72px;margin:10px 0'>领红包</div><div style='text-indent:108px'>消息内容：小程序卡片</div><div style='text-indent:108px'>消息标题：{昵称} 你今天的外卖红包已更新好，立即领取！</div><div style='text-indent:72px;margin:10px 0'>领会员</div><div style='text-indent:108px'>消息内容：小程序卡片</div><div style='text-indent:108px'>消息标题：你有1张外卖会员月卡可免费领！</div>",
              imageSrc: require("../images/14.png"),
            },
            {
              text: "<div style='text-indent:36px;margin:10px 0'>● 文字+小程序</div><div style='text-indent:72px;margin:10px 0'>同时包含文字和小程序部分的内容。</div>",
              imageSrc: require("../images/15.png"),
            },
          ]
        },
        {
          id: 5,
          title: "5. 下单后的互动",
          contentArr: [
            {
              text: "下单互动，是外卖送达后自动向用户推送的消息。有“综合互动”、“返利”两种模式。用户可以选择其中的一种。<div style='text-indent:36px'>可随时手动启用，也可随时禁用。系统默认不开启。启用时默认配置综合互动。</div>",
              imageSrc: require("../images/16.png")
            },
            {
              text: "返利模式是将部分佣金发放给用户。默认30%，可通过预览模板修改。返利到账时间：根据帐期决定，不可修改。",
              imageSrc: require("../images/17.png")
            },
            {
              text: "综合互动模式，有可能发放佣金，也有可能向用户发送电影推荐图片或者趣味占卜文字。返利中奖概率：30%，电影推荐图片和趣味占卜文字的概率都随机。",
              imageSrc: require("../images/18.png")
            },
            {
              imageSrc: require("../images/19.png")
            },
          ]
        },
        {
          id: 6,
          title: "6. 粉丝签到送会员",
          contentArr: [
            {
              text: "每日签到，是用户每月累计领红包一定天数可以获赠饿了么和美团外卖会员月卡的功能，成本较高，但运营效果很好。",
              imageSrc: require("../images/20.png")
            },
            {
              text: "开启签到送会员之前，需要预先采购用于赠送的会员卡，避免库存不足用户投诉。 可点击页面上的“补充库存”按钮购买会员卡。支持微信或支付宝付款。",
              imageSrc: require("../images/21.png")
            },
            {
              text: "<div style='margin:18px 0'>系统会在功能开启过程中持续监控会员卡的预计发放情况。</div><div style='text-indent:72px;margin:10px 0'>库存剩余=历史采购会员卡总量-（已核销卡量+核销中卡量）</div><div style='text-indent:72px;margin:10px 0'>累计已发=历史已核销卡量+核销中卡量</div><div style='text-indent:72px;margin:10px 0'>预计待发=已累计签到15天的用户数</div><div style='text-indent:36px;margin:10px 0'>采购明细中，记录了每次采购会员卡的记录，包括采购的时间、会员卡种类、数量、价格等。</div><div style='text-indent:36px;margin:10px 0'>发放明细中，记录了每次发放会员卡的记录，包括发放时间、会员卡种类、接收者手机号、会员卡状态等。</div><div style='text-indent:36px;margin:10px 0'>会员卡存量小于已累计签到15天的用户数时，系统会向公众号主发起短信提醒。短信内容：【满天星】您的 XXXXX公众号 本月累计签到超过15天的粉丝已有XXXX个，您采购的会员卡存量告急，请及时补货。</div>"
            }
          ]
        },
        {
          id: 7,
          title: "7. 粉丝吃饭提醒",
          contentArr: [
            {
              text: "吃饭提醒功能，公众号每天上午10:30为用户发送点餐提醒消息，用户可以直接互动领取红包。",
              imageSrc: require("../images/22.png")
            },
            {
              text: "<div style='margin:18px 0'>有三种模板：互动文字、小程序卡片。两种模板用户收到的信息如下：</div><div style='text-indent:36px;margin:10px 0'>● 互动文字：</div><div style='text-indent:72px;margin:10px 0'>消息内容：</div><div style='text-indent:108px;margin:10px 0'>{nickname}，干饭时间到！</div><div style='text-indent:108px;margin:10px 0'>【{date}】外卖红包又更新啦</div><div style='text-indent:108px;margin:10px 0'>→戳我生成今日外卖大红包| ᴥ•́ )✧</div><div style='text-indent:36px;margin:10px 0'>● 小程序卡片：</div><div style='text-indent:72px;margin:10px 0'>消息内容：小程序卡片</div><div style='text-indent:108px;margin:10px 0'>消息标题：{nickname}，{date}的外卖红包已更新，立即领取↓</div><div style='text-indent:108px;margin:10px 0'>点击小程序可领取外卖红包。</div><div style='text-indent:36px;margin:10px 0'>吃饭提醒功能默认启用，且启用默认的文字与小程序结合模板，可随时手动禁用。但停用该功能，会失去一个提醒用户领红包下单的机会，在一定程度上会影响领红包率和下单率。</div>"
            }
          ]
        },
        {
          id: 8,
          title: "8. 更换小程序皮肤",
          contentArr: [
            {
              text: "皮肤模板为外卖小程序提供了不同风格的皮肤，可根据公众号自身的风格选取，以配合公众号的整体调性。",
              imageSrc: require("../images/23.png")
            },
            {
              text: "清新版皮肤：清新脱俗，简约大方，营销感不重，视觉辨识度较高。<div style='text-indent:36px;margin:10px 0'>不管选用哪种皮肤，小程序标题都将以公众号的名称命名。</div>"
            }
          ]
        },
        {
          id: 9,
          title: "9. 保持粉丝活跃",
          contentArr: [
            {
              text: "公众号粉丝如果超过48小时没有与公众号进行过互动，公众号下发消息的功能将会受到限制。为此满天星提供了保持活跃的功能，通过不同的方式引导用户进行互动，用来保证公众号各类消息可以正常发给用户。",
              imageSrc: require("../images/24.png")
            },
            {
              text: "功能默认启用，使用吃货小问题模板，可随时手动禁用<div style='text-indent:36px;margin:10px 0'>吃货小问题模板，用若干有意思的吃货小问题引起用户的兴趣并产生互动行为，从而使用户和公众号保持长时间的连接，提升用户粘性。每次将向用户发送一个小问题，吸引用户回答。</div><div style='text-indent:36px;margin:10px 0'>建议保持该功能开启，因为停用该功能，当用户与公众号48小时内没有互动后，将无法收到公众号发出的对话消息。</div>"
            }
          ]
        },
        {
          id: 10,
          title: "10. 收入和提现",
          contentArr: [
            {
              text: "我的收入菜单中，呈现所有的收入汇总和明细。<div style='text-indent:36px;margin:10px 0'>也可在此页面将收入提现。</div>",
              imageSrc: require("../images/25.png")
            },
            {
              imageSrc: require("../images/26.png")
            },
            {
              text: "收入区分饿了么和美团<div style='text-indent:36px;margin:10px 0'>待结算收入，指外卖平台订单状态为“已结算”，但尚未向满天星结算所有订单的收入。</div><div style='text-indent:36px;margin:10px 0'>不同外卖平台的账期不同（美团每月1号，饿了么每月20号），外卖平台在各自的账期，向满天星发送账单数据。满天星在收到账单的5个工作日内，根据账单数据，将外卖平台“已结算”状态的订单收入从“待结算收入”划入“可提现收入”。将订单对应的佣金计提粉丝佣金，粉丝发起提现时平台进行打款。</div><div style='text-indent:36px;margin:10px 0'>收入明细中，佣金指订单中外卖平台发放的佣金。出让返利指按照设定的分佣比例计算出的给粉丝的佣金分成。预计收入指佣金-出让返利。</div><div style='text-indent:36px;margin:10px 0'>平台提供导出功能，导出为Excel格式文件，一次最多导出10万条数据。</div><div style='text-indent:36px;margin:10px 0'>提现功能目前仅支持一次性全提。仅支持向银行卡提现，提现时将代为扣除6%增值税和1%经营税。另根据饿了么平台规则，需收取10%平台服务费。银行卡号必须与购买满天星时提供的信息一致。</div>"
            }
          ]
        },
        {
          id: 11,
          title: "11. 微信小程序相关问题",
          contentArr: [
            {
              text: "<div style='text-indent:0;margin:10px 0'><b style='color:#333'>11.1 关联微信小程序操作手册</b></div><div style='text-indent:36px;margin:10px 0'>1、前往微信公众平台<b style='color:#333'>（https://mp.weixin.qq.com）</b>，登录您的公众号，在左侧菜单栏中找到小程序-小程序管理<span style='color:#999'>（如下图小手所示位置）</span></div>",
              imageSrc: require("../images/27.jpeg")
            },
            {
              text: "2、点击“关联小程序”，并用管理员微信扫描二维码进行验证。",
              imageSrc: require("../images/28.jpeg")
            },
            {
              text: "3、在搜索框内输入需关联的小程序AppID，在搜索结果中选择目标小程序，点击下一步即可添加成功。<div style='text-indent:36px;margin:10px 0'>满天星系统小程序AppID：wx5205047d5686c7dc</div><div style='text-indent:36px;margin:10px 0'>饿了么小程序AppID：wxece3a9a4c82f58c9</div>",
              imageSrc: require("../images/29.png")
            },
            {
              text: "<div style='color:red'>注：如添加失败，微信会告诉您失败原因，您可将该原因反馈给满天星官方服务顾问，我们会为您一对一解决问题。</div>",
              imageSrc: require("../images/50.png"),
              imageSize: 'small'
            },
            {
              text: "<b style='color:#333'>11.2 微信小程序AppID获取方式</b><div style='margin:10px 0'>1、打开微信小程序，点击右上角“...”<span style='color:#999'>（如下图小手所示位置）</span></div>",
              imageSrc: require("../images/31.png"),
              imageSize: 'small'
            },
            {
              text: "2、点击小程序名称<span style='color:#999'>（如下图小手所示位置）</span>",
              imageSrc: require("../images/32.png"),
              imageSize: 'small'
            },
            {
              text: "3、点击“更多资料”<span style='color:#999'>（如下图小手所示位置）</span>",
              imageSrc: require("../images/33.png"),
              imageSize: 'small'
            },
            {
              text: "4、复制AppID<span style='color:#999'>（如下图小手所示位置）</span>",
              imageSrc: require("../images/34.png"),
              imageSize: 'small'
            },
            {
              text: "<div style='color:red'>注：如仍遇到问题，您可将问题反馈给满天星官方服务顾问，我们会为您一对一解决问题。</div>",
              imageSrc: require("../images/50.png"),
              imageSize: 'small'
            },
            {
              text: "<b style='color:#333'>11.3 微信小程序页面路径获取方式</b><div style='margin:10px 0'>*如您需要设置饿了么赏金红包页面路径，<a href='https://docs.qq.com/doc/DREtIRkpvVUpEZ0Nq?pub=1&dver=2.1.26967325' target='_blank'>戳这里查看</span></a></div><div style='margin:18px 0'>1、<a href='https://docs.qq.com/doc/DRHRvUnprRExJQ2VP?pub=1&dver=2.1.26967325' target='_blank'>公众号关联小程序</a></div><div style='margin:18px 0'>2、打开微信公众平台登录您的公众号后台，在公众号后台左侧功能菜单区点击创作管理-图文素材-新的创作-写新图文<span style='color:#999'>（如下图小手所示位置）</span></div>",
              imageSrc: require("../images/35.jpeg")
            },
            {
              text: "3、在图文编辑页顶部点击“小程序”<span style='color:#999'>（如下图小手所示位置）</span>",
              imageSrc: require("../images/36.jpeg")
            },
            {
              text: "4、在跳出弹窗内的输入框内输入小程序AppID，在搜索结果中选择饿了么小程序，并点击下一步",
              imageSrc: require("../images/37.png")
            },
            {
              text: "5、点击“获取更多页面路径”<span style='color:#999'>（如下图小手所示位置）</span>",
              imageSrc: require("../images/38.png")
            },
            {
              text: "<div>6、输入任意个人微信号<span style='color:red'>（填入的微信号需关注公众号）</span>并点击开启<span style='color:#999'>（如下图小手所示位置）</span></div><div style='margin:10px 0;color:red'>除页面路径以外的字段无需填写，无需发布文章</div>",
              imageSrc: require("../images/39.png")
            },
            {
              text: "7、用前一步输入的微信号打开微信小程序卡片，点击右上角“...”，选择复制页面路径<span style='color:#999'>（如下图小手所示位置，图以饿了么微信小程序为例）</span>",
              imageSrc: require("../images/40.png"),
              imageSize: 'small'
            },
            {
              text: "8、将复制的页面路径中去掉“.html”，<span style='color:red'>切记去掉，否则无法生效</span><div style='margin:10px 0'>以外卖省很多小程序的赏金红包链接路径为例：</div><div style='margin:10px 0'>复制得到的页面路径：ele-recommend-price/pages/guest/index.html?inviterId=e8dc051061</div><div style='margin:10px 0'>去掉.html后的页面路径：ele-recommend-price/pages/guest/index?inviterId=e8dc051061</div><div style='color:red;margin:30px 0 10px'>注：如仍遇到问题，您可将问题反馈给满天星官方服务顾问，我们会为您一对一解决问题。</div>",
              imageSrc: require("../images/50.png"),
              imageSize: 'small'
            },
            {
              text: "<b style='color:#333' id='anchor-hb'>11.4 饿了么赏金红包微信小程序页面路径获取方式</b><div style='margin:10px 0'>1、 打开饿了么APP，点击首页“天天赚现金”（如下图小手所示位置）</div>",
              imageSrc: require("../images/41.jpg"),
              imageSize: 'small'
            },
            {
              text: "2、 进入页面，点击“复制小程序链接”（如下图小手所示位置）",
              imageSrc: require("../images/42.jpg"),
              imageSize: 'small'
            },
            {
              text: "3、返回满天星界面，将复制的链接粘贴到下方方框内，并点击按钮“保存并使用”。后台将自动配置赏金红包到小程序当中，呈现形式为“随机红包”",
              imageSrc: require("../images/43.jpg"),
              // imageSize: 'small'
            },
            // {
            //   text: "4、返回满天星界面，将复制的链接粘贴到下方方框内，并点击按钮“保存并使用”。<br /><span style='margin-left:64px;'></span>后台将自动配置赏金红包到小程序当中，呈现形式为“随机红包”。",
            //   imageSrc: require("../images/44.jpeg")
            // },
          ]
        },
        {
          id: 12,
          title: "12.《满天星用户使用协议》",
          quesAnswerArr: [

            {
              question: "",
              answer: "<a style='color:#337fe5;' href='https://saas.mtx.net.cn/#/rule' target='_blank'>点击查看《满天星用户使用协议》</a>"
            },
          ]
        },
        {
          id: 13,
          title: "1、配置相关问题",
          parent_title: "二、售前问题Q&A",
          quesAnswerArr: [
            {
              question: "1、满天星的配置复杂吗？需要做什么前期准备？",
              answer: "您需要提前注册服务号，申请微信企业认证，认证通过后就可以前往满天星官网授权外卖系统。配置很简单，约2分钟就可以配置好全套服务。"
            },
            {
              question: "2、配置中什么是可以修改的，什么是不能修改的？",
              answer: "目前小程序皮肤可以选择不同的模板，小程序头部文案会显示公众号主自己的公众号名称，部分运营策略可自行勾选配置（如下单后是否选择给用户返利等）。 未来会开放更多可自定义内容，如吃饭提醒时间的调整、小胖语录文案内容等。"
            },
            {
              question: "3、满天星配置后是否会和已有其他第三方系统冲突？",
              answer: "和第三方系统不冲突。满天星平台功能是基于菜单入口而定的，粉丝使用的底部菜单对应各自的系统，互不干扰。"
            },
            {
              question: "4、公众号菜单如何配置？",
              answer: "经过我们的长期测试与实验，目前满天星平台中提供的菜单设置是效果最好的，故建议大家先直接使用该菜单去覆盖掉自己原有的菜单。<div style='margin-top:10px'>经过我们的长期测试与实验，目前满天星平台中提供的菜单设置是效果最好的，故建议大家先直接使用该菜单去覆盖掉自己原有的菜单。</div>"
            },
            {
              question: "5、订阅号是否支持配置？",
              answer: "不支持。"
            },
            {
              question: "6、是否可以支持更多红包？如商超红包等。",
              answer: "目前不支持，近期我们只支持4个红包：饿了么新老联盟红包、饿了么赏金红包、美团联盟红包。其他红包的能力我们会在后续版本的迭代中逐步加入。以上四个红包已经是我们测试下来收益最高的红包产品。"
            },
            {
              question: "7、饿了么赏金红包是否支持配置？",
              answer: "第一期版本内不会提供赏金红包领取入口。但满天星会立即迭代，未来您只需自行注册饿了么账号，并生成一个您的赏金红包链接，即可配置在您的满天星系统内。"
            },
            {
              question: "8、满天星只能配置一个公众号吗？",
              answer: "同一时间满天星只支持授权配置1个公众号，但您可以通过在微信公众平台取消授权的方式，再更换另一个公众号进行授权配置。不过，一旦取消授权，原公众号的配置能力均会失效。"
            },
            {
              question: "9、外卖省很多的小惊喜红包怎么实现？",
              answer: "现金红包需要公众号有微信支付能力，该能力对公众号有较高的要求，我们在后期会进行更新。"
            },
            {
              question: "10、满天星只能在PC端使用吗？",
              answer: "是的，我们暂时不支持除PC端之外的终端使用。"
            },
            {
              question: "11、我想要更多的样式和模板，会有吗？",
              answer: "会的，我们会在各个功能里都增加越来越多的样式和模板。"
            }
          ]
        },
        {
          id: 14,
          title: "2、运营相关问题",
          quesAnswerArr: [
            {
              question: "1、外卖省很多的日常推文是否可以转载？",
              answer: "我们允许和建议转载公告与营销类文章，内容与活动类文章不建议转载。因为会导致同质化运营，反而运营效果会下降。未来满天星平台也会考虑上线“推文素材库”，供大家使用，降低内容运营的成本。"
            },
            {
              question: "2、签到送会员必须开启么？成本如何？",
              answer: "签到送会员您可以自主选择是否打开。开启后，您的用户会有更强的粘性，同时为您带来更多的收益，但也会增加运营的成本。预计会占用您佣金部分的25%左右，您可以自行评估。会员卡的费用需要您提前在满天星系统内充值。"
            },
            {
              question: "3、是否支持给用户返利？用户返利什么时候到账，如何提现？",
              answer: "支持，您可在后台开启菜单互动模块。该模块目前共有两种模式，一是综合互动，用户下单后有一定概率得到返利；二是返利互动，用户下单后必得返利。您可根据自身需求选择。<div style='margin-top:10px'>支持，您可在后台开启菜单互动模块。该模块目前共有两种模式，一是综合互动，用户下单后有一定概率得到返利；二是返利互动，用户下单后必得返利。您可根据自身需求选择。</div>"
            },
            {
              question: "4、我想在自己的公众号推文中转载领红包教程，可以提供原文链接吗？",
              answer: "<a style='word-wrap:break-word;text-decoration:underline;color:#579FFA;' href='https://mp.weixin.qq.com/s?__biz=Mzk0NTEzMzUzNA==&mid=2247483744&idx=1&sn=d46da2066ef24780af7abfc5f9bb0a53&chksm=c31b43d1f46ccac74d069544fd125cccb79038b4347f50fcc274e35746768e79caaa23a8e01d&scene=21#wechat_redirect' target='_blank'>https://mp.weixin.qq.com/s?__biz=Mzk0NTEzMzUzNA==&mid=2247483744&idx=1&sn=d46da2066ef24780af7abfc5f9bb0a53&chksm=c31b43d1f46ccac74d069544fd125cccb79038b4347f50fcc274e35746768e79caaa23a8e01d&scene=21#wechat_redirect</a>"
            },
          ]
        },
        {
          id: 15,
          title: "3、收益与结算问题",
          quesAnswerArr: [
            {
              question: "1、结算时间是怎么样的？",
              answer: "结算时间会和饿了么/美团联盟基本保持一致，联盟给我们结算后，我们会在5个工作日内给大家结算，和您自行使用饿了么联盟、美团联盟是一模一样的收入，不会有任何的差别 。"
            },
            {
              question: "2、收益提现多久能到账？",
              answer: "您发起提现后，预计3个工作日内到账，取决于银行的处理速度。"
            },
            {
              question: "3、是否会存在扣量问题，每笔订单是否可查询？",
              answer: "所有通过联盟回传的订单都会进行结算，不存在扣量问题。后台会提供每一张订单的明细，您也可以随时下单进行测试。"
            },
            {
              question: "4、是否会有推广的CPA收益？",
              answer: "满天星已与联盟沟通，联盟将会赋予平台区分渠道结算CPA的能力，CPA的收益将计入在每个结算周期内，并会附上明细数据供查阅。"
            },
            {
              question: "5、用户访问过不同公众号的同一个小程序，订单计给谁？",
              answer: "会计给到距离用户下单时间最近一次跳转的公众号主。"
            },
            {
              question: "6、如原服务号被封，是否会影响提现？",
              answer: "不影响，所有在原服务号中产生的订单会正常结算。并且您可以取消原服务号的授权，重新授权新的服务号。"
            },
            {
              question: "7、个人与企业在提现时扣税的政策是否不同？",
              answer: "个人可以不需要发票，直接通过银行卡提现，扣7%的税（6%的增值服务税和1%的综合经营税）。如果是企业用户开发票，可减少共计1%的经营所得税。我们会在后续优化更多的财务策略，争取为大家节省更多的税项。"
            },
            {
              question: "8、用户申请返利提现，我们需要往满天星账户里提前充值吗？",
              answer: "不需要，返利会在平台账期结算后自动发给粉丝。"
            },
            {
              question: "9、美团和饿了么的CPA奖励后续更新版本是否会有?",
              answer: "很快就会有。"
            },
            {
              question: "10、满天星的跟单记录多久可以看到？",
              answer: "正常不会超过10分钟。"
            }
          ]
        },
        {
          id: 16,
          title: "4、安全性与归属问题",
          quesAnswerArr: [
            {
              question: "1、满天星配置的小程序是否会被封？",
              answer: "满天星目前所有的运营策略都在微信的限制要求内，因此不存在被封的风险。即便如此，为了尽可能避免大家会出现的问题，满天星会准备大量小程序做临时替代方案，让大家对用户的服务不会出现问题。"
            },
            {
              question: "2、满天星系统是否绑定电脑？是否可以多电脑一起使用？",
              answer: "满天星是网页版的，只要保留账号密码，任何电脑都可以进行登录。"
            },
            {
              question: "3、小程序是满天星提供还是需要自行注册？",
              answer: "满天星会提供小程序，无需自行注册。满天星的小程序会嵌入在您的公众号菜单中，给您的用户提供服务。"
            },
            {
              question: "4、满天星是否支持使用自己的联盟账号？",
              answer: "不支持。满天星承诺不抽取任何佣金和扣点，只是代缴基本的联盟手续费，与使用自己的联盟账号在收益上无任何区别。"
            },
          ]
        },
        {
          id: 17,
          title: "5、其他问题",
          quesAnswerArr: [
            {
              question: "1、满天星会考虑接入淘宝，京东，拼多多的CPS吗？",
              answer: "目前版本不支持，未来包括本地优惠类、电商类的能力都在规划中。<div style='margin:10px 0'>希望以上内容可以帮助您更好的了解满天星开放平台，如您还有其他疑问，可扫描下方二维码，添加满天星人工客服进行咨询。</div>",
              imageSrc: require("../images/51.png")
            }
          ]
        },
        {
          id: 18,
          title: " ",
          parent_title: "三、增长服务订购协议",
          saleAnswerArr: [
            {
              text:"在订购前，请您务必仔细阅读并透彻理解增长服务订购内容及订购协议(以下或简称“订购协议”)，在确认充分理解后选择接受或不接受本订购协议；一旦您同意协议并完成订购的，即表明您已阅读并同意受本订购协议的约束。如您不同意本订购协议，您应不再进行下一步或停止订购。"
            }
          ]
        },
        {
          id: 19,
          title: "1、协议内容及签署",
          saleAnswerArr: [
            {
              text: "1、本订购协议内容包括协议正文及所有满天星SaaS服务平台（以下或简称“本平台”）已经发布的或将来可能发布的各类规则。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。除另行明确声明外，任何SaaS服务平台及其关联公司提供的服务均受本协议约束。但法律法规另有强制性规定的，依其规定。"
            },
            {
              text: "2、您在登录SaaS服务平台账户后按照页面提示订购本增长服务的，<span style='text-decoration: underline;'>即视为您接受本协议及各类规则，并同意受其约束。</span>您应当在使用SaaS服务平台服务之前认真阅读全部协议内容并确保完全理解协议内容，如您对协议有任何疑问的，应向SaaS服务平台咨询。但无论您事实上是否在使用SaaS服务平台服务之前认真阅读了本协议内容，只要您登录、正在或者继续使用本平台增长服务的，则视为接受本协议。",
            },
            {
              text: "3、您承诺接受并遵守本协议的约定。如果您不同意本协议的约定，您应立即停止登录程序或停止使用SaaS服务平台增长服务。"
            },
            {
              text: "<span style='text-decoration: underline;'>4、SaaS服务平台有权根据需要不时地制订、修改本协议及/或各类规则，并以网站公示的方式进行公告。变更后的协议和规则一经在网站公布后，立即自动生效。</span>SaaS服务平台的最新的协议和规则以及网站公告可供您随时登陆查阅，您也应当经常性的登陆查阅最新的协议和规则以及网站公告以了解SaaS服务平台最新动态。如您不同意相关变更，应当立即停止使用SaaS服务平台增长服务。您继续使用服务的，即表示您接受经修订的协议和规则。本协议和规则（及其各自的不时修订）条款具有可分割性，任一条款被视为违法、无效、被撤销、变更或因任何理由不可执行，并不影响其他条款的合法性、有效性和可执行性。"
            },
            {
              text: "5、SaaS服务平台增长服务（以下简称“本服务”）是由上海中彦信息科技有限公司向您提供的帮助您实现商业变现的增长服务，您可自行根据自己实际需求情况选择进行订购。"
            },
            {
              text: "<span style='text-decoration: underline;'>6、增长服务：指根据您的业务及运营需求向本平台订购一定数量的粉丝增长服务，本平台为您提供您所属或运营的或您指定的微信公众号粉丝关注量的增长服务。粉丝关注量（以下简称“加粉数”、“粉丝数量”）：是指自您订购之日起通过本平台的指定服务（包括但不限于专属推广渠道、二维码或链接等），在您指定的微信公众号点击关注的粉丝数量。</span>"
            },
          ]
        },
        {
          id: 20,
          title: "2、增长服务订购及支付流程",
          saleAnswerArr: [
            {
              text: "<span style='text-decoration: underline;'>1、您在本平台以月为周期，以充值的方式进行下单订购对应的服务，本平台在收到您的下单信息后将在一定时间内兑现符合您下单金额对应的粉丝数量。</span>"
            },
            {
              text: "2、您可以根据订购页面及您的需求选择不同的增长计划，在增长计划库存数量充足的情况下您可以点击页面中的立即预定按钮及选择一定时间的粉丝库存完成订购。",
            },
            {
              text: "<span style='text-decoration: underline;'>3、您单笔订购增长服务粉丝最低和最高数量及每次增加数量等，以订购页面为准。具体数量可能会随着业务的发展及运营的需求有所调整，具体以订购页面或账户后台显示为准。</span>"
            },
            {
              text: "<span style='text-decoration: underline;'>4、您根据页面完成订购服务的，后台进入订单的分配合并阶段。当您的订单还未被本平台处理时（如物料未被印刷等），您的账户未被锁定，在此期间您可选择退款服务，届时本平台将不再为您提供对应的服务；当您的订单进入本平台处理阶段（如物料印刷至分发完成阶段），您的账户均处于锁定状态，您理解本平台为此已经付出了大量的人力、物力、财力资源，此阶段您不能选择退款服务。当推广任务完成后，您账户剩余未完成的金额被解锁，您可以自行在后台提现。</span>"
            },
            {
              text: "<span style='text-decoration: underline;'>5、您可以在本平台您的账户中查看您的订购订单，推广订单，加粉数及相应的内容，具体以您的账户后台展示查询为准。</span>"
            },
            {
              text: "<span style='text-decoration: underline;'>6、当订购任务完成后如您的粉丝数量超量5%内的，则不额外收取费用；如粉丝数量超量>5%则生成超额账单，您需要支付超额的费用，如不支付，则下次无法继续选择订购服务；如下次需要继续订购的，则您需先支付超额账单费用。</span>"
            },
            {
              text: "<span style='text-decoration: underline;'>7、关于发票：根据您在实际使用本平台服务过程中产生的付费服务项目，您可以在每月1号起就上个自然月的业务总费用申请开票服务，本公司在对您的开票申请进行审核通过后的30个自然日内为您开具发票。如您为企业用户的，本公司可为您开具企业抬头的增值税专用发票或增值税普通发票；如您为个人用户的，本公司将为您开具个人抬头的增值税普通发票。您最晚申请开票时间是n+2月的20号，请您务必注意在以上规定时间内申请发票开具，超过以上开票时间的本公司不再为您开具。如您已在页面操作取消或放弃开票申请的，对于您取消或放弃的开票金额，本公司不再为您开具发票。</span>"
            },
            {
              text: "8、您应妥善保管账号及密码，本平台任何时候均不会主动要求您提供账户及密码。账号项下所进行的一切操作均视为您的行为，您应对该操作依法享有权利并承担相关责任。因此您同意：<br />  1)请您务必妥善保管及正确、安全使用本平台账号、密码及其他账号要素。<br />  2)除根据法律规定本平台存在法定过错外，您应对您账号项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、购买服务及披露信息等）负责。<br />  3)因黑客行为、他人诈骗行为或您的保管疏忽导致账号非法使用，您应通过司法、行政等救济途径向侵权行为人追偿，本平台将积极配合您提供相应的帮助，但本平台不因此而承担责任，如本平台存在法定过错的，仅承担您的直接损失，但直接损失不得超过本合同订购总金额。"
            },
            {
              text: "9、本平台会将订购信息以系统通知（或发送到该手机的短信或电话等）方式通知您订购服务进展情况以及提示您进行下一步的操作。因此，在交易过程中您应当及时登录到本平台网站查看和进行交易操作。因您没有及时查看和对交易状态进行修改或确认或未能提交相关申请而导致的纠纷或损失，您需自行承担。"
            },
            {
              text: "<span style='text-decoration: underline;'>10、您同意，基于运行和交易安全的需要，本平台可以暂时停止提供或者限制本服务部分功能，或提供新的功能，在功能减少、增加或者变化时，涉及到您重大利益的，本平台会提前通过站内信等方式通知您，预留合理时间并听取商家意见反馈。在您有效的服务期限内，本平台不会变更您本次付费订购服务的费用。</span>"
            },
          ]
        },
        {
          id: 21,
          title: "3、知识产权",
          saleAnswerArr: [
            {
              text: "1、本平台上展示的资料（包括但不限于文字、图表、标识、图像、数字下载和数据编辑）均为本平台或其内容提供者的财产或者权利；本平台上所有内容的汇编是属于本平台的著作权；本平台上所有软件都是本平台或其关联公司或其软件供应商的财产或者权利，上述知识产权均受法律保护。您开通使用本平台服务功能的，并不代表所有知识产权的转移，亦不代表本平台授权您可以在外部渠道、平台、公众号、网站等使用本平台及本平台关联平台/关联公司的知识产权。如经本公司发现您未经授权擅自使用或间接侵犯了本平台关联主体、关联平台、关联公司等知识产权的，本平台有权根据平台自身的判断终止对您的服务、冻结账户、扣除账户余额（如有）、直至注销用户账号，同时您还必须承担由此给本平台带来的所有维权损失。"
            },
            {
              text: "2、本平台或其关联方拥有、使用、许可、控制的或者本平台或其关联方对之享有其他权利的所有知识产权、工业产权和专有权利，如您需要使用，可按照官网公示的途径向本平台申请，非经本平台或其关联公司书面同意，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表在本平台网站上的程序或内容。<span style='text-decoration: underline;'>您不得恶意申请和注册与本平台及本平台关联方相同或近似的商标、名称、标识、标志、微信公众号、域名、网页等，用于开展与本平台及本平台关联方实质相同或近似的业务。</span>",
            },
            {
              text: "3、若您违反本条之约定而给本平台及/或本平台关联方造成损失的，您应赔偿全部损失并消除给本平台及/或本平台关联方造成的不良影响。"
            },
          ]
        },
        {
          id: 22,
          title: "4、免责声明",
          saleAnswerArr: [
            {
              text: "(一)您理解并同意，本平台不对因下述情况导致的损害赔偿承担责任，包括但不限于利润、商誉、使用、数据等方面的损失或其他无形损失的损害赔偿："
            },
            {
              text: "1.本平台有权基于综合判断，包含但不限于本平台认为您已经违反本协议的规定及法律法规的要求，暂停、中断或终止向您提供本服务或其任何部分，并移除您的资料。",
            },
            {
              text: "(二)【系统中断或故障的免责】系统因下列状况无法正常运作，导致您无法使用各项服务的，本平台不承担损害赔偿责任，该状况包括但不限于："
            },
            {
              text: "1.本平台在本平台官网公告之系统停机维护期间；"
            },
            {
              text: "2.电信设备出现故障不能进行数据传输的；"
            },
            {
              text: "3.因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成本平台系统障碍不能执行业务的；"
            },
            {
              text: "4.由于电信部门技术调整或故障、网站升级、银行方面的问题等原因而造成的服务中断或者延迟。"
            },
            {
              text: "(三)【特殊情况的免责】对下列情形，本平台不承担任何责任："
            },
            {
              text: "1.并非由于本平台存在法定过错而导致本服务未能提供的；"
            },
            {
              text: "2.由于您的原因导致任何第三方遭受损失的；"
            },
            {
              text: "3.因您未及时续费/订购，导致本服务不能提供或提供时发生任何错误，您须自行承担因此产生的一切后果，本平台不承担任何责任。"
            },
            {
              text: "（四）【版本更新的免责】为了改善消费者体验、完善服务内容，本平台将不断努力开发新的服务，并为您不时提供内容或系统的更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）。为了保证本服务的安全性和功能的一致性，本平台可能不经向您特别通知而对内容或系统进行更新，或者对软件的部分功能效果进行改变或限制。新版本发布后，旧版本的服务可能无法使用。请您随时核对并下载最新版本。"
            },
          ]
        },
        {
          id: 23,
          title: "5、协议中止、解除及终止",
          saleAnswerArr: [
            {
              text: "(一)【协议解除/终止】出现下列情形之一的，本平台有权单方解除/终止本协议，并有权要求你承担有可能发生的违约责任："
            },
            {
              text: "1.你从事其业务的经营事项的合法资格消失；",
            },
            {
              text: "2.本平台接到相关部门的通知，你被相关部门要求停业整顿或其他行政处罚的；"
            },
            {
              text: "3.你违反本平台公布的相关协议或规则，被清退的；"
            },
            {
              text: "4.你出现违规或违法行为的，严重损害本平台或者消费者利益的；"
            },
            {
              text: "5.其它本平台有正当理由认为需中止/终止服务的情况。"
            },
            {
              text: "（二）【协议期限】本协议期限为自订购之日起至协议解除/终止/服务完成之日止；若涉及保密、违约责任、售后服务义务及产品质量保证责任等内容在协议解除/终止后将继续有效。"
            },
          ]
        },
        {
          id: 24,
          title: "6、法律适用、管辖与其他",
          saleAnswerArr: [
            {
              text: "(一)本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，没有相关法律规定的，参照通用国际商业惯例和（或）行业惯例。"
            },
            {
              text: "<span style='text-decoration: underline;'>(二)你因使用本平台服务所产生及与本平台服务有关的争议，由本平台与你协商解决。协商不成时，任何一方均可向本平台公司上海中彦信息科技有限公司企业所在地人民法院诉讼解决。</span>",
            },
            {
              text: "(三)【条款独立性】如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余条款则仍具有法律效力。"
            },
            {
              text: "(四)【权利放弃】本协议任何一方于另一方过失或违约时放弃本协议规定的权利的，不得视为其对一方的其他或以后同类之过失或违约行为弃权。本平台未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。"
            },
          ]
        },
      ]
    }
  }
}

export {
  menuMixins,
  contentMixins
}

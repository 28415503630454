import axios from '@/utils/axios'

const API = {
  init: '/pay/ajaxInit',
  saleInit: '/growth/ajaxGetGrowthPayRecord',
}

function initPagePost (param) {
  return axios.get(API.init, { params: param })
}

function initSalePagePost (param) {
  return axios.get(API.saleInit, { params: param })
}

export {
  initPagePost,
  initSalePagePost
}

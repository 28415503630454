<template>
  <div class="login">
    <div class="logo-box"></div>
    <form class="login-form">
      <div class="form-title"><span>登录</span></div>
      <div class="tips"><span>请填写购买时登记的手机号</span></div>
      <div class="input-item">
        <input type="number" maxlength="14" v-model="loginForm.mobile" placeholder="输入手机号">
        <div v-if="showMobileError" class="error-text">{{ mobileErrorText }}</div>
        <div v-if="showMobileError" class="error-icon"></div>
      </div>
      <div class="input-item input-code">
        <input type="number" v-model="loginForm.smsVerifyCode" maxlength="8" placeholder="输入验证码">
        <div class="code-btn"><span @click="sendCodeHandler" :class="[!mobileStatus ? 'opacity' : '', isSendCode ? 'send-code-color' : '']">{{ codeText }}</span></div>
        <div v-if="showCodeError" class="error-text error-code"> {{ codeErrorText }} </div>
        <div v-if="showCodeError" class="error-icon"></div>
      </div>
      <div class="radio-box">
        <a-checkbox @change="setRule" />我已阅读并同意<a href="javascript:void(0);" @click="pathTo">《满天星用户使用协议》</a>
      </div>
      <a href="javascript:void(0);" :class="['login-btn', !fillStatus ? 'opacity' : '']" @click="loginHandler">登录</a>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      loginForm: {
        mobile: '',
        smsVerifyCode: ''
      },
      mobileErrorText: '', // 手机号错误文案
      codeErrorText: '', // 验证码错误文案
      codeText: '发送验证码',
      mobileStatus: false, // mobile是否符合规则
      fillStatus: false, // 表单填写的状态
      showMobileError: false, // 显示手机号校验错误的icon
      showCodeError: false, // 显示验证码校验错误的icon
      isSendCode: false, // 短信是否发送
      isAgree: false
    }
  },
  methods: {
    ...mapActions(['login', 'sendCode', 'initGlobalPage', 'checkAuthStatus']),
    sendCodeHandler () {
      const mobile = this.loginForm.mobile
      if (mobile.length !== 11 || this.isSendCode) return
      this.isSendCode = true
      this.sendCode(mobile).then(res => {
        this.timeCount()
        this.$message.success('验证码发送成功')
      }).catch(error => {
        this.isSendCode = false
        this.mobileErrorText = error
        this.showMobileError = true
      })
    },
    /**
     * 登录点击函数
     */
    loginHandler () {
      const mobile = this.loginForm.mobile
      const code = this.loginForm.smsVerifyCode
      if (mobile.length !== 11 || !code) return
      if (!this.isAgree) return this.$message.error("请阅读并同意满天星用户使用协议")
      this.login(this.loginForm).then(res => {
        this.$message.success("登录成功")
        this.checkAuthStatus(res.auth).then(() => {
          this.$router.replace('/home')
          setTimeout(() => {
            this.loginForm = {
              mobile: '',
              smsVerifyCode: ''
            }
          }, 5000)
        })
      }).catch(error => {
        this.showCodeError = true
        this.codeErrorText = error
      })
    },
    /**
     * 60s倒计时
     */
    timeCount () {
      let count = 60
      const timer = setInterval(() => {
        if (count < 1) {
          clearInterval(timer)
          this.codeText = '发送验证码'
          this.isSendCode = false
          return
        }
        count--
        this.codeText = `${count}s后重新发送`
      }, 1000)
    },
    setRule (e) {
      this.isAgree = e.target.checked
    },
    pathTo () {
      this.$router.push('/rule')
    }
  },
  created () {
    this.initGlobalPage('/home')
  },
  watch: {
    loginForm: {
      handler (newVal, oldVal) {
        if (newVal.mobile.length === 11) {
          this.mobileStatus = true
          this.showMobileError = false
          if (newVal.smsVerifyCode) {
            this.fillStatus = true
          } else {
            this.fillStatus = false
            this.showCodeError = false
          }
        } else {
          this.mobileErrorText = '请输入正确的手机号'
          this.showMobileError = true
          this.mobileStatus = false
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  position: relative;
  width: 100%;
  height: 100%;
  background: #0E86FB url("./images/login.png") left bottom no-repeat;
  background-size: 1440px 780px;
  .logo-box {
    position: absolute;
    top: 36px;
    left: 36px;
    width: 110px;
    height: 32px;
    background: url("../../images/logo-white.png") no-repeat;
    background-size: contain;
  }
  .login-form {
    position: absolute;
    top: 120px;
    right: 180px;
    width: 420px;
    height: 540px;
    background: #fff;
    border-radius: 12px;
    color: #333847;
    padding: 36px;
    box-sizing: border-box;
    box-shadow: 0px 8px 20px 0px #215DCF;
    font-size: 20px;
    .form-title {
      height: 39px;
      line-height: 39px;
      font-size: 28px;
      margin-bottom: 36px;
      font-weight: 500;
    }
    .tips {
      height: 28px;
      line-height: 28px;
      margin-bottom: 35px;
    }
  }
}
.input-item {
  position: relative;
  margin-bottom: 34px;
  input {
    width: 100%;
    height: 54px;
    line-height: 54px;
    border: none;
    border-bottom: 1px solid #ADB1C3;
    border-radius: 0;
    outline: none;
  }
  /deep/ input::-webkit-outer-spin-button,
  /deep/ input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  /deep/ input[type="number"]{
    -moz-appearance: textfield;
  }
  &.input-code {
    display: flex;
    margin-bottom: 80px;
    input {
      width: 200px;
    }
    .code-btn {
      flex: 1;
      height: 54px;
      line-height: 54px;
      text-align: right;
      color: #4392F6;
      border-bottom: 1px solid #ADB1C3;
      span:hover {
        cursor: pointer;
      }
    }
    .error-icon {
      right: 148px;
    }
  }
  .error-text {
    height: 28px;
    line-height: 28px;
    color: #FF4141;
    margin-top: 11px;
    &.error-code {
      position: absolute;
      margin-top: 0;
      top: 65px;
    }
  }
  .error-icon {
    position: absolute;
    top: 17px;
    right: 0;
    width: 24px;
    height: 19px;
    background: url("./images/error-icon.png") no-repeat;
    background-size: 100% 100%;
  }
}

.radio-box {
  position: absolute;
  bottom: 120px;
  left: 36px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  color: #333847;
}

.login-btn {
  position: absolute;
  bottom: 36px;
  width: 348px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #FFF;
  border-radius: 8px;
  background: #4392F6;
}

.opacity {
  opacity: 0.5;
}
.send-code-color {
  color: #ADB1C3;
}
.ant-checkbox-wrapper {
  margin-right: 4px;
}
</style>

<template>
  <div v-if="showStatusDialog > 0">
    <div class="dialog-mask"></div>
    <div class="dialog-box">
      <img src="../images/wraning.png" alt="">
      <p class="title">{{ showStatusDialog == 1?'授权失败':'关联失败' }}</p>
      <p class="subtitle" v-html="showStatusDialog == 1?'您的公众号授权了超过5个第三方平台，<br>请前往微信公众后台解绑，再尝试授权':'您的公众号可能关联了超3个非同主体小程序<br>或公众号当月关联非同主体小程序超3次，<br>请前往微信公众后台处理，再尝试关联'"></p>
      <div class="btn" @click="closeStatusDialog">确定</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showStatusDialog: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {}
  },
  methods: {
    closeStatusDialog () {
      this.$emit('closeStatusDialog', 0)
    }
  }
}
</script>

<style lang="less" scoped>
.dialog-mask{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:rgba(0,0,0,.7)
}
.dialog-box{
  position:fixed;
  top:50%;
  left:50%;
  width:432px;
  background:#fff;
  border-radius:12px;
  padding: 50px 0 36px;
  transform: translate(-50%,-50%);
  img{
    display:block;
    width:114px;
    height:99px;
    margin:0 auto;
  }
  .title{
    margin-top:23px;
    height:28px;
    text-align: center;
    line-height:28px;
    font-size:20px;
    color:#333;
    font-weight:medium;
  }
  .subtitle{
    margin-top:12px;
    text-align: center;
    line-height:20px;
    font-size:14px;
    color:#666;
    font-weight:medium;
  }
  .btn{
    margin:36px auto 0;
    width:360px;
    height:60px;
    text-align: center;
    line-height:60px;
    border-radius:6px;
    font-weight: medium;
    font-size: 20px;
    color:#FFF;
    background:#4392F6;
  }
}
</style>
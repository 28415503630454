import axios from '@/utils/axios'

const API = {
  init: '/dashboard/ajaxGetDashboardData'
}

function initPagePost () {
  return axios.post(API.init)
}

export {
  initPagePost
}

import storage from 'store'
import { ACCESS_TOKEN } from '@/store/globalConst'
import { sendCodePost, loginPost, logoutPost } from '@/api/user'

const user = {
  state: {
    token: ''
  },
  getters: {
    token: ({ token }) => token
  },
  mutations: {
    SET_TOKEN (state, token) {
      state.token = token
    }
  },
  actions: {
    sendCode ({ commit }, mobile) {
      return new Promise(async (resolve, reject) => {
        const res = await sendCodePost({ mobile })
        if (res.status === 1) {
          resolve(res.info)
        } else {
          reject(res.info)
        }
      })
    },
    login ({ commit }, userInfo) {
      return new Promise(async (resolve, reject) => {
        const res = await loginPost(userInfo)
        if (res.status === 1) {
          const { data } = res
          storage.set(ACCESS_TOKEN, data.token)
          commit('SET_TOKEN', data.token)
          resolve(data)
        } else {
          reject(res.info)
        }
      })
    },
    logOut ({ commit }) {
      return new Promise(async resolve => {
        const res = await logoutPost()
        if (res.status == 1) {
          commit('SET_TOKEN', '')
          storage.remove(ACCESS_TOKEN)
          resolve(res.info)
        } else {
          reject(res.info)
        }
      })
    }
  }
}

export default user

<template>
  <div class="txdialog">
    <div class="dia-left">

      <div class="amount-box">
        <div class="title">提现金额</div>
        <div class="amount-input">
          ￥<input type="number" v-model="amount" placeholder="请输入提现金额"/>
        </div>
        <div class="text">当前可提现金额{{ canTx }}元，<a href="javascript:void(0);" @click="txAllHandler">全部提现</a></div>
      </div>

      <div class="type-box">
        <div class="title">收款类型
          <!-- <span v-if="isFirstTx">请务必慎重选择收款类型，确认后不可更改</span> -->
        </div>
        <a-radio-group v-model="radioValue" @change="onChange">
          <a-radio :value="1">
            个人<span>扣除相关税费7%（详见<a style="color:#337fe5" href="https://saas.mtx.net.cn/#/rule" target="_blank">《满天星用户使用协议》</a>）</span>
          </a-radio>
          <a-radio :value="2">
            <i>对公-</i>普通发票<span>扣除相关税费6%</span>
          </a-radio>
          <a-radio :value="3">
            <i>对公-</i>1%类型专票<span>扣除相关税费5%</span>
          </a-radio>
          <a-radio :value="4">
            <i>对公-</i>3%类型专票<span>扣除相关税费3%</span>
          </a-radio>
          <a-radio :value="5">
            <i>对公-</i>6%类型专票<span>无需相关税费</span>
          </a-radio>
        </a-radio-group>
        <!-- <div v-else class="select-ok" v-html="selectedText"></div> -->
      </div>

      <div class="actual-amount-box">
        <div class="title">实际提现金额<span class="tips">开票金额（价税合计）以此处为准</span></div>
        <div class="money-box" :class="{color: !taxMoney}">{{ taxMoney ? "￥" + taxMoney : "待计算"}}</div>
      </div>

    </div>

    <div class="dia-right">
      <a href="javascript:void(0);" class="down" :class="{o: !Number(amount) || !radioValue || amount > canTx}" @click="downStepHander">下一步</a>
      <a href="javascript:void(0);" class="close" @click="closeDialogStepOne">关闭</a>
    </div>
  </div>
</template>

<script>
import { withDrawalPost } from '@/api/income'
export default {
  props: {
    radioType: {},
    txAmount: {
      default: ""
    },
    canTx: {},
    withDrawId: {
      default: ""
    }
  },
  data () {
    return {
      radioValue: this.radioType,
      amount: this.txAmount,
      isSendAjax:false
    }
  },
  computed: {
    taxMoney () {
      switch (Number(this.radioValue)) {
        case 1:
          return !this.amount ? "" : (this.amount * 0.93).toFixed(2)
        case 2: 
          return !this.amount ? "" : (this.amount * 0.94).toFixed(2)
        case 3: 
          return !this.amount ? "" : (this.amount * 0.95).toFixed(2)
        case 4: 
          return !this.amount ? "" : (this.amount * 0.97).toFixed(2)
        case 5: 
          return this.amount * 1
        default:
          return null
      }
    },
    // selectedText () {
    //   switch (parseInt(this.radioType)) {
    //     case 1:
    //       return "个人<span style='color: #999; font-size: 14px; margin-left: 12px;'>扣除相关税费7%</span>"
      
    //     case 2:
    //       return "普通发票<span style='color: #999; font-size: 14px; margin-left: 12px;'>扣除相关税费6%</span>"
      
    //     case 3:
    //       return "1%类型专票<span style='color: #999; font-size: 14px; margin-left: 12px;'>扣除相关税费5%</span>"
      
    //     case 4:
    //       return "3%类型专票<span style='color: #999; font-size: 14px; margin-left: 12px;'>扣除相关税费3%</span>"
      
    //     case 5:
    //       return "6%类型专票<span style='color: #999; font-size: 14px; margin-left: 12px;'>无需相关税费</span>"
      
    //     default:
    //       return ""
    //   }
    // }
  },
  methods: {
    txAllHandler () {
      this.amount = this.canTx
    },
    onChange(e) {
      this.$emit("changRadioType", e.target.value)
    },
    downStepHander () {
      if (Number(this.amount) < 1 || !this.radioValue || this.amount > this.canTx) {
        if (Number(this.amount) && Number(this.amount) < 1) {
          this.$message.error("最低可提现金额为1元")
        }
        return
      }
      if (this.radioValue == 1) {
        this.closeDialogStepOne("next")
      } else {
        if(!this.isSendAjax){
          this.isSendAjax = true
          this.sendInvoiceType()
        }
      }
    },
    async sendInvoiceType () {
      const ajaxData = {
        payee_type: this.radioValue,
        withdraw_id: this.withDrawId || "",
        withdraw: {
          amount: this.amount,
          after_tax_amount: this.taxMoney
        },
        step:1
      }
      const { status, data, info } = await withDrawalPost(ajaxData)
      if (status === 1) {
        this.$emit("changRadioType", this.radioValue)
        this.closeDialogStepOne("next", data.withdraw_id)
      } else {
        this.$message.error(info)
      }
      this.isSendAjax = false
    },
    closeDialogStepOne (next, withDrawId) {
      if (next === "next") {
        let emitData = {
          next: true,
          withDraw: {
            amount: this.amount,
            afterTaxAmount: this.taxMoney
          },
          open: false
        }
        if (withDrawId) {
          emitData = Object.assign(emitData, { withDrawId })
        }
        this.$emit("closeDialogStepOne", emitData)
      } else {
        this.$emit("closeDialogStepOne", { radioType: null, open: false })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.txdialog {
  display: flex;
  color: #333;
  font-size: 16px;
  .dia-left {
    width: 418px;
    .title {
      display: flex;
      align-items: center;
      height: 28px;
      margin-bottom: 12px;
      color: #333;
      font-size: 20px;
      font-weight: 500;
      // span {
      //   margin-left: 12px;
      //   font-size: 14px;
      //   color: #FF4141;
      //   font-weight: normal;
      // }
    }
    .amount-box, .type-box {
      margin-bottom: 36px;
    }
    .amount-box {
      .amount-input {
        display: flex;
        height: 46px;
        padding-bottom: 6px;
        margin-bottom: 12px;
        box-sizing: border-box;
        border-bottom: 1px solid #EBEBEB;
        font-size: 28px;
        input {
          width: 100%;
          height: 39px;
          line-height: 39px;
          border: none;
        }
        input:focus {
          outline: none;
        }
      }
      .text {
        height: 24px;
        line-height: 24px;
        font-size: 16px;
      }
    }
    .type-box {
      .ant-radio-wrapper {
        display: block;
        height: 24px;
        line-height: 24px;
        margin-bottom: 13px;
        /deep/ .ant-radio {
          width: 14px;
          height: 14px;
          .ant-radio-inner {
            width: 14px;
            height: 14px;
            top: -1px;
            &::after {
              top: 2px;
              left: 2px;
            }
          }
        }
        span {
          color: #999;
          font-size: 14px;
          margin-left: 12px;
        }
        i{
          color:#999;
          font-style: normal;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      .select-ok {
        height: 24px;
        line-height: 24px;
        color: #333;
      }
    }
    .money-box {
      height: 45px;
      line-height: 39px;
      padding-bottom: 6px;
      box-sizing: border-box;
      border-bottom: 1px solid #EBEBEB;
      font-size: 28px;
      &.color {
        color: #ccc;
      }
    }
    .actual-amount-box .tips{
      font-size:14px;
      color:#999;
      margin:2px 0 0 12px;
    }
  }
  .dia-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    a {
      display: block;
      width: 200px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      border-radius: 6px;
      font-size: 20px;
      &.down {
        background: #4392F6;
        color: #fff;
        margin-bottom: 12px;
        &.o {
          opacity: 0.6;
        }
      }
      &.close {
        border: 1px solid #4392F6;
        color: #4392F6;
      }
    }
  }
}
@keyframes animateBlur {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
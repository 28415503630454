import axios from '@/utils/axios'

const API = {
  sendCode: '/user/ajaxSendSmsVerifyCode',
  login: '/user/ajaxLogin',
  logout: '/user/ajaxLoginOut'
}

function sendCodePost (param) {
  return axios.post(API.sendCode, param)
}

function loginPost (param) {
  return axios.post(API.login, param)
}

function logoutPost () {
  return axios.post(API.logout)
}

export {
  sendCodePost,
  loginPost,
  logoutPost
}

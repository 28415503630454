<template>
  <div class="help">
    <PageHeader :show-app-info="2" :auth-info="authInfo"></PageHeader>
    <div class="space-fixed"></div>
    <div class="content">
      <Menu @scrollToId="scrollToId" :defaultMenu="defaultMenu"></Menu>
      <div class="main" style="padding:0" @scroll="scrollContent">
        <div class="mtx-help" v-if="1" id="help">
          <template v-for="item in contentList">
            <section :id="'anchor-' + item.id" :key="item.id">
              <h1 v-if="item.first_anchor" class="web-title">帮助中心</h1>
              <div v-if="item.parent_title" class="parent-title">{{ item.parent_title }}</div>
              <div v-if="item.title" class="title">{{ item.title }}</div>
              <div v-if="item.contentArr">
                <div v-for="(itm, idx) of item.contentArr" :key="idx">
                  <div v-if="itm.text" class="text" v-html="itm.text"></div>
                  <img v-if="itm.imageSrc && !itm.imageSize" :src="itm.imageSrc" />
                  <img v-if="itm.imageSrc && itm.imageSize" class="small-image" :src="itm.imageSrc" />
                </div>
              </div>
              <div v-if="item.quesAnswerArr">
                <div v-for="(itm, idx) of item.quesAnswerArr" :key="idx" class="ques-sec">
                  <div class="question" v-html="itm.question"></div>
                  <div class="answer" v-html="itm.answer"></div>
                  <img v-if="itm.imageSrc" :src="itm.imageSrc" class="ques-image" />
                </div>
              </div>
              <div v-if="item.saleAnswerArr">
                <div v-for="(itm, idx) of item.saleAnswerArr" :key="idx" class="ques-sec">
                  <div v-if="itm.text" class="text" v-html="itm.text"></div>
                </div>
              </div>
            </section>
          </template>
        </div>
        <div class="wx-help" v-else></div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/header/PageHeader"
import Menu from "./components/menu"
import { mapGetters, mapActions } from 'vuex'
import { contentMixins } from './components/mixins'
export default {
  mixins: [contentMixins],
  components: {
    PageHeader,
    Menu
  },
  data () {
    return {
      defaultMenu: {
        defaultSelected: 1,
        defaultOpen: 1
      },
    }
  },
  computed: {
    ...mapGetters(['authInfo'])
  },
  methods: {
    ...mapActions(['initGlobalPage']),
    scrollToId (selector) {
      this.$nextTick(() => {
        const anchor = this.$el.querySelector(`#${selector}`)
        anchor.scrollIntoView()
      })
    },
    scrollContent () { // 滑动内容区域时左侧菜单栏同步滚动
      const scrollHeight = document.querySelector(".main").scrollTop
      let heightArr = [0]
      for (let i in this.contentList) {
        let anchorItemId  = "#anchor-" + (Number(i) + 1)
        let anchorHeight = this.$el.querySelector(anchorItemId).offsetHeight + heightArr[i]
        heightArr.push(anchorHeight)
      }
      
      for (let i in heightArr) {
        if (scrollHeight >= heightArr[i]) {
          this.defaultMenu.defaultSelected = Number(i) + 1
        }
      }
    }
  },
  created () {
    this.initGlobalPage()
  }
}
</script>

<style lang="less" scoped>
.help {
  -moz-user-select:none; /* Firefox私有属性 */
  -webkit-user-select:none; /* WebKit内核私有属性 */
  -ms-user-select:none; /* IE私有属性(IE10及以后) */
  -khtml-user-select:none; /* KHTML内核私有属性 */
  -o-user-select:none; /* Opera私有属性 */
  user-select:none; /* CSS3属性 */
  height: 100%;
  scroll-behavior: smooth;
  .home-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .space-fixed {
    width: 100%;
    height: 60px;
  }
  .content {
    display: flex;
    height: calc(100vh - 60px);
    .main {
      flex: 1;
      background: #F3F6FC;
      overflow-y: auto;
      padding: 60px;
      box-sizing: border-box;
      scroll-behavior: smooth;
      .mtx-help, .wx-help {
        width: 80%;
        margin: 0 auto;
        padding: 0 36px 36px;
        line-height: 25px;
        color: #666;
        box-sizing: border-box;
        background: #fff;
        font-size: 18px;
        // overflow-y: auto;
        // scroll-behavior: smooth;
        .web-title {
          text-align: center;
          padding-top: 40px;
          font-weight: bold;
        }
        .parent-title {
          padding-top: 30px;
          font-size: 26px;
          color: #333;
          font-weight: bold;
        }
        img {
          width: 100%;
          margin-bottom: 12px;
        }
        .small-image {
          display: block;
          width: 30%;
          height: auto;
          margin: 0 auto 12px;
        }
        .ques-image {
          display: block;
          width: 20%;
          height: auto;
          margin: 20px auto 100px;
        }
        .title {
          height: 52px;
          line-height: 28px;
          margin-bottom: 12px;
          padding-top: 24px;
          font-size: 24px;
          color: #333333;
          font-weight: bold;
        }
        .text {
          text-indent: 36px;
          margin-bottom: 16px;
          line-height: 30px;
        }
        a {
          color: #579FFA;
        }
        .ques-sec {
          margin-bottom: 20px;
        }
        .question {
          color: #579FFA;
        }
      }
    }
  }
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height:100px;
  background: #CCC;
}
::-webkit-scrollbar-track {
  background: #F3F6FC;
}
</style>
<template>
  <div class="growth">
    <ContentHeader :is-show-switch="false" header-type="10" />

    <div style="display: flex; margin-bottom: 36px;">
      <PlanModule v-for="(plan, idx) in planList" :key="plan.id" :fans-type="idx + 1" :plan="plan" :show-plan-dialog="showPlanDialog" />
      <!-- <PlanModule plan-name="增长计划B" :show-plan-dialog="showPlanDialog" /> -->
    </div>

    <div class="steps">
      您的推广订单会按该流程进行：
      <img src="./images/step1.png" alt="">待支付
      <img src="./images/step-arrow.png" alt="">
      <img src="./images/step2.png" alt="">进入排期
      <img src="./images/step-arrow.png" alt="">
      <img src="./images/step3.png" alt="">准备推广
      <img src="./images/step-arrow.png" alt="">
      <img src="./images/step4.png" alt="">推广中
      <img src="./images/step-arrow.png" alt="">
      <img src="./images/step5.png" alt="">推广完成
    </div>

    <div class="data-source">
      <div class="table-tab">我的推广订单</div>
      <a-table :columns="columns" :data-source="buyDataList" :pagination="pagination" @change="getPayRecordList" bordered rowKey="id">
        <template slot="fans_type" slot-scope="item">
          <span>{{ item.fans_type == 1 ? "增长计划A" : "增长计划B" }}</span>
        </template>
        <template slot="pay_status" slot-scope="item">
          <div v-if="item.status > 0">
            <span>{{ item.status == 1 ? "准备推广" : item.status == 2 ? "推广中" : "推广完成" }}</span>
          </div>
          <div v-else-if="item.pay_status == 1 || item.pay_status == 2" >
            待支付 还剩<CountDown :remain-time="item.ttl" /><br />
            <a  style="color: #4392F6;" href="javascript:void(0);" @click="goPay(item)">去支付</a>
          </div>
          <div v-else>
            {{ item.pay_status == 6 ? '退款中': item.pay_status == 7 ? '退款成功': item.pay_status == 8 ? '退款失败': item.pay_status == 3 ? "进入排期" : item.pay_status == 4 ? "支付失败" : "交易失败" }}<br />
            <!-- <a  style="color: #4392F6;" href="javascript:void(0);" @click="goPay(item)">取消订单</a> -->
          </div>
        </template>
        <template slot="channel_title" slot-scope="item">
          <a-popover placement="top" overlayClassName="popover-box">
            <div class="table-title">{{ item.channel_title }}</div>
            <template slot="content">{{ item.channel_title }}</template>
          </a-popover>
        </template>
      </a-table>
    </div>

    <div class="dialog-main" v-if="isShowPayStatusDialog">
      <div class="icon"></div>
      <div class="text">是否已完成支付</div>
      <div class="btn-box">
        <a href="javascript:void(0);" class="yes" @click="closePayStatusDialog">是的</a>
        <a href="javascript:void(0);" class="no" @click="closePayStatusDialog">没有</a>
      </div>
    </div>

    <div class="txdialog agreement" v-if="isShowAgreeBuyDialog">
      <div>请阅读并同意《用户购买协议》后再进行支付</div>
      <a href="javascript:void(0);" @click="isShowAgreeBuyDialog = false">好的</a>
    </div>

    <div class="txdialog empty" v-if="isShowEmptyDiaolg">
      <div class="warn"></div>
      <div class="text">您选择的额度池已被预订完<br />请选择其他日期或类型</div>
      <div class="btn-box">
        <a-button type="primary" class="new" @click="reselectHandler(1)">重新选择</a-button>
        <a-button class="cancel" @click="reselectHandler(2)">取消</a-button>
      </div>
    </div>

    <PlanDialog
      v-if="isShowPlanDialog"
      @showAgreeBuy="showAgreeBuy"
      @showPayStatusDialog="showPayStatusDialog"
      @showEmptyDialog="showEmptyDialog"
      :protocol-version="protocolVersion"
      :close-plan-dialog="closePlanDialog"
      :show-agreement-dialog="showAgreementDialog"
      :fans-type="fansType"
    />
    <BuyAgreement v-if="isShowAgreementDialog" :close-agreement-dialog="closeAgreementDialog" />
    <div class="mask" :style="{ 'z-index': isShowAgreeBuyDialog || isShowEmptyDiaolg ? 4 : 2 }" v-if="isShowPayStatusDialog || isShowPlanDialog || isShowAgreementDialog || isShowAgreeBuyDialog || isShowEmptyDiaolg"></div>
  </div>
</template>

<script>
import ContentHeader from "@/components/header/header";
import PlanModule from "./components/PlanModule";
import PlanDialog from "./components/PlanDialog";
import BuyAgreement from "./components/BuyAgreement";
import CountDown from "@/components/countDown/CountDown"
import { initPagePost, getPayRecordsListPost, queryPayInfoPost } from "@/api/growth";
import { mapGetters } from "vuex";

const columns = [
  {
    title: "下单时间",
    dataIndex: "order_date",
    align: "center",
    width: "12.5%",
  },
  {
    title: "增长类型",
    // dataIndex: "fans_type",
    scopedSlots: { customRender: "fans_type" },
    align: "center",
    width: "12.5%",
  },
  {
    title: "预订月份",
    dataIndex: "promote_date",
    align: "center",
    width: "12.5%",
  },
  {
    title: "粉丝单价（元）",
    dataIndex: "fans_amount_format",
    align: "center",
    width: "12.5%",
  },
  {
    title: "预订粉丝数（个）",
    dataIndex: "fans_num",
    align: "center",
    width: "12.5%",
  },
  {
    title: "订单金额（元）",
    dataIndex: "fans_cost_format",
    align: "center",
    width: "12.5%",
  },
  {
    title: "订单状态",
    // dataIndex: 'order_status',
    scopedSlots: { customRender: "pay_status" },
    align: "center",
    width: "12.5%",
  },
  {
    title: "渠道名称",
    // dataIndex: "channel_title",
     scopedSlots: { customRender: "channel_title" },
    align: "center",
    width: "12.5%",
  },
];

export default {
  components: {
    ContentHeader,
    CountDown,
    PlanModule,
    BuyAgreement,
    PlanDialog
  },
  data() {
    return {
      columns,
      buyDataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 10,
      },
      outOrderNo: "",
      isShowPayStatusDialog: false,
      isShowPlanDialog: false,
      planList: [],
      isShowAgreeBuyDialog: false, // 未同意协议支付弹窗
      isShowAgreementDialog: false,
      isShowEmptyDiaolg: false, // 额度为空状态
      fansType: null,
      resetDialog: () => {},
      protocolVersion: {}
    };
  },
  mounted() {
    this.initPage();
    this.getPayRecordList();
  },
  methods: {
    async initPage() {
      const { status, data } = await initPagePost();
      const { config } = data;

      if (status === 1) {
        this.planList = config.products
      }
    },
    async getPayRecordList(current) {
      let ajaxData;
      const { pagination } = this;

      if (current) {
        ajaxData = { page: current.current }
        this.pagination.currentPage = current.current;
      } else {
        ajaxData = { page: pagination.currentPage }
      }
      ajaxData = Object.assign(ajaxData, { pageSize: pagination.pageSize });
      const { status, data } = await getPayRecordsListPost(ajaxData);
      if (status === 1) {
        this.pagination.total = Number(data.totalCount);
        this.buyDataList = data.records;
      }
    },
    showPayStatusDialog({ open, outOrderNo }) {
      this.isShowPayStatusDialog = open;
      this.outOrderNo = outOrderNo;
    },
    closePayStatusDialog() {
      this.isShowPayStatusDialog = false;
      this.queryPayInfo ();
    },
    showEmptyDialog(resetDialog) {
      this.isShowEmptyDiaolg = true
      this.resetDialog = resetDialog
    },
    // 查询订单状态
    async queryPayInfo() {
      const { status, data } = await queryPayInfoPost({ outOrderNo: this.outOrderNo });
      this.initPage();
      this.getPayRecordList();
    },
    goPay({ pay_link, out_order_no }) {
      window.open(pay_link);
      this.showPayStatusDialog({ open: true, outOrderNo: out_order_no });
    },
    showPlanDialog (plan) {
      if (plan.remain_fans_num < 1000 || (plan.sub_products[0].status == '2' && plan.sub_products[1].status == '2')) return
      this.fansType = plan.fans_type
      this.isShowPlanDialog = true
    },
    closePlanDialog () {
      this.isShowPlanDialog = false
    },
    showAgreementDialog () {
      this.isShowAgreementDialog = true
    },
    closeAgreementDialog () {
      this.isShowAgreementDialog = false
    },
    showAgreeBuy () {
      this.isShowAgreeBuyDialog = true
    },
    reselectHandler (type) {
      if (type === 1) {
        this.isShowEmptyDiaolg = false
        this.resetDialog()
      }
      if (type === 2) {
        this.isShowEmptyDiaolg = false
        this.isShowPlanDialog = false
      }
    }
  },
};
</script>

<style lang="less" scoped>
.steps {
  display: flex;
  align-items: center;
  width: 1080px;
  height: 76px;
  margin-bottom: 36px;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 4px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px #dce3f0;
  font-size: 16px;
  color: #666;
  img {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    &:nth-child(2n) {
      width: 21px;
      height: 10px;
      margin: 0 12px;
    }
  }
}
.data-source {
  width: 1080px;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px #dce3f0;

  .table-tab {
    height: 28px;
    line-height: 28px;
    margin-bottom: 24px;
    font-size: 20px;
    color: #333;
  }
}
.pay-status {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 480px;
  height: 334px;
  background: #fff;
  border-radius: 12px;
}
.agreement {
  width: 408px;
  height: 248px;
  padding: 48px 24px 24px;
  box-sizing: border-box;
  z-index: 5;
  div {
    line-height: 40px;
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 36px;
  }
  a {
    display: block;
    height: 60px;
    text-align: center;
    line-height: 60px;
    color: #fff;
    font-size: 20px;
    border-radius: 8px;
    background: #4392F6;
  }
}

.dialog-main, .empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 480px;
  height: 334px;
  padding: 60px 36px 36px;
  border-radius: 12px;
  background: #FFFFFF;
  z-index: 10;
  .icon {
    width: 90px;
    height: 90px;
    margin-bottom: 24px;
    background: url("./images/wallet.png") center center no-repeat;
    background-size: contain;
  }
  .text {
    height: 28px;
    line-height: 28px;
    color: #333847;
    font-size: 20px;
    margin-bottom: 36px;
  }
  .btn-box {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    a {
      width: 192px;
      height: 60px;
      border-radius: 8px;
      font-size: 20px;
      text-align: center;
      line-height: 60px;
      &.yes {
        background: #4392F6;
        color: #fff;
      }
      &.no {
        border: 1px solid #4392F6;
        color: #4392F6;
      }
    }
    .ant-btn {
      font-size: 20px;
    }
    .new {
      width: 216px;
      height: 100%;
    }
    .cancel {
      width: 132px;
      height: 100%;
    }
  }
}
/deep/ .ant-table-thead > tr > th, /deep/ .ant-table-tbody > tr > td {
  height: 60px;
  color: #666;
  font-size: 14px;
  padding: 0;
}
.txdialog.empty {
  width: 408px;
  height: 341px;
  padding: 24px;
  color: #333;
  text-align: center;
  .text {
    height: 74px;
    font-size: 24px;
    line-height: 37px;
  }
  .warn {
    width: 114px;
    height: 99px;
    margin: 0 auto 24px;
    background: url("../../components/statusDialog/images/danger.png");
    background-size: 100% 100%;
  }
}

.table-title {
  width: 150px;
  max-width: 150px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  cursor: default;
}

.popover-box {
  padding: 0 10px;
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0px 4px 40px 0px #8F9CB7;
}
</style>

import axios from '@/utils/axios'
const API = {
  init: '/foretaste/ajaxInit',
  setSwitch: '/foretaste/ajaxSetForetasteConfig'
}
function initPagePost () {
  return axios.post(API.init)
}
function setSwitchPost (param) {
  return axios.post(API.setSwitch, param)
}

export {
  initPagePost,
  setSwitchPost
}
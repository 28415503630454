import axios from './axios'
const protocolAjax = '/protocol/ajaxGetProtocol'
const agreeProtocolAjax = '/protocol/ajaxagreeprotocol'

// 协议版本
export function getProtocolVersionPost (type) {
  return new Promise(async (reslove, reject) => {
    const { status, data, info } = await axios.post(protocolAjax, { type })

    if (status === 1) {
      reslove(data)
    } else {
      reject(info)
    }
  })
}
// 同意协议
export function agreeProtocolPost (type, version) {
  return new Promise(async (reslove, reject) => {
    const { status, data, info } = await axios.post(agreeProtocolAjax, { type, version })

    if (status === 1) {
      reslove(data)
    } else {
      reject(info)
    }
  })
}
import axios from '@/utils/axios'

const API = {
  orderNo: '/pay/ajaxGetOrderInfo',
  getQRCode: '/pay/ajaxGetQrcode'
}

function getOrderNoPost (param) {
  return axios.get(API.orderNo, { params: param })
}

function getQRCodePost (param) {
  return axios.post(API.getQRCode, param)
}

export {
  getOrderNoPost,
  getQRCodePost
}
<template>
  <div class="wxpay">
    <div class="wxpay-head">
      <div class="head-inner">微信支付</div>
    </div>
    <div class="wxpay-content">
      <div class="wxpay-module">
        <div class="module-head">
          <div class="head-left" v-if="time != '00:01'">交易将在<span>{{ time }}</span>后关闭，请及时付款</div>
          <div class="time-out" v-else>交易超时，请<a href="javascript:void(0);"  @click="initQRCode">刷新</a>二维码重新支付</div>
          <div class="head-right">应付金额：<span>{{ totalMoney }}</span>元</div>
        </div>
        <div class="module-bottom">
          <img v-if="time != '00:01'" :src="payQrCode" alt="">
          <a href="javascript:void(0);" v-else class="refresh" @click="initQRCode">
            <div class="icon"></div>
            点击刷新二维码
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrderNoPost, getQRCodePost } from "@/api/wxpay"

export default {
  data () {
    return {
      payQrCode: "",
      totalMoney: "",
      outOrderNo: "",
      time: "",
      // isFromSwitchDialog: ""
    }
  },
  methods: {
    async initQRCode () {
      this.outOrderNo = this.$route.query.out_order_no
      // this.isFromSwitchDialog = this.$route.query.is_from_switch_dialog
      const { status, data } = await getQRCodePost({ out_order_no: this.outOrderNo })
      if (status === 1) {
        this.totalMoney = data.pay_amount
        this.payQrCode = data.qr_code
        this.countDown(data.count_down)
        this.payPostCountDown(data.count_down)
      }
    },
    countDown (outTime) {
      let hour = `0${Math.floor(outTime / 60 / 60)}`
      let minute = Math.floor(outTime / 60 % 60) < 10 ? `0${Math.floor(outTime / 60)}` : Math.floor(outTime / 60 % 60)
      let second = Math.floor(outTime % 60) < 10 ? `0${Math.floor(outTime % 60)}` : Math.floor(outTime % 60)
      this.time = `${hour}:${minute}:${second}`
      const timer = setInterval(() => {
        if (outTime < 1) {
          clearInterval(timer)
        } else {
          outTime--
          hour = `0${Math.floor(outTime / 60 / 60)}`
          minute = Math.floor(outTime / 60 % 60) < 10 ? `0${Math.floor(outTime / 60)}` : Math.floor(outTime / 60 % 60)
          second = Math.floor(outTime % 60) < 10 ? `0${Math.floor(outTime % 60)}` : Math.floor(outTime % 60)
          this.time = `${hour}:${minute}:${second}`
        }
      }, 1000)
    },
    payPostCountDown (outTime) {
      const timer = setInterval(() => {
        if (outTime < 1) {
          clearInterval(timer)
        } else {
          outTime -= 3
          this.getOrderInfo(timer)
        }
      }, 3000)
    },
    async getOrderInfo (timer) {
      const { status, data } = await getOrderNoPost({ out_order_no: this.outOrderNo })
      if (status == 1) {
        if (data.order_info.order_status == 2) {
          this.$router.replace({ path: '/pay-success', query: { out_order_no: this.outOrderNo, is_from_switch_dialog: this.isFromSwitchDialog } })
          clearInterval(timer)
        }
      }
    }
  },
  created () {
    this.initQRCode()
    this.getOrderInfo()
  }
}
</script>

<style lang="less" scoped>
.wxpay {
  height: 100%;
  font-size: 20px;
  .wxpay-head {
    display: flex;
    justify-content: center;
    height: 60px;
    background: #FEFFFF;
    .head-inner {
      width: 884px;
      height: 100%;
      line-height: 60px;
      padding-left: 30px;
      background: url("./images/wxpay-logo.png") left center no-repeat;
      background-size: 24px 20px;
    }
  }
  .wxpay-content {
    display: flex;
    justify-content: center;
    height: calc(100% - 60px);
    padding-top: 47px;
    background: #F3F6FC;
    .wxpay-module {
      width: 884px;
      height: 566px;
      border-radius: 12px;
      background: #FFFFFF;
      box-shadow: 0px 8px 24px 0px #D2DBEE;
      .module-head {
        display: flex;
        align-items: center;
        height: 89px;
        padding: 0 36px;
        border-bottom: 1px solid #EDF1F4;
        .head-left, .time-out {
          flex: 1;
          span {
            color: #FF4141;
          }
        }
        .head-right {
          span {
            font-size: 32px;
            color: #FF4141;
            line-height: 28px;
          }
        }
        .time-out {
          padding-left: 42px;
          background: url("./images/icon-close.png") left center no-repeat;
          background-size: contain;
          span {
            color: #4392F6;
          }
        }
      }
      .module-bottom {
        position: relative;
        height: 476px;
        background: url("./images/wxpay.png") center center no-repeat;
        background-size: contain;
        img, .refresh {
          position: absolute;
          left: 120px;
          top: 55px;
          width: 260px;
          height: 260px;
        }
        .refresh {
          opacity: 0.6;
          background: #000;
          padding-top: 88px;
          color: #fff;
          text-align: center;
          .icon {
            width: 44px;
            height: 44px;
            background: url("./images/refash.png") center top no-repeat;
            background-size: contain;
            margin: 0 auto;
            margin-bottom: 13px;
          }
        }
      }
    }
  }
}
</style>
<template>
  <div class="welcomes">
    <contentHeader @checkSwitch="checkSwitch" :is-default-checked="isDefaultChecked" header-type="2"></contentHeader>
    <div class="content">
      <template v-for="item in previewList">
        <previewModule
          :data="item"
          :key="item.id"
          :class="[selectedId == item.id ? 'selected' : '']"
          @showPreviewDia="showPreviewDia"
          type="4"
        ></previewModule>
      </template>
    </div>
    <previewDialog
      :transmit-data="transmitData"
      :data-list="dataList"
      :selected-id="selectedId"
      :is-open-random="isOpenRandom"
      @selectedOk="selectedOk"
      @closePreviewDia="closePreviewDia"
      size-status="1"
    ></previewDialog>
    <toast v-if="isShowToast" :set-status="setStatus"></toast>
  </div>
</template>

<script>
import contentHeader from '@/components/header/header'
import previewModule from '@/components/preview/preview'
import previewDialog from '@/components/dialog/dialog'
import toast from '@/components/toast/toast'
import { welcomeMixins } from '@/mixins/mixins'
// import { mapActions } from 'vuex'
import { initPagePost, setModulePost } from '@/api/welcome'

export default {
  mixins: [welcomeMixins],
  components: {
    contentHeader,
    previewModule,
    previewDialog,
    toast
  },
  data () {
    return {
      selectedId: 7, // 默认选中模块的id
      transmitData: { // 传给弹窗的数据
        previewId: null, // 点击预览的id
        showPreview: false, // 显示预览弹窗
        imgNum: null, // 弹窗的图片的数量
        isOpenFanli: 0,
        isOpenRemind: 0,
        isElmSpecial: 0
      },
      isDefaultChecked: null, // 控制是否开启开关
      setStatus: null, // 设置模板失败或者成功
      isShowToast: false,
      isOpenRandom: false // 是否有设置随机红包
    }
  },
  methods: {
    async initPage () {
      const { status, data } = await initPagePost()
      if (status == 1) {
        this.isDefaultChecked = data.switch
        this.selectedId = data.config.id
        this.isOpenRandom = data.config.has_bonus == 1 ? true : false
        this.transmitData.isElmSpecial = data.is_elm_special
        this.transmitData.isOpenFanli = Number(data.config.fanli)
        this.transmitData.isOpenRemind = Number(data.config.sign)
      }
    },
    showPreviewDia (data) {
      // this.transmitData = { ...data }
      this.transmitData = Object.assign(this.transmitData, data)
    },
    closePreviewDia (val) {
      this.transmitData.showPreview = val
    },
    async selectedOk (dataObj) {
      let ajaxData = {
        switch: this.isDefaultChecked
      }
      if (dataObj && dataObj.id) {
        ajaxData = { config_id: dataObj.id,  switch: 1}
      }
      const { status, data } = await setModulePost(ajaxData)
      if (status == 1) {
        if (!dataObj || !dataObj.id) return
          this.selectedId = dataObj.id
          this.isDefaultChecked = 1;
          this.setStatus = 1
      } else {
        this.setStatus = 0
      }
      this.isShowToast = true
      setTimeout(() => {
        this.isShowToast = false
      }, 2000)
    },
    checkSwitch (val) {
      this.isDefaultChecked = val
      this.selectedOk()
    }
  },
  created () {
    this.initPage()
  }
}
</script>

<style lang="less" scoped>
.content {
  display: flex;
  flex-wrap: wrap;
  width: 1080px;
  /deep/ .preview {
    .img-box.wide {
      img {
        width: 200px;
        height: 160px;
      }
    }
    &:last-child, &:nth-child(5) {
      .img-box.wide {
        img {
          width: 136px;
          height: 160px;
        }
      }
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
</style>

import axios from '@/utils/axios'
const API = {
  init: '/income/ajaxGetMyIncomeInfo',
  orderInfo: '/income/ajaxGetOrderInfo', // 订单明细list
  txAjax: '/income/ajaxConfirmWithdrawal', // 提现接口
  txRecord: '/income/ajaxGetWithdrawalRecord', // 提现明细list
  exportExcel: '/income/ajaxGetDownloadInfo', // http://localhost:10086/mtxsaas
}

function initPagePost () {
  return axios.post(API.init)
}

function getOrderInfoPost (param) {
  return axios.post(API.orderInfo, param)
}

function withDrawalPost (param) {
  return axios.post(API.txAjax, param)
}

function txRecordPost (param) {
  return axios.post(API.txRecord, param)
}

function exportExcelPost (param) {
  return axios.post(API.exportExcel, param)
}

function checkDownloadLink (link, param) {
  return axios.get(link, { params: param })
}

export {
  initPagePost,
  getOrderInfoPost,
  withDrawalPost,
  txRecordPost,
  exportExcelPost,
  checkDownloadLink
}

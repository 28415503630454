<template>
  <div class="dashboard">
    <div class="dashboard-top">
      <div class="dashboard-left">
        <div class="dashboard-fs">
          <div class="fs-top">
            <div class="fs-left">
              <div class="fs-left-title">昨日加粉</div>
              <div class="fs-left-num">
                <div class="num">{{ addFunData.new_user }}</div>
                <div :class="['icon', addFunData.type == 'lt' ? 'down' : addFunData.type == 'gt' ? 'up' : '']"></div>
              </div>
            </div>
            <div class="fs-right">
              <div class="fs-left-title">累计加粉</div>
              <div class="fs-left-num"><div class="num">{{ addFunData.cumulate_user }}</div></div>
            </div>
          </div>
          <!-- <div class="fs-bottom">预计单用户年价值：<span>9.01元</span></div> -->
        </div>
        <div class="dashboard-fw">
          <div class="fw-title">
            <div class="fw-title-title">公众号加粉量</div>
            <a class="fw-title-month fw-current" href="javascript:void(0)">近30天加粉</a>
          </div>
          <!-- <div class="fw-num"><em>今日：</em><span>12345</span><i></i></div> -->
          <div class="fw-canvas" :id="id" ></div>
        </div>
      </div>
      <div class="dashboard-center">
        <div class="dashboard-yj">
          <div class="fs-top">
            <div class="fs-left">
              <div class="fs-left-title">今日佣金
                <a-popover placement="bottom">
                  <template slot="content">
                    <p class="income-tips">如选择过自有联盟账户，则包含满天星账户来源和自有联盟账户来源</p>
                  </template>
                  <a-icon type="info-circle" />
                </a-popover>
              </div>
              <div class="fs-left-num">
                <div class="num">{{ moneyData.current_income }}</div>
                <div :class="['icon', addFunData.current_type == 'lt' ? 'down' : addFunData.current_type == 'gt' ? 'up' : '']"></div>
              </div>
            </div>
            <div class="fs-right">
              <div class="fs-left-title">累计佣金
                <a-popover placement="bottom">
                  <template slot="content">
                    <p class="income-tips">如选择过自有联盟账户，则包含满天星账户来源和自有联盟账户来源</p>
                  </template>
                  <a-icon type="info-circle" />
                </a-popover>
              </div>
              <div class="fs-left-num"><div class="num">{{ moneyData.total_income }}</div></div>
            </div>
          </div>
          <!-- <div class="fs-bottom">预计年收入：<span>123456789元</span></div> -->
        </div>
        <div class="dashboard-dd">
          <div class="dd-title">
            <div class="dd-title-title">实时订单量
              <a-popover placement="bottom">
                  <template slot="content">
                    <p class="income-tips">如选择过自有联盟账户，则包含满天星账户来源和自有联盟账户来源</p>
                  </template>
                  <a-icon type="info-circle" />
                </a-popover>
            </div>
            <a class="dd-title-today" href="javascript:void(0)" :class="{'dd-current': ddType == 1}" @click="ddBtnClick(1)">今日订单</a>
            <a class="dd-title-month" href="javascript:void(0)" :class="{'dd-current': ddType == 2}" @click="ddBtnClick(2)">近30天订单</a>
          </div>
          <div class="dd-num" v-if="ddType == 1"><em>今日：</em><span :style="{'color': todayDrawOrderData.current_type == 'lt' ? '#FF4141' : todayDrawOrderData.current_type == 'gt' ? '#44CE41' : ''}">{{ todayDrawOrderData.current_order_count }}</span><i :class="todayDrawOrderData.current_type == 'lt' ? 'down' : todayDrawOrderData.current_type == 'gt' ? 'up' : ''"></i><p>累计：{{ todayDrawOrderData.total_order_count }}</p></div>
          <div class="dd-canvas" :style="{'margin-top': ddType == 2 ? '68px' : ''}" :id="orderId" ></div>
        </div>
      </div>
      <div class="dashboard-right">
        <div class="right-title">已开启功能</div>
        <div :class="['right-item', pluginsSwitch.welcome_s == 1 ? 'open': '']">欢迎语</div>
        <div :class="['right-item', pluginsSwitch.order_s == 1 ? 'open': '']">下单互动</div>
        <!-- <div :class="['right-item', pluginsSwitch.sign_s == 1 ? 'open': '']">每日签到</div> -->
        <div :class="['right-item', pluginsSwitch.remind_s == 1 ? 'open': '']">吃饭提醒</div>
        <div :class="['right-item', pluginsSwitch.reactive_s == 1 ? 'open': '']">保持活跃</div>
      </div>
    </div>
    <!-- <div class="dashboard-bottom">
      <div class="bottom-title">
        <div class="title">留存情况</div>
        <a class="tab-item" href="javascript:void(0)" :class="{'item-current': lcType == 1}" @click="lcBtnClick(1)">次日留存</a>
        <a class="tab-item" href="javascript:void(0)" :class="{'item-current': lcType == 2}" @click="lcBtnClick(2)">周留存</a>
        <a class="tab-item" href="javascript:void(0)" :class="{'item-current': lcType == 3}" @click="lcBtnClick(3)">月留存</a>
      </div>
      <div class="bottom-canvas" :id="bottomId"></div>
    </div> -->
  </div>
</template>

<script>
import echarts from "echarts";
import { initPagePost } from "@/api/dashboard"

export default {
  name: "axis",
  props: {
    id: {
      type: String,
      default: "axis",
    },
    orderId:{
      type: String,
      default: "order",
    },
    // bottomId:{
    //   type: String,
    //   default: "bottom",
    // }
  },
  data() {
    return {
      fwChart: null,
      orderChart: null,
      bottomChart:null,
      ddType:1,
      lcType:1,
      addFunData: {},
      moneyData: {},
      pluginsSwitch: {},
      drawFwData: {},
      todayDrawOrderData: {},
      drawOrder30Data: {},
    };
  },
  mounted() {
    this.initPage();
    // this.drawBottom();
  },
  methods: {
    async initPage () {
      const { status, data } = await initPagePost()
      if (status === 1) {
        this.addFunData = data.official_account.day_total
        this.drawFwData = data.official_account.month_total
        this.moneyData = data.incomes
        this.pluginsSwitch = data.plugins.plugin_switch
        this.todayDrawOrderData = data.orders.current_order_data
        this.drawOrder30Data = data.orders.recent_30_day_order_data
        this.drawFw();
        this.ddBtnClick(1)
      }
    },
    drawFw() {
      let days = []
      let datas = []
      for(let key in this.drawFwData) {
        days.push(key)
        if (!this.drawFwData[key]) {
          datas.push(0)
        } else {
          datas.push(parseInt(this.drawFwData[key]))
        }
      }
      this.fwChart = echarts.init(document.getElementById(this.id));
      this.fwChart.setOption({
        title: {
        
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            show:false
        },
        grid: {
            left: '10',
            right: '10',
            bottom: '0',
            top:'10',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: days,
            axisLine:{
              lineStyle:{
                type:'dashed',
                color:['#ccc']
              }
            },
            axisTick:{
              show:false
            },
            axisLabel:{
              color:'transparent'
            }
        },
        yAxis: {
            type: 'value',
            splitLine: {
                show:true,
                lineStyle: {
                    type: 'dashed' // y轴分割线类型
                }
            },
            axisTick:{ //y轴刻度线
              show:false
            },
            axisLine:{ //y轴
              show:false
            },
            axisLabel:{
              show:false
            }
        },
        series: [
            {
                type: 'line',
                data: datas,
            },
          ],
        color:['#44CE41']
      });
    },
    drawOrder(days, datas) {
      this.orderChart = echarts.init(document.getElementById(this.orderId));
      this.orderChart.setOption({
        title: {
        
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            show:false
        },
        grid: {
            left: '10',
            right: '10',
            bottom: '0',
            top:'10',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: days,
            axisLine:{
              lineStyle:{
                type:'dashed',
                color:['#ccc']
              }
            },
            axisTick:{
              show:false
            },
            axisLabel:{
              color:'transparent'
            }
        },
        yAxis: {
            type: 'value',
            splitLine: {
                show:true,
                lineStyle: {
                    type: 'dashed' // y轴分割线类型
                }
            },
            axisTick:{ //y轴刻度线
              show:false
            },
            axisLine:{ //y轴
              show:false
            },
            axisLabel:{
              show:false
            }
        },
        series: [
            {
                type: 'line',
                data: datas,
            },
            // {
            //     name: '联盟广告',
            //     type: 'line',
            //     stack: '总量',
            //     data: [220, 182, 191, 234, 290, 330, 310],
            // },
          ],
        color:['#FF4141']
      });
    },
    // drawBottom() {
    //   this.orderChart = echarts.init(document.getElementById(this.bottomId));
    //   this.orderChart.setOption({
    //     title: {
        
    //     },
    //     tooltip: {
    //         trigger: 'axis',
    //         axisPointer: {
    //           type: 'cross',
    //           label: {
    //               backgroundColor: '#6a7985'
    //           }
    //       }
    //     },
    //     legend: {
    //         top:'10',
    //         right:0,
    //         data: ['邮件营销', '联盟广告']
    //     },
    //     grid: {
    //         left: '20',
    //         right: '20',
    //         bottom: '0',
    //         containLabel: true
    //     },
    //     xAxis: {
    //         type: 'category',
    //         boundaryGap: false,
    //         data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
    //         axisLine:{
    //           lineStyle:{
    //             type:'dashed',
    //             color:['#ccc']
    //           }
    //         },
    //         axisTick:{
    //           show:false
    //         },
    //         axisLabel:{
    //           color:'#111'
    //         }
    //     },
    //     yAxis: {
    //         type: 'value',
    //         splitLine: {
    //             show:true,
    //             lineStyle: {
    //                 type: 'dashed' // y轴分割线类型
    //             }
    //         },
    //         axisTick:{ //y轴刻度线
    //           show:false
    //         },
    //         axisLine:{ //y轴
    //           show:false
    //         },
    //         axisLabel:{
    //           show:false
    //         }
    //     },
    //     series: [
    //         {
    //             name: '邮件营销',
    //             type: 'line',
    //             stack: '总量',
    //             areaStyle: {
    //               color:'rgba(67, 146, 246, 0.2)'
    //             },
    //             data: [120, 132, 101, 134, 90, 230, 210],
    //             smooth: true
    //         },
    //         {
    //             name: '联盟广告',
    //             type: 'line',
    //             stack: '总量',
    //             areaStyle: {
    //               color:'rgba(148, 139, 250, 0.2)'
    //             },
    //             data: [220, 182, 191, 234, 290, 330, 310],
    //             smooth: true
    //         },
    //       ],
    //       color:['#4392F6', '#948BFA']
    //     });
    // },
    ddBtnClick(type){
      let days = []
      let datas = []
      this.ddType = type;
      if (type === 1) {
        for(let key in this.todayDrawOrderData.hour_order_data) {
          days.push(`${key}:00`)
          if (!this.todayDrawOrderData.hour_order_data[key]) {
            datas.push(0)
          } else {
            datas.push(parseInt(this.todayDrawOrderData.hour_order_data[key]))
          }
        }
      } else {
        for(let key in this.drawOrder30Data) {
          days.push(key)
          if (!this.drawOrder30Data[key]) {
            datas.push(0)
          } else {
            datas.push(parseInt(this.drawOrder30Data[key]))
          }
        }
      }

      this.drawOrder(days, datas)
    },
    // lcBtnClick(type){
    //   this.lcType = type;
    // },
  },
};
</script>

<style lang="less" scoped>
.dashboard-top {
  display: flex;
  width: 1080px;
  height: 556px;
  .dashboard-left {
    width: 426px;
    margin-right: 24px;
    .dashboard-fs {
      width: 426px;
      height: 122px;
      border-radius: 4px;
      background: #ffffff;
      box-shadow: 0px 2px 12px 0px #DCE3F0;
      box-sizing: border-box;
      padding: 24px;
      margin-bottom: 24px;
      .fs-top {
        display: flex;
        height: 77px;
        .fs-left {
          flex: 1;
          border-right: 1px solid #edf1f4;
          .fs-left-title {
            line-height: 20px;
            font-size: 14px;
            color: #5c6485;
          }
          .fs-left-num {
            display: flex;
            align-items: center;
            margin-top: 12px;
            height: 45px;
            .num {
              line-height: 45px;
              font-size: 32px;
              color: #333847;
            }
            .icon {
              width: 24px;
              height: 24px;
              margin-left: 6px;
              &.down {
                background: url('./images/down-circle.png') no-repeat;
                background-size:100% auto;
              }
              &.up {
                background: url(./images/up-circle.png) no-repeat;
                background-size:100% auto;
              }
            }
          }
        }
        .fs-right {
          flex: 1;
          box-sizing: border-box;
          padding-left: 24px;
          .fs-left-title {
            line-height: 20px;
            font-size: 14px;
            color: #5c6485;
          }
          .fs-left-num {
            display: flex;
            align-items: center;
            margin-top: 12px;
            height: 45px;
            .num {
              line-height: 45px;
              font-size: 32px;
              color: #333847;
            }
          }
        }
      }
      // .fs-bottom {
      //   margin-top: 21px;
      //   border-top: 1px solid #edf1f4;
      //   padding-top: 23px;
      //   line-height: 22px;
      //   font-size: 16px;
      //   color: #5c6485;
      //   span {
      //     color: #333847;
      //   }
      // }
    }
    .dashboard-fw {
      width: 426px;
      height: 340px;
      border-radius: 4px;
      background: #ffffff;
      box-shadow: 0px 2px 12px 0px #DCE3F0;
      padding:24px;
      box-sizing:border-box;
      .fw-title{
        display:flex;
        justify-content: space-between;
        height:20px;
        line-height:20px;
        font-size:14px;
        color:#333847;
        .fw-title-title{
          width:201px;
        }
        a{
          color:#4392F6;
        }
        .fw-current{
          position:relative;
          color:#5C6485;
        }
      }
      .fw-num{
        margin-top:24px;
        height:20px;
        line-height:20px;
        font-size:14px;
        color:#333847;
        display:flex;
        align-items: center;
        em{
          display:block;
          font-style:normal;
        }
        span{
          display:block;
          color:#44CE41;
        }
        i{
          margin-left:6px;
          display:block;
          width:10px;
          height:12px;
          background: url(./images/up-arrow.png) no-repeat;
          background-size:100% auto;
        }
      }
      .fw-canvas{
        padding-top:68px;
        width:378px;
        height:204px;
      }
    }
  }
  .dashboard-center {
    width: 426px;
    margin-right: 24px;
    .dashboard-yj {
      width: 426px;
      height: 122px;
      border-radius: 4px;
      background: #ffffff;
      box-shadow: 0px 2px 12px 0px #DCE3F0;
      box-sizing: border-box;
      padding: 24px;
      margin-bottom: 24px;
      .fs-top {
        display: flex;
        height: 77px;
        .fs-left {
          flex: 1;
          border-right: 1px solid #edf1f4;
          .fs-left-title {
            line-height: 20px;
            font-size: 14px;
            color: #5c6485;
          }
          .fs-left-num {
            display: flex;
            align-items: center;
            margin-top: 12px;
            height: 45px;
            .num {
              line-height: 45px;
              font-size: 32px;
              color: #333847;
            }
            .icon {
              width: 24px;
              height: 24px;
              margin-left: 6px;
              &.down {
                background: url('./images/down-circle.png') no-repeat;
                background-size:100% auto;
              }
              &.up {
                background: url(./images/up-circle.png) no-repeat;
                background-size:100% auto;
              }
            }
          }
        }
        .fs-right {
          flex: 1;
          box-sizing: border-box;
          padding-left: 24px;
          .fs-left-title {
            line-height: 20px;
            font-size: 14px;
            color: #5c6485;
          }
          .fs-left-num {
            display: flex;
            align-items: center;
            margin-top: 12px;
            height: 45px;
            .num {
              line-height: 45px;
              font-size: 32px;
              color: #333847;
            }
          }
        }
      }
      // .fs-bottom {
      //   margin-top: 21px;
      //   border-top: 1px solid #edf1f4;
      //   padding-top: 23px;
      //   line-height: 22px;
      //   font-size: 16px;
      //   color: #5c6485;
      //   span {
      //     color: #333847;
      //   }
      // }
    }
    .dashboard-dd {
      width: 426px;
      height: 340px;
      border-radius: 4px;
      background: #ffffff;
      box-shadow: 0px 2px 12px 0px #DCE3F0;
      padding:24px;
      box-sizing:border-box;
      .dd-title{
        display:flex;
        justify-content: space-between;
        height:20px;
        line-height:20px;
        font-size:14px;
        color:#333847;
        .dd-title-title{
          width:201px;
        }
        a{
          color:#4392F6;
        }
        .dd-current{
          position:relative;
          color:#5C6485;
        }
      }
      .dd-num{
        margin-top:24px;
        height:20px;
        line-height:20px;
        font-size:14px;
        color:#333847;
        display:flex;
        align-items: center;
        em{
          display:block;
          font-style:normal;
        }
        span{
          display:block;
        }
        i{
          margin-left:6px;
          display:block;
          width:10px;
          height:12px;
          &.up {
            background: url(./images/up-arrow.png) no-repeat;
            background-size:100% auto;
          }
          &.down {
            background: url(./images/down-arrow.png) no-repeat;
            background-size:100% auto;
          }
        }
        p{
          margin:0;
          margin-left:12px;
        }
      }
      .dd-canvas{
        margin-top:24px;
        width:378px;
        height:204px;
      }
    }
  }
  .dashboard-right{
    width:180px;
    height:486px;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px #DCE3F0;
    padding:24px;
    box-sizing: border-box;
    .right-title{
      line-height:20px;
      font-size:14px;
      color:#5C6485;
      padding-bottom:24px;
      border-bottom:1px solid #EBEBEB;
    }
    .right-item{
      position:relative;
      margin-top:24px;
      width:132px;
      height:44px;
      border-radius: 4px;
      background: #F3F6FC;
      padding-left:10px;
      box-sizing: border-box;
      line-height:44px;
      font-size:14px;
      color:#5C6485;
      &:before{
        content:"";
        position:absolute;
        top:14px;
        right:10px;
        width:16px;
        height:16px;
        background: url(./images/close.png) no-repeat;
        background-size:100% auto;
      }
      &.open:before{
        background: url(./images/open.png) no-repeat;
        background-size:100% auto;
      }
    }
  }
}
// .dashboard-bottom{
//   width:1080px;
//   margin-top:24px;
//   .bottom-title{
//     position:relative;
//     display:flex;
//     height:28px;
//     align-items: center;
//     z-index: 1;
//     .title{
//       line-height:28px;
//       font-size:20px;
//       color:#333847;
//     }
//     .tab-item{
//       margin-left:24px;
//       height:20px;
//       line-height:20px;
//       font-size:14px;
//       color:#5C6485;
//       &.item-current{
//           position:relative;
//           color:#4392F6;
//           &:before{
//             content:"";
//             width:20px;
//             height:1px;
//             position:absolute;
//             bottom:-3px;
//             left:50%;
//             margin-left:-10px;
//             background:#4392F6;
//           }
//         }
//     }
//   }
//   .bottom-canvas{
//     margin-top:-40px;
//     width:1080px;
//     height:236px;
//   }
// }
/deep/ .anticon.anticon-info-circle{
  // display:block;
  margin-left:6px;
  width:14px;
  height:14px;
  color:#FFB751;
}
/deep/ .zhuangtai-title-box{
  display:flex;
  align-items: center;
}
/deep/ .zhuangtai-title{
  display:block;
}
.ant-popover-inner-content{
  padding:12px;
}
.income-tips{
  width:156px;
  margin:0;
}
</style>

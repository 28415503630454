<template>
  <a-layout-header class="home-header">
    <a class="logo" href="javascript:void(0);" @click="pathTo('/dashboard')"></a>
    <div class="box">
      <div class="logo-small-box" v-if="showAppInfo !== 1">
        <img :src="authInfo && authInfo.headimgurl ? authInfo.headimgurl : require('./images/default-avatar.png')" alt="">
        <span>{{ authInfo && authInfo.nickname ? authInfo.nickname : '' }}</span>
        <span style="height: 17px;line-height: 17px; font-size: 12px; color: #999;">服务期至{{serviceEndDate}}</span>
      </div>
      <div v-else></div>
      <div class="user-pro" v-if="showAppInfo != 1">
        <a href="javascript:void(0);" class="user-item" @click="pathTo('/help')">
          <img src="./images/shouce.png" alt="">
          <span>使用手册</span>
        </a>
        <a href="javascript:void(0);" class="user-item" @click="phoneHandler">
          <img src="./images/icon-kefu.png" alt="">
          <span>服务顾问</span>
        </a>
        <a href="javascript:void(0);" class="user-item" @click="logOutHandler">
          <img src="./images/icon-close.png" alt="">
          <span>退出平台</span>
        </a>
      </div>
    </div>
    <serviceDialog
      @closeServiceDialog="closeServiceDialog"
      :show-service-dialog="showServiceDialog"
    ></serviceDialog>
  </a-layout-header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import serviceDialog from '@/components/serviceDialog/serviceDialog'

export default {
  props: {
    showAppInfo: {
      type: Number,
      default: 0
    },
    authInfo: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    serviceDialog,
  },
  data () {
    return {
      showServiceDialog: false
    }
  },
  computed: {
    ...mapGetters(['serviceEndDate'])
  },
  methods: {
    ...mapActions(['logOut']),
    logOutHandler () {
      this.logOut().then(() => {
        this.$router.replace('/login')
      })
    },
    phoneHandler () {
      this.showServiceDialog = true;
    },
    closeServiceDialog (val) {
      this.showServiceDialog = val
    },
    pathTo (path) {
      this.$router.push({ path })
    }
  }
}
</script>

<style lang="less" scoped>
.home-header {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 1440px;
  height: 60px;
  line-height: normal;
  font-size: 14px;
  color: #5C6485;
  background: #FEFFFF;
  padding: 0;
  .logo {
    width: 240px;
    height: 100%;
    background: url("../../images/logo-cur.png") 24px center no-repeat;
    background-size: 137px 40px;
    margin-right: 24px;
    border-right: 1px solid #F3F6FC;
  }
  .box {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
  .logo-small-box {
    display: flex;
    align-items: center;
    color: #707793;
    img {
      width: 30px;
      height: 30px;
      margin-right: 12px;
    }
    span {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      margin-right: 12px;
    }
  }
  .user-pro {
    display: flex;
    .user-item {
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #EDF1F4;
      padding: 0 24px;
      height: 36px;
      color: #5C6485;
      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
      &:first-child {
        border: none;
        img {
        width: 18px;
        height: 18px;
        }
      }
    }
  }
}
</style>
<template>
  <div class="service-dialog" v-if="showServiceDialog">
    <div class="mask"></div>
    <div class="service-dialog-box">
      <p class="title">联系服务顾问</p>
      <p class="subtitle">若有疑问，请微信添加满天星人工客服，我们会及时为您解答。</p>
      <img class="erweima" src="./images/code.jpeg" alt="">
      <a class="btn" href="javascript:void(0)" @click="closeServiceDialog">关闭</a>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    showServiceDialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  methods: {
    closeServiceDialog () {
      this.$emit('closeServiceDialog', false)
    }
  }
}
</script>

<style lang="less" scoped>
.service-dialog {
  position: relative;
  .mask {
    z-index: 11;
  }
  .service-dialog-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 432px;
    height: 556px;
    box-sizing: border-box;
    padding-top:36px; 
    border-radius: 12px;
    background: #fff;
    z-index: 1000;
    .title {
      height: 28px;
      text-align: center;
      line-height:28px;
      font-size:20px;
      color:#333847;
      font-weight:medium;
    }
    .subtitle {
      margin-top:12px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      font-size:14px;
      color:#5C6485;
    }
    .erweima{
      display:block;
      margin:24px auto 0;
      width:300px;
      height:300px;
    }
    .btn{
      display:block;
      margin:36px auto 0;
      width:360px;
      height:60px;
      line-height:60px;
      text-align: center;
      font-size:20px;
      color:#FFF;
      font-weight:medium;
      border-radius: 6px;
      background: #4392F6;
    } 
  }
}
</style>
<template>
  <div class="interaction">
    <contentHeader @checkSwitch="checkSwitch" :is-default-checked="isDefaultChecked" header-type="3"></contentHeader>
    <div class="user-data" v-if="isShowUserData">
      <div class="title-box">
        <div class="title">用户返利数据</div>
        <div class="download-box">
          <a href="javascript:void(0);" @click="exportExcel(1)"><a-icon type="download" />账户明细</a>
          <a href="javascript:void(0);" @click="exportExcel(2)"><a-icon type="download" />提现明细</a>
        </div>
      </div>
      <div class="data-box">
        <div class="item">
          <div class="text">返利待到账
            <a-tooltip>
              <template slot="title">公众号内累计待到账的返利金额总和</template>
              <a-icon type="info-circle" />
            </a-tooltip>
          </div>
          <div class="price">{{fanliData && fanliData.coming_soon ? fanliData.coming_soon : 0}}元</div>
        </div>
        <div class="item">
          <div class="text">用户待提现
            <a-tooltip>
              <template slot="title">公众号内累计待提现的返利金额总和</template>
              <a-icon type="info-circle" />
            </a-tooltip>
          </div>
          <div class="price">{{fanliData && fanliData.can_withdraw_amount ? fanliData.can_withdraw_amount : 0}}元</div>
        </div>
        <div class="item">
          <div class="text">用户已提现
            <a-tooltip>
              <template slot="title">公众号内累计已提现的返利金额总和</template>
              <a-icon type="info-circle" />
            </a-tooltip>
          </div>
          <div class="price">{{fanliData && fanliData.withdrawed_amount ? fanliData.withdrawed_amount : 0}}元</div>
        </div>
      </div>
    </div>
    <div class="content">
      <template v-for="item in previewList">
        <interactionModule
          :key="item.id"
          :data="item"
          :fanli-rate="fanliRate"
          :account-type="accountType"
          :class="[selectedId == item.id ? 'selected' : '', {disable: accountType === 2 && item.id != 3}]"
          @showPreviewDia="showPreviewDia"
        ></interactionModule>
      </template>
    </div>
    <previewDialog
      :transmit-data="transmitData"
      :data-list="dataList"
      :fanli-rate="fanliRate"
      :selected-id="selectedId"
      @selectedOk="selectedOk"
      @closePreviewDia="closePreviewDia"
      :size-status="sizeStatus"
    ></previewDialog>
    <toast v-if="isShowToast" :set-status="setStatus"></toast>
    <export-excel-dia
      v-if="isShowExportExcelDia"
      :excelArr="excelArr"
      :totalCount="totalCount"
      :excelType="selectItem"
      @closeExcelDialog="closeExcelDialog"
    ></export-excel-dia>
    <div class="mask" v-if="isShowExportExcelDia"></div>
      <!-- 加载loading Toast -->
      <div class="loading" v-if="loadingFlag">
        <img src="./images/excel-loading.gif" alt="" class="loading-icon">
      </div>
  </div>
</template>

<script>
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/globalConst'
import contentHeader from '@/components/header/header'
import interactionModule from './components/interactionModule'
import previewDialog from '@/components/dialog/dialog'
import toast from '@/components/toast/toast'
import { initPagePost, setModulePost, exportExcelPost } from '@/api/interaction'
import ExportExcelDia from './components/exportExcelDia.vue'
// import { mapActions } from 'vuex'
import { interactionMixins } from '@/mixins/mixins'

export default {
  mixins: [interactionMixins],
  components: {
    contentHeader,
    interactionModule,
    previewDialog,
    toast,
    ExportExcelDia
  },
  data () {
    return {
      selectedId: null,
      fanliRate: 30,
      transmitData: { // 传给弹窗的数据
        previewId: null, // 点击预览的id
        showPreview: false, // 显示预览弹窗
        imgNum: null // 弹窗的图片的数量
      },
      isDefaultChecked: null, // 控制是否开启开关
      isShowToast: false,
      setStatus: null,
      sizeStatus: '2',
      accountType: null,
      isShowExportExcelDia: false,
      excelArr: [],
      totalCount: '',
      loadingFlag: false,
      selectItem: null,
      fanliData: {},
      isShowUserData: false
    }
  },
  methods: {
    // ...mapActions(['checkAuthStatus']),
    async initPage () {
      const { status, data } = await initPagePost()
      if (status == 1) {
        // this.checkAuthStatus(data.auth).then(() => {
        this.isDefaultChecked = data.switch
        this.selectedId = data.config.info.type
        this.fanliRate = parseInt(data.config.info.fanli_rate)
        this.accountType = Number(data.union_account_type) // 联盟跟单类型，0,1：为满天星系统；2为自有联盟
        this.fanliData = data.rebate
        this.isShowUserData = data.is_show_rebate
        // })
      }
    },
    showPreviewDia (data) {
      this.transmitData = { ...data }
      this.sizeStatus = data.sizeStatus
    },
    closePreviewDia (val) {
      this.transmitData.showPreview = val
    },
    async selectedOk (dataObj) {
      let ajaxData = {
        switch: this.isDefaultChecked
      }
      if (dataObj) {
        ajaxData = {
          switch: 1,
          config_type: dataObj.id,
          fanli_rate: dataObj.fanliRateNum
        }
      }
      const { status, data } = await setModulePost(ajaxData)
      if (status == 1) {
        // this.checkAuthStatus(data.auth).then(() => {
        if (!dataObj || !dataObj.id) return 
        this.selectedId = dataObj.id;
        this.isDefaultChecked = 1;
        this.setStatus = 1
        this.initPage()
        // })
      } else {
        this.setStatus = 0
        this.isShowToast = true
        setTimeout(() => {
          this.isShowToast = false
        }, 2000)
      }
      this.isShowToast = true
      setTimeout(() => {
        this.isShowToast = false
      }, 2000)
    },
    checkSwitch (val) {
      this.isDefaultChecked = val
      this.selectedOk()
    },
    async exportExcel (type) { // 导出表格
      this.selectItem = type
      this.loadingFlag = true
      const ajaxData = {
        download_type: type == 1 ? 1 : 2
      }
      let { status, data, info } = await exportExcelPost(ajaxData)
      // const  { status, data, info } = {"data":{"total":"10","link":[{"title":"2021.04.22-2021.05.09","link":"http://saas.mtx.net.cn/income/download?download_id=2806d1742f116526b7f52f122c19928d&download_type=1"},{"title":"2021.04.15-2021.04.17","link":"http:\/\/saas.mtx.net.cn\/income\/download?download_id=195be11160fc1b2deb610a71c4a8d893&download_type=1"},{"title":"2021.04.11-2021.04.13","link":"http:\/\/saas.mtx.net.cn\/income\/download?download_id=a517c7a0a9cd0f5767b3261c8d3521ab&download_type=1"},{"title":"2021.04.09-2021.04.09","link":"https:\/\/saas.mtx.net.cn\/income\/download?download_id=6adcb872e44e53b55b4e3aff9afb386b&download_type=1"}]},"info":"success","status":1}
      if (status == 1) {
        this.excelArr = data.link
        this.totalCount = data.total
        if (data.link.length == 1) { // 只有一张excel表格
          this.isShowExportExcelDia = false
          function randomString (len) {
            len = len || 32;
            let $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
            let maxPos = $chars.length;
            let pwd = '';
            for (let i = 0; i < len; i++) {
              pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
            }
            return pwd;
          }
          let randomStr = randomString(32)
          let time = new Date().getTime()
          let linkStr = data.link[0].link + `&ts=${time}&nonce=${randomStr}`
          let mtx_token = storage.get(ACCESS_TOKEN)

          let xhr = new XMLHttpRequest();
          let that = this
          xhr.open("get", linkStr);
          xhr.responseType = "blob";
          xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
          xhr.setRequestHeader("mtx_token", mtx_token);
          xhr.onreadystatechange = function() {
            if (xhr.readyState === 4 && xhr.status != 200) {
              that.$message.error("下载出错，请稍后重试")
              that.loadingFlag = false
            } else {
              if (xhr.readyState === 4 && xhr.status === 200) {
                let blob = new Blob([xhr.response], {type: "application/vnd.ms-excel;charset=utf-8"});
                let objectUrl = window.URL.createObjectURL(blob);
                let filename = ''
                try {
                  let contentDisposition = xhr.getResponseHeader('content-disposition');
                  if (contentDisposition) {
                    filename = decodeURIComponent(contentDisposition.split('=')[1]).replace(/^\"|\"+$/g, '');
                  }
                } catch (e) {}
                let a = document.createElement('a');
                a.setAttribute("href",objectUrl);
                a.setAttribute("download", filename);
                a.click()
                that.loadingFlag = false
              }
            }
          }
          xhr.send(null)
        } else if (data.link.length > 1) {
          this.isShowExportExcelDia = true
          this.loadingFlag = false
        } else {
          this.loadingFlag = false
          this.$message.error("暂无可下载数据")
        }
      } else {
          this.$message.error(info)
          this.loadingFlag = false
      }
    },
    closeExcelDialog () {
      this.isShowExportExcelDia = false
    }
  },
  created () {
    this.initPage()
  }
}
</script>

<style lang="less" scoped>
.content {
  display: flex;
  flex-wrap: wrap;
  width: 1080px;
}
.user-data {
  width: 1080px;
  height: 184px;
  margin-bottom: 30px;
  border-radius: 4px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px #DCE3F0;
  padding: 24px 32px 21px 0;
  .title-box {
    display: flex;
    justify-content: space-between;
    height: 30px;
    margin-bottom: 28px;
    padding-left: 32px;
    .title {
      height: 28px;
      line-height: 28px;
      color: #333;
      font-size: 20px;
      font-weight: 700;
    }
    .download-box {
      display: flex;
      height: 30px;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      border-radius: 4px;
      background: #FFFFFF;
      border: 1px solid #4392F6;
      padding: 5px 12px;
      margin-left: 10px;
      .anticon-download {
        font-size: 18px;
        margin-right: 7px;
      }
    }
  }
  .data-box {
    display: flex;
    height: 80px;
    .item {
      flex: 1;
      border-right: 1px solid #EDF1F4;
      padding-left: 32px;
      &:last-of-type {
        border-right: none;
      }
      .text {
        height: 20px;
        line-height: 20px;
        color: #666666;
        font-size: 14px;
        margin-bottom: 12px;
        .anticon.anticon-info-circle {
          color: #FF7A00;
        }
      }
      .price {
        height: 39px;
        left: 39px;
        font-size: 28px;
        color: #333847;
      }
    }
  }
}
.loading {
  width: 164px;
  height: 134px;
  opacity: 0.6;
  border-radius: 12px;
  background: #000000;
  border-radius: 6px;
  position: absolute;
  z-index: 100;
  left: 50%;
  top: 400px;
  margin-left: -82px;
  .loading-icon {
    display: block;
    width: 164px;
    height: auto;
  }
}
</style>

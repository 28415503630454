import axios from '@/utils/axios'

const API = {
  initAuth: '/authorizer/ajaxInit',
  authBack: '/authorizer/ajaxAuthorizer',
  initMini: '/wxamplink/ajaxInit',
  miniBack: '/wxamplink/ajaxLink',
  accountType: '/union/ajaxSetting',
  authStatus: '/union/ajaxGetTkAuthInfo'
}

function initAuthPost () {
  return axios.post(API.initAuth)
}

function authBackPost (param) {
  return axios.post(API.authBack, param)
}

function initMiniPost () {
  return axios.post(API.initMini)
}

function miniBackPost () {
  return axios.post(API.miniBack)
}
function setAccountTypePost (params) {
  return axios.post(API.accountType, params)
}
function authStatusPost (params) {
  return axios.post(API.authStatus, params)
}

export {
  initAuthPost,
  authBackPost,
  initMiniPost,
  miniBackPost,
  setAccountTypePost,
  authStatusPost
}

import router from '@/router'
import axios from 'axios'
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/globalConst'

axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? '/mtxsaas' : ''
axios.interceptors.request.use(config => {
  if (config.url !== 'login') {
    config.headers.mtx_token = storage.get(ACCESS_TOKEN)
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

axios.interceptors.response.use(response => {
  if(response.data.status == 0 && response.data.data.err_code == 10011){
    storage.remove(ACCESS_TOKEN)
    router.replace('/login')
  }
  return response.data
}, function (error) {
  return Promise.reject(error)
})

export default axios
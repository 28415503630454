<template>
  <div class="auth-status">
    <header>
      <div class="logo">
        <img src="./../../images/logo-cur.png" alt="">
      </div>
    </header>
    <section>
        <div class="content">
          <img v-if="authStatus" src="../authorizer/images/success.png" alt="">
          <img v-else src="../../images/error.png" alt="">
          <div class="text">授权{{ authStatus ? '成功' : '失败'}}</div>
          <div v-if="authStatus" class="subtext">请返回满天星系统进行后续配置</div>
          <div v-else class="subtext">请返回满天星系统进行重新授权</div>
        </div>
    </section>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      authStatus: null
    }
  },
  methods: {},
  mounted() {
    this.authStatus = this.$route.query.auth_status == 1 ? true : false
  },
}
</script>
<style lang="less" scoped>
header {
  height: 60px;
  background: #feffff;
  .logo {
    width: 240px;
    height: 60px;
    line-height: 60px;
    padding-left: 24px;
    border-right: 1px solid #F3F6FC;
    img {
      width: 137px;
      height: 40px;
    }
  }
}
section {
  width: 100vw;
  height: calc(100vh - 60px);
  padding-top: 47px;
  background: #F3F6FC;
  .content {
    width: 884px;
    height: 566px;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0px 8px 24px 0px #D2DBEE;
    border-radius: 12px;
    text-align: center;
    img {
      width: 90px;
      height: 90px;
      margin: 185px 0 24px;
    }
    .text {
      height: 45px;
      line-height: 45px;
      font-size: 32px;
      color: #333;
      margin-bottom: 12px;
    }
    .subtext {
      height: 24px;
      line-height: 24px;
      font-size: 20px;
      color: #666;
    }
  }
}
</style>
<template>
  <div class="txdialog">
    <div class="title" style="margin-bottom:6px">您的【{{ excelType == 1 ? '账户' : '提现' }}明细】数据共有<span class="count">{{ totalCount }}</span>条</div>
    <div class="title">为确保数据顺利下载，已为您生成<span class="count">{{ excelArr.length }}</span>个文件</div>
    <div class="excel-sec">
      <div class="excel-item" v-for="(item, index) of excelArr" :key="index">
        <img class="excel-icon" src="../images/excel-icon.png" alt="">
        <div class="file-time">{{ item.title }}</div>
        <div class="download-btn" @click="downloadFile(item.link, excelType)">下载</div>
      </div>
    </div>
    <a-button class="btn" @click="closeDialog">关闭</a-button>
    <!-- 加载loading Toast -->
    <div class="loading" v-if="loadingFlag">
      <img src="../images/excel-loading.gif" alt="" class="loading-icon">
    </div>
  </div>
</template>

<script>
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/globalConst'
import axios from 'axios'
import { checkDownloadLink } from '@/api/income'
export default {
  props: {
    totalCount: {
      type: String,
      default: ''
    },
    excelArr: {
      type: Array,
      default: () => []
    },
    excelType: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      loadingFlag: false,
      // 文件数据
      files: {},
      // 等待下载的文件index
      waitIndex: []
    }
  },
  methods: {
    closeDialog () {
      this.$emit("closeExcelDialog", false)
    },
    async downloadFile (link, type, index) {
      if (this.waitIndex.includes(index)) {
        return
      }
      this.waitIndex.push(index)
      this.loadingFlag = true
      let time = new Date().getTime()
      // let getStorage_timeStamp = localStorage.getItem('download_timeStamp')
      // let getStorage_link = localStorage.getItem('download_link')
      // if (getStorage_timeStamp && getStorage_link && link == getStorage_link  && ((time - getStorage_timeStamp) < 1000)) { // 5秒内重复点击同一个下载链接，则不生效
      //   this.loadingFlag = false
      //   return
      // }
      // localStorage.setItem('download_timeStamp', time)
      // localStorage.setItem('download_link', link)
      function randomString (len) {
        len = len || 32;
        let $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
        let maxPos = $chars.length;
        let pwd = '';
        for (let i = 0; i < len; i++) {
          pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;
      }
      let randomStr = randomString(32)
      let linkStr = link + `&ts=${time}&nonce=${randomStr}`
      let mtx_token = storage.get(ACCESS_TOKEN)

      let xhr = new XMLHttpRequest();
      let that = this
      xhr.open("get", linkStr);
      xhr.responseType = "blob";
      xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
      xhr.setRequestHeader("mtx_token", mtx_token);
      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4 && xhr.status != 200) {
          if (xhr.statusText == '60004') {
            that.$message.error("操作过于频繁，请稍后重试")
          } else {
            that.$message.error("下载出错，请稍后重试")
          }
          that.loadingFlag = false
          that.waitIndex.splice(that.waitIndex.indexOf(index), 1)
        } else {
          if (xhr.readyState === 4 && xhr.status === 200) {
            let blob = new Blob([xhr.response], {type: "application/vnd.ms-excel;charset=utf-8"});
            let objectUrl = window.URL.createObjectURL(blob);
            let filename = ''
            try {
              let contentDisposition = xhr.getResponseHeader('content-disposition');
              if (contentDisposition) {
                filename = decodeURIComponent(contentDisposition.split('=')[1]).replace(/^\"|\"+$/g, '');
              }
            } catch (e) {}
            let a = document.createElement('a');
            a.setAttribute("href",objectUrl);
            a.setAttribute("download", filename);
            a.click()
            that.loadingFlag = false
            that.waitIndex.splice(that.waitIndex.indexOf(index), 1)
          }
        }
      }
      xhr.send(null)
    }
  }
}
</script>

<style lang="less" scoped>
div {
  box-sizing: border-box;
}
.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 14px;
  color: #333;
}
.count {
  color: orange;
}
.excel-sec {
  width: 454px;
  height: 410px;
  border: 1px solid #cccccca1;
  overflow: auto;
  border-radius: 4px;
  .excel-item {
    width: 406px;
    height: 80px;
    border-bottom: 1px solid #cccccca1;
    padding: 24px 0;
    margin: 0 24px;
    position: relative;
    display: flex;
    align-items: center;
    .excel-icon {
      width: 30px;
      height: auto;
      position: relative;
      left: -5px;
      margin-right: 15px;
    }
    .file-time {
      padding-right: 85px;
      color: #777;
      word-break: break-word;
    }
    .download-btn {
      width: 74px;
      height: 30px;
      border-radius: 15px;
      background: #4392F6;
      color: #f3f3f3;
      text-align: center;
      line-height: 30px;
      font-size: 14px;
      cursor: pointer;
      position: absolute;
      right: 0;
      &.gray {
        background-color: #9e9e9e;
        cursor: default;
      }
    }
  }
}

.btn {
  width: 200px;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  border-radius: 6px;
  position: absolute;
  right: 36px;
  bottom: 36px;
  color:#4392F6;
  border: 1px solid #4392F6;
}
.loading {
  width: 164px;
  height: 134px;
  opacity: 0.6;
  border-radius: 12px;
  background: #000000;
  border-radius: 6px;
  position: absolute;
  z-index: 100;
  left: 60%;
  top: 130px;
  margin-left: -82px;
  .loading-icon {
    display: block;
    width: 164px;
    height: auto;
  }
}
</style>

<template>
  <div class="txdialog agreement-dialog">
    <div class="title">增长服务订购协议
      <span class="close" @click="closeAgreementDialog">关闭
        <a-icon type="close" />
      </span>
    </div>
    <div class="box">
      <div class="detail">在订购前，请您务必仔细阅读并透彻理解增长服务订购内容及订购协议(以下或简称“订购协议”)，在确认充分理解后选择接受或不接受本订购协议；一旦您同意协议并完成订购的，即表明您已阅读并同意受本订购协议的约束。如您不同意本订购协议，您应不再进行下一步或停止订购。
      </div>
      <div class="sub-title">
        一、协议内容及签署
      </div>
      <div class="text">
        1、本订购协议内容包括协议正文及所有满天星SaaS服务平台（以下或简称“本平台”）已经发布的或将来可能发布的各类规则。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。除另行明确声明外，任何SaaS服务平台及其关联公司提供的服务均受本协议约束。但法律法规另有强制性规定的，依其规定。
      </div>
      <div class="text">
        2、您在登录SaaS服务平台账户后按照页面提示订购本增长服务的，<span style="text-decoration: underline;">即视为您接受本协议及各类规则，并同意受其约束。</span>您应当在使用SaaS服务平台服务之前认真阅读全部协议内容并确保完全理解协议内容，如您对协议有任何疑问的，应向SaaS服务平台咨询。但无论您事实上是否在使用SaaS服务平台服务之前认真阅读了本协议内容，只要您登录、正在或者继续使用本平台增长服务的，则视为接受本协议。
      </div>
      <div class="text">
        3、您承诺接受并遵守本协议的约定。如果您不同意本协议的约定，您应立即停止登录程序或停止使用SaaS服务平台增长服务。
      </div>
      <div class="text">
        <span style="text-decoration: underline;">4、SaaS服务平台有权根据需要不时地制订、修改本协议及/或各类规则，并以网站公示的方式进行公告。变更后的协议和规则一经在网站公布后，立即自动生效。</span>SaaS服务平台的最新的协议和规则以及网站公告可供您随时登陆查阅，您也应当经常性的登陆查阅最新的协议和规则以及网站公告以了解SaaS服务平台最新动态。如您不同意相关变更，应当立即停止使用SaaS服务平台增长服务。您继续使用服务的，即表示您接受经修订的协议和规则。本协议和规则（及其各自的不时修订）条款具有可分割性，任一条款被视为违法、无效、被撤销、变更或因任何理由不可执行，并不影响其他条款的合法性、有效性和可执行性。
      </div>
      <div class="text">
        5、SaaS服务平台增长服务（以下简称“本服务”）是由上海中彦信息科技有限公司向您提供的帮助您实现商业变现的增长服务，您可自行根据自己实际需求情况选择进行订购。
      </div>
      <div class="text">
        <span style="text-decoration: underline;">6、增长服务：指根据您的业务及运营需求向本平台订购一定数量的粉丝增长服务，本平台为您提供您所属或运营的或您指定的微信公众号粉丝关注量的增长服务。粉丝关注量（以下简称“加粉数”、“粉丝数量”）：是指自您订购之日起通过本平台的指定服务（包括但不限于专属推广渠道、二维码或链接等），在您指定的微信公众号点击关注的粉丝数量。</span>
      </div>
      <div class="sub-title">
        二、增长服务订购及支付流程
      </div>
      <div class="text">
        <span style="text-decoration: underline;">1、您在本平台以月为周期，以充值的方式进行下单订购对应的服务，本平台在收到您的下单信息后将在一定时间内兑现符合您下单金额对应的粉丝数量。</span>
      </div>
      <div class="text">
        2、您可以根据订购页面及您的需求选择不同的增长计划，在增长计划库存数量充足的情况下您可以点击页面中的立即预定按钮及选择一定时间的粉丝库存完成订购。
      </div>
      <div class="text">
        <span style="text-decoration: underline;">3、您单笔订购增长服务粉丝最低和最高数量及每次增加数量等，以订购页面为准。具体数量可能会随着业务的发展及运营的需求有所调整，具体以订购页面或账户后台显示为准。</span>
      </div>
      <div class="text">
        <span style="text-decoration: underline;">4、您根据页面完成订购服务的，后台进入订单的分配合并阶段。当您的订单还未被本平台处理时（如物料未被印刷等），您的账户未被锁定，在此期间您可选择退款服务，届时本平台将不再为您提供对应的服务；当您的订单进入本平台处理阶段（如物料印刷至分发完成阶段），您的账户均处于锁定状态，您理解本平台为此已经付出了大量的人力、物力、财力资源，此阶段您不能选择退款服务。当推广任务完成后，您账户剩余未完成的金额被解锁，您可以自行在后台提现。</span>
      </div>
      <div class="text">
        <span style="text-decoration: underline;">5、您可以在本平台您的账户中查看您的订购订单，推广订单，加粉数及相应的内容，具体以您的账户后台展示查询为准。</span>
      </div>
      <div class="text">
        <span style="text-decoration: underline;">6、当订购任务完成后如您的粉丝数量超量5%内的，则不额外收取费用；如粉丝数量超量>5%则生成超额账单，您需要支付超额的费用，如不支付，则下次无法继续选择订购服务；如下次需要继续订购的，则您需先支付超额账单费用。</span>
      </div>
      <div class="text">
        <span style="text-decoration: underline;">7、关于发票：根据您在实际使用本平台服务过程中产生的付费服务项目，您可以在每月1号起就上个自然月的业务总费用申请开票服务，本公司在对您的开票申请进行审核通过后的30个自然日内为您开具发票。如您为企业用户的，本公司可为您开具企业抬头的增值税专用发票或增值税普通发票；如您为个人用户的，本公司将为您开具个人抬头的增值税普通发票。您最晚申请开票时间是n+2月的20号，请您务必注意在以上规定时间内申请发票开具，超过以上开票时间的本公司不再为您开具。如您已在页面操作取消或放弃开票申请的，对于您取消或放弃的开票金额，本公司不再为您开具发票。</span>
      </div>
      <div class="text">
        8、您应妥善保管账号及密码，本平台任何时候均不会主动要求您提供账户及密码。账号项下所进行的一切操作均视为您的行为，您应对该操作依法享有权利并承担相关责任。因此您同意：
      </div>
      <div class="text">
        1)请您务必妥善保管及正确、安全使用本平台账号、密码及其他账号要素。<br />
        2)除根据法律规定本平台存在法定过错外，您应对您账号项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、购买服务及披露信息等）负责。<br />
        3)因黑客行为、他人诈骗行为或您的保管疏忽导致账号非法使用，您应通过司法、行政等救济途径向侵权行为人追偿，本平台将积极配合您提供相应的帮助，但本平台不因此而承担责任，如本平台存在法定过错的，仅承担您的直接损失，但直接损失不得超过本合同订购总金额。<br />
      </div>
      <div class="text">
        9、本平台会将订购信息以系统通知（或发送到该手机的短信或电话等）方式通知您订购服务进展情况以及提示您进行下一步的操作。因此，在交易过程中您应当及时登录到本平台网站查看和进行交易操作。因您没有及时查看和对交易状态进行修改或确认或未能提交相关申请而导致的纠纷或损失，您需自行承担。
      </div>
      <div class="text">
        <span style="text-decoration: underline;">10、您同意，基于运行和交易安全的需要，本平台可以暂时停止提供或者限制本服务部分功能，或提供新的功能，在功能减少、增加或者变化时，涉及到您重大利益的，本平台会提前通过站内信等方式通知您，预留合理时间并听取商家意见反馈。在您有效的服务期限内，本平台不会变更您本次付费订购服务的费用。</span>
      </div>
      <div class="sub-title">
        三、知识产权
      </div>
      <div class="text">
        1、本平台上展示的资料（包括但不限于文字、图表、标识、图像、数字下载和数据编辑）均为本平台或其内容提供者的财产或者权利；本平台上所有内容的汇编是属于本平台的著作权；本平台上所有软件都是本平台或其关联公司或其软件供应商的财产或者权利，上述知识产权均受法律保护。您开通使用本平台服务功能的，并不代表所有知识产权的转移，亦不代表本平台授权您可以在外部渠道、平台、公众号、网站等使用本平台及本平台关联平台/关联公司的知识产权。如经本公司发现您未经授权擅自使用或间接侵犯了本平台关联主体、关联平台、关联公司等知识产权的，本平台有权根据平台自身的判断终止对您的服务、冻结账户、扣除账户余额（如有）、直至注销用户账号，同时您还必须承担由此给本平台带来的所有维权损失。
      </div>
      <div class="text">
        2、本平台或其关联方拥有、使用、许可、控制的或者本平台或其关联方对之享有其他权利的所有知识产权、工业产权和专有权利，如您需要使用，可按照官网公示的途径向本平台申请，非经本平台或其关联公司书面同意，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表在本平台网站上的程序或内容。<span style="text-decoration: underline;">您不得恶意申请和注册与本平台及本平台关联方相同或近似的商标、名称、标识、标志、微信公众号、域名、网页等，用于开展与本平台及本平台关联方实质相同或近似的业务。</span>
      </div>
      <div class="text">
        3、若您违反本条之约定而给本平台及/或本平台关联方造成损失的，您应赔偿全部损失并消除给本平台及/或本平台关联方造成的不良影响。
      </div>
      <div class="sub-title">
        四、免责声明
      </div>
      <div class="text">
        (一)您理解并同意，本平台不对因下述情况导致的损害赔偿承担责任，包括但不限于利润、商誉、使用、数据等方面的损失或其他无形损失的损害赔偿：
      </div>
      <div class="text">
        1.本平台有权基于综合判断，包含但不限于本平台认为您已经违反本协议的规定及法律法规的要求，暂停、中断或终止向您提供本服务或其任何部分，并移除您的资料。
      </div>
      <div class="text">
         (二)【系统中断或故障的免责】系统因下列状况无法正常运作，导致您无法使用各项服务的，本平台不承担损害赔偿责任，该状况包括但不限于：
      </div>
      <div class="text">
         1.本平台在本平台官网公告之系统停机维护期间；<br />
         2.电信设备出现故障不能进行数据传输的；<br />
         3.因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成本平台系统障碍不能执行业务的；<br />
         4.由于电信部门技术调整或故障、网站升级、银行方面的问题等原因而造成的服务中断或者延迟。<br />
      </div>
      <div class="text">
         (三)【特殊情况的免责】对下列情形，本平台不承担任何责任：<br />
         1.并非由于本平台存在法定过错而导致本服务未能提供的；<br />
         2.由于您的原因导致任何第三方遭受损失的；<br />
         3.因您未及时续费/订购，导致本服务不能提供或提供时发生任何错误，您须自行承担因此产生的一切后果，本平台不承担任何责任。
      </div>
      <div class="text">
        （四）【版本更新的免责】为了改善消费者体验、完善服务内容，本平台将不断努力开发新的服务，并为您不时提供内容或系统的更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）。为了保证本服务的安全性和功能的一致性，本平台可能不经向您特别通知而对内容或系统进行更新，或者对软件的部分功能效果进行改变或限制。新版本发布后，旧版本的服务可能无法使用。请您随时核对并下载最新版本。
      </div>
      <div class="sub-title">
        五、协议中止、解除及终止
      </div>
      <div class="text">
        (一)【协议解除/终止】出现下列情形之一的，本平台有权单方解除/终止本协议，并有权要求你承担有可能发生的违约责任：<br />
        1.你从事其业务的经营事项的合法资格消失；<br />
        2.本平台接到相关部门的通知，你被相关部门要求停业整顿或其他行政处罚的；<br />
        3.你违反本平台公布的相关协议或规则，被清退的；<br />
        4.你出现违规或违法行为的，严重损害本平台或者消费者利益的；<br />
        5.其它本平台有正当理由认为需中止/终止服务的情况。
      </div>
      <div class="text">
        （二）【协议期限】本协议期限为自订购之日起至协议解除/终止/服务完成之日止；若涉及保密、违约责任、售后服务义务及产品质量保证责任等内容在协议解除/终止后将继续有效。
      </div>
      <div class="sub-title">
        六、法律适用、管辖与其他
      </div>
      <div class="text">
        (一)本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，没有相关法律规定的，参照通用国际商业惯例和（或）行业惯例。
      </div>
      <div class="text">
        <span style="text-decoration: underline;">(二)你因使用本平台服务所产生及与本平台服务有关的争议，由本平台与你协商解决。协商不成时，任何一方均可向本平台公司上海中彦信息科技有限公司企业所在地人民法院诉讼解决。</span>
      </div>
      <div class="text">
        (三)【条款独立性】如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余条款则仍具有法律效力。
      </div>
      <div class="text">
        (四)【权利放弃】本协议任何一方于另一方过失或违约时放弃本协议规定的权利的，不得视为其对一方的其他或以后同类之过失或违约行为弃权。本平台未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    closeAgreementDialog: {
      type: Function
    }
  },
  data() {
    return {
    }
  },
  methods: {},
  mounted() {},
}
</script>
<style lang="less" scoped>
.agreement-dialog {
  z-index: 5;
  width: 910px;
  height: 579px;
  color: #666;
  font-size: 14px;
  padding: 36px 0 36px 36px;
  .title {
    position: relative;
    height: 52px;
    line-height: 28px;
    padding-bottom: 24px;
    border-bottom: 1px solid #EBEBEB;
    color: #333;
    font-size: 20px;
    .close {
      position: absolute;
      top: 0;
      right: 36px;
      display: flex;
      align-items: center;
      height: 20px;
      font-size: 14px;
      cursor: pointer;
      .anticon-close {
        margin-left: 4px;
      }
    }
  }
  .box {
    height: 468px;
    padding-top: 24px;
    padding-right: 24px;
    overflow-y: auto;
    .sub-title {
      color: #333;
      margin: 24px 0 12px;
    }
    .text {
      margin-bottom: 12px;
    }
  }
}
</style>
<template>
  <div @click="showPreviewDia(data)" class="module-box">
    <div class="title">{{ data.title }}</div>
    <div class="text">{{ data.subTitle1 }}</div>
    <ul>
      <li class="text" v-for="(item, idx) in data.list" :key="idx" v-html="item"></li>
      <li v-if="data.id != 3" class="text">将佣金的<span class="box">{{ fanliRate }}%</span>作为返利<span v-if="data.id != 2"></span></li>
    </ul>
    <div class="tips" v-if="data.subTitle2">{{ data.subTitle2 }}</div>
    <a v-if="accountType === 2 && data.id != 3" href="javascript:void(0);" class="preview-btn disable" >不可使用该模版</a>
    <a v-else href="javascript:void(0);" :style="{bottom: accountType === 2 && data.id != 3 ? '54px' : '36px'}" class="preview-btn" >预览</a>
    <div class="tip" v-if="accountType === 2 && data.id != 3">使用自有联盟账户时，不支持返利</div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    },
    fanliRate: {},
    accountType: {
      type: Number,
      default: null
    }
  },
  data () {
    return {}
  },
  methods: {
    showPreviewDia (data) {
      if (this.accountType === 2 && data.id != 3) return
      const dataObj = {
        previewId: data.id,
        imgNum: data.imgNum,
        showPreview: true,
        sizeStatus: data.id == 3 ? '4' : '2'
      }
      this.$emit('showPreviewDia', dataObj)
    }
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
.module-box {
  position: relative;
  width: 522px;
  height: 362px;
  background: #fff;
  border-radius: 8px;
  padding: 36px;
  margin: 0 36px 36px 0;
  box-shadow: 0px 8px 20px 0px #DCE3F0;
  color: #5C6485;
  font-size: 14px;
  cursor: pointer;
  &:nth-child(2n) {
    margin-right: 0;
  }
  .title {
    height: 28px;
    line-height: 28px;
    margin-bottom: 24px;
    font-weight: 500;
    color: #333847;
    font-size: 20px;
  }
  ul {
    padding-left: 18px;
    margin-bottom: 0;
    li.text:last-child {
      margin-bottom: 0;
    }
  }
  .text {
    height: 20px;
    line-height: 20px;
    margin-bottom: 12px;
    span {
      &.box {
        color: #4392F6;
        opacity: 1;
      }
    }
  }
  .preview-btn {
    position: absolute;
    bottom: 54px;
    width: 450px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    // margin-top: 42px;
    border-radius: 4px;
    background: #F3F6FC;
    color: #4392F6;
    font-size: 18px;
    &.disable {
      color: #ADB1C3;
    }
  }
  &.selected {
    position: relative;
    border: 1px solid #4392F6;
    box-sizing: border-box;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-radius: 0 7px;
      border-top: 30px solid #4392F6;
      border-right: 30px solid #4392F6;
      border-bottom: 30px solid transparent;
      border-left: 30px solid transparent;
    }
    &::before {
      content: "";
      position: absolute;
      right: 5px;
      top: 10px;
      width: 20px;
      height: 10px;
      border-left: 3px solid #FFF;
      border-bottom: 3px solid #FFF;
      border-radius: 2px;
      transform: rotate(-45deg);
      z-index: 1;
    }
  }
  .tips{
    margin-top:12px;
    line-height:20px;
    font-size:14px;
    color:#ADB1C3;
  }
  &.disable {
    opacity: 0.6;
  }
  .tip {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    height: 20px;
    line-height: 20px;
    // margin-top: 14px;
    text-align: center;
    color: #FF7A00;
    font-size: 14px;
  }
}
</style>

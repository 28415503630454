<template>
  <a-layout-sider class="slider">
    <a class="logo" href="javascript:void(0);" @click="pathTo('/dashboard')">
      <img src="../../images/logo-cur.png" alt="">
    </a>
    <div class="space-box"></div>
    <div class="menu-box">
      <a-menu mode="inline" :selected-keys="[selectedMenu]">
        <a-menu-item-group title="账号看板">
          <a-menu-item key="/dashboard" @click="pathTo('/dashboard')">
            <svg-icon :icon-class="selectedMenu == '/dashboard' ? 'data-report-active' : 'data-report'"></svg-icon>
            <span class="nav-text">数据总览</span>
          </a-menu-item>
          <a-menu-item key="/growth" @click="pathTo('/growth')">
            <svg-icon :icon-class="selectedMenu == '/growth' ? 'growth-active' : 'growth'"></svg-icon>
            <span class="nav-text">增长服务</span>
          </a-menu-item>
          <a-menu-item key="/channelManagement" @click="pathTo('/channelManagement')">
            <svg-icon :icon-class="selectedMenu == '/channelManagement' ? 'channel-active' : 'channel'"></svg-icon>
            <span class="nav-text">渠道管理</span>
          </a-menu-item>
          <a-menu-item key="/accountType" @click="pathTo('/accountType')" v-if="accountTypeSwitch === 1">
            <svg-icon :icon-class="selectedMenu == '/accountType' ? 'accountType-active' : 'accountType'"></svg-icon>
            <span class="nav-text">账户类型</span>
          </a-menu-item>
          <a-menu-item key="/income" @click="pathTo('/income')">
            <svg-icon :icon-class="selectedMenu == '/income' ? 'income-active' : 'income'"></svg-icon>
            <span class="nav-text">我的收入</span>
          </a-menu-item>
        </a-menu-item-group>
        <a-menu-item-group title="功能设置">
          <a-menu-item key="/bottommenu" @click="pathTo('/bottommenu')">
            <svg-icon :icon-class="selectedMenu == '/bottommenu' ? 'bottom-menu-active' : 'bottom-menu'"></svg-icon>
            <span class="nav-text">底部菜单</span>
          </a-menu-item>
          <a-menu-item key="/welcomes" @click="pathTo('/welcomes')">
            <svg-icon :icon-class="selectedMenu == '/welcomes' ? 'welcomes-active' : 'welcomes'"></svg-icon>
            <span class="nav-text">欢迎语</span>
          </a-menu-item>
          <a-menu-item key="/interaction" @click="pathTo('/interaction')">
            <svg-icon :icon-class="selectedMenu == '/interaction' ? 'interaction-active' : 'interaction'"></svg-icon>
            <span class="nav-text">下单互动</span>
          </a-menu-item>
          <a-menu-item key="/hongbao" @click="pathTo('/hongbao')">
            <svg-icon :icon-class="selectedMenu == '/hongbao' ? 'hongbao-active' : 'hongbao'"></svg-icon>
            <span class="nav-text">红包设置</span>
            <!-- <span class="dot" v-if="!updateDotStatus">·</span> -->
          </a-menu-item>
          <a-menu-item key="/sign" @click="pathTo('/sign')">
            <svg-icon :icon-class="selectedMenu == '/sign' ? 'sign-active' : 'sign'"></svg-icon>
            <span class="nav-text">每日签到</span>
          </a-menu-item>
          <a-menu-item key="/mealreminder" @click="pathTo('/mealreminder')">
            <svg-icon :icon-class="selectedMenu == '/mealreminder' ? 'mealreminder-active' : 'mealreminder'"></svg-icon>
            <span class="nav-text">吃饭提醒</span>
          </a-menu-item>
          <a-menu-item key="/skinbox" @click="pathTo('/skinbox')">
            <svg-icon :icon-class="selectedMenu == '/skinbox' ? 'skinbox-active' : 'skinbox'"></svg-icon>
            <span class="nav-text">皮肤模板</span>
          </a-menu-item>
          <a-menu-item key="/invigorate" @click="pathTo('/invigorate')">
            <svg-icon :icon-class="selectedMenu == '/invigorate' ? 'invigorate-active' : 'invigorate'"></svg-icon>
            <span class="nav-text">保持活跃</span>
          </a-menu-item>
        </a-menu-item-group>
        <!-- <a-menu-item-group title="我的钱包">
        </a-menu-item-group> -->
        <a-menu-item-group title="增值服务" v-if="shichiSwitch == 1">
          <a-menu-item key="/shichituan" @click="pathTo('/shichituan')">
            <svg-icon :icon-class="selectedMenu == '/shichituan' ? 'shichituan-active' : 'shichituan'"></svg-icon>
            <span class="nav-text">试吃团</span>
          </a-menu-item>
        </a-menu-item-group>
        <a-menu-item-group title="营销素材">
          <a-menu-item key="/apppath" @click="pathTo('/apppath')">
            <svg-icon :icon-class="selectedMenu == '/apppath' ? 'apppath-active' : 'apppath'"></svg-icon>
            <span class="nav-text">我的路径</span>
          </a-menu-item>
        </a-menu-item-group>
      </a-menu>
    </div>
  </a-layout-sider>
</template>

<script>
export default {
  name: 'Slider',
  props: ['shichiSwitch', 'accountTypeSwitch'],
  data () {
    return {
    }
  },
  computed: {
    selectedMenu () {
      return this.$route.path
    }
  },
  methods: {
    pathTo (path) {
      this.$router.replace(path)
    },
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
.slider {
  flex: 0 0 240px !important;
  max-width: 240px !important;
  min-width: 240px !important;
  width: 240px !important;
  background-color: #FEFFFF;
  .menu-box {
    height: calc(100vh - 60px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .space-box {
    height: 60px;
  }
  .logo {
    position: fixed;
    top: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    width: 240px;
    height: 60px;
    padding-left: 24px;
    border-right: 1px solid #F3F6FC;
    background: #FEFFFF;
    img {
      width: 137px;
      height: 40px;
    }
  }
  .title {
    height: 20px;
    line-height: 20px;
    color: #ADB1C3;
    font-size: 14px;
  }
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none;
}
.ant-menu-item {
  display: flex;
  align-items: center;
  &::after {
    border-right: 0;
  }
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: #4392F6;
  background: #F3F6FC;
}
.ant-menu-item-selected {
  &::after {
    left: 0;
    right: auto;
    border: none;
    border-left: 2px solid #4392F6;
  }
}
.ant-menu-item-group {
  /deep/ .ant-menu-item-group-title, .ant-menu-item{
    padding: 0;
    padding-left: 24px;
    height: 60px;
    line-height: 60px;
  }
}
span.nav-text {
  font-size: 16px;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #E6E6FA;
}
::-webkit-scrollbar-track {
  background: #F3F6FC;
}
</style>

import { render, staticRenderFns } from "./dialog2.vue?vue&type=template&id=dde83612&scoped=true&"
import script from "./dialog2.vue?vue&type=script&lang=js&"
export * from "./dialog2.vue?vue&type=script&lang=js&"
import style0 from "./dialog2.vue?vue&type=style&index=0&id=dde83612&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dde83612",
  null
  
)

export default component.exports
<template>
  <div class="income">
    <div class="incom-info">
      <div class="info-left">
        <div class="info-item">
          <div class="title">
            <span>饿了么待结算收入</span>
            <a-popover placement="bottom">
              <template slot="content">
                <p class="income-tips">饿了么尚未结算的所有订单的收入，每月25日结算上个自然月账单</p>
              </template>
              <a-icon type="info-circle" />
            </a-popover>
          </div>
          <div class="price">{{ eleWaitSet }}元</div>
          <div class="date">{{ elmDay }}</div>
        </div>
        <div class="info-item">
          <div class="title">
            <span>美团待结算收入</span>
            <a-popover placement="bottom">
              <template slot="content">
                <p class="income-tips">美团尚未结算的所有订单的收入，每月25日结算上个自然月账单</p>
              </template>
              <a-icon type="info-circle" />
            </a-popover>
          </div>
          <div class="price">{{ mtWaitSet }}元</div>
          <div class="date">{{ mtDay }}</div>
        </div>
        <div class="info-item">
          <div class="title">
            <span>可提现收入</span>
            <a-popover placement="bottom">
              <template slot="content">
                <p class="income-tips">已结算未提现的收入总和。根据饿了么平台规则，已扣除10%平台服务费</p>
              </template>
              <a-icon type="info-circle" />
            </a-popover>
          </div>
          <div class="price">{{ canTxTotal }}元</div>
        </div>
        <a href="javascript:void(0);" class="info-tx-btn" @click="showTxDialogHandler">提现</a>
      </div>
      <div class="info-right">
        <div class="title">
            <span>历史累计收入</span>
            <a-popover placement="bottom">
              <template slot="content">
                <p class="income-tips">历史累计已结算的总收入</p>
              </template>
              <a-icon type="info-circle" />
            </a-popover>
          </div>
          <div class="price">{{ totalMoney }}元</div>
          <div class="date">截至今天</div>
      </div>
    </div>
    <div class="income-excel">
      <!-- 加载loading Toast -->
      <div class="loading" v-if="loadingFlag">
        <img src="./images/excel-loading.gif" alt="" class="loading-icon">
      </div>
      <div class="excel-title">
        <div class="title-left">
          <a href="javascript:void(0);" @click="checkTab(1)" :class="['left-item', selectItem == 1 ? 'item-current' : '']">CPS订单明细</a>
          <a href="javascript:void(0);" @click="checkTab(3)" :class="['left-item', selectItem == 3 ? 'item-current' : '']">CPA订单明细</a>
          <a href="javascript:void(0);" @click="checkTab(2)" :class="['left-item',selectItem == 2 ? 'item-current' : '']">提现明细</a>
        </div>
        <div class="title-right">
          <!-- <download-excel class="export-excel-wrapper"
            :data="selectItem == 1 ? orderData : txdata"
            :fields="selectItem == 1 ? srFields : txFields"
            :name="selectItem == 1 ? '订单明细.xls' : '提现明细.xls'"
          >
            <a href="javascript:void(0);" class="right-download">导出所有{{ selectItem == 1 ? '订单' : '提现' }}记录</a>
          </download-excel> -->
        </div>
      </div>
      <div class="excel-title">
        <div class="title-left mt-10">
          <a-space class="title-space">
            日期范围
            <a-select v-model="searchConditionDate" @change="selectSearchDate" style="width: 130px;">
              <a-select-option value="1">今天</a-select-option>
              <a-select-option value="2">近7天</a-select-option>
              <a-select-option value="3">近30天</a-select-option>
              <a-select-option value="4">近90天</a-select-option>
              <a-select-option value="5">自定义时间</a-select-option>
              <a-select-option value="6">有史以来</a-select-option>
            </a-select>
          </a-space>
          <a-space class="title-space" v-if="searchConditionCustom">
            自定义
            <a-range-picker
              v-model="searchConditionRange"
              :locale="locale"
              :allowClear="false"
              :disabledDate="disabledDate"
              class="calendar-custome"
            />
          </a-space>
          <a-space class="title-space">
            状态筛选
            <a-select v-if="selectItem === 2" v-model="searchConditionCashStatus" style="width: 130px;">
              <a-select-option value="0">全部</a-select-option>
              <a-select-option value="1">待开票</a-select-option>
              <a-select-option value="2">提现中</a-select-option>
              <a-select-option value="3">审核中</a-select-option>
              <a-select-option value="4">提现成功</a-select-option>
              <a-select-option value="5">提现失败</a-select-option>
            </a-select>
            <a-select v-else v-model="searchConditionOrderStatus" style="width: 130px;">
              <a-select-option value="0">全部</a-select-option>
              <a-select-option value="1">已下单</a-select-option>
              <a-select-option value="2">支付成功</a-select-option>
              <a-select-option value="3">确认收货</a-select-option>
              <a-select-option value="4">订单失效</a-select-option>
              <a-select-option value="5">已结算</a-select-option>
            </a-select>
          </a-space>
          <a-space v-if="selectItem !== 2" class="title-space">
            订单
            <a-input v-model.trim="searchOrderNo" placeholder="输入订单号查询" />
          </a-space>
        </div>
        <div class="title-right">
          <a-space>
            <a-button
              type="primary"
              class="title-button-w"
              @click="searchOrder"
            >查询</a-button>
            <a-button
              type="primary"
              class="title-button-w"
              v-if="selectItem !== 2"
              @click="exportExcel(selectItem)"
            >导出数据</a-button>
          </a-space>
        </div>
      </div>
      <div v-if="over1w" class="over1w">{{ over1w }}</div>
      <a-table v-if="selectItem == 1 || selectItem == 3" :pagination="paginationA" rowKey="orderid_parent" :columns="orderColumns" :data-source="orderData" @change="getOrderInfo" bordered>
        <template
          slot="order_status"
          slot-scope="text"
        >
          <div>{{ text == 0 ? '已下单' : text == 2 ? '支付成功' : (text == 3 || text == 5) ? '确认收货' : text == 4 ? '订单失效' : text == 6 ? '已结算' : '' }}</div>
        </template>
        <template
          slot="orderid_parent"
          slot-scope="text, record"
        >
          <a
            href="javascript:void(0)"
            :data-clipboard-target="`.copy-content-${record.orderid_parent}`"
            class="table-copy" :class="`copy-btn-${record.orderid_parent}`"
          >
            <i :class="record.shopid == 712 ? 'elm' : 'mt'"></i>
            <span :class="`copy-content-${record.orderid_parent}`">{{text}}</span>
          </a>
        </template>
      </a-table>
      <a-table v-if="selectItem == 2" :columns="txcolumns" :data-source="txdata" rowKey="id" :pagination="paginationB" @change="getTxRecordList" bordered>
        <template
          slot="withdrawal_status"
          slot-scope="text"
        >
        {{ text == 1 ? '待开票' : text == 2 ? '提现中' : text == 3 ? '审核中' : text == 4 ? '提现成功' : text == 5 ? '提现失败' : '' }}
        </template>
        <template
          slot="remark_status"
          slot-scope="text, record"
        >
          <a href="javascript:void(0);" @click="listItemHandler(record)">
            {{ text == 1 ? '填写发票信息' : text == 2 ? '提现到银行卡' + record.intercept_bank_card_number : text == 3 ? '查看发票邮寄地址' : text == 4 || text == 5 ? '查看详情' : '' }}
          </a>
        </template>
      </a-table>
    </div>
    <statusDialog
      @closeStatusDialog="closeStatusDialog"
      :show-status-dialog="showStatusDialog"
      :dialog-status="dialogStatus"
    ></statusDialog>
    <DialogStepOne
      v-if="isShowDialogStepOne"
      @closeDialogStepOne="closeDialogStepOne"
      @changRadioType="changRadioType"
      :radio-type="radioType"
      :can-tx="canTx"
      :tx-amount="amount"
      :with-draw-id="withDrawId"
    ></DialogStepOne>

    <DialogStepTwo
      v-if="isShowDialogStepTwo"
      @closeDialogStepTwo="closeDialogStepTwo"
    ></DialogStepTwo>

    <DialogStepThree
      v-if="isShowDialogStepThree"
      @closeDialogStepThree="closeDialogStepThree"
      :radio-type="radioType"
      :amount="amount"
      :after-tax-amount="afterTaxAmount"
    ></DialogStepThree>

    <DialogStepFour
      v-if="isShowDialogStepFour"
      :radio-type="radioType"
      :after-tax-amount="afterTaxAmount"
      :with-draw-status="withDrawStatus"
      :invoice-info="invoiceInfo"
      :faild-reason="faildReason"
      :faild-reason-other="faildReasonOther"
      :payee="payee"
      @closeDialogStepFour="closeDialogStepFour"
    ></DialogStepFour>

    <DialogStepFive
      v-if="isShowDialogStepFive"
      @closeDialogStepFive="closeDialogStepFive"
      :amount="amount"
      :after-tax-amount="afterTaxAmount"
      :radio-type="radioType"
      :is-first-tx="isFirstTx"
      :payee="payee"
      :with-draw-id="withDrawId"
      :invoice-title="invoiceTitle"
    ></DialogStepFive>

    <export-excel-dia
      v-if="isShowExportExcelDia"
      :excelArr="excelArr"
      :totalCount="totalCount"
      :excelType="selectItem"
      @closeExcelDialog="closeExcelDialog"
    ></export-excel-dia>

    <div class="mask" v-if="isShowDialogStepOne || isShowDialogStepTwo || isShowDialogStepThree || isShowDialogStepFour || isShowDialogStepFive || isShowExportExcelDia"></div>
  </div>
</template>

<script>
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/globalConst'
import ClipboardJS from 'clipboard'
import DialogStepOne from './components/dialog1'
import DialogStepTwo from './components/dialog2'
import DialogStepThree from './components/dialog3'
import DialogStepFour from './components/dialog4'
import DialogStepFive from './components/dialog5'
import ExportExcelDia from './components/exportExcelDia.vue'
import statusDialog from '@/components/statusDialog/statusDialog'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import 'moment/locale/zh-cn'
import moment from 'moment'
import {
  initPagePost,
  getOrderInfoPost,
  txRecordPost,
  exportExcelPost,
  checkDownloadLink
} from '@/api/income'

const txcolumns = [
  {
    title: '提现时间',
    dataIndex: 'insert_at',
    scopedSlots: { customRender: 'insert_at' },
    width:'206px'
  },
  {
    title: '提现金额(元)',
    dataIndex: 'withdrawal_amount',
    scopedSlots: { customRender: 'withdrawal_amount' },
    width:'206px'
  },
  {
    title: '到账金额(元)',
    dataIndex: 'after_tax_amount',
    scopedSlots: { customRender: 'after_tax_amount' },
    width:'206px'
  },
  {
    title: '状态',
    dataIndex: 'withdrawal_status',
    scopedSlots: { customRender: 'withdrawal_status' },
    width:'206px'
  },
  {
    title: '备注',
    dataIndex: 'remark_status',
    scopedSlots: { customRender: 'remark_status' },
    width:'206px'
  }
];
const orderColumns = [
  {
    title: '下单时间',
    dataIndex: 'buy_date',
    scopedSlots: { customRender: 'buy_date' },
    width:'230px'
  },
  {
    title: '订单额(元)',
    dataIndex: 'real_paid',
    scopedSlots: { customRender: 'real_paid' },
    width:'114px'
  },
  {
    title: '佣金(元)',
    dataIndex: 'yongjin',
    scopedSlots: { customRender: 'yongjin' },
    width:'116px'
  },
  {
    title: '让出返利(元)',
    dataIndex: 'fanli',
    scopedSlots: { customRender: 'fanli' },
    width:'114px'
  },
  {
    title: '预计收入(元)',
    dataIndex: 'estimated_revenue',
    scopedSlots: { customRender: 'estimated_revenue' },
    width:'116px'
  },
  {
    title: '订单状态',
    dataIndex: 'order_status',
    // slots: { title: 'zhuangtaiSlot' },
    scopedSlots: { customRender: 'order_status' },
    width:'116px'
  },
  {
    title: '订单号(点击复制)',
    dataIndex: 'orderid_parent',
    scopedSlots: { customRender: 'orderid_parent' },
    width:'220px'
  },
];
const cpaOrderColumns = [
  {
    title: '下单时间',
    dataIndex: 'buy_date',
    scopedSlots: { customRender: 'buy_date' },
    width:'230px'
  },
  {
    title: '订单额(元)',
    dataIndex: 'real_paid',
    scopedSlots: { customRender: 'real_paid' },
    width:'114px'
  },
  {
    title: '佣金(元)',
    dataIndex: 'yongjin',
    scopedSlots: { customRender: 'yongjin' },
    width:'116px'
  },
  // {
  //   title: '让出返利(元)',
  //   dataIndex: 'fanli',
  //   scopedSlots: { customRender: 'fanli' },
  //   width:'114px'
  // },
  // {
  //   title: '预计收入(元)',
  //   dataIndex: 'estimated_revenue',
  //   scopedSlots: { customRender: 'estimated_revenue' },
  //   width:'116px'
  // },
  {
    title: '订单状态',
    dataIndex: 'order_status',
    // slots: { title: 'zhuangtaiSlot' },
    scopedSlots: { customRender: 'order_status' },
    width:'116px'
  },
  {
    title: '订单号(点击复制)',
    dataIndex: 'orderid_parent',
    scopedSlots: { customRender: 'orderid_parent' },
    width:'220px'
  }
]
// 导出收入明细
const srFields = {
  "下单时间": "time",
  "订单额（元）": "dingdan",
  "佣金（元）": "yongjin",
  "预计收入(元)": "shouru",
  "让出返利（元）": "fanli",
  "状态": "zhuangtai",
  "订单号": "fuzhi"
}
// 导出提现明细
const txFields = {
  "发生时间": "insert_at",
  "状态": "withdrawal_status",
  "金额（元）": "withdrawal_amount",
  "备注": "beizhu"
}

export default {
  components: {
    statusDialog,
    DialogStepOne,
    DialogStepTwo,
    DialogStepThree,
    DialogStepFour,
    DialogStepFive,
    ExportExcelDia
  },
  data() {
    /**
     * dialogStatus
     * 根据dialog展示的不同操作显示不同状态的icon
     * 1、2 success 3 danger
     * 1 提交成功 2 支付成功 3 人数拥挤
     */
    return {
      locale,
      eleWaitSet: '', // 饿了么待结算
      mtWaitSet: '', // 美团待结算
      elmDay: '',
      mtDay: '',
      canTx: '', // 可提现
      canTxTotal: '',
      totalMoney: '', // 累计收入
      txdata: [],
      txcolumns,
      orderData: [],
      orderColumns,
      selectItem: 1,
      showStatusDialog: false,
      dialogStatus: 1,
      srFields: srFields,
      txFields: txFields,
      paginationA: {
        current: 1,
        pageSize: 20,
        total: 20
      },
      paginationB: {
        current: 1,
        pageSize: 20,
        total: 20
      },
      isShowDialogStepOne: false,
      isShowDialogStepTwo: false,
      isShowDialogStepThree: false,
      isShowDialogStepFour: false,
      isShowDialogStepFive: false,
      limitTxMoreClick: false,
      limitCopyMoreClick: false,
      radioType: null, // 选择税率单选
      isFirstTx: false, // 是否首次提现
      invoiceTitle: "", // 发票抬头
      invoiceInfo: {}, // 发票信息
      withDrawId: null,
      amount: null, // 提现金额
      afterTaxAmount: null, // 税后金额
      payee: {}, // 收款信息
      remarkInfo: {}, // 提现备注信息
      withDrawStatus: "", // 提现失败成功 4 成功 5 失败
      faildReason: "", // 提现失败原因
      faildReasonOther:"",//提现失败其他原因
      isShowExportExcelDia: false, // 导出多个excel表格时-弹窗
      excelArr: '',
      totalCount: '',
      loadingFlag: false,
      searchConditionDate: '1',
      searchConditionOrderStatus: '0',
      searchConditionCashStatus: '0',
      // 是否自定义时间段
      searchConditionCustom: false,
      searchConditionRange: [moment(), moment()],
      searchOrderNo: '',
      // 是否点击查询按钮
      clickedSearch: false,
      // 订单总数是否超过1万条
      over1w: '',
    };
  },
  computed: {
    orderInfoOpt () {
      if (!this.clickedSearch) {
        return {
          date_type: '1',
          status: '0'
        }
      }
      let range = {}
      if (this.searchConditionDate === '5') {
        range = {
          starttime: this.searchConditionRange[0].format('YYYY-MM-DD'),
          endtime: this.searchConditionRange[1].format('YYYY-MM-DD')
        }
      }
      let orderNo = {}
      if (this.searchOrderNo) {
        orderNo = {
          orderid_parent: this.searchOrderNo
        }
      }
      return {
        date_type: this.searchConditionDate,
        status: this.selectItem === 2 ? this.searchConditionCashStatus : this.searchConditionOrderStatus,
        ...range,
        ...orderNo,
      }
    }
  },
  mounted() {
    let that = this;
    that.initPage()
    that.getOrderInfo()
  },
  methods: {
    async initPage (fn) {
      const { status, data } = await initPagePost()
      if (status === 1) {
        this.eleWaitSet = data.eleme_settlement
        this.mtWaitSet = data.meituan_settlement
        this.canTx = data.withdrawable_income
        this.canTxTotal = data.withdrawable_income
        this.totalMoney = data.historical_accumulated_revenue
        this.elmDay = data.elm_account_period
        this.mtDay = data.meituan_account_period
        this.radioType = Number(data.payee_type)
        this.invoiceTitle = data.invoice_title
        this.isFirstTx = !!data.payee_type ? false : true
        this.payee = data.payee
        fn && fn()
      }
    },
    async getOrderInfo (current) {
      let ajaxData = {}
      if (current && current.current) {
        this.paginationA.current = current.current
        ajaxData = { page: this.paginationA.current }
      }
      ajaxData = Object.assign(ajaxData, {
        step: this.paginationA.pageSize,
        // 1 CPS订单 2 CPA订单
        order_type: this.selectItem == 1 ? 1 : 2,
        ...this.orderInfoOpt
      })
      const { status, data } = await getOrderInfoPost(ajaxData)
      if (status === 1) {
        this.over1w = data.show_tip
        this.paginationA.total = Number(data.total)
        this.orderData = data.order_info
        this.$nextTick(function(){
          for (let i = 0; i < this.orderData.length; i++) {
            this.initCopy(`.copy-btn-${this.orderData[i].orderid_parent}`)
          }
        })
      }
    },
    initCopy(elemStr){
      //在组件mounted钩子函数中实例化clipboard对象
      let ClipboardJSObj=new ClipboardJS(elemStr) //className中有copyEle的元素
      //定义复制成功回调
      ClipboardJSObj.on('success', (e) => {
        if (this.limitCopyMoreClick) return
          this.limitCopyMoreClick = true
          this.$message.success("复制成功", 1, () => {
            this.limitCopyMoreClick = false
          })
          e.clearSelection();
      });
      //定义复制失败回调
      ClipboardJSObj.on('error', function(e) {
      });
    },
    checkTab (num) {
      if (this.selectItem === num) return
      this.selectItem = num
      // 清空筛选条件
      this.clearSearchData()
      this.over1w = ''
      if (num === 1 || num === 3) {
        if (num == 3) {
          this.orderColumns = cpaOrderColumns
        } else {
          this.orderColumns = orderColumns
        }
        this.paginationA.current = 1
        this.getOrderInfo()
      }
      if (num === 2) {
        this.paginationB.current = 1
        this.getTxRecordList()
      }
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment()
    },
    selectSearchDate (val) {
      this.searchConditionCustom = val === '5'
      if (!this.searchConditionCustom) {
        this.searchConditionRange = [moment(), moment()]
      }
    },
    clearSearchData () {
      this.searchConditionRange = [moment(), moment()]
      this.searchConditionOrderStatus = '0'
      this.searchConditionCashStatus = '0'
      this.searchConditionDate = '1'
      this.searchConditionCustom = false
      this.clickedSearch = false
      this.searchOrderNo = ''
    },
    searchOrder () {
      this.clickedSearch = true
      if (this.selectItem === 2) {
        this.getTxRecordList()
      } else {
        this.getOrderInfo()
      }
    },
    showTxDialogHandler () {
      if (this.canTx < 1) {
        if (this.limitTxMoreClick) return
        this.limitTxMoreClick = true
        this.$message.error("最低可提现金额为1元", 1, () => {
          this.limitTxMoreClick = false
        })
        return
      }
      this.amount = ""
      this.withDrawId = ""
      this.isShowDialogStepOne = true
    },
    closeStatusDialog (val) {
      this.showStatusDialog = val
      this.initPage()
    },
    async getTxRecordList (current) {
      let ajaxData = {}
      if (current && current.current) {
        this.paginationB.current = current.current
      }
      ajaxData = {
        page: this.paginationB.current,
        step: this.paginationB.pageSize,
        ...this.orderInfoOpt
      }
      const { status, data } = await txRecordPost(ajaxData)
      if (status === 1) {
        this.paginationB.total = Number(data.total)
        this.txdata = data.record_info
      }
    },
    closeDialogStepOne (data) {
      this.isShowDialogStepOne = data.open
    
      if (data && data.next) {
        this.amount = data.withDraw.amount
        this.afterTaxAmount = data.withDraw.afterTaxAmount
        if (data.withDrawId) {
          this.withDrawId = data.withDrawId
        }
        
        if (this.radioType == 1) {
          this.isShowDialogStepFive = true
        } else {
          this.isShowDialogStepThree = true
        }
      } else {
        this.radioType = data.radioType
        this.initPage()
        if (this.selectItem == 2) return this.getTxRecordList()
      }
    },
    closeDialogStepTwo (value) {
      this.isShowDialogStepTwo = value
      this.initPage()
      if (this.selectItem == 2) return this.getTxRecordList()
    },
    closeDialogStepThree (data) {
      this.isShowDialogStepThree = data.open
      if (data && data.next === 'ok') {
        this.isShowDialogStepFive = true
      } else if (data && data.next === 'upstep') {
        this.radioType = data.radioType
        this.amount = data.amount
        this.isShowDialogStepOne = true
        this.initPage(() => {
          this.canTx = Number(this.canTx) + Number(data.amount)
        })
      } else {
        this.radioType = data.radioType
        this.initPage()
        if (this.selectItem == 2) return this.getTxRecordList()
      }
    },
    closeDialogStepFour (data) {
      this.isShowDialogStepFour = data.open
      this.withDrawStatus = data.status
      this.initPage()
      if (this.selectItem == 2) return this.getTxRecordList()
    },
    closeDialogStepFive (data) {
      this.isShowDialogStepFive = data.open
      if (data && data.next === "next") {
        this.radioType = data.radioType
        this.isShowDialogStepTwo = true
      } else if (data && data.next === "person") {
        this.isShowDialogStepFour = true
      } else {
        this.radioType = data.radioType
        this.initPage()
        if (this.selectItem == 2) return this.getTxRecordList()
      }
    },
    changRadioType (radioType) {
      this.radioType = radioType
    },
    listItemHandler (record) {
      this.withDrawId = record.id
      this.radioType = Number(record.payee_type)
      this.payee = record.payee
      this.invoiceInfo = record.invoice
      this.afterTaxAmount = record.after_tax_amount
      if (record.withdrawal_status == 1) {
        this.amount = record.withdrawal_amount
        this.isShowDialogStepThree = true
      } else if (record.withdrawal_status == 2) {

      } else if (record.withdrawal_status == 3) {
        this.isShowDialogStepTwo = true
      } else if (record.withdrawal_status == 4 || record.withdrawal_status == 5) {
        if (record.withdrawal_status == 5) {
          this.faildReason = record.faild_reason
          this.faildReasonOther = record.faild_remark
        }
        this.withDrawStatus = record.withdrawal_status
        this.isShowDialogStepFour = true
      }
    },
    closeExcelDialog (val) {
      if (!val) {
        this.isShowExportExcelDia = false
      }
    },
    async exportExcel (type) { // 导出表格
      this.loadingFlag = true
      this.clickedSearch = true
      // 1 导出CPS订单 2 导出CPA订单 3 导出提现明细
      const ajaxData = {
        download_type: type == 1 ? 1 : 2,
        ...this.orderInfoOpt
      }
      let { status, data, info } = await exportExcelPost(ajaxData)
      // const  { status, data, info } = {"data":{"total":"10","link":[{"title":"2021.04.22-2021.05.09","link":"http://saas.mtx.net.cn/income/download?download_id=2806d1742f116526b7f52f122c19928d&download_type=1"},{"title":"2021.04.15-2021.04.17","link":"http:\/\/saas.mtx.net.cn\/income\/download?download_id=195be11160fc1b2deb610a71c4a8d893&download_type=1"},{"title":"2021.04.11-2021.04.13","link":"http:\/\/saas.mtx.net.cn\/income\/download?download_id=a517c7a0a9cd0f5767b3261c8d3521ab&download_type=1"},{"title":"2021.04.09-2021.04.09","link":"https:\/\/saas.mtx.net.cn\/income\/download?download_id=6adcb872e44e53b55b4e3aff9afb386b&download_type=1"}]},"info":"success","status":1}
      // const  { status, data, info } = {"data":{"total":"10","link":[{"title":"2021.04.22-2021.05.09","link":"http://saas.mtx.net.cn/income/download?download_id=2806d1742f116526b7f52f122c19928d&download_type=1"}]},"info":"success","status":1}
      if (status == 1) {
        this.excelArr = data.link
        this.totalCount = data.total
        if (data.link.length == 1) { // 只有一张excel表格
          this.isShowExportExcelDia = false
          function randomString (len) {
            len = len || 32;
            let $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
            let maxPos = $chars.length;
            let pwd = '';
            for (let i = 0; i < len; i++) {
              pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
            }
            return pwd;
          }
          let randomStr = randomString(32)
          let time = new Date().getTime()
          let linkStr = data.link[0].link + `&ts=${time}&nonce=${randomStr}`
          let mtx_token = storage.get(ACCESS_TOKEN)

          let xhr = new XMLHttpRequest();
          let that = this
          xhr.open("get", linkStr);
          xhr.responseType = "blob";
          xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
          xhr.setRequestHeader("mtx_token", mtx_token);
          xhr.onreadystatechange = function() {
            if (xhr.readyState === 4 && xhr.status != 200) {
              that.$message.error("下载出错，请稍后重试")
              that.loadingFlag = false
            } else {
              if (xhr.readyState === 4 && xhr.status === 200) {
                let blob = new Blob([xhr.response], {type: "application/vnd.ms-excel;charset=utf-8"});
                let objectUrl = window.URL.createObjectURL(blob);

                // function getQueryVal (name) { // 根据responseURL获取filename
                //   var query = xhr.responseURL.split('?')[1]
                //   var para = query.split("&");
                //   for (var i = 0;i < para.length; i++) {
                //     var pair = para[i].split("=");
                //     if(pair[0] == name){return pair[1];}
                //   }
                //   return(false);
                // }
                // let filename = decodeURIComponent(getQueryVal('name'))
                let filename = ''
                try {
                  let contentDisposition = xhr.getResponseHeader('content-disposition');
                  if (contentDisposition) {
                    filename = decodeURIComponent(contentDisposition.split('=')[1]).replace(/^\"|\"+$/g, '');
                  }
                } catch (e) {}
                let a = document.createElement('a');
                a.setAttribute("href",objectUrl);
                a.setAttribute("download", filename);
                a.click()
                that.loadingFlag = false
              }
            }
          }
          xhr.send(null)
        } else if (data.link.length > 1) {
          this.isShowExportExcelDia = true
          this.loadingFlag = false
        } else {
          this.loadingFlag = false
          this.$message.error("暂无可下载数据")
        }
      } else {
          this.$message.error(info)
          this.loadingFlag = false
      }
    }
  }
};
</script>

<style lang="less" scoped>
.incom-info{
  display:flex;
  width:1080px;
  justify-content: space-between;
  .info-left{
    position: relative;
    display:flex;
    width:792px;
    height:157px;
    box-sizing: border-box;
    padding:24px 0;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px #DCE3F0;
    .info-item{
      flex:1;
      box-sizing: border-box;
      padding-left:24px;
      border-right:1px solid #EDF1F4;
      .title{
        display:flex;
        height:20px;
        align-items: center;
        font-size:14px;
        color:#5C6485;
        span{
          display:block;
        }
      }
      .price{
        margin-top:12px;
        line-height:39px;
        font-size:28px;
        color:#333847;
      }
      .date{
        margin-top:12px;
        line-height:20px;
        font-size:14px;
        color:#ADB1C3;
      }
    }
    .info-tx-btn{
      position:absolute;
      top:14px;
      right:14px;
      width:48px;
      height:40px;
      line-height:40px;
      text-align: center;
      font-size:14px;
      color:#4392F6;
    }
  }
  .info-right{
    display:block;
    width:264px;
    height:157px;
    padding:24px 0 0 24px;
    box-sizing: border-box;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px #DCE3F0;
    .title{
        display:flex;
        height:20px;
        align-items: center;
        font-size:14px;
        color:#5C6485;
        span{
          display:block;
        }
      }
      .price{
        margin-top:12px;
        line-height:39px;
        font-size:28px;
        color:#333847;
      }
      .date{
        margin-top:12px;
        line-height:20px;
        font-size:14px;
        color:#ADB1C3;
      }
  }

}
/deep/ .anticon.anticon-info-circle{
  display:block;
  margin-left:6px;
  width:14px;
  height:14px;
  color:#FFB751;
}
/deep/ .zhuangtai-title-box{
  display:flex;
  align-items: center;
}
/deep/ .zhuangtai-title{
  display:block;
}
.ant-popover-inner-content{
  padding:12px;
}
.income-tips{
  width:156px;
  margin:0;
}
.income-excel{
  position: relative;
  margin-top:24px;
  width:1080px;
  border-radius: 4px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px #DCE3F0;
  box-sizing: border-box;
  padding:19px 24px 24px;
  .loading {
    width: 164px;
    height: 134px;
    opacity: 0.6;
    border-radius: 12px;
    background: #000000;
    border-radius: 6px;
    position: absolute;
    z-index: 100;
    left: 50%;
    top: 130px;
    margin-left: -82px;
    .loading-icon {
      display: block;
      width: 164px;
      height: auto;
    }
  }
  .excel-title{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom:15px;
    .title-left{
      //flex:1;
      display:flex;
      line-height:20px;
      align-items: center;
      flex-wrap: wrap;
      &.mt-10 {
        margin-top: -10px;
      }
      .left-item{
        margin-right:36px;
        font-size:14px;
        color: #4392F6;
        &.item-current{
          color:#333847;
        }
      }
    }
    .title-right{
      flex:1;
      display:flex;
      height:30px;
      justify-content: flex-end;
      .right-download{
        position: relative;
        display: block;
        line-height:30px;
        font-size:14px;
        color:#4392F6;
        cursor: pointer;
        &:before{
          content:"";
          position:absolute;
          width:14px;
          height:14px;
          top:8px;
          left:-18px;
          background:url(./images/download.png) no-repeat;
          background-size:100% auto;
        }
      }
      .right-search{
        margin-left: 24px;
        /deep/ &.ant-input-search.ant-input-affix-wrapper{
        .ant-input{
          width:180px;
          padding-left:12px;
          border-radius:20px;
          height:30px;
          border:1px solid #ADB1C3;
          line-height:28px;
          background:transparent;
        }
      }
      }
    }
  }
  /deep/ .table-text{
    font-size:14px;
    color:#5C6485;
  }
  /deep/ .table-copy{
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:14px;
    color:#4392F6;
    .mt{
      display:block;
      width:14px;
      height:14px;
      margin-right:6px;
      background:url(./images/mt.png) no-repeat;
      background-size:100% auto;
    }
    .elm{
      display:block;
      width:14px;
      height:14px;
      margin-right:6px;
      background:url(./images/elm.png) no-repeat;
      background-size:100% auto;
    }
    span{
      display:block;
    }
  }
  /deep/ .table-time{
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:14px;
    color:#5C6485;
    .up{
      display:block;
      width:10px;
      height:12px;
      margin-right:6px;
      background:url(./images/up.png) no-repeat;
      background-size:100% auto;
    }
    .down{
      display:block;
      width:10px;
      height:12px;
      margin-right:6px;
      background:url(./images/down.png) no-repeat;
      background-size:100% auto;
    }
    span{
      display:block;
    }
  }
}

/deep/ .ant-table-bordered .ant-table-thead > tr > th, /deep/ .ant-table-bordered .ant-table-tbody > tr > td{
  text-align:center
}
/deep/ .ant-table-thead > tr > th {
  background: #F3F6FC;
}

.calendar-custome {
  width: 250px;
}
.title-space {
  margin: 10px 40px 0 0;
}
.title-button-w {
  width: 90px;
}
.over1w {
  line-height: 24px;
  padding-left: 36px;
  margin: 24px 0;
  color: #ff7a00;
  background: url(./images/tip.png) no-repeat left center;
  background-size: 24px 24px;
}
</style>

<template>
  <div class="sign">
    <contentHeader :is-show-tips="true" @checkSwitch="checkSwitch" :is-default-checked="isDefaultChecked" header-type="4"></contentHeader>
    <div class="content">
      <section class="step-wrap">
        <div class="step">第一步：补充会员卡库存</div>
        <div class="table-box">
          <div class="table-stock">
            <div class="stock-item">
              <p class="title">饿了么会员卡</p>
              <a v-if="!!kg_info && !!kg_info.elm" class="btn" :class="{'disable': kg_info.elm == '0'}" href="javascript:void(0)" @click="showAddStockDialog(1)">补充库存</a>
            </div>
            <div class="stock-item">
              <p class="title">库存剩余</p>
              <p class="subtitle">{{ storeInfo.elm_stock }}</p>
            </div>
            <div class="stock-item">
              <p class="title">累计已发</p>
              <p class="subtitle">{{ storeInfo.elm_grant_total }}</p>
            </div>
          </div>
          <div class="table-stock">
            <div class="stock-item">
              <p class="title">美团会员卡</p>
              <a v-if="!!kg_info && !!kg_info.mt" class="btn" :class="{'disable': kg_info.mt == '0'}" href="javascript:void(0)" @click="showAddStockDialog(2)">补充库存</a>
            </div>
            <div class="stock-item">
              <p class="title">库存剩余</p>
              <p class="subtitle">{{ storeInfo.mt_stock }}</p>
            </div>
            <div class="stock-item">
              <p class="title">累计已发</p>
              <p class="subtitle">{{ storeInfo.mt_grant_total }}</p>
            </div>
          </div>
          <div class="table-stock fetrue-stock">
            <div class="stock-item">
              <div class="title">
                <span>预计待发</span>
                <a-popover placement="bottom">
                  <template slot="content">
                    <p class="income-tips">目前为止已累计签到大于等于15天的用户数</p>
                  </template>
                  <a-icon type="info-circle" />
                </a-popover>
              </div>
              <p class="subtitle">{{ storeInfo.expected_total }}</p>
            </div>
          </div>
        </div>
        <div class="step">第二步：规则设置</div>
        <div class="rule-wrap">
          <div class="rule-text">
            <p class="rule-item" v-if="!rulesData.now && !rulesData.next">请设置送会员的规则，初次设置后当日生效，如需再次变更规则，新规则将在次月生效。</p>
            <p class="rule-item" v-else-if="rulesData.now">当前设置方案是我们推荐的方案，可根据运营需要变更规则，变更的新规则将在次月生效。</p>
            <p class="rule-item" v-else>更改设置的新规则将在次月生效，如未更改设置规则，下个月将继续沿用本月的规则。</p>
            <p class="rule-item">已经开始打卡的粉丝在他的当前打卡周期内不受规则更改的影响。</p>
            <p class="rule-item" v-if="!rulesData.now && !rulesData.next">如未更改下个月规则，将沿用上月设置的规则。</p>
            <p class="rule-item" v-else-if="rulesData.now && !rulesData.next">如未更改设置下个月规则，下个月将继续沿用本月的规则。</p>
            <p class="rule-item" v-else-if="rulesData.next" style="color: #FF7A00;">您更改设置的新规则：订单数{{rulesData.next.target_orders}}，签到天数{{rulesData.next.target_progress}}，将于{{rulesData.next.start_date}}生效。</p>
            <a-button class="set-btn" type="primary" @click="openChangeRuleDialog">{{ !rulesData.now && !rulesData.next ? '初次' : '更改' }}设置</a-button>
          </div>
          <div class="rule-data">
            <div class="data-item">
              <div class="text">订单数</div>
              <div class="number">{{!rulesData.now && !rulesData.next ? '待配置' : rulesData.now.target_orders}}</div>
            </div>
            <div class="data-item">
              <div class="text">签到天数</div>
              <div class="number">{{!rulesData.now && !rulesData.next ? '待配置' : rulesData.now.target_progress}}</div>
            </div>
          </div>
        </div>
      </section>

      <div class="income-excel">
        <!-- 加载loading Toast -->
        <div class="loading" v-if="loadingFlag">
          <img src="./images/excel-loading.gif" alt="" class="loading-icon">
        </div>
        <div class="excel-title">
          <div class="title-left">
            <a href="javascript:void(0);" @click="checkTab(1)" :class="['left-item', selectItem == 1 ? 'item-current' : '']">采购明细</a>
            <a href="javascript:void(0);" @click="checkTab(2)" :class="['left-item',selectItem == 2 ? 'item-current' : '']">发放明细</a>
          </div>
          <div class="title-right">
            <!-- <download-excel class="export-excel-wrapper"
              :data="selectItem == 1 ? cgdata : ffdata"
              :fields="selectItem == 1 ? cgFields : ffFields"
              :name="selectItem == 1 ? '采购明细.xls' : '发放明细.xls'"
            >
              <a href="javascript:void(0);" class="right-download">导出所有{{ selectItem == 1 ? '采购' : '发放' }}记录</a>
            </download-excel> -->
          </div>
        </div>
        <div class="excel-title">
          <div class="title-left mt-10">
            <a-space class="title-space">
              日期范围
              <a-select v-model="searchConditionDate" @change="selectSearchDate" style="width: 130px;">
                <a-select-option value="1">今天</a-select-option>
                <a-select-option value="2">近7天</a-select-option>
                <a-select-option value="3">近30天</a-select-option>
                <a-select-option value="4">近90天</a-select-option>
                <a-select-option value="5">自定义时间</a-select-option>
                <a-select-option value="6">有史以来</a-select-option>
              </a-select>
            </a-space>
            <a-space class="title-space" v-if="searchConditionCustom">
              自定义
              <a-range-picker
                v-model="searchConditionRange"
                :locale="locale"
                :allowClear="false"
                :disabledDate="disabledDate"
                class="calendar-custome"
              />
            </a-space>
            <a-space class="title-space">
              状态筛选
              <a-select v-if="selectItem === 2" v-model="searchConditionCashStatus" style="width: 130px;">
                <a-select-option value="0">全部</a-select-option>
                <a-select-option value="1">兑换中</a-select-option>
                <a-select-option value="2">兑换成功</a-select-option>
                <a-select-option value="3">兑换失败</a-select-option>
              </a-select>
              <a-select v-else v-model="searchConditionOrderStatus" style="width: 130px;">
                <a-select-option value="0">全部</a-select-option>
                <a-select-option value="1">待支付</a-select-option>
                <a-select-option value="2">已入库存</a-select-option>
                <a-select-option value="3">支付失败</a-select-option>
              </a-select>
            </a-space>
            <a-space v-if="selectItem === 2" class="title-space">
              手机
              <a-input v-model.trim="searchTelNo" placeholder="输入手机号查询订单" />
            </a-space>
          </div>
          <div class="title-right">
            <a-space>
              <a-button
                type="primary"
                class="title-button-w"
                @click="searchOrder"
              >查询</a-button>
              <a-button
                type="primary"
                class="title-button-w"
                @click="exportExcel(selectItem)"
              >导出数据</a-button>
            </a-space>
          </div>
        </div>
        <div v-if="over1w" class="over1w">{{ over1w }}</div>
        <a-table
          v-if="selectItem == 1"
          @change="getBuyDetail"
          :pagination="paginationA"
          :columns="cgcolumns"
          :data-source="cgdata"
          rowKey="id"
          bordered
        >
        </a-table>
        <a-table
          v-if="selectItem == 2"
          @change="getGrantList"
          :pagination="paginationB"
          :columns="ffcolumns"
          rowKey="id"
          :data-source="ffdata"
          bordered
        >
        </a-table>
      </div>
    </div>
    <div class="add-stock-dialog" v-if="isShowAddStockDialog">
      <div class="add-stock-dialog-content">
        <a href="javascript:void(0);" class="close-dia-btn" @click="closeAddStockDialog">返回</a>
        <p class="add-stock-dialog-title">补充库存</p>
        <p class="add-stock-dialog-subtitle">请输入您需要采购的{{stockType == 1?'饿了么':'美团'}}会员卡数量</p>
        <p class="add-stock-dialog-subtitle">支持使用微信及支付宝付款</p>
        <div class="add-stock-dialog-input">
          <span>{{stockType == 1?'饿了么':'美团'}}会员卡</span>
          <input type="number" v-model="cardNum" placeholder="0">
          <span>张x{{stockType == 1? elmCgPrice : mtCgPrice}}元={{ cardNum * (stockType == 1? elmCgPrice : mtCgPrice)}}元</span>
        </div>
        <div class="tips">会员卡单价或因渠道方调价有波动，请以实际价格为准</div>
        <div :class="['pay-box', {o: !parseInt(cardNum)}]">
          <a @click="getPayLink(2, 'stepBuy')" v-if="isShowWxPay == 1" class="wx" href="javascript:void(0);">微信支付</a>
          <a class="wx disable" href="javascript:void(0);" v-else>微信支付</a>
          <a @click="getPayLink(1, 'stepBuy')" class="zfb" href="javascript:void(0);">支付宝支付</a>
        </div>
      </div>
    </div>
    <statusDialog
      @closeStatusDialog="closeStatusDialog"
      :show-status-dialog="showStatusDialog"
      :dialog-status="dialogStatus"
    ></statusDialog>
    <div class="pay-dialog" v-if="isShowPayStatusDialog">
      <div class="dialog-main">
        <div class="icon"></div>
        <div class="text">是否已完成支付</div>
        <div class="btn-box">
          <a href="javascript:void(0);" class="yes" @click="payStatusHandler">是的</a>
          <a href="javascript:void(0);" class="no" @click="payStatusHandler">没有</a>
        </div>
      </div>
    </div>
    <!-- <div class="switch-open-check-dialog dialog" v-if="isShowSwitchDialog">
      <div class="dialog-left">
        <div class="dialog-title title">库存采购</div>
        <div class="purchase-item" :class="{ disable: kg_info.elm == 0 }">
          <div class="title">饿了么会员</div>
          <div class="subtext">请输入您需要采购的饿了么会员卡数量</div>
          <div class="subtext">支持使用微信及支付宝付款</div>
          <div class="subtext input-box">饿了么会员卡
            <input :disabled="kg_info.elm == 0" type="number" v-model="elmSwitchCardNum" placeholder="0">
            张x{{elmCgPrice}}元={{elmSwitchCardNum * elmCgPrice}}元
          </div>
          <div class="subtext gray">会员卡单价或因渠道方调价有波动，请以实际价格为准</div>
        </div>
        <div class="purchase-item" :class="{ disable: kg_info.mt == 0 }">
          <div class="title">美团会员{{kg_info.mt == 0 ? '（暂时缺货）' : ''}}</div>
          <div class="subtext">请输入您需要采购的美团会员卡数量</div>
          <div class="subtext">支持使用微信及支付宝付款</div>
          <div class="subtext input-box">美团会员卡
            <input :disabled="kg_info.mt == 0" v-model="mtSwitchCardNum" type="number" placeholder="0">
            张x{{mtCgPrice}}元={{mtSwitchCardNum * mtCgPrice}}元
          </div>
          <div class="subtext gray">会员卡单价或因渠道方调价有波动，请以实际价格为准</div>
        </div>
      </div>
      <div class="dialog-right">
        <a class="wx" @click="getPayLink(2, 'switchBuy')" :class="{o: disable}" href="javascript:void(0);">微信支付</a>
        <a class="zfb" @click="getPayLink(1, 'switchBuy')" :class="{o: disable}" href="javascript:void(0);">支付宝支付</a>
        <a class="close" @click="closeSwitchDialog" href="javascript:void(0);">关闭</a>
      </div>
    </div> -->
    <div class="set-rule-dialog dialog" v-if="isShowSetRuleDialog">
      <div class="dialog-left">
        <div class="dialog-title">签到送会员设置</div>
        <div class="rule-text" v-if="!rulesData.now && !rulesData.next">请设置送会员的规则，初次设置后当日生效，如需再次变更规则，新规则将在次月生效。已经开始打卡的粉丝在他的当前打卡周期内不受规则更改的影响。如未更改下个月规则，将沿用上月设置的规则。</div>
        <div class="rule-text" v-else>本月更改设置成功的新规则，将于{{nextStartDate}}生效</div>
        <div class="rule-item">
          <div class="item-title">订单数</div>
          <div class="item-text">订单数是指用户一个月内需要下多少单，我们的推荐值是0，这个参数设置的越高对用户来说就越难，效果会下降。</div>
          <div class="item-limit-mt">
            <a-checkbox @change="onChange" :checked="diyongSwitch">
              过滤美团低佣订单
              <a-popover placement="top" :overlayStyle="{width:'250px'}">
                <a-icon type="info-circle" />
                <template slot="content">美团联盟跟单策略调整，部分订单佣金将降至0.1%<br>勾选订单过滤后，领券超24小时使用的美团低佣订单不计入订单数<br>领会员页面规则将增加相关说明</template>
              </a-popover>
            </a-checkbox>
            <div class="item-limit-mt-tips">该功能勾选/取消后即时生效</div>
          </div>
          <div class="count-box">
            <a href="javascript:void(0);" class="count-item" @click="countDown('order', 'reduce')" :class="{ disable: changeRulesDataNext.target_orders == 0 }">-</a>
            <input class="count-num" type="number" @input="orderInput" v-model="changeRulesDataNext.target_orders" />
            <a class="count-item" @click="countDown('order', 'add')" :class="{ disable: changeRulesDataNext.target_orders == 60 }">+</a>
          </div>
          <div class="item-text" style="float: right; margin-bottom: 0;">最多可设置60单</div>
        </div>
        <div class="rule-item">
          <div class="item-title">签到天数</div>
          <div class="item-text">签到天数是指用户一个月内需要签到的天数，我们的推荐值是20，这个参数设置的越高对用户来说就越难，效果会下降。</div>
          <div class="count-box">
            <a href="javascript:void(0);" @click="countDown('day', 'reduce')" class="count-item" :class="{ disable: changeRulesDataNext.target_progress == 0 }">-</a>
            <input class="count-num" type="number" @input="dateInput" v-model="changeRulesDataNext.target_progress" />
            <a class="count-item" @click="countDown('day', 'add')" :class="{ disable: changeRulesDataNext.target_progress == 28 }">+</a>
          </div>
          <div class="item-text" style="float: right; margin-bottom: 0;">最多可设置28天</div>
        </div>
      </div>
      <div class="dialog-right">
        <a-button type="primary" class="submit-btn" @click="submitChangeRuleDialog">提交</a-button>
        <a-button class="close-btn" @click="closeChangeRuleDialog">关闭</a-button>
      </div>
    </div>
    <div class="set-rule-submit-dialog dialog" v-if="isShowSubmitRuleDialog">
      <div class="dialog-title">您将{{rulesData.now ? "更改" : "设置"}}设置为以下新规则</div>
      <div class="rule-data">
        <div class="data-item">
          <div class="text">订单数</div>
          <div class="number">{{changeRulesDataNext.target_orders}}</div>
        </div>
        <div class="data-item">
          <div class="text">签到天数</div>
          <div class="number">{{changeRulesDataNext.target_progress}}</div>
        </div>
      </div>
      <div class="set-date">{{ rulesData.now ? `本月更改设置成功的新规则，将于${nextStartDate}生效` : "" }}</div>
      <div class="btn-box">
        <a-button type="primary" class="submit-btn" @click="submitRuleDialog">确定</a-button>
        <a-button class="cancel-btn" @click="closeSubmitRuleDialog">取消</a-button>
      </div>
    </div>
    <Toast v-if="isShowToast" :set-info="toastText" :set-status="toastStatus"></Toast>
    <export-excel-dia
      v-if="isShowExportExcelDia"
      :excelArr="excelArr"
      :totalCount="totalCount"
      :excelType="selectItem"
      @closeExcelDialog="closeExcelDialog"
    ></export-excel-dia>
    <div class="mask" v-if="isShowSubmitRuleDialog || isShowSetRuleDialog || isShowAddStockDialog || isShowPayStatusDialog"></div>
  </div>
</template>

<script>
import contentHeader from '@/components/header/header'
import statusDialog from '@/components/statusDialog/statusDialog'
import Toast from '@/components/toast/toast'
import ExportExcelDia from '../income/components/exportExcelDia'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import 'moment/locale/zh-cn'
import moment from 'moment'
import { ACCESS_TOKEN } from '@/store/globalConst'
import {
  initPagePost,
  setModulePost,
  getBuyDetailPost,
  getPayLinkPost,
  getGrantListPost,
  getCgPricePost,
  setRulePost,
  exportExcelPost,
  setDiyongSwitch,
} from '@/api/sign'
import storage from 'store'

const ffcolumns = [
  {
    title: '发放时间',
    dataIndex: 'order_at',
    scopedSlots: { customRender: 'order_at' },
    width:'290px'
  },
  {
    title: '会员卡',
    dataIndex: 'type_name',
    scopedSlots: { customRender: 'type_name' },
    width:'146px'
  },
  {
    title: '发放到账号',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' },
    width:'150px'
  },
  {
    title: '数量',
    dataIndex: 'number',
    scopedSlots: { customRender: 'number' },
    width:'146px'
  },
  {
    title: '状态',
    dataIndex: 'status_name',
    scopedSlots: { customRender: 'status_name' },
    width:'294px'
  }
];

const cgcolumns = [
  {
    key: 1,
    title: '采购时间',
    dataIndex: 'buy_at',
    scopedSlots: { customRender: 'buy_at' },
    width:'290px'
  },
  {
    key: 2,
    title: '会员卡',
    dataIndex: 'type_name',
    scopedSlots: { customRender: 'type_name' },
    width:'146px'
  },
  {
    key: 3,
    title: '数量',
    dataIndex: 'buy_num',
    scopedSlots: { customRender: 'buy_num' },
    width:'150px'
  },
  {
    key: 4,
    title: '金额(元)',
    dataIndex: 'pay_amount',
    scopedSlots: { customRender: 'pay_amount' },
    width:'146px'
  },
  {
    key: 5,
    title: '状态',
    dataIndex: 'order_status_name',
    scopedSlots: { customRender: 'order_status_name' },
    width:'294px'
  },
];

// 导出采购明细
const cgFields = {
  "采购时间": "buy_at",
  "会员卡": "type_name",
  "数量": "buy_num",
  "金额(元)": "pay_amount",
  "状态": "order_status_name",
}
// 导出发放明细
const ffFields = {
  "发放时间": "order_at",
  "会员卡": "type_name",
  "发放到账号":"mobile",
  "数量": "number",
  "状态": "status_name",
}

export default {
  components: {
    contentHeader,
    statusDialog,
    Toast,
    ExportExcelDia,
  },
  data () {
    return {
      locale,
      ffdata: [],
      ffcolumns,
      cgdata: [],
      cgcolumns,
      selectItem: 1,
      isShowAddStockDialog: false,
      showStatusDialog: false,
      dialogStatus: 4,
      ffFields: ffFields,
      cgFields: cgFields,
      stockType:1,
      isDefaultChecked: null, // 控制是否开启开关
      diyongSwitch: null,
      storeInfo: {}, // 会员卡信息
      paginationA: {
        current: 1,
        pageSize: 20,
        total: 20
      },
      paginationB: {
        current: 1,
        pageSize: 20,
        total: 20
      },
      cardNum: 100, // 购买会员卡的数量
      isShowPayStatusDialog: false,
        //  limitMoreClick: false,
      kg_info: null,
      elmCgPrice:0,
      mtCgPrice:0,
      // isShowSwitchDialog: false, // 首次打开switch 补充库存 设置规则
      isShowSetRuleDialog: false, // 配置规则弹窗
      isShowSubmitRuleDialog: false, // 确定设置新规则弹窗
      // elmSwitchCardNum: 100, // 开关switch无库存 补充库存 饿了么卡片数量
      // mtSwitchCardNum: 100, // 开关switch无库存 补充库存 美团卡片数量
      // isFromSwitchDialog: "", // 打开开关补充库存成功返回进行设置规则
      rulesData: {}, // 初始化规则设置数据
      changeRulesDataNext: {}, // 重新设置次月规则
      toastText: "",
      toastStatus: 0,
      isShowToast: false,
      nextStartDate: "", // 更改规则下次生效时间
      submitRulePost: false, // 限制规则防并发
      searchConditionDate: '1',
      searchConditionOrderStatus: '0',
      searchConditionCashStatus: '0',
      searchTelNo: '',
      // 是否自定义时间段
      searchConditionCustom: false,
      searchConditionRange: [moment(), moment()],
      // 是否点击查询按钮
      clickedSearch: false,
      loadingFlag: false,
      isShowExportExcelDia: false, // 导出多个excel表格时-弹窗
      excelArr: '',
      totalCount: '',
      // 订单总数是否超过1万条
      over1w: '',
      isShowWxPay: 0
    }
  },
  computed: {
    orderInfoOpt () {
      if (!this.clickedSearch) {
        return {
          date_type: '1',
          status: '0'
        }
      }
      let range = {}
      if (this.searchConditionDate === '5') {
        range = {
          starttime: this.searchConditionRange[0].format('YYYY-MM-DD'),
          endtime: this.searchConditionRange[1].format('YYYY-MM-DD')
        }
      }
      let tel = {}
      if (this.searchTelNo) {
        tel = {
          mobile: this.searchTelNo
        }
      }
      return {
        date_type: this.searchConditionDate,
        status: this.selectItem === 2 ? this.searchConditionCashStatus : this.searchConditionOrderStatus,
        ...range,
        ...tel,
      }
    }
  },
  methods: {
    async initPage () {
      // this.isFromSwitchDialog = this.$route.query.is_from_switch_dialog
      // if (this.isFromSwitchDialog === "switchBuy") {
      //   this.isShowSetRuleDialog = true
      // }
      const { status, data } = await initPagePost()
      if (status == 1) {
        this.isDefaultChecked = data.switch
        this.storeInfo = data.info
        this.rulesData = data.rule
        this.nextStartDate = data.next_start_date
        this.changeRulesDataNext = data.rule.next ? data.rule.next : { target_orders: 0, target_progress: 20 }
        this.kg_info = data.kg_info
        this.isShowWxPay = data.is_show_wxpay
        this.diyongSwitch = data.diyong_switch?true:false
      }
    },
    checkTab (num) {
      if (this.selectItem === num) return

      // 清空筛选条件
      this.clearSearchData()
      this.over1w = ''
      if(num == 1){
        this.paginationA.current = 1
        this.getBuyDetail()
      } else {
        this.paginationB.current = 1
        this.getGrantList()
      }
      this.selectItem = num
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment()
    },
    selectSearchDate (val) {
      this.searchConditionCustom = val === '5'
      if (!this.searchConditionCustom) {
        this.searchConditionRange = [moment(), moment()]
      }
    },
    clearSearchData () {
      this.searchConditionRange = [moment(), moment()]
      this.searchConditionOrderStatus = '0'
      this.searchConditionCashStatus = '0'
      this.searchConditionDate = '1'
      this.searchConditionCustom = false
      this.clickedSearch = false
      this.searchTelNo = ''
    },
    searchOrder () {
      this.clickedSearch = true
      if (this.selectItem === 2) {
        this.getGrantList()
      } else {
        this.getBuyDetail()
      }
    },
    closeExcelDialog (val) {
      if (!val) {
        this.isShowExportExcelDia = false
      }
    },
    async exportExcel (type) { // 导出表格
      this.loadingFlag = true
      this.clickedSearch = true
      // 1 导出CPS订单 2 导出CPA订单 3 导出提现明细
      const ajaxData = {
        download_type: type == 1 ? 1 : 2,
        ...this.orderInfoOpt
      }
      let { status, data, info } = await exportExcelPost(ajaxData)
      if (status == 1) {
        this.excelArr = data.link
        this.totalCount = data.total
        if (data.link.length == 1) { // 只有一张excel表格
          this.isShowExportExcelDia = false
          function randomString (len) {
            len = len || 32;
            let $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
            let maxPos = $chars.length;
            let pwd = '';
            for (let i = 0; i < len; i++) {
              pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
            }
            return pwd;
          }
          let randomStr = randomString(32)
          let time = new Date().getTime()
          let linkStr = data.link[0].link + `&ts=${time}&nonce=${randomStr}`
          let mtx_token = storage.get(ACCESS_TOKEN)

          let xhr = new XMLHttpRequest();
          let that = this
          xhr.open("get", linkStr);
          xhr.responseType = "blob";
          xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
          xhr.setRequestHeader("mtx_token", mtx_token);
          xhr.onreadystatechange = function() {
            if (xhr.readyState === 4 && xhr.status != 200) {
              that.$message.error("下载出错，请稍后重试")
              that.loadingFlag = false
            } else {
              if (xhr.readyState === 4 && xhr.status === 200) {
                let blob = new Blob([xhr.response], {type: "application/vnd.ms-excel;charset=utf-8"});
                let objectUrl = window.URL.createObjectURL(blob);

                // function getQueryVal (name) { // 根据responseURL获取filename
                //   let contentDisposition = xhr.headers['content-disposition'];
                //   if (contentDisposition) {
                //     fileName = window.decodeURI(contentDisposition.split('=')[1], 'UTF-8');
                //   }
                //   return(false);
                // }
                // let filename = decodeURIComponent(getQueryVal('name'))
                let filename = ''
                try {
                  let contentDisposition = xhr.getResponseHeader('content-disposition');
                  if (contentDisposition) {
                    filename = decodeURIComponent(contentDisposition.split('=')[1]).replace(/^\"|\"+$/g, '');
                  }
                } catch (e) {}
                let a = document.createElement('a');
                a.setAttribute("href",objectUrl);
                a.setAttribute("download", filename);
                a.click()
                that.loadingFlag = false
              }
            }
          }
          xhr.send(null)
        } else if (data.link.length > 1) {
          this.isShowExportExcelDia = true
          this.loadingFlag = false
        } else {
          this.loadingFlag = false
          this.$message.error("暂无可下载数据")
        }
      } else {
        this.$message.error(info)
        this.loadingFlag = false
      }
    },
    closeAddStockDialog(){
      this.cardNum = 100
      this.isShowAddStockDialog = false;
    },
    showAddStockDialog(type){
      if((type == 1 && this.kg_info.elm == '0') || (type == 2 && this.kg_info.mt == '0')){
        return;
      }
      this.getCgPrice(type)
    },
    closeStatusDialog (val) {
      this.showStatusDialog = val
    },
    async checkSwitch (val) {
      const { status, data } = await setModulePost({ switch: val })
      if (status == 1) {
        this.isDefaultChecked = val
      } else {
        if (data.err_code == 20100) {
          this.$message.error("会员卡库存不足，请先前往补充库存")
          // this.isShowSwitchDialog = true
          // this.getCgPrice(3)
        } else if (data.err_code == 20103) {
          this.isShowSetRuleDialog = true
        }
      }
    },
    async getBuyDetail (current) {
      let page = null
      if (current) {
        page = current.current
        this.paginationA.current = current.current
      } else {
        page = this.paginationA.current
      }
      const { status, data } = await getBuyDetailPost({ page, ...this.orderInfoOpt })
      if (status == 1) {
        this.over1w = data.show_tip
        this.paginationA.total = data.total
        this.cgdata = data.list
      }
    },
    // getAjaxData (ajaxData) {
    //   const { kg_info, mtSwitchCardNum, elmSwitchCardNum } = this
    //   const elmInfo = {
    //     type: 1,
    //     buy_num: elmSwitchCardNum
    //   }
    //   const mtInfo = {
    //     type: 2,
    //     buy_num: mtSwitchCardNum
    //   }
    //   if (kg_info.mt == 1 && kg_info.elm == 1) {
    //     if (mtSwitchCardNum > 0 && elmSwitchCardNum > 0) {
    //       ajaxData = Object.assign(ajaxData, {
    //         card_info: [elmInfo, mtInfo]
    //       })
    //     } else if (mtSwitchCardNum > 0) {
    //       ajaxData = Object.assign(ajaxData, {
    //         card_info: [mtInfo]
    //       })
    //     } else if (elmSwitchCardNum > 0) {
    //       ajaxData = Object.assign(ajaxData, {
    //         card_info: [elmInfo]
    //       })
    //     }
    //   } else if (kg_info.mt == 1) {
    //     ajaxData = Object.assign(ajaxData, {
    //       card_info: [mtInfo]
    //     })
    //   } else if (kg_info.elm == 1) {
    //     ajaxData = Object.assign(ajaxData, {
    //       card_info: [elmInfo]
    //     })
    //   }
    // },
    async getPayLink (payType, btnPath) {
      // let ajaxData = { pay_type: payType }
      // if (btnPath === "stepBuy") {
      //   if (!parseInt(this.cardNum)) return
      //   ajaxData = Object.assign(ajaxData, {
      //     card_info: [{
      //       buy_num: this.cardNum,
      //       type: this.stockType
      //     }]
      //   })
      // }
      // if (btnPath === "switchBuy") {
      //   if (this.disable) return
      //   ajaxData = this.getAjaxData(ajaxData)
      // }
      if (!parseInt(this.cardNum)) return
      const ajaxData = {
        buy_num: this.cardNum,
        pay_type: payType,
        type: this.stockType
      }
      const { status, data, info } = await getPayLinkPost(ajaxData)
      if (status === 1) {
        if (payType === 1) {
          this.openLink(data.pay_link)
        } else {
          const { href } = this.$router.resolve({ path: '/wxpay', query: { out_order_no: data.out_order_no } });
          this.openLink(href)
        }
        this.isShowAddStockDialog = false
        this.isShowPayStatusDialog = true
      } else {
        this.$message.error(info)
      }
      this.closeAddStockDialog()
    },
    async getGrantList (current) {
      if (current) {
        this.paginationB.current = current.current
      }
      let ajaxData = {
        page: this.paginationB.current,
        ...this.orderInfoOpt
      }
      const { status, data } = await getGrantListPost(ajaxData)
      if (status === 1) {
        this.over1w = data.show_tip
        this.paginationB.total = data.total
        this.ffdata = data.list
      }
    },
    async getCgPrice (type) {
      let ajaxData = {}
      if(type == 1){
        ajaxData = { shop_name: 'elm' }
      } else if (type == 2){
        ajaxData = { shop_name: 'mt' }
      }
      const { status, data } = await getCgPricePost(ajaxData)
      if (status == 1) {
        if(type == 1){
          this.elmCgPrice = data.elm
        } else if(type == 2){
          this.mtCgPrice = data.mt
        }

        this.stockType = type
        this.isShowAddStockDialog = true
      }
    },
    async onChange () {
      let ajaxData = {diyong_switch : this.diyongSwitch?0:1}
      
      const { status, info } = await setDiyongSwitch(ajaxData)
      if (status == 1) {
        this.diyongSwitch = !this.diyongSwitch
      } else {
        this.$message.error(info)
      }
    },
    payStatusHandler () {
      this.initPage()
      this.getBuyDetail()
      this.isShowPayStatusDialog = false
    },
    openLink(url, target) {
      let a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('target', target || '_blank');
      document.body.appendChild(a);
      a.click();
    },
    // closeSwitchDialog () {
    //   this.isShowSwitchDialog = false
    //   this.elmSwitchCardNum = 100
    //   this.mtSwitchCardNum = 100
    // },
    openChangeRuleDialog () {
      this.isShowSetRuleDialog = true
    },
    closeChangeRuleDialog () {
      const { rulesData } = this
      this.changeRulesDataNext = rulesData.next ? rulesData.next : { target_orders: 0, target_progress: 20 }
      this.isShowSetRuleDialog = false
    },
    submitChangeRuleDialog () {
      const { changeRulesDataNext } = this
      if (changeRulesDataNext.target_progress == 0 && changeRulesDataNext.target_orders == 0) {
        this.toastText = "请至少设置一项规则参数大于0"
        this.toastStatus = 0
        this.isShowToast = true
        setTimeout(() => {
          this.isShowToast = false
        }, 2000);
        return
      }
      this.isShowSetRuleDialog = false
      this.isShowSubmitRuleDialog = true
    },
    countDown (rule, type) {
      const { changeRulesDataNext } = this
      if (rule === "order") {
        if (type === "add") {
          if (changeRulesDataNext.target_orders == 60) return
          this.changeRulesDataNext.target_orders++
        } else {
          if (changeRulesDataNext.target_orders == 0) return
          this.changeRulesDataNext.target_orders--
        }
      } else {
        if (type === "add") {
          if (changeRulesDataNext.target_progress == 28) return
          this.changeRulesDataNext.target_progress++
        } else {
          if (changeRulesDataNext.target_progress == 0) return
          this.changeRulesDataNext.target_progress--
        }
      }
    },
    async submitRuleDialog () {
      if (this.submitRulePost) return
      this.submitRulePost = true
      const { rulesData, changeRulesDataNext } = this
      const ajaxData = {
        target_orders: changeRulesDataNext.target_orders,
        target_progress: changeRulesDataNext.target_progress
      }
      const { status, info } = await setRulePost(ajaxData)
      if (status === 1) {
        this.toastStatus = 1
        if (rulesData.now) {
          this.toastText = "规则更改成功"
        } else {
          this.toastText = "规则设置成功"
        }
      } else {
        this.toastStatus = 0
        this.toastText = info
      }
      this.isShowToast = true
      this.isShowSubmitRuleDialog = false
      setTimeout(() => {
        this.submitRulePost = false
        this.isShowToast = false
      }, 2000);
      this.initPage()
      // if (this.isFromSwitchDialog === "switchBuy") {
      //   this.$router.replace({path: '/sign'})
      // }
    },
    closeSubmitRuleDialog () {
      // if (this.isFromSwitchDialog === "switchBuy") {
      //   this.$router.replace({ path: '/sign' })
      // }
      const { rulesData } = this
      this.changeRulesDataNext = rulesData.next ? rulesData.next : { target_orders: 0, target_progress: 20 }
      this.isShowSubmitRuleDialog = false
    },
    orderInput () {
      const { changeRulesDataNext } = this
      if (changeRulesDataNext.target_orders > 60) {
        this.changeRulesDataNext.target_orders = 60
      } else if (changeRulesDataNext.target_orders == "") {
        this.changeRulesDataNext.target_orders = 0
      }
      this.changeRulesDataNext.target_orders = Math.round(changeRulesDataNext.target_orders)
    },
    dateInput () {
      const { changeRulesDataNext } = this
      if (changeRulesDataNext.target_progress > 28) {
        this.changeRulesDataNext.target_progress = 28
      } else if (changeRulesDataNext.target_progress == "") {
        this.changeRulesDataNext.target_progress = 0
      }
      this.changeRulesDataNext.target_progress = Math.round(changeRulesDataNext.target_progress)
    }
  },
  created () {
    this.initPage()
    this.getBuyDetail()
  }
}
</script>

<style lang="less" scoped>
.content {
  .step-wrap {
    width:1080px;
    height: 405px;
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px 0px #DCE3F0;
    padding: 24px;
    box-sizing: border-box;
    .step {
      height: 20px;
      line-height: 20px;
      margin-bottom: 12px;
      font-size: 14px;
      color: #333;
    }
    .rule-wrap {
      display: flex;
      justify-content: space-between;
      .rule-item {
        height: 20px;
        line-height: 20px;
        margin-bottom: 6px;
        font-size: 14px;
        color: #666;
        &:last-of-type {
          margin-bottom: 20px;
        }
      }
      .set-btn {
        width: 104px;
        height: 30px;
      }
      .rule-data {
        display: flex;
        width: 462px;
        height: 122px;
        padding: 23px;
        box-sizing: border-box;
        background: #f6f6f6;
        border-radius: 4px;
        .data-item {
          flex: 1;
          text-align: center;
          .text {
            height: 20px;
            line-height: 20px;
            margin-bottom: 9px;
            font-size: 14px;
            color: #666;
          }
          .number {
            height: 45px;
            line-height: 45px;
            color: #333;
            font-size: 32px;
            text-shadow: 0px 2px 8px 0px #338BDA;
          }
          &:first-child {
            border-right: 1px solid #EBEBEB;
          }
        }
      }
    }
  }
  .table-box {
    display: flex;
    width:100%;
    justify-content: space-between;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #F6F6F6;
    box-sizing: border-box;
    .step {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      margin-bottom: 12px;
      color: #333;
    }
    .table-stock{
      display:flex;
      width:416px;
      height:122px;
      box-sizing: border-box;
      padding:24px 0;
      border-radius: 4px;
      background: #F6F6F6;
      .stock-item{
        flex:1;
        height:77px;
        box-sizing: border-box;
        border-left:1px solid #EBEBEB;
        &:first-child{
          border:none;
        }
        .title{
          display:flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          line-height:20px;
          font-size:14px;
          color:#333847;
          span{
            display:block;
          }
        }
        .subtitle{
          margin-top:12px;
          margin-bottom: 0;
          text-align: center;
          line-height:45px;
          font-size:32px;
          color:#333847;
        }
        .btn{
          display:block;
          margin:20px auto 0;
          width:76px;
          height:30px;
          line-height:30px;
          text-align: center;
          font-size:14px;
          color:#FFF;
          border-radius: 4px;
          background: #4392F6;
          &.disable{
            background:#999;
          }
        }
      }
      &.fetrue-stock{
        width:144px;
      }
      /deep/ .anticon.anticon-info-circle{
        display:block;
        margin-left:6px;
        width:14px;
        height:14px;
        color:#FFB751;
      }
    }
  }
}
.income-tips{
  width:156px;
  margin:0;
}
.supply-btn {
  display: block;
  width: 76px;
  height: 30px;
  border-radius: 4px;
  background: #4392F6;
  text-align: center;
  line-height: 30px;
  color: #FFFFFF;
}

.income-excel{
  margin-top:24px;
  width:1080px;
  border-radius: 4px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px #DCE3F0;
  box-sizing: border-box;
  padding:19px 24px 24px;
  .excel-title{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom:15px;
    .title-left{
      //flex:1;
      display:flex;
      line-height:20px;
      align-items: center;
      flex-wrap: wrap;
      &.mt-10 {
        margin-top: -10px;
      }
      .left-item{
        margin-right:36px;
        font-size:14px;
        color:#4392F6;
        &.item-current{
          color:#333847;
        }
      }
    }
    .title-right{
      flex:1;
      display:flex;
      height:30px;
      justify-content: flex-end;
      .right-download{
        position: relative;
        display: block;
        line-height:30px;
        font-size:14px;
        color:#4392F6;
        &:before{
          content:"";
          position:absolute;
          width:14px;
          height:14px;
          top:8px;
          left:-18px;
          background:url(../income/images/download.png) no-repeat;
          background-size:100% auto;
        }
      }
      .right-search{
        margin-left: 24px;
        /deep/ &.ant-input-search.ant-input-affix-wrapper{
        .ant-input{
          width:180px;
          padding-left:12px;
          border-radius:20px;
          height:30px;
          border:1px solid #ADB1C3;
          line-height:28px;
          background:transparent;
        }
      }
      }
    }
  }
  /deep/ .status-again-btn{
    margin-left:6px;
  }
}
/deep/ .ant-table-bordered .ant-table-thead > tr > th, /deep/ .ant-table-bordered .ant-table-tbody > tr > td{
  text-align:center
}

.add-stock-dialog {
  .add-stock-dialog-content {
    position: absolute;
    top: 210px;
    left: 50%;
    transform: translateX(-50%);
    width: 432px;
    height: 382px;
    padding: 36px;
    background: #fff;
    border-radius: 12px;
    font-size: 18px;
    color: #333847;
    z-index: 10;
    .add-stock-dialog-title{
      line-height:28px;
      font-size:20px;
      color:#333847;
      font-weight: 500;
      margin-bottom:18px;
    }
    .add-stock-dialog-subtitle{
      line-height:24px;
      font-size:18px;
      color:#333847;
      margin-bottom: 12px;
      &:last-of-type {
        margin-bottom: 24px;
      }
    }
    .add-stock-dialog-input{
      display:flex;
      align-items: center;
      padding-top:25px;
      border-top:1px solid #EBEBEB;
      height:55px;
      margin-bottom:12px;
      span{
        display: block;
        line-height:30px;
        font-size:18px;
        color:#333847;
      }
      input{
        display:block;
        border-radius: 2px;
        border: 1px solid #ADB1C3;
        width:74px;
        height:30px;
        box-sizing: border-box;
        text-align: center;
        line-height:28px;
        font-size:18px;
        color:#4392F6;
        margin:0 6px;
        &.more{
          border: 1px solid #4392F6;
        }
      }
    }
    .pay-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      font-size: 20px;
      &.o {
        opacity: 0.6;
      }
      a {
        width: 168px;
        border-radius: 6px;
        line-height: 60px;
        color: #fff;
        // &.wx {
        //   padding-left: 59px;
        //   background: #999 url("./images/wxpay-icon.png") 30px center no-repeat;
        //   background-size: 24px 20px;
        // }
        &.wx {
          padding-left: 59px;
          background: #44CE41 url("./images/wxpay-icon.png") 30px center no-repeat;
          background-size: 24px 20px;
          &.disable {
            background: #999 url("./images/wxpay-icon.png") 30px center no-repeat;
            background-size: 24px 20px;
          }
        }
        &.zfb {
          padding-left: 49px;
          background: #4392F6 url("./images/alipay-icon.png") 19px center no-repeat;
          background-size: 24px 24px;
        }
      }
    }
    .tips {
      height: 24px;
      line-height: 24px;
      margin-bottom: 29px;
      font-size: 14px;
      color: #999;
    }
  }
}
.dialog-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 480px;
  height: 334px;
  padding: 60px 36px 36px;
  border-radius: 12px;
  background: #FFFFFF;
  z-index: 10;
  .icon {
    width: 90px;
    height: 90px;
    margin-bottom: 24px;
    background: url("./images/wallet.png") center center no-repeat;
    background-size: contain;
  }
  .text {
    height: 28px;
    line-height: 28px;
    color: #333847;
    font-size: 20px;
    margin-bottom: 36px;
  }
  .btn-box {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    a {
      width: 192px;
      height: 60px;
      border-radius: 8px;
      font-size: 20px;
      text-align: center;
      line-height: 60px;
      &.yes {
        background: #4392F6;
        color: #fff;
      }
      &.no {
        border: 1px solid #4392F6;
        color: #4392F6;
      }
    }
  }
}
.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  padding: 36px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 12px;
}
// .switch-open-check-dialog {
//   display: flex;
//   justify-content: space-between;
//   width: 762px;
//   height: 600px;
//   .dialog-left {
//     width: 418px;
//     .title {
//       margin-bottom: 12px;
//       line-height: 28px;
//       font-size: 20px;
//       color: #333;
//       &.dialog-title {
//         margin-bottom: 0;
//         padding-bottom: 24px;
//         border-bottom: 1px solid #ebebeb;
//         box-sizing: border-box;
//       }
//     }
//     .purchase-item {
//       padding: 24px 0;
//       border-bottom: 1px solid #ebebeb;
//       box-sizing: border-box;
//       &.disable {
//         opacity: 0.5;
//       }
//       .subtext {
//         height: 24px;
//         line-height: 24px;
//         margin-bottom: 12px;
//         font-size: 18px;
//         color: #333;
//         &.input-box {
//           height: 30px;
//           line-height: 30px;
//           margin-top: 24px;
//         }
//         &.gray {
//           font-size: 14px;
//           color: #999;
//           margin-bottom: 0;
//         }
//       }
//     }
//   }
//   .dialog-right {
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-end;
//     a {
//       width: 200px;
//       height: 60px;
//       margin-bottom: 12px;
//       border-radius: 6px;
//       line-height: 60px;
//       color: #fff;
//       font-size: 20px;
//       &.wx {
//         padding-left: 72px;
//         background: #44CE41 url("./images/wxpay-icon.png") 45px center no-repeat;
//         background-size: 24px 20px;
//       }
//       &.zfb {
//         padding-left: 62px;
//         background: #4392F6 url("./images/alipay-icon.png") 35px center no-repeat;
//         background-size: 24px 24px;
//       }
//       &.close {
//         color: #4392F6;
//         text-align: center;
//         border: 1px solid #4392F6;
//         margin-bottom: 0;
//       }
//       &.o {
//         opacity: 0.5;
//       }
//     }
//   }
//   input {
//     width: 74px;
//     height: 30px;
//     text-align: center;
//     line-height: 30px;
//     border: 1px solid #999;
//     border-radius: 2px;
//     margin: 0 6px;
//   }
// }
.set-rule-dialog {
  display: flex;
  justify-content: space-between;
  width: 762px;
  .dialog-left {
    width: 454px;
    .dialog-title {
      height: 39px;
      line-height: 39px;
      margin-bottom: 6px;
      font-size: 28px;
      color: #333;
    }
    .rule-text {
      margin-bottom: 24px;
      line-height: 24px;
      font-size: 14px;
      color: #FF4141;
    }
    .rule-item {
      position:relative;
      height: 207px;
      border: 1px solid #ccc;
      border-radius: 2px;
      padding: 22px 24px 18px;
      margin-bottom: 24px;
      &:last-of-type {
        margin-bottom: 0;
      }
      .item-title {
        height: 25px;
        line-height: 25px;
        margin-bottom: 12px;
        font-size: 18px;
        color: #333;
      }
      .item-text {
        margin-bottom: 12px;
        line-height: 24px;
        font-size: 14px;
        color: #333;
      }
      .count-box {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 45px;
        margin-bottom: 1px;
        .count-item {
          width: 24px;
          height: 24px;
          line-height: 18px;
          text-align: center;
          border-radius: 2px;
          border: 1px solid #CCCCCC;
          box-sizing: border-box;
          font-size: 20px;
          &.disable {
            color: #ccc;
          }
        }
        .count-num {
          width: 44px;
          text-align: center;
          border: none;
          font-size: 32px;
          margin: 0 12px;
        }
        input:focus { // 获取焦点后的样式
          border: none;
          outline: none;
        }
      }
      .item-limit-mt{
        position:absolute;
        top:132px;
        left:24px;
        .anticon.anticon-info-circle{
          color:#FFB751;
        }
        .item-limit-mt-tips{
          margin:2px 0 0 0;
          color:#FFB751;
        }
      }
    }
  }
  .dialog-right {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    .ant-btn {
      width: 200px;
      height: 60px;
      line-height: 60px;
      font-size: 20px;
      margin-bottom: 12px;
    }
    .close-btn {
      color: #4392F6;
      border-color: #4392F6;
      margin-bottom: 0;
    }
  }
}
.set-rule-submit-dialog {
  width: 432px;
  height: 378px;
  padding-top: 48px;
  .dialog-title {
    height: 28px;
    text-align: center;
    line-height: 28px;
    margin-bottom: 24px;
    font-size: 20px;
    color: #333;
    font-weight: 700;
  }
  .rule-data {
    display: flex;
    width: 360px;
    height: 122px;
    padding: 23px;
    box-sizing: border-box;
    background: #f6f6f6;
    border-radius: 4px;
    margin-bottom: 12px;
    .data-item {
      flex: 1;
      text-align: center;
      .text {
        height: 20px;
        line-height: 20px;
        margin-bottom: 9px;
        font-size: 14px;
        color: #666;
      }
      .number {
        height: 45px;
        line-height: 45px;
        color: #333;
        font-size: 32px;
        text-shadow: 0px 2px 8px 0px #338BDA;
      }
      &:first-child {
        border-right: 1px solid #EBEBEB;
      }
    }
  }
  .set-date {
    height: 24px;
    text-align: center;
    line-height: 24px;
    margin-bottom: 24px;
    font-size: 14px;
    color: #FF4141;
  }
  .btn-box {
    display: flex;
    justify-content: space-between;
    height: 60px;
    .ant-btn {
      height: 60px;
      font-size: 20px;
    }
    .submit-btn {
      width: 216px;
    }
    .cancel-btn {
      width: 120px;
      border-color: #666;
      color: #666;
    }
  }
}
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/deep/ input[type="number"]{
  -moz-appearance: textfield;
}
.calendar-custome {
  width: 250px;
}
.title-space {
  margin: 10px 40px 0 0;
}
.title-button-w {
  width: 90px;
}
.over1w {
  line-height: 24px;
  padding-left: 36px;
  margin: 24px 0;
  color: #ff7a00;
  background: url(./images/tip.png) no-repeat left center;
  background-size: 24px 24px;
}
</style>

import axios from '@/utils/axios'

const API = {
  init: '/promotion/ajaxinit',
  addChannel: '/promotion/ajaxAdd',
  editChannel: '/promotion/ajaxedit',
  todayDataList: '/promotion/ajaxGetTodayData',
  totalDataList: '/promotion/ajaxGetTotalData',
  channelInfo: '/promotion/ajaxGetChannel',
  channelCodeCreate: '/promotion/ajaxCreate',
  channelCode: '/promotion/ajaxGetChannelQrCode'
}

function initPagePost () {
  return axios.get(API.init)
}

function addChannelPost (params) {
  return axios.post(API.addChannel, params)
}

function editChannelPost (params) {
  return axios.post(API.editChannel, params)
}

function todayDataListPost (params) {
  return axios.get(API.todayDataList, {params})
}

function totalDataListPost (params) {
  return axios.get(API.totalDataList, {params})
}

function channelInfoPost (params) {
  return axios.get(API.channelInfo, {params})
}

function channelCodeCreatePost (params) {
  return axios.get(API.channelCodeCreate, {params})
}

function channelCodePost (params) {
  return axios.post(API.channelCode, params)
}

export {
  initPagePost,
  addChannelPost,
  editChannelPost,
  todayDataListPost,
  totalDataListPost,
  channelInfoPost,
  channelCodeCreatePost,
  channelCodePost
}
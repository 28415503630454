<template>
  <div class="rule">
    <PageHeader class="page-rule" :show-app-info="1"></PageHeader>
    <div class="space-fixed"></div>
    <div class="container">
      <div class="rule-container">
        <div class="title">满天星用户使用协议</div>
        <div class="item">
          在完成登录或以任何方式使用满天星平台服务前，请您务必仔细阅读并透彻理解满天星网站用户使用协议(以下或简称“使用协议”)，在确认充分理解后选择接受或不接受本使用协议；一旦您同意协议并完成登录或开始以其他方式使用满天星服务，即表明您已阅读并同意受本使用协议的约束。如您不同意本使用协议，您应不再进行下一步或停止登录账号。
        </div>
        <div class="subtitle">一、协议内容及签署</div>
        <div class="item">
          1、本协议内容包括协议正文及所有满天星已经发布的或将来可能发布的各类规则。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。除另行明确声明外，任何满天星及其关联公司提供的服务均受本协议约束。但法律法规另有强制性规定的，依其规定。
        </div>
        <div class="item">
          2、您在登录满天星账户时点击勾选“满天星用户使用协议”<span style="text-decoration:underline">即视为您接受本协议及各类规则，并同意受其约束。</span>您应当在使用满天星服务之前认真阅读全部协议内容并确保完全理解协议内容，如您对协议有任何疑问的，应向满天星咨询。但无论您事实上是否在使用满天星服务之前认真阅读了本协议内容，只要您登录、正在或者继续使用满天星服务，则视为接受本协议。
        </div>
        <div class="item">
          3、您承诺接受并遵守本协议的约定。如果您不同意本协议的约定，您应立即停止登录程序或停止使用满天星服务。
        </div>
        <div class="item">
          <span style="text-decoration:underline">4、满天星有权根据需要不时地制订、修改本协议及/或各类规则，并以网站公示的方式进行公告。变更后的协议和规则一经在网站公布后，立即自动生效。</span>满天星的最新的协议和规则以及网站公告可供您随时登陆查阅，您也应当经常性的登陆查阅最新的协议和规则以及网站公告以了解满天星最新动态。如您不同意相关变更，应当立即停止使用满天星服务。您继续使用服务的，即表示您接受经修订的协议和规则。本协议和规则（及其各自的不时修订）条款具有可分割性，任一条款被视为违法、无效、被撤销、变更或因任何理由不可执行，并不影响其他条款的合法性、有效性和可执行性。
        </div>
        <div class="item">
          5、满天星产品是由上海中彦信息科技有限公司自主开发，为有需要的企业、团体提供微信公众号功能插件助手，由用户自行使用本平台开展业务运营活动，满天星提供用户使用本平台过程中的售后服务。
        </div>
        <div class="subtitle">二、用户及账户管理</div>
        <div class="item">
          1、申请资格<br />
          <span style="text-decoration:underline">您应当是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织。若您不具备前述主体资格，则您及您的监护人应承担因此而导致的一切后果，且满天星有权注销（永久冻结）您的满天星账户，并向您及您的监护人索偿或者追偿。若您不具备前述主体资格，则需要监护人同意您方可登录成为满天星用户，否则您和您的监护人应承担因此而导致的一切后果，且满天星有权注销（永久冻结）您的满天星账户，并向您及您的监护人索偿或者追偿。<br /></span>满天星并无能力对您的民事权利能力和民事行为能力进行实质性审查，因此一旦您进行了登录，满天星可以视为您具备完全民事权利能力和完全民事行为能力。
        </div>
        <div class="item">
          2、账户<br />
          在您签署本协议，完成用户登录程序或以其他满天星允许的方式实际使用满天星服务时，满天星会向您提供唯一编号的满天星账户（以下亦称账户）。您可以对账户设置用户名和密码，通过该用户名密码或与该用户名密码关联的其它用户名密码登陆满天星平台。您设置的用户名及图像不得侵犯或涉嫌侵犯他人合法权益。您应对您的账户（用户名）和密码的安全，以及对通过您的账户（用户名）和密码实施的行为负责。除非经过正当法律程序，且征得满天星的同意，否则，账户（用户名）和密码不得以任何方式转让、赠与或继承。如果发现任何人不当使用您的账户或有任何其他可能危及您的账户安全的情形时，您应当立即以有效方式通知满天星，要求满天星暂停相关服务。您理解满天星对您的请求采取行动需要合理时间，满天星对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任，但满天星未能在合理时间内采取行动的情况除外。您认可您在登录、使用满天星服务过程中提供、形成的数据等相关信息的所有权及其他相关权利属于满天星，满天星有权使用上述信息。
        </div>
        <div class="item">
          3、用户<br />
          在您按照登录页面提示填写信息、阅读并同意本协议并完成全部登录程序后或以其他满天星允许的方式实际使用满天星服务时，您即成为满天星用户（亦称用户）。
          <span style="text-decoration:underline">
            您在使用本平台期间所产生的佣金（如有）归您自己所有，与您选择授权的推广渠道无关，您与推广渠道所产生的相关结算等纠纷由您自行解决，与本平台无关。<br />
          </span>
          在登录时，您应当按照法律法规要求，或登录页面的提示准确提供，并及时更新您的资料，以使之真实、及时、完整和准确。如有合理理由怀疑您提供的资料错误、不实、过时或不完整的，满天星有权向您发出询问及/或要求改正的通知，若您未能在满天星要求的合理期限内回复满天星的询问及/或完成改正，满天星有权做出删除相应资料并暂时停止账户的处理，直至终止对您提供部分或全部满天星服务，满天星对此不承担任何责任，您将承担因此产生的任何成本或支出。<br />
          <span style="text-decoration:underline">
            您应当准确填写并及时更新您提供的电子邮件地址、联系电话、联系地址等联系方式，以便满天星或其他用户与您进行有效联系，因通过这些联系方式无法与您取得联系，导致您在使用满天星服务过程中产生任何损失或增加费用的，应由您完全独自承担，满天星对此不予承担。<br />
            您在使用满天星服务过程中，所产生的应纳税费，以及一切硬件、软件、服务、账户维持及其它方面的费用，均由您独自承担。您同意满天星有权从您相关账户中优先扣除上述费用，具体费用以页面显示为准。对于被满天星账户冻结或者暂时停止帐户的用户，满天星将不再提供用户连锁项目下的服务。
          </span>
        </div>
        <div class="item">
          4、服务费<br />
          <span style="text-decoration:underline">
            满天星平台收取一定金额的产品服务费，产品服务费用户周期为一年，自您首次登录本平台时开始计算，具体费用以页面实际展示为准。若您在有效期届满后希望继续使用本平台的，则需根据页面提示进行重新申请。如您在使用过程中因您原因提前中止/终止使用本平台的，您的产品服务费不予退还。
          </span>
        </div>
        <div class="item">
          5、关于发票<br />
          <span style="text-decoration:underline">根据您在实际使用本平台服务过程中产生的付费服务项目，您可以在每月1号起就上个自然月的业务总费用申请开票服务，本公司在对您的开票申请进行审核通过后的30个自然日内为您开具发票。如您为企业用户的，本公司可为您开具企业抬头的增值税专用发票或增值税普通发票；如您为个人用户的，本公司将为您开具个人抬头的增值税普通发票。您最晚申请开票时间是n+2月的20号，请您务必注意在以上规定时间内申请发票开具，超过以上开票时间的本公司不再为您开具。如您已在页面操作取消或放弃开票申请的，对于您取消或放弃的开票金额，本公司不再为您开具发票。</span>
        </div>
        <div class="item">
          6、关于提现<br />
          <span style="text-decoration:underline">如您为个人主体，在本平台产生相关佣金收入的，本平台依法委托共享经济合作伙伴“灵猴网”代为发放，同时您应同意“灵猴网”共享经济服务相关协议（附件二所示）。</span>
          <span style="text-decoration:underline">如您为企业用户的，您在申请提现时应根据相关规定向本平台提供合法有效的发票，本平台在收到您的发票并确认无误后向您所申请的企业账户进行支付本平台审核通过的款项。如您提供的发票不符合本平台及相关规定的，本平台有权延迟打款，由此所产生的责任由您自行承担。</span>
        </div>
        <div class="subtitle">三、满天星服务：</div>
        <div class="item">
          1、满天星用户（以下简称用户）可以通过参加满天星及其合作组织所举行的各种活动。
        </div>
        <div class="item">
          2、您在满天星的交易过程中与其他用户或第三方发生交易纠纷时，一旦您或其它用户或第三方任一方或双方共同提交满天星要求调处，则满天星有权根据单方判断做出调处决定，包括但不限于暂时冻结您的账号、下架您的产品信息等。您了解并同意接受满天星的判断和调处决定。该决定将对您的纠纷具有约束力。
        </div>
        <div class="item">
          3、您了解并同意，满天星有权应政府部门（包括司法及行政部门）的正当合法的要求，向其提供您在满天星填写的登录信息和交易纪录等必要信息。如您涉嫌侵犯他人知识产权或者其他合法权益，则满天星亦有权在初步判断涉嫌侵权等违法行为存在的情况下，向权利人提供您必要的身份信息。除非法律法规或相关政府部门另有要求，满天星将在前述信息披露情况发生后及时向您发出书面通知。
        </div>
        <div class="subtitle">四、满天星服务使用规范及处理规定</div>
        <div class="item">
          在使用满天星服务过程中，您承诺遵守下列使用规范：<br />
          1、用户承诺其登录信息的正确性。<br />
          2、如果用户提供给满天星的资料有变更，请及时通知满天星做出相应的修改。<br />
          3、用户不得出现恶意登录恶意点击等行为。<br />
          <span style="text-decoration:underline">
            4、满天星用户帐户如果一年内无登陆记录，将被视为休眠账户作冻结处理。用户账户自冻结第二个月开始，满天星保留在每月1日自动扣除已经超出有效期的积分（如有）部分的权利。用户可向满天星申请账号解冻，收到解冻申请后满天星可以为用户解冻账号，但是已经扣除的积分不能恢复。<br />
            5、超过两年无登陆记录，满天星保留注销该帐户的权利。注销后该账户内所有积分（如有）自动清零且不予恢复。此时满天星不接受用户申请解冻或找回账户，相应的用户名将开放给任意用户登录登记使用。<br />
          </span>
          6、在使用满天星服务过程中实施的所有行为均遵守国家法律、法规等规范性文件及满天星各项规则的规定和要求，不违背社会公共利益或公共道德，不损害他人的合法权益，不违反本协议及相关规则。您如果违反前述承诺，产生任何法律后果的，您应以自己独自承担所有的法律责任，并确保满天星免于因此产生任何损失。如满天星因此承担相应责任或者赔偿相关损失，满天星有权冻结您的账户余额并直接扣除满天星平台因此所造成的损失费用，不足的满天星可以继续向您追偿，相关责任或损失由您最终承担。<br />
          7、在与其他用户交易过程中，遵守诚实信用原则，不采取不正当竞争行为，不扰乱网上交易的正常秩序，不从事与网上交易无关的行为。<br />
          8、不以虚构或歪曲事实的方式不当评价其他用户，不采取不正当方式制造或提高自身的信用度，不采取不正当方式制造或提高（降低）其他用户的信用度。<br />
          9、不对满天星平台上的任何数据作商业性利用，包括但不限于在未经满天星事先书面同意的情况下，以复制、传播等任何方式使用满天星站上展示的资料。<br />
          <span style="text-decoration:underline">
            10、满天星严禁用户通过以下行为获得利益，一经发现，满天星有权追回用户账号及其关联账号（关联账户由满天星根据自身系统数据判定，判定依据包括但不限于用户登记信息、交易信息、第三方反馈信息、设备终端信息、IP地址等，下同）中已经给予的相关推广费奖励和已经兑换的奖励，并视情节严重可中止用户账号、冻结账户、扣除账户余额、直至注销用户账号，同时该用户必须承担由此给满天星带来的所有损失：<br />
          </span>
          a)购买产品后恶意取消订单；<br />
          b)劫持流量；<br />
          c)自买自卖；<br />
          d)劫持其他用户的正常访问链接使其变成推广链接；<br />
          e)骗取其他用户点击其设置的非满天星设置的链接；<br />
          f)违反本平台或第三方合作平台活动规则的；<br />
          g)经本平台判定涉及违法犯罪的；<br />
          h)其他违反法律法规或者违反诚实信用、公平原则的行为。<br />
          <span style="text-decoration:underline">
            11、满天星严禁各种针对满天星活动的作弊行为。对于查实的作弊行为，我们有权收回该账号及其关联账号全部推广费、奖励、取消邀请资格，扣除一定的积分（如有），并列入满天星黑名单账户。作弊行为包括但不限于：使用相同的电脑或设备，相同的IP地址在同一天内登录多个账号，以骗取邀请奖励的行为；以登录送钱或登录送积分等利益诱导用户来登录满天星获取奖励的行为；获取推广费后恶意取消交易或退款退货的行为等。<br />
          </span>
          12、如您因自身原因与其自身合作渠道产生的相关推广投诉、费用等纠纷由您自行解决，与满天星平台无关。如因此给满天星平台造成损失的，满天星有权视情节严重情况给予账户冻结、扣除账户余额、注销账号等措施。<br />
          <span style="text-decoration:underline">
            13、您不得使用任何装置、软件或例行程序干预或试图干预满天星平台的正常运作或正在满天星上进行的任何交易、活动。您不得采取任何将导致不合理的庞大数据负载加诸满天星网络设备的行动，否则满天星将追究您的相关责任，包括但不限于取消相关积分、收回相关邀请奖励、取消邀请资格、列入满天星黑名单账户、冻结账户或者注销账户等。如造成满天星损失或者承担相应法律责任的，满天星有权要求您赔偿并最终承担相应的责任。
          </span>
        </div>
        <div class="item">
          您了解并同意满天星有权作如下处理：<br />
          <span style="text-decoration:underline">
            1、满天星有权对您是否违反上述承诺做出单方认定，并根据单方认定结果适用规则予以处理，这无须征得您的同意。<br />
            2、经国家行政或司法机关的生效法律文书确认您存在违法或侵权行为，或者满天星根据自身的判断，认为您的行为涉嫌违反本协议和/或规则的条款或涉嫌违反法律法规的规定的，则满天星有权在满天星上公示您的该等涉嫌违法或违约行为及满天星已对您采取的措施。<br />
            3、对于您在满天星上发布的涉嫌违法或涉嫌侵犯他人合法权利或违反本协议和/或规则的信息，满天星有权予以删除，且按照规则的规定进行处罚。<br />
            4、对于您在满天星上实施的行为，包括您未在满天星上实施但已经对满天星及其用户产生影响的行为，满天星有权单方认定您行为的性质及是否构成对本协议和/或规则的违反，并据此作出相应处罚。您应自行保存与您行为有关的全部证据，并应对无法提供充要证据而承担的不利后果。<br />
          </span>
          5、对于您涉嫌违反承诺的行为对任意第三方造成损害的，您均应当以自己的名义独立承担所有的法律责任，并应确保满天星免于因此产生损失或增加费用。如满天星因此承担相应责任或者赔偿相关损失，则您承诺满天星可以向您追偿，相关责任或损失由您最终承担，相关损失包括合理的律师费用、相关机构的查询费用等。<br />
          6、如您涉嫌违反有关法律或者本协议之规定，使满天星遭受任何损失，或受到任何第三方的索赔，或受到任何行政管理部门的处罚，您应当赔偿满天星因此造成的损失及（或）发生的费用，包括合理的律师费用、相关机构的查询费用等。<br />
          <span style="text-decoration:underline">
            7、满天星上展示的资料（包括但不限于文字、图表、标识、图像、数字下载和数据编辑）均为满天星或其内容提供者的财产或者权利；满天星上所有内容的汇编是属于满天星的著作权；满天星上所有软件都是满天星或其关联公司或其软件供应商的财产或者权利，上述知识产权均受法律保护。您开通使用满天星平台服务功能的，并不代表所有知识产权的转移，亦不代表本平台授权您可以在外部渠道、平台、公众号、网站等使用满天星平台及满天星关联平台/关联公司的知识产权。如经本公司发现您未经授权擅自使用或间接侵犯了满天星平台关联主体、关联平台、关联公司等知识产权的，满天星有权根据平台自身的判断终止对您的服务、冻结账户、扣除账户余额（如有）、直至注销用户账号，同时您还必须承担由此给满天星带来的所有维权损失。<br />
          </span>
          8、满天星并无能力对您的相关登录、登记资料进行实质性审查，因此一旦因您的的登录、登记资料的问题导致的相关后果应全部由您自己承担，满天星对此不承担责任。如果根据法律法规要求满天星先行承担了相关责任，那么您承诺满天星有权向您追偿，由您最终承担上述责任。
        </div>
        <div class="subtitle">五、特别授权</div>
        <div class="item">
          您完全理解并不可撤销地授予满天星及其关联公司下列权利：<br />
          1、一旦您向满天星或其关联公司或其合作组织作出任何形式的承诺，且相关公司或组织已确认您违反了该承诺，则满天星有权立即按您的承诺或协议约定的方式对您的账户采取限制措施，包括中止或终止向您提供服务，并公示相关公司确认的您的违约情况。您了解并同意，除非法律法规另有明确要求，满天星无须就相关确认与您核对事实，或另行征得您的同意，且满天星无须就此限制措施或公示行为向您承担任何的责任。<br />
          2、一旦您违反本协议，或与满天星签订的其他协议的约定，满天星有权以任何方式通知满天星关联公司或其合作组织，要求其对您的权益采取限制措施，包括但不限于要求满天星将您账户内的积分支付给满天星指定的对象，要求关联公司中止、终止对您提供部分或全部服务，且在其经营或实际控制的任何网站公示您的违约情况。<br />
          <span style="text-decoration:underline">
            3、满天星及其关联公司有权受理您与其他用户因交易产生的争议，并有权单方判断与该争议相关的事实及应适用的规则，进而作出处理决定。该处理决定对您有约束力。如您未在限期内执行处理决定的，则满天星及其关联公司有权利（但无义务）直接处分您满天星账户内的余额。
          </span>如该争议造成满天星及其关联公司损失的，您应及时弥补满天星及其关联公司的损失，否则满天星及其关联公司有权直接抵减您在合同项下和账户中的权益，并有权继续追偿。
        </div>
        <div class="subtitle">六、责任范围和责任限制</div>
        <div class="item">
          1、满天星负责按"现状"和"可得到"的状态向您提供服务，且满天星仅对自身提供的服务承担责任。满天星对于第三方向用户提供的服务和产品不提供保证也不承担任何责任，但应当协助用户和第三方进行交涉谈判以维护用户合法权益。<br />
          2、您了解满天星上的信息系第三方或者其他用户自行发布，且可能存在风险和瑕疵。您应自行谨慎判断确定相关物品及/或信息的真实性、合法性和有效性，并自行承担相关风险。<br />
          <span style="text-decoration:underline">
            3、满天星上的商品价格、数量、是否有货等商品信息随时有可能发生变动，满天星不就此作出特别通知。您知悉并理解由于网站上商品信息数量极其庞大，虽然满天星会尽合理的最大努力保证您所浏览的商品信息的准确性、迅捷性，但由于众所周知的互联网技术因素等客观原因，满天星显示的信息可能存在一定的滞后性和差错，由此给您带来的不便或产生相应问题，满天星不承担责任。请您注意商品的具体售价以订单结算页价格为准，如您发现活动商品售价或促销信息有异常，请您购买前先咨询客服。<br />
            4、关于价格说明：划线价格：指商品的专柜价、吊牌价、正品零售价、厂商指导价或该商品的曾经展示过的销售价，并非原价，仅供参考。未划线价格，指商品的实时标价，不因表述的差异改变性质。具体成交价格根据商品参加活动，或用户使用优惠券、积分等发生变化，最终以订单结算页价格为准。商家详情页（含主图）以图片或文字形式标注的一口价、促销价、优惠价等价格可能是在使用优惠券、满减或特定优惠活动和时段等情形下的价格，具体请以结算页面的标价、优惠条件或活动规则为准。此说明仅当出现价格比较时有效，若商家单独对划线价格进行说明，以商家的表述为准。<br />
          </span>
          5、除非法律法规明确要求，或出现以下情况，否则，满天星没有义务对所有用户的登录数据、商品（服务）信息、交易行为以及与交易有关的其它事项进行事先审查：<br />
          a)满天星有合理的理由认为特定用户及具体交易事项可能存在重大违法或违约情形。<br />
          b)满天星有合理的理由认为用户在满天星的行为涉嫌违法或不当。<br />
          <span style="text-decoration:underline">
            6、您理解并同意，满天星及其关联公司并非司法机构，仅能以普通人的身份对证据进行鉴别，满天星及其关联公司对争议的调处完全是基于您的委托，满天星及其关联公司无法保证争议处理结果符合您的期望，也不对争议调处结论承担任何责任。如您因此遭受损失，您同意自行通过法律途径向受益人或者其他相关人员索偿。<br />
          </span>
          7、您了解并同意，满天星不对因下述任一情况而导致您的任何损害赔偿承担责任，包括但不限于利润、商誉、使用、数据等方面的损失或其它
          无形损失的损害赔偿 (无论满天星是否已被告知该等损害赔偿的可能性)：<br />
          a)第三方未经批准的使用您的账户或更改您的数据。<br />
          b)通过满天星服务购买或获取任何商品、样品、数据、信息或进行交易等行为或替代行为产生的费用及损失，但因满天星提供的服务信息错误所导致的除外。<br />
          c)您对满天星服务的误解。<br />
          d)任何非因满天星的原因而引起的与满天星服务有关的其它损失。<br />
          8、因使用满天星服务而引起的任何损害或经济损失，满天星承担的全部责任均不超过您通过满天星所购买的或与该索赔有关的服务或商品的价格。本责任限制条款在用户资格被冻结、暂停或注销后仍继续有效。<br />
          9、不论在何种情况下，满天星均不对由于罢工、暴乱、起义、骚乱、火灾、洪水、风暴、爆炸、战争、政府行为、司法行政机关的命令，以及其它非因满天星的原因而造成的不能服务或延迟服务承担责任。满天星会尽合理努力处理善后事宜。
        </div>
        <div class="subtitle">七、协议终止</div>
        <div class="item">
          1、您同意，满天星有权依据本协议决定中止、终止向您提供部分或全部满天星平台服务，暂时冻结或永久冻结（注销）您的账户，且无须为此向您或任何第三方承担任何责任，但本协议或法律法规另有明确要求的除外。<br />
          <span style="text-decoration:underline">
            2、出现以下情况时，满天星有权直接以注销账户的方式终止本协议：<br />
          </span>
          a)用户超过两年无登陆记录；<br />
          b)满天星终止向您提供服务后，您涉嫌再一次直接或间接或以他人名义登录为满天星用户的；<br />     
          c)您提供的电子邮箱不存在或无法接收电子邮件，且没有其他方式可以与您进行联系，或满天星以其它联系方式通知您更改电子邮件信息，而您在满天星通知后七个工作日内仍未更改为有效的电子邮箱的；<br />
          d)您登录信息中的主要内容不真实或不准确或不及时或不完整；<br />
          e)本协议（含规则）变更时，您明示并通知满天星不愿接受新的服务协议的；<br />
          f)其它满天星认为应当终止服务的情况。<br />
          <span style="text-decoration:underline">
            3、您有权向满天星要求注销您的账户，经满天星审核同意的，满天星注销（永久冻结）您的账户，届时，您与满天星基于本协议的合同关系即终止。您的账户被注销（永久冻结）后，满天星没有义务为您保留或向您披露您账户中的任何信息，也没有义务向您或第三方转发任何您未曾阅读或发送过的信息。关于您注销账户的方式以及您应满足的条件，请详见附件一《满天星账户注销须知》。<br />
          </span>
          4、您同意，您与满天星的合同关系终止后，满天星及其关联公司或者其合作组织仍享有下列权利<br />
          a)继续保存并使用您的登录、登记信息、数据及您使用满天星服务期间的所有交易数据。<br />
          b)您在使用满天星服务期间存在违法行为或违反本协议和/或规则的行为的，满天星仍可依据本协议向您主张权利。<br />
          5、满天星中止或终止向您提供服务后，对于您在服务中止或终止之前的交易行为依下列原则处理，您应独力处理并完全承担进行以下处理所产生的任何争议、损失或增加的任何费用，并应确保满天星免于因此产生任何损失或承担任何费用：<br />
          a)您在服务中止或终止之前已经上传至满天星的物品尚未交易的，满天星有权在中止或终止服务的同时删除此项物品的相关信息；<br />
          b)您在服务中止或终止之前已经与其他用户达成买卖合同，但合同尚未实际履行的，满天星有权删除该买卖合同及其交易物品的相关信息；<br /> 
          c)您在服务中止或终止之前已经与其他用户达成买卖合同且已部分履行的，满天星可以不删除该项交易，但满天星有权在中止或终止服务的同时将相关情形通知您的交易对方。
        </div>
        <div class="subtitle">八、隐私权政策</div>
        <div class="item">
          <span style="text-decoration:underline">
            1、满天星对希望成为用户的用户没有任何限制，但18岁以下的用户使用满天星服务必须取得监护人的同意。<br />
            2、一个帐号仅限一个用户使用，用户必须向满天星提供真实确实的信息，对于由于资料提供不正确导致汇款无法收到等后果，满天星不承担任何责任。<br />
            3、用户资料修改后必须及时通知满天星做出相应变更。<br />
            4、满天星及其关联公司承诺不向其它第三方机构透露用户涉及隐私的信息，但我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：<br />
            4.1、根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；<br />
            4.2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们会依据所要求的个人信息类型和披露方式公开披露您的个人信息。
          </span>
        </div>
        <div class="subtitle">九、法律适用、管辖与其他</div>
        <div class="item">
          1、本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，如无相关法律规定的，则应参照通用国际商业惯例和（或）行业惯例。<br />
          <span style="text-decoration:underline">
            2、因本协议产生之争议，应依照中华人民共和国法律予以处理。双方对于争议协商不成的，应当提交上海中彦信息科技有限公司企业所在地人民法院诉讼解决。
          </span>
        </div>
        <div class="subtitle">附件一《满天星账户注销须知》</div>
        <div class="item">
          您在申请注销流程前，应当认真阅读《满天星账户注销须知》（以下称“《注销须知》”）。<br />
          <span style="text-decoration:underline">
            请您务必审慎阅读、充分理解协议中相关条款内容，其中包括：<br />
            1、与您约定免除或限制责任的条款；<br />
            2、其他以粗体下划线标识的重要条款。<br />
          </span>
          如您对本《注销须知》有任何疑问，您也可通过满天星网站联系在线客自助查询。<br />
          【特别提示】当您按照注销页面提示填写信息、阅读并同意本《注销须知》及相关条款与条件且完成全部注销程序后，即表示您已充分阅读、理解并接受本《注销须知》的全部内容。阅读本《注销须知》的过程中，如果您不同意相关任何条款和条件约定，请您立即停止账户注销程序。<br />
          <span style="text-decoration:underline">
            亲爱的满天星个人消费者用户：<br />
            我们在此善意地提醒您，您注销账户的行为会给您的售后维权带来诸多不便，且注销满天星账户后，您的个人信息我们只会在满天星的前台系统中去除，使其保持不可被检索、访问的状态，或对其进行匿名化处理。您知晓并理解，根据相关法律规定相关交易记录须在满天星后台保存5年甚至更长的时间。<br />
            1、如果您仍执意注销账户，您的账户需同时满足以下条件：<br />
          </span>
          （1）在最近一个月内，账户没有进行更改密码、更改绑定信息等敏感操作，账户没有被盗、被封等风险；<br />
          （2）账户在满天星系统中无资产和虚拟权益（包括但不限于账户余额、F币、超级用户、优惠券等）、无欠款；<br />
          （3）账户无任何纠纷，包括投诉举报或被投诉举报；<br />
          （4）账户为正常使用中的账户且无任何账户被限制的记录；<br />
          <span style="text-decoration:underline">
            2、满天星账户一旦被注销将不可恢复，请您在操作之前自行备份满天星账户相关的所有信息和数据。请您保存好订单商品和服务的交易凭证、票据等资料，否则您有可能须支付额外的账户和订单查询费用，或无法享受售后服务。<br />
          </span>
          3、如果您的满天星账户同时是满天星平台商家店铺的绑定账户名，您需先解除相关绑定；<br />
          4、在满天星账户注销期间，如果您的满天星账户涉及争议纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，满天星有权自行终止本满天星账户的注销而无需另行得到您的同意。<br />
          <span style="text-decoration:underline">
            5、注销满天星账户，您将无法再使用本满天星账户，也将无法找回您满天星账户中及与账户相关的任何内容或信息，包括但不限于：<br />
            （1）您将无法登录、使用本满天星账户；<br />
            （2）本满天星账户的个人资料和历史信息（包括但不限于用户名、头像、购物记录、关注信息等）都将无法找回；<br />
            （3）您通过满天星账户使用、授权登录或绑定满天星账户后使用的满天星相关或第三方的其他服务的所有记录将无法找回。您将无法再登录、使用前述服务，您曾获得或即将获得的余额、优惠券、积分、资格、订单、超级用户、及其他卡券、未完成订单权益等视为您自行放弃，将无法继续使用。您理解并同意，满天星无法协助您重新恢复前述服务。<br />
            6、如您确认放弃账户中所有已享有或即将享有的任何权益，并要求满天星为您强制注销的，以上所有权益将视为您主动放弃并不再享有，同时可能引起的任何法律责任，与满天星无关。<br />
            7、注销本满天星账户并不代表本满天星账户注销前的账户行为和相关责任得到豁免或减轻。
          </span>
        </div>
        <div class="subtitle">附件二 “灵猴网”共享经济服务协议</div>
        <div class="item">
          <span style="font-weight:bold">重要提示：</span><br />
          欢迎您与灵猴网平台运营方<span style="font-weight:bold">【智阳网络技术（上海）有限公司】</span>（简称“智阳”）以及平台发包需方【包括但不限于<span style="font-weight:bold">大德共享经济服务外包(海南)有限公司、智阳网络技术（上海）有限公司武宁分公司、拓扬供应链管理（湖北）有限公司、 全正服务外包（安庆）有限公司、重庆华辰嘉元科技有限公司、青岛大熊企业服务有限公司、安徽爱薪宝共享经济服务有限公司、宜春大熊信息科技有限公司】</span>签署本协议，您签署本协议后将取得灵猴网协议分包商的资格，在灵猴网提供项目承揽服务。<br />
          <span style="font-weight:bold">【审慎阅读】</span>为维护您的自身权益，请您认真阅读《“灵猴网”共享经济服务协议》（简称“本协议”）所有内容，务必审慎阅读、充分理解各条款内容。以粗体及下划线标识的条款，您应重点阅读。<br />
          <span style="font-weight:bold">【签约】</span>当您按照提示填写您的个人信息并完成本协议的确认/签署后，即表示您已充分阅读、理解并接受本协议的全部内容,同意注册成为 “灵猴网”共享经济平台（简称“灵猴”）用户、开通共享经济服务。在阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，请您立即停止任何形式的协议确认/签署程序。<br />
          <br />
          <span style="font-weight:bold">【协议条款】</span><br />
          <span style="font-weight:bold">第一条 合作内容</span><br />
          1.1 为满足业务发展需要，平台发包需方（简称“发包方”）通过灵猴网发布项目发包信息，您作为平台协议分包商通过灵猴网从事承揽项目分包的业务活动，智阳搭建灵猴网共享经济平台，为发包方及分包商之间完成交易提供平台技术服务。<br />
          1.2协议分包商自主选择项目进行接单并按照发包方的要求完成分包项目的实施，分包商与发包方之间自行完成项目实施、交付及费用结算。<br />
          <br />
          <span style="font-weight:bold">第二条 协议分包商的权利和义务</span><br />
          <span style="font-weight:bold">2.1鉴于灵猴网仅招募自由职业者或个体工商户或临时税务登记纳税人为协议分包商，如您不属于自由职业者或个体工商户或临时税务登记纳税人，请拒绝签署本协议并停止注册或使用灵猴平台。如您不属于自由职业者或个体工商户或临时税务登记纳税人仍注册成为灵猴网协议分包商的，所有法律责任由您自行承担。</span><br />
          <span style="font-weight:bold">2.2您在灵猴网注册时：</span><br />
          <span style="font-weight:bold">2.2.1 若您选择以自由职业者的身份注册灵猴网账户的，您应配合完成灵猴网的用户认证流程，包括但不限于如实填报个人身份信息、联系信息、银行账户信息、上传身份证正反面照片等环节，并应根据税务部门及相关机构的监管要求配合完成灵猴网或灵猴网授权的第三方系统平台的实名认证及活体认证流程。</span><br />
          <span style="font-weight:bold">2.2.2若您选择以个体工商户的身份注册灵猴网账户的，您应配合完成灵猴网的用户认证流程；同时，您同意授权智阳为您提供工商注册登记、工商税务代理、纳税申报、工商年检、汇算清缴、注销登记等服务，并同意委托智阳保管您的营业执照等相关文件；同时为完成委托事项您应向智阳提供身份证原件及授权书等材料（具体以相关政府部门的要求为准）。智阳承诺：对您委托保管的个体工商户相关文件（营业执照等）进行妥善保管。</span><br />
          <span style="font-weight:bold">2.2.3若您选择以临时税务登记纳税人的身份注册灵猴网账户的，除应完成灵猴网的用户认证流程，您还需完成临时税务登记的申办流程，包括但不限于通过税务局指定的第三方系统平台（包括但不限于“易汇云”系统、“大熊企服”共享经济平台等）进行实名认证、活体认证等环节。</span><br />
          <span style="font-weight:bold">特别提示：如您不同意以临时税务登记纳税人身份注册灵猴网的，则您应明确拒绝进行活体认证流程，否则由此产生的所有相关法律责任均由您自行承担。</span><br />
          2.3您的签约信息（包括姓名、身份证号、身份证正反面照片、联系电话、银行账户信息等），以您线上或线下提供的信息为准。您承诺并保证您所提供的签约信息均是真实、准确、完整、有效且合法的，如信息变更应及时通知智阳。<br />
          <span style="font-weight:bold">2.4您有权自主决定是否承揽相关分包项目，您自备生产经营所需要的设备设施、自行承担生产经营活动中发生的必要费用，您独立完成分包项目的实施。</span><br />
          <span style="font-weight:bold">2.5您在决定是否承揽相关分包项目之前，如您存在下列所述情形的，应及时告知发包方：</span><br />
          <span style="font-weight:bold">（1）您是任一企业的法定代表人、股东、董事、监事；</span><br />
          <span style="font-weight:bold">（2）您与任一企业具有劳动/劳务合同关系或其他类似的劳动人事法律关系；</span><br />
          <span style="font-weight:bold">（3）您属于外籍人员，或港澳台人士，或公职人员（含军人）。</span><br />
          <span style="font-weight:bold">如存在前述情形但未告知发包方且仍承接相关分包项目的，所有相关法律责任均由您自行承担。</span><br />
          <span style="font-weight:bold">2.6 服务期间，您应按照发包方的有关服务内容及交付质量标准的要求，按时尽责地完成发包方的服务任务，并达到规定的质量标准，不得有侵犯他人知识产权、财产权、人身权、肖像权、隐私权、商业秘密或其他合法权益，不得违反国家法律法规、国家政策，不得有悖公序良俗。</span><br />
          2.7未经智阳书面许可，您不得披露或转让在履行合同过程中获悉的智阳及发包方的任何商业信息；保密期限为协议期内以及协议终止后十年内。<br />
          2.8 您同意智阳及发包方收集您的个人信息，包括但不限于姓名、身份证号、身份证正反面照片、联系电话、银行账户信息等。<br />
          <br />
          <span style="font-weight:bold">第三条 平台运营方及发包方的权利和义务</span><br />
          3.1 平台运营方及发包方可以留存为实现本次合作内容从您处获取的您的个人信息，并承诺对您所披露的个人信息进行保密，未经您授权或非基于合法并可执行的法院命令、监管机构的要求或非为履行网络安全保护义务或非应对网络安全事件的需要，平台运营方及发包方不得将您的个人信息披露给第三方，如违反此约定造成您相应经济损失的，平台运营方及发包方将承担该损失的全部赔偿责任。<br />
          平台运营方承诺按照相关法律法规的要求对您的个人信息履行安全保护义务，保障网络免受干扰、破坏或未经授权的访问，防止网络数据泄露或被窃取、篡改。<br />
          <span style="font-weight:bold">3.2 协议期内，发包方有权根据项目的服务内容和服务质量等要求，对分包项目制定具体的进度安排和验收标准，您应遵守。</span><br />
          <span style="font-weight:bold">3.3发包方将对您交付的工作成果进行验收。发包方对您交付的项目成果验收通过后，将按约定及时向您支付项目分成款。</span><br />
          <span style="font-weight:bold">3.4您与发包方通过本协议建立业务外包合作关系。您与发包方不构成任何劳动法律层面的雇佣、劳动、劳务关系，发包方不承担您任何社保福利待遇，也不负责您的任何医疗保险费用（包括但不限于商业保险）。</span><br />
          <br />
          <span style="font-weight:bold">第四条 项目分成款的支付</span><br />
          <span style="font-weight:bold">4.1发包方将根据项目完成情况向您支付项目分成款。由于您所承揽的项目情况的不同，该等项目分成款金额可能呈现浮动，您清楚并了解该等浮动为正常情况。发包方以人民币形式向您支付项目分成款；对于您因取得项目分成款应缴纳的个人所得税及其他相关税费由发包方按照本协议第六条之约定进行处理。</span><br />
          <span style="font-weight:bold">4.2 发包方将通过银行转账的方式向您支付项目分成款。您应向发包方提供您本人的实名账户信息，若具体账户信息在灵猴网或发包方认可的其他网络平台在线提供的，以您在线提供的信息为准。</span><br />
          4.3 项目分成款支付以您提供的收款账号为准。因您提供的收款账号错误或不实造成的一切损失由您自行承担。如您账号变更或发生无法使用等情况时，应及时书面通知发包方进行变更操作，否则，由此造成的一切损失由您自行负责。<br />
          <br />
          <span style="font-weight:bold">第五条 违约责任</span><br />
          5.1 本协议对各方均有约束力和可执行力。若任何一方未能履行其在本协议项下之义务或责任，应视为违反本协议，由该违约方负责赔偿守约方因该违约行为而遭受的经济损失及维权费用（包括但不限于诉讼或仲裁费用、律师费、差旅费、鉴定费、公证费等合理费用）。<br />
          5.2 因不可抗力因素致使无法实现合同目的，任何一方可以终止或解除本协议。但发生不可抗力一方应及时将有关情况通知其余各方，并尽最大努力进行补救。本协议所称不可抗力是指不能预见、不能克服并不能避免且对一方当事人造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、火灾和风暴等以及社会事件如战争、动乱、政府行为、黑客事件、大范围电信事故等。<br />
          5.3任何由于您声明不实或违反您声明承诺事项导致他方向智阳或发包方提起诉讼、索赔及/或导致智阳或发包方声誉受损之后果，您将承担智阳或发包方因此产生的全部直接及间接费用，包括但不限于诉讼支付的律师费等。<br />
          5.4您违反本协议下任何规定的，智阳有权单方面采取相应限制或处罚措施，包括但不限于：限制、关闭、终止您的灵猴网平台账号并根据实际情况决定是否恢复使用。<br />
          您交付的项目成果或提供的服务不符合发包方的要求，发包方有权单方面采取相应限制或处罚措施，包括但不限于：扣除您帐号中尚未结算的项目分成款或暂停结算支付。<br />
          5.5如因国家政策或税务部门政策等调整，智阳认为有必要解除本协议的，您认可并同意智阳有权单方面无条件解除本协议且无需承担任何违约责任。<br />
          5.6出现以下情形之一的，智阳可以随时解除本协议，并有权要求您承担相应违约责任，赔偿相应损失：<br />
          （1）您在使用灵猴服务时存在严重损害智阳利益的行为；<br />
          （2）您利用、借助 “灵猴网”谋取不正当利益或从事任何非法活动；<br />
          （3）您不符合税务部门或智阳的身份真实性核查要求；<br />
          （4）您违反对本协议所做的声明与承诺的；<br />
          <br />
          <span style="font-weight:bold">第六条 涉税事务委托授权</span><br />
          6.1您委托发包方代理本合同交易项下项目分成款的涉税事务，包括代理报税、代开个人完税证明。<br />
          6.2您确认：您在灵猴网提交的涉税相关的申报资料是真实、完整、准确且符合有关法律法规的。<br />
          <br />
          <span style="font-weight:bold">第七条 适用法律、争议及纠纷解决和司法管辖</span><br />
          <span style="font-weight:bold">本协议的订立、执行和解释及争议的解决均应适用中国法律。凡因本协议引起的或与本协议有关的任何争议，应友好协商解决。如不能协商解决，一致同意由智阳网络技术（上海）有限公司所在地上海市浦东新区人民法院进行管辖。</span><br />
          <br />
          第八条 其他<br />
          8.1除本协议另有约定外，在履行本协议过程中的一切通知，智阳可通过网页公告、电子邮件、系统通知、短信提醒、电话或其他合理方式向您送达，该等通知自发送之日视为送达。<br />
          8.2智阳有权根据业务需要不时地更新本协议条款，并在灵猴网站、小程序或以其他方式予以公布。如您不同意所修改的内容，请立即停止使用灵猴服务并书面通知智阳。<br />
          <span style="font-weight:bold">8.3 本协议为线上电子签署，自您在“灵猴网”或其他授权网站点击“提交”、“注册”、“同意”、“登录”或其他类似确认键时生效。本协议有效期为：自签订之日起生效，至按照本协议约定终止或解除时为止。</span><br />
          <span style="font-weight:bold">8.4 本协议采用电子文本形式制成，您认可该签约形式的法律效力，不得以未签署书面协议为由主张本协议无效。</span><br />
          （以下无正文）
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/header/PageHeader";
export default {
  components: {
    PageHeader,
  },
};
</script>

<style lang="less" scoped>
.rule {
  min-height: 100%;
  color: #333847;
  background: #f3f6fc;
  padding-bottom: 24px;
  .page-rule {
    position: fixed;
    top: 0;
    left: 0;
  }
  .space-fixed {
    height: 60px;
  }
}
.container {
  width: 100%;
  height: calc(100vh - 84px);
  overflow-y: auto;
}
.rule-container {
  width: 960px;
  margin: 24px auto;
  padding: 36px;
  box-sizing: border-box;
  font-size: 14px;
  background: #feffff;
  .title {
    height: 28px;
    line-height: 28px;
    font-size: 20px;
    margin-bottom: 24px;
    text-align: center;
  }
  .subtitle {
    height: 20px;
    line-height: 20px;
    margin: 24px 0 12px;
  }
  .item {
    color: #707793;
    margin-bottom: 12px;
  }
}
</style>

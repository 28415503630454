import { render, staticRenderFns } from "./statusDialog.vue?vue&type=template&id=1ddc10df&scoped=true&"
import script from "./statusDialog.vue?vue&type=script&lang=js&"
export * from "./statusDialog.vue?vue&type=script&lang=js&"
import style0 from "./statusDialog.vue?vue&type=style&index=0&id=1ddc10df&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ddc10df",
  null
  
)

export default component.exports
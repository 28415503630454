<template>
  <div>
    <div class="mask" style="z-index: 1031;"></div>
    <div class="txdialog" style="z-index: 1032;">
        <div v-if="diaType == 1">
            <div class="title">创建渠道</div>
            <div class="item-name">渠道名称</div>
            <a-input class="input" :maxLength="12" v-model="channelNameVal" placeholder="最多不超过12个字符" />
            <div style="display:flex; align-items:center;height:24px;margin-bottom:12px;">
                <div class="item-name" style="margin-bottom:0;">二维码标识</div>
                <span class="qr-tip">方便识别二维码来自哪个渠道</span>
            </div>
            <a-input class="input" :maxLength="12" v-model="qrcodeFlagVal" placeholder="最多不超过12个字符" />
            <div class="biaoshi-toggle-wrap">
                <div class="item-name" style="margin-right: 10px">在二维码下方显示标识</div>
                <div>
                    <a-switch :checked="qrToggleVal" @change="qrCodeFlagToggle" />
                </div>
            </div>
            <div class="item-name">渠道有效期</div>
            <a-radio-group v-model="expireVal" @change="selectchannelTime">
                <div style="display:flex; align-items:center;height:24px;margin-bottom:10px">
                    <a-radio :value="1" class="radio-name">永久</a-radio>
                    <span class="radio-tip">建议稳定推广渠道使用，可根据需要停用和开启</span>
                </div>
                <div style="display:flex; align-items:center;height:24px;margin-bottom:10px">
                    <a-radio :value="2" class="radio-name">临时</a-radio>
                    <span class="radio-tip">建议短期推广渠道使用，有效期30天，到期后二维码失效</span>
                </div>
            </a-radio-group>
            <div class="btn-wrap">
                <a-button class="btn" :class="{o: !channelNameVal || (this.qrToggleVal && !this.qrcodeFlagVal) || !expireVal}" type="primary" @click="saveNewChannel">保存</a-button>
                <a-button class="btn close-btn" @click="closeBtn">关闭</a-button>
            </div>
        </div>
        <div v-else>
            <div class="title">管理渠道</div>
            <div class="item-name">渠道名称</div>
            <a-input class="input" :maxLength="12" v-model="channelNameData" placeholder="最多不超过12个字符" />
            <div style="display:flex; align-items:center;height:24px;margin-bottom:12px;">
                <div class="item-name" style="margin-bottom:0;">二维码标识</div>
                <span class="qr-tip">方便识别二维码来自哪个渠道</span>
            </div>
            <a-input class="input" :maxLength="12" v-model="qrBiaoshiData" placeholder="最多不超过12个字符" />
            <div class="biaoshi-toggle-wrap">
                <div class="item-name" style="margin-right: 10px">在二维码下方显示标识</div>
                <div>
                    <a-switch :checked="isShowBiaoshiData" @change="qrCodeFlagToggle" />
                </div>
            </div>
            <div class="item-name">渠道有效期</div>
            <div class="expire-wrap">
                <span class="expire-value" v-if="channelInfo.type==1">永久</span>
                <span class="expire-value" v-if="channelInfo.type==2">临时</span>
                <span class="expire-tip" v-if="channelInfo.type==2">有效期为{{ channelInfo.startDate }}-{{ channelInfo.endDate }}，到期后二维码失效</span>
            </div>
            <div class="line"></div>
            <div class="item-name" style="display:inline-block;margin-right:10px">停用该渠道</div>
            <a-switch :checked="stopBiaoshiData" @change="stopChannelToggle" />
            <div class="btn-wrap">
                <a-button class="btn" type="primary" :class="{o: !channelNameData || (isShowBiaoshiData && !qrBiaoshiData)}" @click="saveUpdateChannel">保存</a-button>
                <a-button class="btn close-btn" @click="closeBtn">关闭</a-button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { addChannelPost, editChannelPost,  } from '@/api/channel'
export default {
    props: {
        channelInfo: {
            type: Object,
            default: () => {}
        },
        diaType: {
            type: String,
            default: "",
        },
    },
    data () {
        return {
            // 新建渠道
            channelNameVal: '',
            qrcodeFlagVal: '',
            qrToggleVal: false,
            expireVal: 1,
            // 管理渠道
            channelNameData: this.channelInfo.title,
            qrBiaoshiData: this.channelInfo.identification,
            isShowBiaoshiData: this.channelInfo.identification_show == 1 ? true : false,
            stopBiaoshiData: this.channelInfo.status == 2 ? true : false,
            editStatus: false,
            addStatus: false
        }
    },
    methods: {
        saveNewChannel () {
            if (!this.channelNameVal || (this.qrToggleVal && !this.qrcodeFlagVal) || !this.expireVal) return
            const saveData = {
                title: this.channelNameVal,
                identification: this.qrcodeFlagVal,
                identification_show: this.qrToggleVal ? 1 : 0,
                type: this.expireVal
            }
            this.addChannel(saveData)
        },
        async addChannel (params) {
            if (this.addStatus) return
            this.addStatus = true
            const { status, info } = await addChannelPost(params)
            if (status === 1) {
                this.addStatus = false
                this.$message.success(info)
                this.$emit('closeDia', {initPage: true,dialogStatus: false})
            } else {
                this.$message.error(info)
            }
        },
        saveUpdateChannel () {
            if (!this.channelNameData || (this.isShowBiaoshiData && !this.qrBiaoshiData)) return
            const saveData = {
                id: this.channelInfo.id,
                title: this.channelNameData,
                identification: this.qrBiaoshiData,
                identification_show: this.isShowBiaoshiData ? 1 : 0,
                status:this.stopBiaoshiData ? 2 : 1
            }
            this.editChannel(saveData)
        },
        async editChannel (params) {
            if (this.editStatus) return
            this.editStatus = true
            const { status, info } = await editChannelPost(params)
            if (status === 1) {
                this.editStatus = false
                this.$message.success(info)
                this.$emit('closeDia', {initPage: true,dialogStatus: false})
            } else {
                this.$message.error(info)
            }
        },
        closeBtn () {
            if (this.diaType == 1) {
                this.channelNameVal = ''
                this.qrcodeFlagVal = ''
                this.qrToggleVal = false
                this.expireVal = 0
            }
            this.$emit('closeDia', {dialogStatus: false})
        },
        qrCodeFlagToggle (val) {
            if (this.diaType == 1) {
                this.qrToggleVal = val
            } else {
                this.isShowBiaoshiData = val
            }
        },
        selectchannelTime (e) {
            this.expireVal = e.target.value
        },
        stopChannelToggle (val) {
            this.stopBiaoshiData = val
        }
    }
}
</script>

<style lang="less" scoped>
.title {
    color: #333;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 24px;
}
.biaoshi-toggle-wrap {
    width: 400px;
    display: flex;
    margin-bottom: 20px;
}
.item-name {
    color: #333;
    font-size: 16px;
    margin-bottom: 10px;
}
.qr-tip {
    color: #FF7A00;
    font-size: 1px;
    display: inline-block;
    margin-left: 10px;
}
.input {
    display: block;
    width: 454px;
    height: 36px;
    margin-bottom: 20px;
}
.expire-value {
    font-size: 16px;
}
.expire-tip {
    height: 24px;
    line-height: 24px;
    color: #999;
    font-size: 14px;
    margin-left: 10px;
}
.line {
    width: 454px;
    height: 1px;
    background: #ccc;
    margin: 20px 0;
}
.radio-name {
    height: 24px;
    margin-right: 10px;
    font-size: 16px;
}
.radio-tip {
    height: 24px;
    line-height: 24px;
    color: #999;
    font-size: 14px;
}
.expire-wrap {
    display: flex;
    align-items: center;
    height: 24px;
    color: #333;
}
.btn-wrap {
    width: 200px;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    bottom: 36px;
    right: 36px;
    .btn {
        box-sizing: border-box;
        width: 200px;
        height: 60px;
        line-height: 60px;
        border-radius: 6px;
        font-size: 20px;
    }
    .close-btn {
        border: 1px solid #4392F6;
        color: #4392F6;
        margin-top: 10px;
    }
}
</style>
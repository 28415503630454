<template>
  <div class="plan-module">
    <div class="plan-name">
      {{ plan.title }}
      <a-popover placement="bottom">
        <template slot="content">
          <span>{{ plan.tip }}</span>
        </template>
        <a-icon type="info-circle" />
      </a-popover>
    </div>
    <div class="text">粉丝单价：{{ plan.fans_amount }}元</div>
    <div class="text">剩余可订粉丝数：
      <span :style="{color: (plan.remain_fans_num >= 1000 && (plan.sub_products[0].status != '2' || plan.sub_products[1].status != '2')) ? '#19AF4E' : ''}">{{ plan.remain_fans_num }}个</span>
    </div>
    <a class="cover-btn" href='https://saas.mtx.net.cn/#/cover' target="_blank">增长保障计划 ></a>
    <!-- <a href="javascript: void(0);" class="btn disabled">售罄</a> -->
    <a href="javascript: void(0);" class="btn" :class="{ disabled: plan.remain_fans_num < 1000 || (plan.sub_products[0].status == '2' && plan.sub_products[1].status == '2') }" @click="showPlanDialog(plan)">立即预定</a>
  </div>
</template>
<script>
export default {
  props: {
    plan: {
      type: Object
    },
    fansType: {
      type:Number
    },
    showPlanDialog: {
      type: Function
    }
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {},
}
</script>
<style lang="less" scoped>
.plan-module {
  width: 355px;
  height: 278px;
  border-radius: 4px;
  padding: 24px;
  box-sizing: border-box;
  margin-right: 24px;
  background: #FFFFFF;
  color: #333;
  box-shadow: 0px 8px 20px 0px #dce3f0;
  .plan-name {
    display: flex;
    align-items: center;
    height: 34px;
    margin-bottom: 24px;
    font-size: 24px;
    i {
      margin-left: 12px;
      color: #FF7A00;
      font-size: 24px;
    }
  }
  .text {
    height: 28px;
    line-height: 28px;
    margin-bottom: 12px;
    font-size: 20px;
    &:last-of-type {
      margin-bottom: 12px;
    }
    &.have-count {
      color: #19AF4E;
    }
  }
  .btn {
    display: block;
    width: 307px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border-radius: 4px;
    color: #fff;
    font-size: 18px;
    background: #4392F6;
    &.disabled {
      background: #CCCCCC;
    }
  }
  .cover-btn{
    display:block;
    margin-bottom:24px;
    line-height:20px;
    font-size:14px;
    color:#4392F6;
  }
}
</style>
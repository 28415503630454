import axios from '@/utils/axios'

// 欢迎语
const API = {
  init: '/welcome/ajaxInit',
  setModule: '/welcome/ajaxSetWelcomeConfig'
}

function initPagePost () {
  return axios.post(API.init)
}

function setModulePost (param) {
  return axios.post(API.setModule, param)
}

export {
  initPagePost,
  setModulePost
}

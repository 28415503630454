<template>
  <div class="rule">
    <PageHeader class="page-rule" :show-app-info="1"></PageHeader>
    <div class="space-fixed"></div>
    <div class="container">
      <div class="rule-container">
        <div class="title">试吃团活动规则</div>
        <div class="item">
          1.用户可以在小胖试吃团抢购试吃名额，抢购名额时需支付0.1元报名费，报名成功后再去对应平台下单。用户在外卖交易成功后需上传点评截图和订单截图，可获得小胖试吃金。
        </div>
        <div class="item">
          2.每个用户每次只能抢购一个试吃名额，未完成点评不能抢新的试吃名额。当用户主动取消试吃任务时，0.1元的报名费将会自动返回。如用户在报名成功后的24小时内未上传点评截图，则会被自动取消该试吃名额并返还0.1元的报名费。
        </div>
        <div class="item">
          3.用户在报名成功后需要在24小时内上传点评截图和订单截图，若24小时内未上传截图，则自动取消名额。在用户首次上传点评截图后的48小时内，如果审核未通过，试吃团会通知用户重新上传符合要求的截图。超过48小时未重新上传有效截图，试吃活动将失效。在用户首次上传点评截图的48小时内，首次审核未通过，用户还用于另外两次重新上传截图的机会，如最终还无法提供有效截图，则可在第三次审核失败后可联系客服。
        </div>
        <div class="item">
          4.审核通过的试吃金于领取时立即到账，试吃金会直接打款到用户的零钱当中。如未到账或因用户的微信账号存在风险，可联系试吃团客服处理。
        </div>
        <div class="item">
          5.已完成试吃任务后，红包会在用户试吃列表中存放14天。一旦用户点击“领取试吃金”按钮，我们会以微信小程序红包的形式将试吃金发放至用户微信零钱账户。若用户在审核点评截图通过后的14天内未领取试吃金，将会失去领取试吃金的机会。
        </div>
        <div class="subtitle">常见问题（满天星版）</div>
        <div class="item">
          1.用户通过A公众号进入小程序报名，又通过B公众号进入小程序进行后续操作。如果试吃任务成功通过审核，流量主奖励算谁的？<br />算A公众号的流量主奖励，流量主奖励归属于用户报名时对应的入口公众号。
        </div>
        <div class="item">
          2.如果用户的试吃任务成功通过审核，14天内未领取试吃金，我是否能获得流量主奖励？<br />可以，只要试吃任务成功通过审核，您就能获取1~2元流量主奖励，与用户是否领取试吃金无关。
        </div>
        <div class="item">
          3.用户的试吃任务没有通过审核，我是否能获得流量主奖励？<br />不可以，此种情况视为试吃任务失效。
        </div>
        <div class="item">
          4.相关功能上线后，我多久才能在满天星看到通过审核的试吃任务？<br />用户试吃任务成功通过审核的五个工作日内。
        </div>
        <div class="item">
          5.相关功能上线后，流量主奖励如何提现？<br />与美团、饿了么订单佣金结算相同，流量主奖励将在每月25日结算，届时可以进行提现。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/header/PageHeader";
export default {
  components: {
    PageHeader,
  },
};
</script>

<style lang="less" scoped>
.rule {
  min-height: 100%;
  color: #333847;
  background: #f3f6fc;
  padding-bottom: 24px;
  .page-rule {
    position: fixed;
    top: 0;
    left: 0;
  }
  .space-fixed {
    height: 60px;
  }
}
.container {
  width: 100%;
  height: calc(100vh - 84px);
  overflow-y: auto;
}
.rule-container {
  width: 960px;
  margin: 24px auto;
  padding: 36px;
  box-sizing: border-box;
  font-size: 14px;
  background: #feffff;
  .title {
    height: 28px;
    line-height: 28px;
    font-size: 20px;
    margin-bottom: 24px;
    text-align: center;
  }
  .subtitle {
    height: 20px;
    line-height: 20px;
    margin: 24px 0 12px;
  }
  .item {
    color: #707793;
    margin-bottom: 12px;
  }
}
</style>

<template>
  <div class="authorizer">
    <PageHeader :show-app-info="1"></PageHeader>
    <div class="authorizer-main">
      <div class="main-wrap">
        <a-steps :current="stepCurrent">
          <a-step title="授权公众号" />
          <a-step title="关联小程序" />
          <!-- <a-step v-if="authStepSwitch === 1" title="选择联盟账户" /> -->
          <a-step title="完成"/>
        </a-steps>
        <div class="wrap-bottom">
          <authone v-if="stepCurrent == 0" :auth-status="authStatus"></authone>
          <authtwo v-else-if="stepCurrent == 1"></authtwo>
          <!-- <auththree @selectedType="selectedType" v-else-if="stepCurrent == 2 && authStepSwitch === 1"></auththree> -->
          <div class="auththree" v-else-if="stepCurrent == 2">
            <div v-if="relationStatus == 1">
              <div class="top">恭喜您完成初始流程，默认使用满天星结算账户</div>
              <!-- <div class="item">每月我们都会有4到5次更新<br />记得时常关注我们的推送消息</div> -->
              <div class="item">您可以前往【账户类型】选择使用自己的美团分销联盟账户作为结算账户。</div>
            </div>
            <div class="relation-error" v-else-if="relationStatus == 0">
              <div class="icon">很遗憾，系统关联失败</div>
              <div class="">您可以尝试<a href="https://docs.qq.com/doc/DRHRvUnprRExJQ2VP?pub=1&dver=2.1.26944036">手动关联</a>，如有问题可
                <a-popover placement="bottom">
                  <template slot="content">
                    <img class="code-img" src="./images/erweima.png" alt="">
                  </template>
                  <a-button class="tips">联系客服顾问</a-button>
                </a-popover>
              </div>
              <div>关联成功后记得返回，进行后续操作</div>
            </div>
          </div>
          <a @click="goAuth" class="auth-btn" v-if="stepCurrent == 0" href="javascript:void(0);">{{ authBtnText }}</a>
          <a @click="goRelationApp" class="auth-btn" v-if="stepCurrent == 1" href="javascript:void(0);">关联小程序</a>
          <a @click="pathTo('accountType')" class="auth-btn btn" v-if="stepCurrent == 2 && relationStatus == 1">前往设置账户</a>
          <a @click="showGuideDia" class="auth-btn" v-if="stepCurrent == 2 && relationStatus == 1">开始使用满天星</a>
        </div>
      </div>
      <div class="main-wrap" v-if="isShowGuideDia">
        <div class="wrap-title">新手引导</div>
        <div class="wrap-box">
          <div class="left">
            <div class="title">我们已自动帮您开启4项功能：</div>
            <div class="wrap-text">公众号欢迎语</div>
            <div class="wrap-text">公众号吃饭提醒</div>
            <div class="wrap-text">保持用户活跃策略</div>
            <div class="wrap-text">公众号配套小程序</div>
          </div>
          <div class="right">
            <div class="title">以下2项功能可根据您的需要手动开启：</div>
            <div class="wrap-text wait">下单后互动行为</div>
            <div class="wrap-text wait">签到送外卖会员</div>
          </div>
        </div>
        <div class="text">为了把小程序领红包能力快速部署到您的公众号，请先开始配置菜单</div>
        <a @click="pathTo('/bottommenu')" class="auth-btn">配置菜单</a>
      </div>
    </div>
    <!-- <div class="result-main" v-if="isShowAuthResult">
      <img src="./images/auth-result-icon.png" alt="">
      <div class="text">是否已完成授权</div>
      <div class="result-btn-wrap">
        <a-button type="primary" @click="getAuthResult">是的</a-button>
        <a-button @click="getAuthResult">没有</a-button>
      </div>
    </div>
    <div class="auth-success" v-if="authTaobaoStatus == 1">
      <img src="./images/icon-success.png" alt="">
      <div class="text">授权成功</div>
      <div class="subtext">当前已授权{{authAccountNum}}个淘宝联盟账户</div>
      <a-button type="primary" @click="showAddPromoteDialog">下一步</a-button>
    </div> -->
    <serviceDialog
      @closeServiceDialog="closeServiceDialog"
      :show-service-dialog="showServiceDialog"
    ></serviceDialog>
    <failDialog
      @closeStatusDialog="closeStatusDialog"
      :show-status-dialog="statusDialog"
    ></failDialog>
    <!-- <DetailIntroduce
      v-if="isShowDetailIntroduce"
      :account-type="accountType"
      :from="1"
      @detaliBtnHandler="detaliBtnHandler"
      @closeDetailDialog="closeDetailDialog"
    />
    <AuthTaobao
      v-if="isShowAuthTaobaoDialog || authTaobaoStatus === 2"
      :auth-taobao-status="authTaobaoStatus"
      :auth-url="authUrl"
      @closeAuthTaobao="closeAuthTaobao"
      @authResultDialog="authResultDialog"
    />
    <AddPromote v-if="isShowAddPromote" :auth-info="authInfo" @closeAddPromote="closeAddPromote" />
    <div class="mask" v-if="isShowAuthResult || authTaobaoStatus"></div> -->
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import storage from 'store'
import { IS_FIRST_AUTH } from '@/store/globalConst'
import authone from './components/authone'
import authtwo from './components/authtwo'
// import auththree from './components/auththree'
import failDialog from './components/dialog'
// import DetailIntroduce from './components/detailIntroduce'
// import AuthTaobao from './components/authTaobao'
// import AddPromote from './components/addPromote'
import serviceDialog from '@/components/serviceDialog/serviceDialog'
import PageHeader from "@/components/header/PageHeader"
// import { authStatusPost } from '@/api/authorizer'
export default {
  data () {
    return {
      isShowGuideDia: false,
      showServiceDialog:false,
      // isShowDetailIntroduce: false,
      // accountType: 0, // 选择账户类型
      // isShowAuthTaobaoDialog: false,
      // isShowAuthResult: false,
      // authTaobaoStatus: 0,
      // isShowAddPromote: false,
      // authInfo: {},
      // authAccountNum: null,
      // authUrl: '',
      // authTaobaoCode:11 ''
    }
  },
  components: {
    authone,
    authtwo,
    serviceDialog,
    PageHeader,
    failDialog,
    // auththree,
    // DetailIntroduce,
    // AuthTaobao,
    // AddPromote
  },
  computed: {
    ...mapGetters(['authLink', 'authStatus', 'stepCurrent', 'isNeedLink', 'relationStatus', 'statusDialog', 'authStepSwitch']),
    authBtnText () {
      switch (this.authStatus) {
        case 1:
          return '开始授权公众号'
        case 2:
          return '授权公众号'
        case 3:
          return '重新授权公众号'
        case 4:
          this.SET_AUTH_LINK('https://mp.weixin.qq.com')
          return '前往认证'
        case 5:
          return '重新授权公众号'
        default:
          return '授权公众号'
      }
    },
  },
  methods: {
    ...mapMutations(['SET_AUTH_LINK', 'SET_STEP_CURRENT', 'SET_STATUS_DIALOG']),
    ...mapActions(['getAuthLink', 'goRelationApp']),
    pathTo (path) {
      this.$router.replace(path)
    },
    goAuth () {
      window.location.href = this.authLink
    },
    showGuideDia () {
      const isFirstAuth = storage.get(IS_FIRST_AUTH)
      if (!isFirstAuth) {
        this.isShowGuideDia = true
        storage.set(IS_FIRST_AUTH, 1)
      } else {
        this.pathTo('/home')
      }
    },
    closeServiceDialog (val) {
      this.showServiceDialog = val
    },
    closeStatusDialog (val) {
      this.SET_STATUS_DIALOG(val)
    },
    // selectedType (type) {
    //   this.isShowDetailIntroduce = true
    //   this.accountType = type
    // },
    // detaliBtnHandler ({ accountType, authUrl, authTaobaoCode }) {
    //   this.authUrl = authUrl
    //   this.authTaobaoCode = authTaobaoCode
    //   this.closeDetailDialog()
    //   if (accountType === 5515) {
    //     this.isShowAuthTaobaoDialog = true
    //   } else {
    //     this.SET_STEP_CURRENT(3)
    //   }
    // },
    // closeDetailDialog () {
    //   this.isShowDetailIntroduce = false
    // },
    // closeAuthTaobao () {
    //   this.isShowAuthTaobaoDialog = false
    //   this.authTaobaoStatus = null
    // },
    // authResultDialog () {
    //   this.isShowAuthResult = true
    // },
    // async getAuthResult () {
    //   const { status, data } = await authStatusPost({ auth_code: this.authTaobaoCode })
    //   if (status === 1) {
    //     this.authInfo = data.auth_info
    //     this.authAccountNum = data.total
    //     this.isShowAuthResult = false
    //     this.authTaobaoStatus = data.err_code === 0 ? 1 : 2
    //   } else {
    //     this.authTaobaoStatus = 2
    //     this.isShowAuthResult = false
    //   }
    // },
    // showAddPromoteDialog () {
    //   this.authTaobaoStatus = null
    //   this.isShowAddPromote = true
    // },
    // closeAddPromote () {
    //   this.isShowAddPromote = false
    //   this.SET_STEP_CURRENT(3)
    // }
  },
  created () {
    const authCode = this.$route.query.auth_code
    this.getAuthLink(authCode)
  }
}
</script>

<style lang="less" scoped>
.authorizer-main {
  position: relative;
  width: 100%;
  height: calc(100vh - 60px);
  background: url('./images/authorizer.png') center center no-repeat;
  background-size: 100% 100%;
  .main-wrap {
    position: absolute;
    top: 48px;
    left: 50%;
    transform: translateX(-50%);
    width: 900px;
    height: 565px;
    border-radius: 12px;
    padding: 48px 0;
    background: #FFFFFF;
    box-shadow: 0px 12px 24px 0px #1F5DD2;
    color: #5C6485;
    font-size: 20px;
    /deep/ .ant-steps {
      height: 61px;
      line-height: 30px;
      font-size: 20px;
      padding: 0 48px;
      border-bottom: 1px solid #EDF1F4;
      .ant-steps-item.ant-steps-item-active {
        .ant-steps-item-icon {
          background: #4384FC;
          border-color: #4384FC;
          .ant-steps-icon {
            color: #fff;
          }
        }
        .ant-steps-item-title {
          color: #4384FC !important;
          font-weight: 400;
        }
      }
      .ant-steps-item-container {
        height: 30px;
        .ant-steps-item-icon {
          width: 30px;
          height: 30px;
          line-height: 30px;
          font-size: 20px;
          margin-right: 12px;
          border-color: #5C6485;
          .ant-steps-icon {
            color: #5C6485;
          }
        }
        .ant-steps-item-content {
          height: 30px;
          .ant-steps-item-title {
            height: 30px;
            line-height: 30px;
            color: #5C6485;
            font-size: 20px;
            &::after {
              border-bottom: 1px dashed #A7BCE5;
              background: transparent;
            }
          }
        }
      }
    }
    .wrap-bottom {
      padding: 36px 48px 0;
      .top {
        height: 48px;
        line-height: 48px;
        padding-left: 60px;
        margin-bottom: 36px;
        font-size: 32px;
        color: #333847;
        background: url('./images/success.png') left center no-repeat;
        background-size: 48px 100%;
      }
      .item {
        line-height: 51px;
        margin-bottom: 12px;
        color: #5C6485;
        font-size: 28px;
      }
      &.step3 {
        height: 456px;
        border-radius: 0 0 12px 12px;
        background: #F3F6FC;
      }
    }
    .wrap-title {
      height: 45px;
      line-height: 45px;
      padding: 0 48px;
      margin-bottom: 24px;
      color: #333847;
      font-size: 32px;
      font-weight: 500;
    }
    .wrap-box {
      display: flex;
      padding: 0 48px;
      .title {
        height: 34px;
        line-height: 34px;
        margin-bottom: 24px;
        font-size: 24px;
        color: #333847;
      }
      .left {
        width: 375px;
      }
      .right {
        flex: 1;
      }
      .wrap-text {
        height: 34px;
        line-height: 34px;
        color: #5C6485;
        margin-bottom: 24px;
        padding-left: 44px;
        background: url('./images/icon-success.png') left center no-repeat;
        background-size: 30px 30px;
        &.wait {
          background: url('./images/icon-waitopen.png') left center no-repeat;
        background-size: 30px 30px;
        }
      }
    }
    .text {
      height: 34px;
      line-height: 34px;
      padding: 0 48px;
      color: #333847;
    }
  }
}
a.auth-btn {
  position: absolute;
  right: 48px;
  bottom: 48px;
  width: 200px;
  height: 60px;
  border-radius: 8px;
  background: #4392F6;
  text-align: center;
  line-height: 60px;
  color: #fff;
  // &.opc-3 {
  //   opacity: 0.3;
  // }
  &.btn {
    right: 280px;
    color: #4392F6;
    background: #fff;
    border: 1px solid #4392F6FF;
  }
}
.relation-error {
  color: #5C6485;
  font-size: 28px;
  line-height: 51px;
  .tips.ant-btn {
    height: 45px;
    border: none;
    box-shadow: none;
    padding: 0;
    color: #4384FC;
    /deep/ span {
      font-size: 28px;
    }
  }
  .icon {
    color: #333847;
    background: url('./images/icon-close.png') left 11px no-repeat;
    background-size: 30px 30px;
    text-indent: 42px;
    margin-bottom: 20px;
  }
}
img.code-img {
  width: 156px;
  height: 156px;
}
.result-main, .auth-success {
  position: fixed;
  top: 50%;
  left:50%;
  width:480px;
  height: 334px;
  background:#fff;
  border-radius:12px;
  padding: 63px 36px 36px;
  transform: translate(-50%,-50%);
  z-index: 4;
  color: #333;
  font-size: 20px;
  text-align: center;
  img {
    width: 74px;
    height: 86px;
    margin-bottom: 25px;
  }
  .text {
    height: 28px;
    left: 28px;
    margin-bottom: 36px;
  }
  .result-btn-wrap {
    display: flex;
    justify-content: space-between;
    .ant-btn {
      width: 192px;
      height: 60px;
      font-size: 20px;
      &:last-of-type {
        color: #4392F6;
        border-color: #4392F6;
      }
    }
  }
}
.auth-success {
  width: 762px;
  height: 440px;
  padding-top: 65px;
  img {
    width: 90px;
    height: 90px;
    margin-bottom: 24px;
  }
  .text {
    height: 45px;
    line-height: 45px;
    margin-bottom: 2px;
    font-size: 32px;
    color: #333;
  }
  .subtext {
    height: 24px;
    line-height: 24px;
    margin-bottom: 70px;
    color: #FF7A00;
    font-size: 18px;
  }
  .ant-btn {
    width: 248px;
    height: 60px;
    font-size: 20px;
  }
}
</style>

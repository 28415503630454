import axios from '@/utils/axios'

const API = {
  init: '/init/ajaxInit',
  agreeProtocol: '/protocol/ajaxagreeprotocol'
}

function initGlobalPagePost () {
  return axios.post(API.init)
}

function agreeProtocolPost (params) {
  return axios.post(API.agreeProtocol, params)
}

export {
  initGlobalPagePost,
  agreeProtocolPost
}

<template>
  <a-layout id="components-layout-demo-fixed-sider">
    <slider :shichi-switch="shichiSwitch" :account-type-switch="accountTypeSwitch" />
    <a-layout>
      <a-layout-header class="home-header">
        <div class="logo-small-box">
          <img :src="authInfo && authInfo.headimgurl ? authInfo.headimgurl : require('./images/default-avatar.png')" alt="">
          <span>{{ authInfo && authInfo.nickname ? authInfo.nickname : '' }}</span>
          <span style="height: 17px;line-height: 17px; margin-left: 12px; font-size: 12px; color: #999;">服务期至{{serviceEndDate}}</span>
        </div>
        <div class="user-pro">
          <a href="javascript:void(0);" class="user-item" @click="pathTo('/help')">
            <img src="./images/shouce.png" alt="">
            <span>使用手册</span>
          </a>
          <a href="javascript:void(0);" class="user-item" @click="phoneHandler">
            <img src="./images/icon-kefu.png" alt="">
            <span>服务顾问</span>
          </a>
          <a href="javascript:void(0);" class="user-item" @click="logOutHandler">
            <img src="./images/icon-close.png" alt="">
            <span>退出平台</span>
          </a>
        </div>
      </a-layout-header>
      <div class="space-tag"></div>
      <a-layout-content class="home-content">
        <router-view />
      </a-layout-content>
    </a-layout>
    <serviceDialog
      @closeServiceDialog="closeServiceDialog"
      :show-service-dialog="showServiceDialog"
    ></serviceDialog>
    <Agreement
      v-if="isShowAgreement"
      :log-out="logOut"
      @submitAgreement="submitAgreement"
    ></Agreement>
    <div class="wx-dialog txdialog" v-if="isShowWxRuleDialog">
      <a href="javascript:void(0);" class="close-dia-btn" @click="closeWxRuleDialog">关闭</a>
      <div class="title">微信新规相关变动公告</div>
      微信从12月9日起基于公众号消息原则进行了相关规则变更，对此满天星也在12月8日进行了相关功能与策略：
      <div class="text">1. 下线所有回复类模版并自动切换模版：</div>
      回复版欢迎语（自动切换到图文版）、菜单（仅保留并自动切换至小程序卡片版）
      <div class="text">2. 自动停用与下线与原则出入的功能：</div>
      吃饭提醒（暂时下线）、48小时活跃、大明神占卜
      <div class="text">3. 去除功能中的回复环节并自动生效：</div>
      签到、下单互动
      <div style="margin-top:30px;">以上更新在12月8日晚自动生效。</div>
    </div>
      <div class="mask" v-if="isShowWxRuleDialog"></div>


<!--STORY #91238::【SaaS满天星】自有联盟-淘宝联盟账号功能下线-->
    <div class="wx-dialog txdialog" v-if="isShowEleUnionRuleDialog">
      <a href="javascript:void(0);" class="close-dia-btn" @click="closeEleUnionRuleDialog">关闭</a>
      <div class="title">账户类型-淘宝联盟账户下线通知</div>
      由于淘宝联盟账户权限变更，满天星将从2022年8月31日进行相关功能进行调整：
      <div class="text">1、下线账户类型-淘宝联盟账户功能，用户不能再使用自己的淘宝联盟账号。<br/>账户将默认使用账户绑定的满天星系统的淘宝联盟账户，并通过满天星计算。 </div>
      <div class="text">2、已经使用了淘宝联盟账户的用户，将自动切换为满天星系统账户。</div>
      <div style="margin-top:30px;">以上更新将在2022年8月31日晚自动生效。</div>
    </div>
    <div class="mask" v-if="isShowEleUnionRuleDialog"></div>


    <!-- STORY #100307::【满天星】由于美团分销联盟停止运营，满天星的业务页面修改 -->
    <div class="wx-dialog txdialog" v-if="isShowMtUnionRuleDialog">
      <a href="javascript:void(0);" class="close-dia-btn" @click="closeMtUnionRuleDialog">关闭</a>
      <div class="title">账户类型-美团分销联盟账户下线通知</div>
      由于美团分销联盟政策变更，满天星将从2024年6月30日对相关功能进行调整：
      <div class="text">1、下线账户类型-美团分销联盟账户，用户不能在使用自己的美团分销联盟账户。<br/>账户将默认使用账户绑定的满天星系统的联盟账号，并通过满天星计算。 </div>
      <div class="text">已经使用了美团分销联盟账户的用户，将自动切换为满天星系统账户。</div>
      <div style="margin-top:30px;">以上更新将在2024年6月30日晚自动生效。</div>
    </div>
    <div class="mask" v-if="isShowMtUnionRuleDialog"></div>

  </a-layout>
</template>

<script>
import slider from '@/components/home/slider.vue'
import serviceDialog from '@/components/serviceDialog/serviceDialog'
import Agreement from './components/Agreement.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'Home',
  components: { slider, serviceDialog, Agreement },
  data () {
    return {
      showServiceDialog:false,
      isShowWxRuleDialog: false,
      isShowEleUnionRuleDialog:false,
      isShowMtUnionRuleDialog:false,
    }
  },
  computed: {
    ...mapGetters(['authInfo', 'serviceEndDate', 'isShowAgreement', 'shichiSwitch', 'accountTypeSwitch'])
  },
  methods: {
    ...mapMutations(['SET_ISSHOW_AGREEMENT']),
    ...mapActions(['logOut', 'initGlobalPage']),
    initPage () {
      this.isShowWxRuleDialog = localStorage.getItem('wxRule') == 1 ? false : true;
      this.isShowEleUnionRuleDialog = localStorage.getItem('eleUnionRule') == 1 ? false : true;
      this.isShowMtUnionRuleDialog = localStorage.getItem('mtUnionRule') == 1 ? false : true;
      if (this.isShowWxRuleDialog) {
        localStorage.setItem('wxRule', 1)
      }
      if (this.isShowEleUnionRuleDialog) {
        localStorage.setItem('eleUnionRule', 1)
      }
      if (this.isShowMtUnionRuleDialog) {
        localStorage.setItem('mtUnionRule', 1)
      }
    },
    phoneHandler () {
      this.showServiceDialog = true;
    },
    logOutHandler () {
      this.logOut().then(() => {
        this.$router.replace('/login')
      })
    },
    closeServiceDialog (val) {
      this.showServiceDialog = val
    },
    pathTo (path) {
      let routeUrl = this.$router.resolve({ path });
      window.open(routeUrl.href, '_blank');
    },
    submitAgreement (emitData) {
      this.SET_ISSHOW_AGREEMENT(emitData.isOpen)
    },
    closeWxRuleDialog () {
      this.isShowWxRuleDialog = false
    },
    closeEleUnionRuleDialog () {
      this.isShowEleUnionRuleDialog = false
    },
    closeMtUnionRuleDialog () {
      this.isShowMtUnionRuleDialog = false
    },
  },
  created () {
    this.initPage()
    this.initGlobalPage()
  }
}
</script>

<style lang="less" scoped>
.home-header {
  position: fixed;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100vw - 240px);
  min-width: 1200px;
  height: 60px;
  line-height: normal;
  font-size: 14px;
  color: #5C6485;
  background: #FEFFFF;
  padding-left: 24px;
  padding-right: 0;
  .logo-small-box {
    display: flex;
    align-items: center;
    color: #707793;
    img {
      width: 30px;
      height: 30px;
      margin-right: 12px;
    }
    span {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
    }
  }
  .user-pro {
    display: flex;
    .user-item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      border-left: 1px solid #EDF1F4;
      padding: 0 24px;
      box-sizing: border-box;
      color: #5C6485;
      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
      &:first-child {
        border: none;
        img {
        width: 18px;
        height: 18px;
        }
      }
    }
  }
}
.space-tag {
  height: 60px;
}
.home-content {
  height: calc(100vh - 60px);
  overflow-y: auto;
  padding: 60px;
  background: #F3F6FC;
}
.wx-dialog {
  width: 825px;
  height: 482px;
  background: #fff;
  padding: 49px 0 0 36px;
  color: #333;
  font-size: 14px;
  font-weight: 200;
  .title {
    height: 28px;
    text-align: center;
    line-height: 28px;
    font-size: 20px;
    color: #333;
    margin-bottom: 32px;
  }
  .text {
    color: #333;
    font-size: 14px;
    margin: 20px 0;
    font-weight: 700;
  }
  .close-dia-btn{
    top: 36px;
    right: 36ox;
    color: #333;
    &::after{
      top: 2px;
      right: -20px;
      width: 18px;
      height: 18px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiBAMAAADIaRbxAAAAG1BMVEUAAAAyMjIwMDAwMDAzMzMyMjIyMjIyMjIzMzOMyWXQAAAACHRSTlMA3yAQz4BwYCC2TscAAAB7SURBVCjPxdDBDYAgDAVQg3EAOfUIJno2bmDcwBVcwBVIODi2pPb7wwT0AD/wSGi75uVOC/NqYUjhu5EbRrzuMZspUZGTo6xEIEQgRCBEkxGi0QjRA8ITj4hXewo1yZsQ2XcjEDpCdyTojgTdkSj6J1ZIPbH+srDAtKsX7oIUKxBDeOgAAAAASUVORK5CYII=)no-repeat center;
      background-size: 100%;
      border: none;
      transform: none;
    }
  }
}
</style>

<template>
  <div class="module">
    <div class="title">{{ item.title }}</div>
    <div class="subtitle" v-html="item.subtitle"></div>
    <div class="image-box">
      <img :src="item.image" alt="">
    </div>
    <a href="javascript:void(0);" class="btn" @click="showPreviewDialog(item.id)">获取路径</a>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: (() => {})
    }
  },
  methods: {
    showPreviewDialog (id) {
      let emitData = {
        id,
        isShow: true
      }
      this.$emit('showPreviewDialog', emitData)
    }
  },
}
</script>

<style lang="less" scoped>
.module {
  width: 336px;
  height: 454px;
  padding: 36px 0;
  margin: 0 36px 36px 0;
  box-sizing: border-box;
  border-radius: 12px;
  background: #FFFFFF;
  box-shadow: 0px 8px 20px 0px #DCE3F0;
  &:nth-child(3n) {
    margin-right: 0;
  }
  .title {
    height: 28px;
    margin-bottom: 12px;
    line-height: 28px;
    text-align: center;
    font-weight: 700;
    color: #333333;
    font-size: 20px;
  }
  .subtitle {
    margin-bottom: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 14px;
    text-align: center;
    color: #666;
  }
  .image-box {
    width: 264px;
    height: 198px;
    margin: 0 auto 24px;
    border-radius: 6px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
  .btn {
    display: block;
    width: 264px;
    height: 48px;
    margin: 0 auto;
    font-size: 18px;
    color: #4392F6;
    text-align: center;
    line-height: 48px;
    border-radius: 4px;
    background: #F3F6FC;
  }
}
</style>
<template>
  <div v-if="transmitData.showPreview" class="dialog">
    <div class="mask"></div>
    <div
      :class="[
        'dia-content',
        transmitData.imgNum == 2
          ? 'center'
          : transmitData.imgNum == 3
          ? 'big'
          : '',
      ]"
    >
      <a
        href="javascript:void(0);"
        class="close-dia-btn"
        @click="closePreviewDia"
        >关闭</a
      >
      <dialogLeft
        v-for="item in dataList"
        :transmit-data="transmitData"
        :key="item.id"
        :item="item"
        class="dialog-left"
        id="dialogLeft"
      >
        <template #arrow v-if="transmitData.imgNum == 3">
          <div
            @click="scrollHandler('left')"
            v-if="showScrollArrow == 1"
            class="arrow arrow-left"
          ></div>
          <div
            @click="scrollHandler('right')"
            v-if="showScrollArrow == 2"
            class="arrow arrow-right"
          ></div>
        </template>
      </dialogLeft>
      <dialogRight
        :preview-id="transmitData.previewId"
        :size-status="sizeStatus"
        :is-open-random="isOpenRandom"
        :data-list="dataList"
        :fanli-rate="fanliRate"
        :selected-id="selectedId"
        :app-path="appPath"
        :closePreviewDia="closePreviewDia"
        @selectedOk="selectedOk"
      >
      </dialogRight>
    </div>
  </div>
</template>

<script>
import dialogLeft from "./dialogLeft";
import dialogRight from "./dialogRight";

export default {
  components: {
    dialogLeft,
    dialogRight,
  },
  props: {
    dataList: {
      type: Array,
    },
    transmitData: {
      type: Object,
      default: () => {},
    },
    /**
     * 预览弹窗右侧文字多少
     * 1. 欢迎语、每日签到、
     * 2. 综合互动
     */
    sizeStatus: {
      type: String,
      default: "",
    },
    fanliRate: {},
    selectedId: {
      default: 0,
    },
    appPath: {
      type: String,
      default: "",
    },
    isOpenRandom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showScrollArrow: 1,
    };
  },
  methods: {
    closePreviewDia() {
      this.$emit("closePreviewDia", false);
    },
    selectedOk(data) {
      this.$emit("selectedOk", data);
      this.closePreviewDia();
    },
    scrollHandler(val) {
      const dialogLeft = document.querySelector("#dialogLeft");
      if (val === "left") {
        dialogLeft.scrollLeft = dialogLeft.scrollWidth - dialogLeft.offsetWidth;
        this.showScrollArrow = 2;
      } else {
        dialogLeft.scrollLeft = 0;
        this.showScrollArrow = 1;
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.dialog {
  .dia-content {
    position: fixed;
    top: 150px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    width: 542px;
    height: 468px;
    padding: 36px 36px 0;
    box-sizing: border-box;
    border-radius: 12px;
    background: #ffffff;
    z-index: 3;
    .close-dia-btn{
      color: #333;
      &::after{
        top: 2px;
        right: -20px;
        width: 18px;
        height: 18px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiBAMAAADIaRbxAAAAG1BMVEUAAAAyMjIwMDAwMDAzMzMyMjIyMjIyMjIzMzOMyWXQAAAACHRSTlMA3yAQz4BwYCC2TscAAAB7SURBVCjPxdDBDYAgDAVQg3EAOfUIJno2bmDcwBVcwBVIODi2pPb7wwT0AD/wSGi75uVOC/NqYUjhu5EbRrzuMZspUZGTo6xEIEQgRCBEkxGi0QjRA8ITj4hXewo1yZsQ2XcjEDpCdyTojgTdkSj6J1ZIPbH+srDAtKsX7oIUKxBDeOgAAAAASUVORK5CYII=)no-repeat center;
        background-size: 100%;
        border: none;
        transform: none;
      }
    }
    .dialog-left {
      position: relative;
      display: flex;
      width: 214px;
      margin-right: 36px;
    }
    &.center {
      width: 780px;
      .dialog-left {
        width: 452px;
      }
    }
    &.big {
      width: 840px;
      .dialog-left {
        scroll-behavior: smooth;
        width: 527px;
        overflow-y: auto;
      }
    }
  }
}
</style>

<template>
  <div>
    <div class="authone">
      <div class="authone-text">
        <div class="icon" v-if="authStatus == 3 || authStatus == 4 || authStatus == 5"></div>
        {{ authText }}
      </div>
      <div class="error" v-if="authStatus == 3">您授权的账号不是微信服务号，请重新选择需要授权的账号</div>
      <div class="error" v-if="authStatus == 4">您授权的微信服务号还未认证，请前往微信公众平台完成认证</div>
      <div class="error" v-if="authStatus == 5">您的服务号未开启全部权限，请重新授权公众号，并勾选所有权限</div>
    </div>
    <div class="bottom-care">
      <div class="bottom-title">授权公众号注意：</div>
      <ol>
        <li>需授权已认证的服务号，且该服务号需开启全部权限</li>
        <li>必须是公众号绑定的管理员个人微信扫码授权，运营者无法授权</li>
        <li>每个公众号最多可以授权给5家第三方平台，超过后将无法授权</li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    authStatus: {
      default: 1
    }
  },
  computed: {
    authText () {
      switch (this.authStatus) {
        case 1:
          return '感谢您选择满天星，请开始授权公众号'
        case 2:
          return '您的授权已过期/您已取消授权，请再次授权公众号'
        case 3:
          return '公众号授权失败'
        case 4:
          return '公众号授权失败'
        case 5:
          return '公众号授权失败'
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.authone {
  height: 112px;
  .authone-text {
    display: flex;
    align-items: center;
    height: 39px;
    color: #333847;
    font-size: 28px;
    margin-bottom: 12px;
    .icon {
      width: 30px;
      height: 30px;
      background: url('../images/icon-danger.png') no-repeat;
      background-size: contain;
      margin-right: 12px;
    }
  }
  .error {
    height: 28px;
    line-height: 28px;
    color: #FF4141;
    font-size: 20px;
  }
}
.bottom-care {
  margin-bottom: 39px;
  .bottom-title {
    height: 30px;
    line-height: 30px;
    padding-left: 36px;
    margin-bottom: 23px;
    background: url('../images/clock.png') center left no-repeat;
    background-size: contain;
    color: #333847;
  }
  ol {
    padding-left: 26px;
    li {
      height: 28px;
      line-height: 28px;
      margin-bottom: 12px;
      font-size:20px;
      color:#999;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
    
</style>